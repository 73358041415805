import React, { Fragment } from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';

import { hideModalAction } from '../../../redux/actions';

const mapStateToProps = (state) => ({
    modal: state.modal,
    modalType: state.modal.currentModal.modalType,
    buttons: state.modal.currentModal.modalButtons,
    currentModal: state.modal.currentModal
});

const mapDispatchToProps = (dispatch) => ({
    hideModalAction: () => dispatch(hideModalAction())
});
type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const StandardModalContent = (props: Props) => {
    let body =
        (props.currentModal && props.currentModal.content && props.currentModal.content.body) || '';
    if (typeof body === 'string') {
        body = [body];
    }

    return (
        <Fragment>
            <ModalHeader toggle={() => props.hideModalAction()}>
                {props.currentModal.content?.title || 'Notice'}
            </ModalHeader>
            <ModalBody>
                {body.map((bod) => (
                    <p key={bod}>{bod}</p>
                ))}
            </ModalBody>
            <ModalFooter>
                {props.buttons &&
                    props.buttons.map((button) => (
                        <Button
                            color="primary"
                            key={button.text}
                            onClick={() => {
                                button.func();
                                props.hideModalAction();
                            }}
                        >
                            {button.text}
                        </Button>
                    ))}
            </ModalFooter>
        </Fragment>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(StandardModalContent);
