import React, { useEffect, useState } from 'react';
import { startCase } from 'lodash';
import moment from 'moment';

import { calcShorthandFromDates } from 'Dashboard/home/Context/helper';
import Select from '../../components/Select/Select';
import { options } from './constants';
import DateRangePicker from './DateRangePicker/DateRangePicker';
import './FilterByDate.scss';
import { changeShorthandToPicker } from './helpers';
import { SelectOption } from './types';

type Props = {
    startDate: string | null;
    setStartDate: Function;
    endDate: string | null;
    setEndDate: Function;
    pickerClassName: string;
    selectClassName: string;
    showHighLevel: boolean;
    dateFormat: string;
    onChange?: () => void;
};

const FilterByDate = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    pickerClassName,
    selectClassName,
    showHighLevel,
    dateFormat,
    onChange
}: Props): JSX.Element => {
    const [dateShortHandHasChanged, setDateShortHandHasChanged] = useState(false);
    const [shorthand, setShorthand] = useState<SelectOption>(() => {
        const value = calcShorthandFromDates(moment(), moment(startDate), moment(endDate));
        return { value, label: startCase(value).split('_').join(' ') };
    });

    // Change short hand when date range picker is changed
    useEffect(() => {
        if (!dateShortHandHasChanged && startDate !== null && endDate !== null) {
            const dateOptionValue = calcShorthandFromDates(
                moment(),
                moment(startDate),
                moment(endDate)
            );
            const dateOptionLabel = startCase(dateOptionValue);
            setShorthand({
                value: dateOptionValue,
                label: dateOptionLabel
            });
        }
        setDateShortHandHasChanged(false);
    }, [startDate, endDate]);

    return (
        <div className={showHighLevel ? 'dates-high-level' : 'dates-low-level'}>
            <div className="select-container">
                <span>Date</span>
                <DateRangePicker
                    dateFormat={dateFormat}
                    endDate={endDate || null}
                    endDateId="2"
                    onChange={onChange}
                    setEndDate={setEndDate}
                    setShorthand={setShorthand}
                    setStartDate={setStartDate}
                    startDate={startDate || null}
                    startDateId="1"
                    usePastDate
                />
            </div>

            <Select
                className="date-select"
                classNamePrefix="date-select"
                containerClassName="mb-0"
                isClearable={false}
                isSearchable={false}
                onChange={(newShorthand) => {
                    const { start, end } = changeShorthandToPicker(newShorthand);

                    setDateShortHandHasChanged(true);
                    setStartDate(start.format('YYYY/MM/DD'));
                    setEndDate(end?.format('YYYY/MM/DD'));
                    setShorthand(newShorthand);
                    if(onChange) onChange();
                }}
                options={options}
                placeholder="Select a preset date..."
                value={shorthand}
            />
        </div>
    );
};

export default FilterByDate;
