import classNames from 'classnames';
import React, { LegacyRef, useEffect, useRef, useState } from 'react';

interface Props {
    label: string;
    id: string;
    value: string;
    type: string;
    onChange?;
    onKeyDown?;
    className?: string;
    autoFocus?: boolean;
    // Allow custom parameters to be passed to input
    [x: string]: unknown;
}

const Input = ({
    label,
    id,
    value,
    type,
    onChange = () => {},
    onKeyDown = () => {},
    className = '',
    autoFocus,
    ...parameters
}: Props): JSX.Element => {
    const ref: LegacyRef<HTMLInputElement> | null = useRef(null);
    const [focus, setFocus] = useState(false);
    const [fill, setFill] = useState(!!(value?.length || ref?.current?.value));

    // Allow chrome autofill to trigger fill
    useEffect(() => {
        const interval = setInterval(() => {
            if (ref?.current?.matches(':-internal-autofill-selected')) {
                setFill(true);
                clearInterval(interval);
            }
        }, 300);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div
            className={classNames('input-container', focus && 'focus', fill && 'fill', className)}
            id={`${id}-container`}
        >
            <div className="notch">
                <label htmlFor="login-email"> {label} </label>
            </div>

            <input
                autoFocus={autoFocus}
                id={id}
                name={id}
                onBlur={() => {
                    setFocus(false);
                }}
                onChange={(e) => {
                    const { value } = e.target;
                    setFill(!!(value && value.length));
                    onChange(e);
                }}
                onFocus={() => {
                    setFocus(true);
                }}
                onKeyDown={onKeyDown}
                ref={ref}
                type={type}
                value={value}
                {...parameters}
            />
        </div>
    );
};

export default Input;
