import classNames from 'classnames';
import React, { useRef, useState } from 'react';

import Menu from 'components/Menu/Menu';
import './MenuTrigger.scss';
import { anchorStyles } from './helpers';

interface Props {
    Trigger;
    menu: { className; listItems };
}

const MenuTrigger = ({ menu: { className, listItems }, Trigger }: Props): JSX.Element => {
    const [open, setOpen] = useState(false);
    const triggerRef = useRef();

    return (
        <div style={{ position: 'relative' }}>
            <button
                className={classNames('menu-background', open ? 'active' : '')}
                onClick={() => {
                    setOpen(false);
                }}
            />
            <div
                onClick={() => {
                    setOpen((prev) => !prev);
                }}
            >
                {typeof Trigger === 'function' ? Trigger(triggerRef) : Trigger}
            </div>
            {open && (
                <Menu
                    anchor={anchorStyles({ y: 'top', x: 'left' }, triggerRef)}
                    className={className}
                    listItems={listItems}
                    setOpen={setOpen}
                />
            )}
        </div>
    );
};

export default MenuTrigger;
