import React from 'react';

import ItemCascade from './components/ItemCascade';
import ItemSingle from './components/ItemSingle';
import './List.scss';
import { ListItem } from './types';

interface Props {
    items: ListItem[];
    style?: {};
    setSubMenuPositions?: any;
    setOpen;
}

const List = ({ items, style = {}, setSubMenuPositions, setOpen }: Props): JSX.Element => {
    return (
        <ul className="list" style={style}>
            {items.map((item) => {
                if (!item.subItems) {
                    return (
                        <ItemSingle
                            key={item.label}
                            label={item.label}
                            onClick={() => {
                                setOpen(false);
                                item.onClick?.();
                            }}
                        />
                    );
                }

                return (
                    <ItemCascade
                        key={item.label}
                        label={item.label}
                        setSubMenuPositions={setSubMenuPositions}
                        subItems={item.subItems}
                    />
                );
            })}
        </ul>
    );
};

export default List;
