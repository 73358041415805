import React from 'react';

type Props = {
    sizeSelectorOpen: boolean;
    setSizeSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
    sizePerPage: number;
    sizePerPageOptions: number[];
    setSizePerPage: React.Dispatch<React.SetStateAction<number>>;
};

const CustomSizePerPageDropdown: React.FC<Props> = ({
    sizeSelectorOpen,
    setSizeSelectorOpen,
    sizePerPage,
    sizePerPageOptions,
    setSizePerPage
}) => {
    return (
        // DOCS: https://github.com/AllenFang/react-bootstrap-table/blob/master/src/pagination/SizePerPageDropDown.js
        // TODO make this complete. Create a separate page dropedown using the esisting classes. For some reason the onclick doesn't work on this element.

        <span
            className={`dropup ${
                sizeSelectorOpen ? 'open' : ''
            } my-size-per-page react-bs-table-sizePerPage-dropdown`}
        >
            <button
                aria-expanded={sizeSelectorOpen ? 'true' : 'false'}
                aria-haspopup={sizeSelectorOpen ? 'false' : 'true'}
                className="btn btn-default btn-primary dropdown-toggle"
                data-testid="table-sizes-dropdown"
                data-toggle="dropdown"
                id="pageDropDown"
                onClick={() => setSizeSelectorOpen(true)}
            >
                {sizePerPage >= 1000000 ? 'All' : sizePerPage}
                <span>
                    {' '}
                    <span className="caret" />
                </span>
            </button>
            <div
                aria-labelledby="pageDropDown"
                className={`dropdown-menu ${sizeSelectorOpen ? 'open show' : ''}`}
                role="menu"
            >
                {sizePerPageOptions.map(value => (
                    <button
                        children={value >= 1000000 ? 'All' : value}
                        className="dropdown-item"
                        data-testid="table-sizes-dropdown-item"
                        key={value}
                        onClick={() => {
                            setSizePerPage(value);
                            setSizeSelectorOpen(false);
                        }}
                    />
                ))}
            </div>
        </span>
    );
};

export default CustomSizePerPageDropdown;
