import React, { FunctionComponent } from 'react';
import { FormatCurrencyWithStore, FormatNumberWithStore } from 'components/Locales';
import get from 'lodash.get';
import { connect } from 'react-redux';

import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { addModalAction } from 'redux/actions';
import { IAppStoreState } from 'redux/interfaces';
import './CodesSummary.scss';
import Tooltip from 'components/Tooltip/Tooltip';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const CodesSummary: FunctionComponent<Props> = (props: Props) => {
    const combinedCurrentEntry = get(props, 'currentCode.combinedCurrentEntry', {});
    const { totalRevenue, totalAttempts, totalOrders, totalDiscount, validAttempts } =
        combinedCurrentEntry;
    const averageRevenue = totalRevenue / totalOrders;
    const averageDiscount = totalDiscount / totalOrders;

    const MyWidg = ({ title, tooltip, valA, valB }) => {
        return (
            <Card className="my-widg card--grow">
                <CardHeader className="no-divider">
                    <div className="title">
                        <span>{title}</span>
                        <Tooltip id={`tooltip-${title.replace(' ', '-')}`}>{tooltip}</Tooltip>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="body">
                        <div className="value">
                            <span>{valA.label}</span>
                            <span>{valA.value}</span>
                        </div>
                        {valB && (
                            <div className="value">
                                <span>{valB.label}</span>
                                <span>{valB.value}</span>
                            </div>
                        )}
                    </div>
                </CardBody>
            </Card>
        );
    };

    const stuff = [
        {
            title: 'Orders',
            tooltip: 'Total number of transactions placed by customers',
            valA: { label: 'Total:', value: FormatNumberWithStore(totalOrders) }
        },
        {
            title: 'Discounts',
            tooltip: 'The total discounts applied at the time of purchase',
            valA: { label: 'Total:', value: FormatCurrencyWithStore(totalDiscount) },
            valB: { label: 'Average:', value: FormatCurrencyWithStore(averageDiscount) }
        },
        {
            title: 'Revenue',
            tooltip: 'Revenue successfully generated from sales',
            valA: { label: 'Total:', value: FormatCurrencyWithStore(totalRevenue) },
            valB: { label: 'Average:', value: FormatCurrencyWithStore(averageRevenue) }
        },
        {
            title: 'Code Attempts',
            tooltip: 'Number of times the code has been used',
            valA: { label: 'Total:', value: FormatNumberWithStore(totalAttempts) },
            valB: {
                label: 'Valid:',
                value: validAttempts
            }
        }
    ];

    return (
        <>
            <Row>
                {stuff.map(({ title, tooltip, valA, valB }) => (
                    <Col key={title} md="3" xs="12">
                        <MyWidg title={title} tooltip={tooltip} valA={valA} valB={valB} />
                    </Col>
                ))}
            </Row>
        </>
    );
};

const mapStateToProps = (state: IAppStoreState) => ({
    currentCode: state.smartercodes.codes.currentCode
});

const mapDispatchToProps = (dispatch) => ({
    addModalAction: (a, b, c) => dispatch(addModalAction(a, b, c))
});

export default connect(mapStateToProps, mapDispatchToProps)(CodesSummary);
