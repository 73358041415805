import React, { createRef, useState } from 'react';
import { useSelector as uSel } from 'react-redux';

import DownloadWrapper from 'components/Charts/DownloadWrapper';
import LineChart from 'components/Charts/LineChart/LineChart';
import SpinnerCard from 'components/Spinner/SpinnerCard';
import { IAppStoreState as S } from 'redux/interfaces';
import { getDatasets, getOptions } from './helpers';

const CodesPerformanceTimeline = () => {
    const isLoading = uSel((state: S) => state.smartercodes.api.codeDetails.isLoading);
    const lineChartData =
        uSel((state: S) => state.smartercodes.codes.currentCode.lineChartData) || {};
    const lineChartMeta = uSel((state: S) => state.smartercodes.codes.lineChartMeta);
    const timezone = uSel((state: S) => state.user.settings.timezone);

    const [lineRef, setLineRef] = useState(createRef());
    const cardHeader = 'Performance Timeline';

    const datasets = getDatasets(lineChartData);
    const options = getOptions(lineChartData);

    if (isLoading) return <SpinnerCard />;
    if (!lineChartMeta.labels) return null;
    return (
        <div className="pt-2" style={{ width: '100%' }}>
            <DownloadWrapper canvasRef={lineRef} downloadName={cardHeader}>
                <LineChart
                    datasets={datasets}
                    height={200}
                    labels={lineChartMeta.labels}
                    options={options}
                    setRef={setLineRef}
                    timezone={timezone}
                />
            </DownloadWrapper>
        </div>
    );
};

export default CodesPerformanceTimeline;
