import { SelectOption } from './types';

export const customOption: SelectOption = { value: 'CUSTOM', label: 'Custom' };
export const todayOption: SelectOption = { value: 'TODAY', label: 'Today' };
export const yesterdayOption: SelectOption = { value: 'YESTERDAY', label: 'Yesterday' };
export const last3DaysOption: SelectOption = { value: 'LAST_3_DAYS', label: 'Last 3 Days' };
export const last7DaysOption: SelectOption = { value: 'LAST_7_DAYS', label: 'Last 7 Days' };
export const last30DaysOption: SelectOption = { value: 'LAST_30_DAYS', label: 'Last 30 Days' };
export const last60DaysOption: SelectOption = { value: 'LAST_60_DAYS', label: 'Last 60 Days' };
export const last90DaysOption: SelectOption = { value: 'LAST_90_DAYS', label: 'Last 90 Days' };
export const last365DaysOption: SelectOption = { value: 'LAST_365_DAYS', label: 'Last 365 Days' };
export const thisWeekOption: SelectOption = { value: 'THIS_WEEK', label: 'This Week' };
export const lastWeekOption: SelectOption = { value: 'LAST_WEEK', label: 'Last Week' };
export const thisMonthOption: SelectOption = { value: 'THIS_MONTH', label: 'This Month' };
export const lastMonthOption: SelectOption = { value: 'LAST_MONTH', label: 'Last Month' };
export const thisYearOption: SelectOption = { value: 'THIS_YEAR', label: 'Year to Date' };

export const options: SelectOption[] = [
    todayOption,
    yesterdayOption,
    thisWeekOption,
    lastWeekOption,
    last3DaysOption,
    last7DaysOption,
    thisMonthOption,
    lastMonthOption,
    last30DaysOption,
    last60DaysOption,
    last90DaysOption,
    thisYearOption,
    last365DaysOption
];
