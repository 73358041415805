import { ModalActionTypes } from '../actions';

const initialState = {
    executionQueue: [],
    isShow: false,
    currentModal: {}
};

export function modal(state: any = initialState, action) {
    const { type, payload } = action;
    const modalType = payload?.modalType;
    const queuePrev = state.executionQueue;

    switch (type) {
        case ModalActionTypes.ADD:
            const basePrecedence = modalType === 'loginModal' ? 1000 : 100;
            const uniquePrecedence = queuePrev.reduce(
              (acc, { precedence }) => {
                return precedence === acc ? acc + 1 : acc;
              },
              basePrecedence
            );
            const uniqueModal = { ...action.payload, precedence: uniquePrecedence };
            const queueSorted = [...queuePrev, uniqueModal].sort(
                (a, b) => a.precedence - b.precedence
            );

            return {
                ...state,
                executionQueue: queueSorted
            };

        case ModalActionTypes.SHOW:
            const lastIndex = queuePrev.length - 1;
            const queueNext = queuePrev.slice(0, lastIndex);
            const currentModal = queuePrev[lastIndex];

            return {
                ...state,
                executionQueue: queueNext,
                isShow: true,
                currentModal
            };

        case ModalActionTypes.HIDE:
            return {
                ...state,
                isShow: false,
                currentModal: {}
            };

        default:
            return state;
    }
}
