import React from 'react';
import { useSelector as uSel } from 'react-redux';
import { IAppStoreState as S } from 'redux/interfaces';

import ReactTable from 'components/ReactTable/ReactTable';
import { generateColumns } from './helpers';
import { Card, CardBody } from 'reactstrap';
import SpinnerCard from '../../../../../../../components/Spinner/SpinnerCard';

const CodesSources = (): JSX.Element => {
    const isLoading = uSel((state: S) => state?.smartercodes?.api?.codeDetails?.isLoading || false);
    const sourcesData = uSel((state: S) => state?.smartercodes?.codes?.currentCode?.sources || []);
    const tagName = uSel(
      (state: S) => state?.dashboard?.context?.tag?.tag_name
    );

    const downloadName = `Referrers${tagName ? ` - ${tagName}` : ''}`;

    if (isLoading) {
        return <SpinnerCard />;
    }

    return (
        <Card className="card-table">
            <CardBody>
                <ReactTable
                    columns={generateColumns()}
                    data={sourcesData}
                    download={downloadName}
                    pagination
                    search
                    sort={{ id: 'uniqueUsers', desc: true }}
                />
            </CardBody>
        </Card>
    );
};

export default CodesSources;
