import React from 'react';
import { useSelector as uSel } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { IAppStoreState as S } from 'redux/interfaces';
import { routes } from './SmarterCodes.routes';

const SmarterCodes = (): JSX.Element => {
    const pathname = uSel((state: any) => state.router?.location?.pathname);
    const landing = uSel((state: S) => state.user?.settings?.landing || 'overview');
    const safeLanding = landing.replace('smartercodes/', '');
    const isMatch = routes.find((route) => String(route.path ?? '').split('/')[1] === pathname.replace('/', '') );

    return (
        <>
            {!isMatch && (
                <Redirect to={{ pathname: `/${safeLanding}` }} />
            )}
            {routes.map((route) => {
                return (
                    <Route
                        component={route.component}
                        exact={route.isExact || false}
                        key={route.key}
                        path={`${route.path}`}
                    />
                );
            })}
        </>
    );
};

export default SmarterCodes;
