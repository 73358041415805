import {
    ISmartercodesState,
    TRevenueLostData,
} from '../smartercodes.reducers';

export default function apiDataRevenueLost(
    state: ISmartercodesState,
    rawApiData: TRevenueLostData,
) {
    let newState = { ...state };
    const revenueLostOutput = rawApiData?.output ?? [];

    newState.overview.revenueLost = revenueLostOutput[0].revenue_lost;

    return newState;
}
