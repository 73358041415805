import {
    changeMFAResetErrorAction,
    deleteMFAAction,
    updateMFAConfirmAction,
    updateMFAInitiateAction
} from 'Dashboard/views/Account/redux/account.actions';
import React, { Fragment, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, ModalBody, ModalHeader, Row } from 'reactstrap';
import { IAppStoreState } from 'redux/interfaces';
import { hideModalAction } from '../../../redux/actions';
import { ModalMFADelete } from './MFA/Delete';
import { ModalMFASetup } from './MFA/Setup';
const mapStateToProps = (state: IAppStoreState) => ({
    mfaActive: state.user.mfaActive,
    changeMfa: state.account.changeMfa
    /*buttonPrimary: state.modal.currentModal.modalButtons[0],
    buttonSecondary: state.modal.currentModal.modalButtons[1]*/
});

const mapDispatchToProps = dispatch => ({
    hideModalAction: () => dispatch(hideModalAction()),
    deleteMFAAction: p => dispatch(deleteMFAAction(p)),
    updateMFAInitiateAction: p => dispatch(updateMFAInitiateAction(p)),
    updateMFAConfirmAction: c => dispatch(updateMFAConfirmAction(c)),
    changeMFAResetErrorAction: () => dispatch(changeMFAResetErrorAction())
});

type Props = ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> & {
        add?: boolean;
        delete?: boolean;
    };
const StandardModalContent: FunctionComponent<Props> = (props: Props) => {
    // If the user has MFA already, only show them the delete confirmation.
    // IF no MFA, show the add MFA view.
    // Any errors are common and shown regardless.
    return (
        <Fragment>
            <ModalHeader toggle={() => props.hideModalAction()}>
                <i className="fa fa-qrcode mr-2"> </i>
                <span>
                    {props.add && 'Setup'}
                    {props.delete && 'Remove'} Multi-Factor Authentication
                </span>
            </ModalHeader>
            <ModalBody>
                {props.delete && (
                    <ModalMFADelete
                        changeMFAResetErrorAction={props.changeMFAResetErrorAction}
                        deleteMFAAction={props.deleteMFAAction}
                        requestInProcess={props.changeMfa.requestInProcess}
                        success={props.changeMfa.success}
                    />
                )}
                {props.add && (
                    <ModalMFASetup
                        QRCode={props.changeMfa.QRCode}
                        changeMFAResetErrorAction={props.changeMFAResetErrorAction}
                        haveQRCode={props.changeMfa.haveQRCode}
                        requestInProcess={props.changeMfa.requestInProcess}
                        success={props.changeMfa.success}
                        updateMFAConfirmAction={props.updateMFAConfirmAction}
                        updateMFAInitiateAction={props.updateMFAInitiateAction}
                    />
                )}
                <Row>
                    <Col xs={12}>
                        <Alert className="mt-4" color="danger" isOpen={props.changeMfa.isError}>
                            <i className="fa fa-times" />
                            <span className="ml-2">{props.changeMfa.currentError}</span>
                        </Alert>
                    </Col>
                </Row>
            </ModalBody>
        </Fragment>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StandardModalContent);
