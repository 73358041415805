import { ISmartercodesState, TCodeDetailsLineChart } from '../smartercodes.reducers';
import { IContext } from 'Dashboard/redux/dashboard.reducer';
import get from 'lodash.get';
import {
    getLineChartMeta,
    fillEmptyTimelineChart,
    getDatesFromApiResult,
    calcPercentFromTotal
} from 'utils';
import moment, { Moment } from 'moment';

/**
 * Increments the various lineChart fields based on which section the code's timestamp falls into
 */
export function incrementLineChart(
    rawItem: any,
    startDate: Moment,
    // TODO: don't use any
    lineChartMeta: any,
    lineChartData: any
) {

    const codeDate = new Date(rawItem.timestamp * 1000).getTime();
    const codeDiff = codeDate - startDate.valueOf();
    const index = Math.floor((codeDiff / lineChartMeta.dateDiff) * lineChartMeta.maxLabels);
    const status = get(rawItem, 'status', 0);
    const revenue = get(rawItem, 'revenue', 0);
    const basketDiscount = get(rawItem, 'basket_discount', 0);
    const deliveryDiscount = get(rawItem, 'delivery_discount', 0);
    let totalDiscount = 0;
    if (revenue > 0 && status > 0) {
        totalDiscount = basketDiscount + deliveryDiscount;
    }

    if (index >= 0 && index < lineChartMeta.maxLabels) {
        lineChartData.validCodeAttempts[index].y += status > 0 ? 1 : 0;
        lineChartData.invalidCodeAttempts[index].y += status <= 0 ? 1 : 0;
        // lineChart.validFullDiscount[index] += status === 1 ? 1 : 0;
        // lineChart.validBasketDiscount[index] += status === 2 ? 1 : 0;
        // lineChart.validDeliveryDiscount[index] += status === 3 ? 1 : 0;
        lineChartData.revenue[index].y += revenue;
        lineChartData.totalDiscount[index].y += totalDiscount;
    }
}

function incrementDailyBreakdown(rawItem, dailyEntries, timezoneOffset) {
    if (!rawItem.timestamp) {
        return;
    }
    const newDate = new Date(rawItem.timestamp * 1000 + timezoneOffset).setHours(0, 0, 0, 0) - timezoneOffset;
    const status = get(rawItem, 'status', 0);
    const revenue = get(rawItem, 'revenue', 0);
    const basketDiscount = get(rawItem, 'basket_discount', 0);
    const deliveryDiscount = get(rawItem, 'delivery_discount', 0);
    const totalDiscount = basketDiscount + deliveryDiscount;
    const uniqueUsers = get(rawItem, 'user_id', null);

    // TODO this will need checking over the time change, how does it cope.
    if (!dailyEntries[newDate]) {
        dailyEntries[newDate] = {
            datestamp: newDate,
            timestamp: rawItem.timestamp,
            key: rawItem.timestamp,
            valid: 0,
            invalid: 0,
            'validFullDiscount': 0,
            'validBasketDiscount': 0,
            'validDeliveryDiscount': 0,
            revenue: 0,
            'totalDiscount': 0,
            'uniqueUserCodes': [],
            'uniqueUsers': 0,
            'codeAttempts': 0,
            'validRate': 0
        };
    }

    dailyEntries[newDate].valid += status > 0 ? 1 : 0;
    dailyEntries[newDate].invalid += status <= 0 ? 1 : 0;
    dailyEntries[newDate]['validFullDiscount'] += status === 1 ? 1 : 0;
    dailyEntries[newDate]['validBasketDiscount'] += status === 2 ? 1 : 0;
    dailyEntries[newDate]['validDeliveryDiscount'] += status === 3 ? 1 : 0;
    dailyEntries[newDate]['revenue'] += revenue;
    dailyEntries[newDate]['totalDiscount'] += revenue > 0 && status > 0 ? totalDiscount : 0;
    dailyEntries[newDate]['uniqueUserCodes'].push(uniqueUsers);
    dailyEntries[newDate]['codeAttempts'] =
        dailyEntries[newDate].valid + dailyEntries[newDate].invalid;
}

function incrementSourcesBreakdown(rawItem: CodeDetail, sourcesMap) {
    const sourceHash = `${rawItem.channel_id}-${rawItem.source_id}-${rawItem.publisher_id}`;
    const status = rawItem.status ?? 0;
    const basketDiscount = rawItem.basket_discount ?? 0;
    const deliveryDiscount = rawItem.delivery_discount ?? 0;
    const revenue = rawItem.revenue ?? 0;
    const totalDiscount = basketDiscount + deliveryDiscount;

    // TODO this will need checking over the time change, how does it cope.
    if (!sourcesMap[sourceHash]) {
        sourcesMap[sourceHash] = {
            channelId: rawItem.channel_id,
            sourceId: rawItem.source_id,
            publisherId: rawItem.publisher_id,
            key: rawItem.timestamp,
            uniqueUsersHash: {},
            codeAttempts: 0,
            valid: 0,
            invalid: 0,
            'valid full discount': 0,
            'valid basket discount': 0,
            'valid delivery discount': 0,
            totalDiscount: 0,
            totalRevenue: 0
        };
    }

    sourcesMap[sourceHash].uniqueUsersHash[rawItem.user_id] = true;
    sourcesMap[sourceHash].codeAttempts += 1;
    sourcesMap[sourceHash].valid += status > 0 ? 1 : 0;
    sourcesMap[sourceHash].invalid += status <= 0 ? 1 : 0;
    sourcesMap[sourceHash]['valid full discount'] += status === 1 ? 1 : 0;
    sourcesMap[sourceHash]['valid basket discount'] += status === 2 ? 1 : 0;
    sourcesMap[sourceHash]['valid delivery discount'] += status === 3 ? 1 : 0;
    sourcesMap[sourceHash].totalDiscount += revenue > 0 && status > 0 ? totalDiscount : 0;
    sourcesMap[sourceHash].totalRevenue += revenue;
}

function incrementCountryBreakdown(rawItem: CodeDetail, countriesMap: any) {
    const countryHash = rawItem.country;

    if (!countriesMap[countryHash]) {
        countriesMap[countryHash] = {
            attempts: 0,
            countryCode: rawItem.country,
        };
    }

    countriesMap[countryHash].attempts += 1;
}

function incrementReferralsBreakdown(rawItem: CodeDetail, referralsMap: any) {
    const referralsHash = `referrer-${rawItem.referrer_id}`;
    const status = get(rawItem, 'status', 0);
    const basketDiscount = get(rawItem, 'basket_discount', 0);
    const deliveryDiscount = get(rawItem, 'delivery_discount', 0);
    const revenue = get(rawItem, 'revenue', 0);
    const totalDiscount = basketDiscount + deliveryDiscount;

    if (!referralsMap[referralsHash]) {
        referralsMap[referralsHash] = {
            referrer_id: rawItem.referrer_id,
            key: rawItem.timestamp,
            uniqueUsersHash: {},
            codeAttempts: 0,
            valid: 0,
            invalid: 0,
            'valid full discount': 0,
            'valid basket discount': 0,
            'valid delivery discount': 0,
            totalDiscount: 0,
            totalRevenue: 0
        };
    }

    referralsMap[referralsHash].uniqueUsersHash[rawItem.user_id] = true;
    referralsMap[referralsHash].codeAttempts += 1;
    referralsMap[referralsHash].valid += status > 0 ? 1 : 0;
    referralsMap[referralsHash].invalid += status <= 0 ? 1 : 0;
    referralsMap[referralsHash]['valid full discount'] += status === 1 ? 1 : 0;
    referralsMap[referralsHash]['valid basket discount'] += status === 2 ? 1 : 0;
    referralsMap[referralsHash]['valid delivery discount'] += status === 3 ? 1 : 0;
    referralsMap[referralsHash].totalDiscount += revenue > 0 && status > 0 ? totalDiscount : 0;
    referralsMap[referralsHash].totalRevenue += revenue;
}

function incrementDomainReferralsBreakdown(rawItem: CodeDetail, domainReferralsMap: any) {
    const domainReferralsHash = `domain-referrer-${rawItem.domain_referrer_id}`;
    const status = get(rawItem, 'status', 0);
    const basketDiscount = get(rawItem, 'basket_discount', 0);
    const deliveryDiscount = get(rawItem, 'delivery_discount', 0);
    const revenue = get(rawItem, 'revenue', 0);
    const totalDiscount = basketDiscount + deliveryDiscount;

    if (!domainReferralsMap[domainReferralsHash]) {
        domainReferralsMap[domainReferralsHash] = {
            domain_referrer_id: rawItem.domain_referrer_id,
            key: rawItem.timestamp,
            uniqueUsersHash: {},
            codeAttempts: 0,
            valid: 0,
            invalid: 0,
            'valid full discount': 0,
            'valid basket discount': 0,
            'valid delivery discount': 0,
            totalDiscount: 0,
            totalRevenue: 0
        };
    }

    domainReferralsMap[domainReferralsHash].uniqueUsersHash[rawItem.user_id] = true;
    domainReferralsMap[domainReferralsHash].codeAttempts += 1;
    domainReferralsMap[domainReferralsHash].valid += status > 0 ? 1 : 0;
    domainReferralsMap[domainReferralsHash].invalid += status <= 0 ? 1 : 0;
    domainReferralsMap[domainReferralsHash]['valid full discount'] += status === 1 ? 1 : 0;
    domainReferralsMap[domainReferralsHash]['valid basket discount'] += status === 2 ? 1 : 0;
    domainReferralsMap[domainReferralsHash]['valid delivery discount'] += status === 3 ? 1 : 0;
    domainReferralsMap[domainReferralsHash].totalDiscount +=
        revenue > 0 && status > 0 ? totalDiscount : 0;
    domainReferralsMap[domainReferralsHash].totalRevenue += revenue;
}

interface CodeDetail {
    timestamp: number;
    status: number;
    device_id: number;
    message_id: number;
    country: string;
    channel_id: number;
    source_id: number;
    publisher_id: number;
    user_id: number;
    landing_id: number;
    referrer_id: number;
    domain_referrer_id: number;
    basket_discount: number;
    delivery_discount: number;
    order_value_gbp: number;
    code_id: number;
    basket_contents: ({ title: string; } & Record<string, string>)[];
    revenue: number;
    basket_value: number;
}

interface CodeDetailsMap {
    channels: Record<string, string>;
    city: Record<string, number>;
    codes: Record<string, string>;
    country: Record<string, number>;
    domain_referrers: Record<number, string>;
    landings: Record<string, string | null>;
    messages: Record<number, string>;
    networks: Record<number, string>;
    publishers: Record<number, string>;
    referrers: Record<number, string | null>;
    region: Record<string, number>;
    sources: Record<number, string>;
    stats: {
        timestamp: number;
        status: number;
        device_id: number;
        landing_id: number;
        message_id: number;
        country_id: number;
        region_id: number;
        city_id: number;
        order_value_gbp: number;
        referrer_id: number;
        domain_referrer_id: number;
        basket_discount: number;
        delivery_discount: number;
        basket_percentage: number;
        delivery_percentage: number;
        publisher_id: number;
        network_id: number;
        user_id: number;
    }
}
interface CodeDetails {
    map: CodeDetailsMap,
    output: Array<CodeDetail>
}
export default function apiDataCodeDetails(
    state: ISmartercodesState,
    rawApiData: CodeDetails,
    context: IContext,
    extraParams: { timezone: string },
) {
    let newState = { ...state };
    const apiData = rawApiData?.output ?? [];
    const apiMap = rawApiData?.map ?? [];
    const timezone = extraParams.timezone;
    const { startDate, endDate } = getDatesFromApiResult(rawApiData, timezone);

    const lineChartMeta = getLineChartMeta(startDate, endDate);
    const lineChartData: TCodeDetailsLineChart = fillEmptyTimelineChart(startDate, endDate, {
        validCodeAttempts: [],
        invalidCodeAttempts: [],
        revenue: [],
        totalDiscount: []
    });

    // we loop the data only once to improve performance

    let dailyEntries: any = {};
    let sourcesMap: any = {};
    let countriesMap: any = {};
    let referralsMap: any = {};
    let domainReferralsMap: any = {};

    const attempts: Array<Object> = [];

    // calculate the time difference in the server return date and browser date
    // will be used in the calculation in the incrementDailyBreakdown
    const timezoneDate = moment(moment.utc(apiData[0]?.timestamp ?? 0 * 1000))
        .tz(timezone)
        .startOf('day')
        .valueOf();
    const browserDate = new Date(apiData[0]?.timestamp ?? 0 * 1000).setHours(0, 0, 0, 0);
    const timezoneOffset = browserDate - timezoneDate;

    apiData.forEach((rawItem, i) => {
        incrementLineChart(rawItem, startDate, lineChartMeta, lineChartData); // not a pure function, will modify map
        incrementDailyBreakdown(rawItem, dailyEntries, timezoneOffset);
        incrementSourcesBreakdown(rawItem, sourcesMap);
        incrementCountryBreakdown(rawItem, countriesMap);
        incrementReferralsBreakdown(rawItem, referralsMap);
        incrementDomainReferralsBreakdown(rawItem, domainReferralsMap);

        //code attempts
        const codeId = rawItem.code_id;
        const voucherCode = apiMap?.codes?.[codeId];
        const validity = rawItem.status;
        const messageId = rawItem.message_id;
        const message = apiMap?.messages?.[messageId];
        const channelId = rawItem.channel_id;
        const channel = apiMap?.channels[channelId];
        const sourceId = rawItem.source_id;
        const source = apiMap?.sources[sourceId];
        const publisherId = rawItem.publisher_id;
        const publisher = apiMap?.publishers[publisherId];
        const revenue = rawItem.revenue ?? 0;
        const basketValue = rawItem.basket_value ?? 0;
        const basket = rawItem.basket_contents;

        attempts.push({
            voucherCode,
            validity,
            message,
            channel,
            source,
            publisher,
            revenue,
            basket,
            basketValue,
            channelId,
            sourceId,
            publisherId,
            basketView: 'shopping_cart',
        });

        // TODO: add additional methods here for calculating other info
    });

    for (let date in dailyEntries) {
        if (dailyEntries.hasOwnProperty(date)) {
            dailyEntries[date]['uniqueUserCodes'] = Array.from(new Set(dailyEntries[date]['uniqueUserCodes']));
            dailyEntries[date]['uniqueUsers'] = dailyEntries[date]['uniqueUserCodes'].length;
            dailyEntries[date]['validRate'] = calcPercentFromTotal(
                dailyEntries[date].valid,
                dailyEntries[date].valid + dailyEntries[date].invalid
            );
        }
    }

    let map = get(rawApiData, 'map', {});

    let sources = Object.keys(sourcesMap).map((key: string) => ({
        ...sourcesMap[key],
        publisher: map?.publishers?.[sourcesMap?.[key]?.publisherId] ?? null,
        source: map?.sources?.[sourcesMap?.[key]?.sourceId] ?? null,
        channel: map?.channels?.[sourcesMap?.[key]?.channelId] ?? null,
        validPercent:
            (100 * sourcesMap[key].valid) / (sourcesMap[key].invalid + sourcesMap[key].valid),
        uniqueUsers: Object.keys(sourcesMap[key].uniqueUsersHash).length
    }));

    let countries = Object.values(countriesMap);

    let referrers = Object.keys(referralsMap).map((key: string) => ({
        ...referralsMap[key],
        referrer:
            typeof referralsMap[key].referrer_id === 'number'
                ? map.referrers[referralsMap[key].referrer_id]
                : null,
        validPercent:
            (100 * referralsMap[key].valid) / (referralsMap[key].invalid + referralsMap[key].valid),
        uniqueUsers: Object.keys(referralsMap[key].uniqueUsersHash).length
    }));

    const messagesObj = apiData.reduce((acc, { message_id, code_id, status: statusId }) => {
        const key = `${code_id}|${message_id}|${statusId}`;
        const prev = acc[key] ?? {};

        const prevAttempts = prev.attempts ?? 0;
        const attempts = prevAttempts + 1;
        const voucherCode = apiMap?.codes?.[code_id];
        const message = apiMap?.messages?.[message_id];
        const statusMessages = [
            'Invalid Code',
            'Full Discount',
            'Basket Discount',
            'Delivery Discount',
            'No Discount'
        ];
        const status = statusMessages[statusId];
        acc[key] = { voucherCode, message, attempts, status };
        return acc;
    }, {});
    const messages = Object.values(messagesObj);

    let domainReferrers = Object.keys(domainReferralsMap).map((key: string) => ({
        ...domainReferralsMap[key],
        domainReferrer:
            typeof domainReferralsMap[key].domain_referrer_id === 'number'
                ? map.domain_referrers[domainReferralsMap[key].domain_referrer_id]
                : 'Link not found',
        validPercent:
            (100 * domainReferralsMap[key].valid) /
            (domainReferralsMap[key].invalid + domainReferralsMap[key].valid),
        uniqueUsers: Object.keys(domainReferralsMap[key].uniqueUsersHash).length
    }));

    const codesMap = rawApiData?.map?.codes ?? {};

    const identity: Array<{ name: string, codeId: number }> = [];
    const combinedCurrentEntry = {
        totalRevenue: 0,
        totalAttempts: 0,
        totalOrders: 0,
        totalDiscount: 0,
        validAttempts: 0,
        invalidAttempts: 0,
        minDiscount: 0,
        maxDiscount: 0,
        totalRevenueWithDiscounts: 0,
        totalOrdersWithDiscounts: 0,
        totalDiscountPercentage: 0
    };
    for (let codeId in codesMap) {//Object.keys(codesMap).map((codeId) => {
        if (!codesMap.hasOwnProperty(codeId)) {
            //The current property is not a direct property of p
            continue;
        }
        //Do your logic with the property here
        const codeEntry = codesMap[codeId];
        identity.push({
            name: codeEntry,
            codeId: Number(codeId)
        });
        const code = state.codes.combinedEntries[codeId];
        getCombinedCurrentEntryCalculation(code, combinedCurrentEntry);
    };

    newState.codes.currentCode = {
        ...newState.codes.currentCode,
        codeDetails: Object.values(dailyEntries).sort(
            (a: any, b: any) => b.datestamp - a.datestamp
        ),
        lineChartData,
        sources,
        countries,
        referrers,
        domainReferrers,
        combinedCurrentEntry: { ...combinedCurrentEntry, identity },
        messages,
        attempts
    };
    newState.codes.lineChartMeta = lineChartMeta;

    return newState;
}

const getCombinedCurrentEntryCalculation = (code, combinedCurrentEntry) => {
    if (code) {
        combinedCurrentEntry.totalRevenue += code.totalRevenue;
        combinedCurrentEntry.totalAttempts += code.totalAttempts;
        combinedCurrentEntry.totalOrders += code.totalOrders;
        combinedCurrentEntry.totalDiscount += code.totalDiscount;
        combinedCurrentEntry.validAttempts += code.validAttempts;
        combinedCurrentEntry.invalidAttempts += code.invalidAttempts;
        combinedCurrentEntry.minDiscount =
            code.minDiscount < combinedCurrentEntry.minDiscount
                ? code.minDiscount
                : combinedCurrentEntry.minDiscount;
        combinedCurrentEntry.maxDiscount =
            code.maxDiscount > combinedCurrentEntry.maxDiscount
                ? code.maxDiscount
                : combinedCurrentEntry.maxDiscount;
        combinedCurrentEntry.totalRevenueWithDiscounts += code.totalRevenueWithDiscounts;
        combinedCurrentEntry.totalOrdersWithDiscounts += code.totalOrdersWithDiscounts;
        combinedCurrentEntry.totalDiscountPercentage = calcPercentFromTotal(
            combinedCurrentEntry.totalDiscount,
            combinedCurrentEntry.totalRevenue
        );
    } else {
        combinedCurrentEntry.minDiscount = 0;
        combinedCurrentEntry.totalDiscountPercentage = calcPercentFromTotal(
            combinedCurrentEntry.totalDiscount,
            combinedCurrentEntry.totalRevenue
        );
    }
};

export function getCombinedCurrentEntry(selectedCodes) {
    const combinedCurrentEntry = {
        totalRevenue: 0,
        totalAttempts: 0,
        totalOrders: 0,
        totalDiscount: 0,
        validAttempts: 0,
        invalidAttempts: 0,
        minDiscount: 0,
        maxDiscount: 0,
        totalRevenueWithDiscounts: 0,
        totalOrdersWithDiscounts: 0,
        totalDiscountPercentage: 0
    };

    selectedCodes.forEach((code) => {
        getCombinedCurrentEntryCalculation(code, combinedCurrentEntry);
    });

    return combinedCurrentEntry;
}
