import get from 'lodash.get';
import React, { FC, Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, ModalBody, ModalHeader, Row, Button } from 'reactstrap';

import Table from '../../Table/Table';
import { hideModalAction } from '../../../redux/actions';
import { setCurrentCodeAction } from 'Dashboard/views/SmarterCodes/redux/smartercodes.actions';

// Helper Functions
/*================================================================================================*/
const alphaNumericSort = (myArray) => {
    var a,
        b,
        a1,
        b1,
        rx = /(\d+)|(\D+)/g,
        rd = /\d+/;
    return myArray.sort(function (as, bs) {
        a = String(as.name).toLowerCase().match(rx);
        b = String(bs.name).toLowerCase().match(rx);
        while (a.length && b.length) {
            a1 = a.shift();
            b1 = b.shift();
            if (rd.test(a1) || rd.test(b1)) {
                if (!rd.test(a1)) return 1;
                if (!rd.test(b1)) return -1;
                if (a1 !== b1) return a1 - b1;
            } else if (a1 !== b1) return a1 > b1 ? 1 : -1;
        }
        return a.length - b.length;
    });
};

const selectedSort = (allCodes, selectedCodes) => {
    const nonRedundantAllCodes: any = [];
    const detailedSelectedCodes: any = [];

    // Remove selected codes from
    for (let i = 0; i < allCodes.length; i++) {
        const allCode = allCodes[i];
        let isSelected = false;

        for (let i = 0; i < selectedCodes.length; i++) {
            const selectedCode = selectedCodes[i];

            if (allCode.name === selectedCode.name) {
                detailedSelectedCodes.push(allCode);
                isSelected = true;
            }
        }

        if (isSelected === false) {
            nonRedundantAllCodes.push(allCode);
        }
    }

    // Get complete list of all selected codes
    const completedSelectedCodes: any = [];
    for (let i = 0; i < selectedCodes.length; i++) {
        const selectedCode = selectedCodes[i];
        let isMatch = false;

        for (let i = 0; i < detailedSelectedCodes.length; i++) {
            const detailedSelectedCode = detailedSelectedCodes[i];

            if (selectedCode.name === detailedSelectedCode.name) {
                isMatch = true;
                completedSelectedCodes.push(detailedSelectedCode);
            }
        }

        if (isMatch === false) {
            completedSelectedCodes.push({ ...selectedCode, key: selectedCode.name });
        }
    }

    const finalArray: any = [];

    finalArray.unshift(...nonRedundantAllCodes);
    finalArray.unshift(...alphaNumericSort(completedSelectedCodes));

    return finalArray;
};

// Redux
/*================================================================================================*/
const mapStateToProps = (state) => ({
    modal: state.modal,
    modalType: state.modal.currentModal.modalType,
    codes: state.smartercodes.codes,
    identity: get(state.smartercodes.codes, 'currentCode.combinedCurrentEntry.identity', [])
});

const mapDispatchToProps = (dispatch) => ({
    hideModalAction: () => dispatch(hideModalAction()),
    setCurrentCodeAction: (a) => dispatch(setCurrentCodeAction(a))
});

// Types
/*================================================================================================*/
type Props = {
    hideModalAction: Function;
    setCurrentCodeAction: Function;
    codes?;
    identity?: Object;
};

// Component
/*================================================================================================*/
const StandardModalContent: FC<Props> = ({
    codes,
    identity,
    hideModalAction,
    setCurrentCodeAction
}: Props) => {
    const [tableData, setTableData] = useState([]);
    const [codesToRequest, setCodesToRequest]: any[] = useState(identity || []);

    const codesArray = alphaNumericSort(
        Object.values(codes?.combinedEntries || {}).map((entry: any) => ({
            ...entry,
            key: entry.name
        }))
    );

    useEffect(() => {
        // re-sync identity if it changes
        setCodesToRequest(identity);

        setTableData(selectedSort(codesArray, codesToRequest));
    }, [identity]);

    return (
        <Fragment>
            <ModalHeader toggle={() => hideModalAction()}>
                <span>Select a Code:</span>
            </ModalHeader>
            <ModalBody className="modal-code-select">
                <Row>
                    <Col className="text-center align-self-center" xs="12">
                        <div className="hide-table-header">
                            <Table
                                checkboxAllCallback={function () {
                                    setCodesToRequest(
                                        codesArray.map(({ name, codeId }) => ({
                                            name,
                                            codeId
                                        }))
                                    );
                                }}
                                checkboxCallback={function (row, isChecked) {
                                    if (isChecked) {
                                        setCodesToRequest([
                                            ...codesToRequest,
                                            { codeId: row.codeId, name: row.name }
                                        ]);
                                    } else {
                                        let newArray = [...codesToRequest];
                                        newArray = newArray.filter(
                                            (item) => item.codeId !== row.codeId
                                        );
                                        setCodesToRequest(newArray);
                                    }
                                }}
                                checkboxClearCallback={function () {
                                    setCodesToRequest([]);
                                }}
                                checkboxPrefill={codesToRequest.map((code) => code.name)}
                                headers={[{ text: 'name', hidden: true }]}
                                pagination
                                search
                                selectedCheckboxes={codesToRequest}
                                sizesPerPage={[10]}
                                striped
                                tableData={tableData}
                                toolBar="fullSearch"
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="submit-container text-center align-self-center" xs="12">
                        <Button
                            className="btn btn-lg btn-block"
                            color="primary"
                            disabled={!codesToRequest.length}
                            onClick={(e) => {
                                setCurrentCodeAction(codesToRequest);
                                hideModalAction();
                            }}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Fragment>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(StandardModalContent);
