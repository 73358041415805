import { cellCurrency, cellNumber, cellPercent, cellValue } from 'components/ReactTable/utils';
import { ColumnDef } from '@tanstack/table-core';

type dataValue = Record<string, string | number>;
type GenerateColumns = () => (ColumnDef<dataValue, string> | ColumnDef<dataValue, number>)[];

export const generateColumns: GenerateColumns = () => [
  {
    id: 'domainReferrer',
    accessorKey: 'domainReferrer',
    header: 'Domain Referrer Link',
    cell: cellValue
  },
  {
    id: 'uniqueUsers',
    accessorKey: 'uniqueUsers',
    header: 'Users',
    cell: cellNumber,
    meta: { align: 'right' }
  },
  {
    id: 'codeAttempts',
    accessorKey: 'codeAttempts',
    header: 'Code Attempts',
    cell: cellNumber,
    meta: { align: 'right' }
  },
  {
    id: 'valid',
    accessorKey: 'valid',
    header: 'Valid',
    cell: cellNumber,
    meta: { align: 'right' }
  },
  {
    id: 'invalid',
    accessorKey: 'invalid',
    header: 'Invalid',
    cell: cellNumber,
    meta: { align: 'right' }
  },
  {
    id: 'validPercent',
    accessorKey: 'validPercent',
    header: 'Valid Rate',
    cell: cellPercent,
    meta: {
      align: 'right',
      from: ['valid', 'codeAttempts']
    }
  },
  {
    id: 'totalDiscount',
    accessorKey: 'totalDiscount',
    header: 'Total Discount',
    cell: cellCurrency,
    meta: { align: 'right' }
  },
  {
    id: 'totalRevenue',
    accessorKey: 'totalRevenue',
    header: 'Revenue',
    cell: cellCurrency,
    meta: { align: 'right' }
  }
];
