import React from 'react';
import DownloadDropdown from '../DownloadDropdown/DownloadDropdown';
import { ExportType } from './Table';

type Props = {
    exportTypes: {
        CSV: ExportType;
        TSV: ExportType;
    };
    setExportType: React.Dispatch<React.SetStateAction<ExportType>>;
    onClick: Function;
};

const ExportCSVButton: React.FC<Props> = ({ exportTypes, setExportType, onClick }) => {
    return (
        <>
            <DownloadDropdown
                className="float-right pb-2"
                dropdown={[
                    {
                        key: 1,
                        text: 'CSV',
                        onClick: async () => {
                            await setExportType(exportTypes.CSV);
                            onClick();
                        }
                    }
                    // {
                    //     key: 2,
                    //     text: 'TSV',
                    //     onClick: async () => {
                    //         await setExportType(exportTypes.TSV);
                    //         onClick();
                    //     }
                    // }
                ]}
            />
            <div className="clearfix" />
        </>
    );
};

export default ExportCSVButton;
