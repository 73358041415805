export interface RequestOptions extends RequestInit {
    headers: Headers;
}
export interface blankReq {
    requestOptions: RequestOptions;
    url: string;
    open: boolean;
    critical: boolean;
}

export interface IUserAuthLogin {
    email: string;
    password: string;
    mfa?: string;
}

export type BasicResponse = {
    result: string;
    errors?: string[];
    time_taken: number;
    success?: string;
};

export type UserAuthLoginResponse = User &
    BasicResponse & {
        key: string;
        secret: string;
        last_login: string; //YYYY-MM-DD HH:mm:ss
    };

export enum SupportedLocales {
    ENGB = 'en-gb'
}
export enum SupportedCurrencies {
    GBP = 'GBP'
}
export interface UserDataPreferences {
    currency: SupportedCurrencies;
    locale: SupportedLocales;
}
export interface IUserSettings {
    profile_image: string;
    currency: string;
    currency_symbol: string;
    landing: string;
    date: string;
    logo: string;
    banner: string;
    timezone: string;
    currency_rate: number;
}

export type UserSetupMfaResponse = BasicResponse & {
    qr_code: string;
};
export type User = {
    client_id: string;
    client_name: string;
    client_email: string;
};
export type Tag = {
    tag_index: number;
    active: 0 | 1;
    tag_name: string;
};
export type Publisher = {
    publisher_id: string;
    name: string;
};
export type Network = {
    network_id: string;
    name: string;
};
export type UserDataResponse = User &
    IUserSettings &
    BasicResponse & {
        data: {
            last_login: string; //YYYY-MM-DD HH:mm:ss
            user_type: 'smarterclick' | 'client';
            tags: Tag[];
            publishers: Publisher[];
            networks: Network[];
            settings: IUserSettings;
        }
    };

export type UserSettingsResponse = BasicResponse & {
    settings: IUserSettings;
};
export type UserListResponse = BasicResponse & {
    [userId: string]: User;
};
export interface IUserAuthChangePassword {
    current: string;
    new: string;
    confirm: string;
}
export interface IUserAuthResetPassword {
    token: string;
    password: string;
    'password-confirm': string;
}
export interface IUserAuthForgotPassword {
    email: string;
    domain: string;
}
class APIService {
    private key: string | undefined;
    private secret: string | undefined;
    private blank = () => {
        let headers = new Headers();
        headers.set('Content-Type', 'application/json');
        headers.set('accept', 'application/vnd.smarterclick.v2+json');
        this.next = {
            requestOptions: {
                headers
            },
            url: process.env.REACT_APP_API_URL || 'https://rc.api.smct.co',
            open: false,
            critical: false
        };

        return this.next;
    };
    private next: blankReq = this.blank();

    private authFailAction: any;
    private failAction: any;
    public setCredentials = (key: string, secret: string) => {
        this.key = key;
        this.secret = secret;
    };
    public setAuthFailAction = (authFailAction) => {
        this.authFailAction = authFailAction;
    };
    public setFailAction = (failAction) => {
        this.failAction = failAction;
    };
    public clear = () => {
        this.key = this.secret = this.authFailAction = undefined;
    };

    subordinate = () => {
        this.blank();
        this.next.url += '/users/list';
        return {
            get: this.get
        };
    };

    user = () => {
        this.blank();

        this.next.url += '';
        return this.userDirs;
    };

    private authDirs = {
        login: (params: IUserAuthLogin): Promise<UserAuthLoginResponse> => {
            this.next.url += '/authenticate';
            this.next.open = true;
            this.next.critical = true;
            return this.post(JSON.stringify(params));
        },
        changePassword: (params: IUserAuthChangePassword): Promise<BasicResponse> => {
            this.next.url += '/change-password';
            this.next.critical = true;
            return this.patch(JSON.stringify(params));
        },
        resetPassword: (params: IUserAuthResetPassword): Promise<BasicResponse> => {
            this.next.url += '/reset-password';
            this.next.open = true;
            this.next.critical = true;
            return this.post(JSON.stringify(params));
        },
        forgotPassword: (params: IUserAuthForgotPassword): Promise<BasicResponse> => {
            this.next.url += '/forgot-password';
            this.next.open = true;
            this.next.critical = true;
            return this.post(JSON.stringify(params));
        },
        mfa: (): {
            get: () => Promise<BasicResponse & { active: number }>;
            delete: (password: string) => Promise<BasicResponse>;
        } => {
            this.next.url += '/mfa';
            this.next.critical = true;
            return {
                get: this.get,
                delete: (password) => this.delete(JSON.stringify({ password }))
            };
        },
        setupMfa: (): {
            post: (password: string) => Promise<UserSetupMfaResponse>;
            patch: (mfa_code: string) => Promise<UserSetupMfaResponse>;
        } => {
            this.next.url += '/setup-mfa';
            this.next.critical = true;
            return {
                post: (password) => this.post(JSON.stringify({ password })),
                patch: this.patch
            };
        }
    };
    private userDirs = {
        data: (
            id = null
        ): {
            get: () => Promise<UserDataResponse>;
            patch: (params: Partial<IUserSettings>) => Promise<UserDataResponse>;
        } => {
            this.next.url += '/user-data';
            if (id !== null) {
                this.next.url += `/${id}`;
            }
            return {
                get: this.get,
                patch: (params: Partial<IUserSettings>) => this.patch(JSON.stringify(params))
            };
        },
        image: (): {
            post: (params: any) => Promise<UserSettingsResponse>;
        } => {
            this.next.url += '/user/upload-image';
            return {
                post: (params: any) => this.formPost(params)
            };
        },
        settings: (
            id = null
        ): {
            post: (params: Partial<IUserSettings>) => Promise<UserSettingsResponse>;
        } => {
            this.next.url += '/user-settings';
            if (id !== null) {
                this.next.url += `/${id}`;
            }
            return {
                post: (params: Partial<IUserSettings>) =>
                    this.post(JSON.stringify({ settings: params }))
            };
        },
        auth: () => this.authDirs,
        currencyList: (
            id = null
        ): {
            get: () => Promise<UserDataResponse>;
            patch: (params: Partial<IUserSettings>) => Promise<UserDataResponse>;
        } => {
            this.next.url += '/currencies';
            if (id !== null) {
                this.next.url += `/${id}`;
            }
            return {
                get: this.get,
                patch: (params: Partial<IUserSettings>) => this.patch(JSON.stringify(params))
            };
        }
    };

    reports = () => {
        this.blank();
        this.next.url += '/reports';
        return this.reportsDir;
    };

    private reportsDir = {
        smartercodes: ({ path, body }) => {
            this.next.url += `/smartercodes${path}`;

            return this.post(body);
        },
        notifications: () => {
            return {
                get: this.get
            };
        },
        overlays: ({ path, body }) => {
            this.next.url += `/overlays${path}`;

            return this.formPost(body);
        }
    };

    overlays = () => {
        this.blank();
        this.next.url += '/';
    };

    // private body = (body: string) => {
    //     this.next.requestOptions.body = body;
    //     return this;
    // };

    private addAuthHeaders = () => {
        if (!this.next.open) {
            if (!this.key || !this.secret) {
                const authenticate =
                    localStorage.getItem('authenticate') || sessionStorage.getItem('authenticate');

                if (authenticate) {
                    const parsed = JSON.parse(authenticate);
                    if (parsed.key && parsed.secret && this.next.requestOptions) {
                        this.setCredentials(parsed.key, parsed.secret);
                        this.next.requestOptions.headers.set('x-api-key', parsed.key);
                        this.next.requestOptions.headers.set('x-api-secret', parsed.secret);
                        return;
                    }
                }
                return Promise.reject('Missing key or secret for a closed API endpoint');
            }
            if (this.next.requestOptions.headers) {
                this.next.requestOptions.headers.set('x-api-key', this.key);
                this.next.requestOptions.headers.set('x-api-secret', this.secret);
            }
        }
    };

    private delete = (body: string) => {
        this.addAuthHeaders();
        this.next.requestOptions.method = 'DELETE';
        this.next.requestOptions.body = body || '';
        return fetch(this.next.url.toString(), this.next.requestOptions).then(this.handleResponse);
    };
    private get = () => {
        this.addAuthHeaders();
        return fetch(this.next.url.toString(), this.next.requestOptions).then(this.handleResponse);
    };
    private post = (body: any) => {
        this.addAuthHeaders();
        this.next.requestOptions.method = 'POST';
        this.next.requestOptions.body = body || '';
        return fetch(this.next.url.toString(), this.next.requestOptions).then(this.handleResponse);
    };
    private formPost = (body: any) => {
        this.addAuthHeaders();
        this.next.requestOptions.method = 'POST';
        this.next.requestOptions.body = body || '';
        this.next.requestOptions.headers.delete('Content-Type');
        return fetch(this.next.url.toString(), this.next.requestOptions).then(this.handleResponse);
    };
    private patch = (body: string) => {
        this.addAuthHeaders();
        this.next.requestOptions.method = 'PATCH';
        this.next.requestOptions.body = body || '';
        return fetch(this.next.url.toString(), this.next.requestOptions).then(this.handleResponse);
    };

    private handleResponse = (response) => {
        // console.log('response',response);
        return response.text().then((text) => {
            try {
                let data: any = {};
                if (text && text.length > 1) {
                    data = JSON.parse(text);
                }

                if (!response.ok || data.result === 'error') {
                    if (data.errors && data.errors[0]) {
                        throw new Error(data.errors[0]);
                    } else {
                        throw new Error(response.statusText);
                    }
                }

                //delete data.result;
                delete data.time_taken;
                return data;
            } catch (error) {
                // @ts-ignore
                return Promise.reject({ response, errorMessage: error.message });
            }
        });
    };
}

let API = new APIService();
export { API };
