import { OverlaysState, OverlayRealtimeData } from '../overlays.reducers';
import { IContext } from 'Dashboard/redux/dashboard.reducer';
import moment from 'moment';

export type RealtimeFunnelData = {
    uniqueVisitors: number;
    newVisitors: number;
    repeatVisitors: number;
    overlaysShown: number;
    engagement: number;
};

export type RealtimeGraphData = {
    dates: string[];
    impressions: number[];
    shown: number[];
    engagements: number[];
};

export function apiOverlayRealtimeData(
    state: OverlaysState,
    rawApiData: OverlayRealtimeData,
    context: IContext,
    extraParams: { pixel_table_number: number }
) {
    const {
        output: { funnel, realtime }
    } = rawApiData;

    let engagement = 0;
    let overlaysShown = 0;
    let uniqueVisitors = 0;
    let repeatVisitors = 0;

    funnel.forEach(item => {
        engagement += item['Engagement'];
        overlaysShown += item['Overlays Shown'];
        uniqueVisitors += item['Unique Users'];
        repeatVisitors += item['Repeat Users'];
    });

    const funnelData: RealtimeFunnelData = {
        engagement,
        overlaysShown,
        uniqueVisitors,
        repeatVisitors,
        newVisitors: uniqueVisitors - repeatVisitors
    };

    const chartData: RealtimeGraphData = {
        dates: [],
        impressions: [],
        shown: [],
        engagements: []
    };

    const sortedRealtimeData = realtime.sort((a, b) => Date.parse(a.last) - Date.parse(b.last));

    sortedRealtimeData.forEach(item => {
        if (extraParams.pixel_table_number === 3) {
            chartData.dates.push(moment(item.last).format('DD/MM'));
        } else {
            chartData.dates.push(moment(item.last).format('hh:mm'));
        }
        chartData.impressions.push(item.script_access);
        chartData.shown.push(item.overlay_shown);
        chartData.engagements.push(item.engagement);
    });

    const newState: OverlaysState = {
        ...state,
        realtimeData: {
            ...state.realtimeData,
            [extraParams.pixel_table_number]: {
                funnelData,
                chartData
            }
        }
    };
    return newState;
}
