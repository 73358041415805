import React, { useEffect, useRef } from 'react';
import { ListItem } from '../types';

interface Props {
    label: string | number;
    subItems: ListItem[];
    setSubMenuPositions?;
}

const ButtonRipple = ({ label, setSubMenuPositions }: Props): JSX.Element => {
    const ref = useRef(null);

    useEffect(() => {
        setSubMenuPositions(prev => {
            //@ts-ignore
            const { offsetTop, offsetLeft, offsetWidth } = ref.current;
            setSubMenuPositions(prev => ({
                ...prev,
                [label]: { top: offsetTop, right: offsetLeft + offsetWidth }
            }));
        });
    }, []);

    return (
        <li
            className="option cascade"
            key={label}
            onMouseEnter={() => {
                setSubMenuPositions(prev => ({
                    ...prev,
                    [label]: { ...prev[label], hover: true }
                }));
            }}
            onMouseLeave={() => {
                setSubMenuPositions(prev => ({
                    ...prev,
                    [label]: { ...prev[label], hover: false }
                }));
            }}
            ref={ref}
        >
            <span className="option-text">{label}</span>
            <span className="cascade-arrow material-icons-outlined">arrow_right</span>
        </li>
    );
};

export default ButtonRipple;
