import classNames from 'classnames';
import React from 'react';
import { ReactComponent as LogoIcon } from '../../assets/img/brand/logo-icon.svg';
import './Spinner.scss';

type Props = {
    text?: string;
    large?: boolean;
};

const Spinner = ({ text, large }: Props): JSX.Element => {
    return (
        <div className={classNames('spinner', large ? 'large' : '')} data-testid="spinner">
            <LogoIcon />
            {text && <span>{text}</span>}
        </div>
    );
};

export default Spinner;
