import { ISmartercodesState, TFilterCodesData } from '../smartercodes.reducers';

export default function apiDataFilterCodes(state: ISmartercodesState, data: TFilterCodesData) {
  const output = data?.output ?? [];
  const codesList = output.map((codeObj) => {
    return {
      id: Number(codeObj.code_id),
      tagId: Number(codeObj.tag_index),
      name: codeObj.code
    };
  });

  return {
    ...state,
    codes: {
      ...state.codes,
      codesList
    }
  };
}
