import React from 'react';
import { Card, CardBody } from 'reactstrap';
import ValidityCategorySummary from '../ValidityCategorySummary/ValidityCategorySummary';
import ValidityChart from '../ValidityChart/ValidityChart';

interface Props {
    isLoading;
    pieDatasets;
    overall;
    types;
    breakdownAnchor;
    downloadName: string;
}

const Daily = ({ isLoading, pieDatasets, overall, types, breakdownAnchor, downloadName }: Props): JSX.Element => {
    return (
        <Card className="card-table has-media">
            <div className="card-media">
                <ValidityChart isLoading={isLoading} pieDatasets={pieDatasets} types={types} />
            </div>

            <CardBody>
                <ValidityCategorySummary
                    breakdownAnchor={breakdownAnchor}
                    data={Object.values(overall)}
                    downloadName={downloadName}
                    isLoading={isLoading}
                    types={types}
                />
            </CardBody>
        </Card>
    );
};

export default Daily;
