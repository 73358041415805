import React, { useState } from 'react';
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import snakeCase from 'lodash/snakeCase';

import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import CodesLink from 'components/CodesLink/CodesLink';
import SpinnerCard from 'components/Spinner/SpinnerCard';
import ReactTable from 'components/ReactTable/ReactTable';
import { generateColumns } from './helpers';
import { isEmpty } from 'ramda';
import { setCodesAction } from 'Dashboard/redux/dashboard.actions';

type Props = {
    // required
    types;
    data;
    breakdownAnchor: string;
    downloadName: string;
    // optional
    isLoading?: boolean;
};

const ValidityTableLarge = ({ types, isLoading, data, breakdownAnchor, downloadName }: Props): JSX.Element => {
    const dispatch = useDispatch();
    const [codesRedirect, setCodesRedirect] = useState({
        codeId: '',
        codeName: '',
        trigger: false
    });

    const [activeTab, setActiveTab] = useState(0);

    if (isLoading) return <SpinnerCard />;

    //let tableData = Object.keys(props.data).map(key => props.data[key]) || [];

    const orderedTypes = [
        ...[types && { key: 'invalid_codes', ...types.invalid_codes }],
        ...[types && { key: 'valid_codes_basket_discount', ...types.valid_codes_basket_discount }],
        ...[
            types && {
                key: 'valid_codes_delivery_discount',
                ...types.valid_codes_delivery_discount
            }
        ],
        ...[types && { key: 'valid_codes_full_discount', ...types.valid_codes_full_discount }],
        ...[types && { key: 'valid_codes_no_discount', ...types.valid_codes_no_discount }]
    ];

    return (
        <>
            <CodesLink
                codeId={codesRedirect.codeId}
                codeName={codesRedirect.codeName}
                trigger={codesRedirect.trigger}
            />
            <Card className="card-table" id={breakdownAnchor}>
                <CardBody>
                    <Nav tabs>
                        {orderedTypes.map((tab: any, i) => (
                            <NavItem key={tab.text}>
                                <NavLink
                                    className={classnames({ active: activeTab === i }, tab.colour)}
                                    onClick={() => {
                                        setActiveTab(i);
                                    }}
                                >
                                    {tab.text}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        {orderedTypes.map((tab: any, i) => {
                            return (
                                <TabPane key={tab.text} tabId={i}>
                                    {!isEmpty(data) ? (
                                        <ReactTable
                                            columns={generateColumns()}
                                            data={data?.[snakeCase(tab.key)]}
                                            download={downloadName + ` - ${tab.text}`}
                                            pagination
                                            rowsMeta={{
                                                onClick: (row) => {
                                                    dispatch(
                                                            setCodesAction([{
                                                              id: row.original.code_id,
                                                              name: row.original.name
                                                            }])
                                                          );
                                                        
                                                    setCodesRedirect({
                                                        codeId: row.original.code_id,
                                                        codeName: row.original.name,
                                                        trigger: true
                                                    });
                                                }
                                            }}
                                            search
                                            sort={{ id: 'code_attempts', desc: true }}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </TabPane>
                            );
                        })}
                    </TabContent>
                </CardBody>
            </Card>
        </>
    );
};

export default ValidityTableLarge;
