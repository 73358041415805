import ButtonRipple from 'components/Buttons/ButtonRipple/ButtonRipple';
import MenuTrigger from 'components/MenuTrigger/MenuTrigger';
import React, { Fragment } from 'react';
import { Button, Col, Row } from 'reactstrap';

// DOCS: http://allenfang.github.io/react-bootstrap-table/custom.html#toolbar

type Props = {
    type: string | undefined;
    checkboxCallback: Function | undefined;
    checkboxClearCallback: Function | undefined;
    checkboxAllCallback: Function | undefined;
    extraToolbarRow: any;
    setSelectedCheckboxes: any;
    libraryProps: {
        components: {
            // here are all the components
            exportCSVBtn; // export CSV button JSX
            insertBtn; // insert button JSX
            deleteBtn; // delete button JSX
            showSelectedOnlyBtn; // show selected button JSX
            searchPanel; // search panel JSX
            btnGroup; // button groups JSX
            searchField; // search field JSX
            clearBtn; // clear search field JSX
        };
        event: {
            // here are all the related event you may use it
            openInsertModal; // call it to open insert modal
            closeInsertModal; // call it to close insert modal
            dropRow; // call it to drop row
            showOnlyToogle; // call it to toogle show only selections
            exportCSV; // call it to export CSV file
            search; // call it with search text to search table
        };
    };
};

const Toolbar = ({
    type,
    checkboxCallback,
    checkboxClearCallback,
    checkboxAllCallback,
    extraToolbarRow,
    setSelectedCheckboxes,
    libraryProps
}: Props): JSX.Element => {
    const { components, event } = libraryProps;
    const { searchPanel, exportCSVBtn } = components;
    const { exportCSV } = event;

    if (type === 'fullSearch') {
        if (!checkboxCallback || !checkboxClearCallback) {
            return <Col xs="12">{searchPanel}</Col>;
        } else {
            return (
                <Fragment>
                    <Col className="search" style={{ flex: '1 1 auto' }} xs="auto">
                        <span className="search-icon material-icons-outlined">filter_list</span>
                        {searchPanel}
                    </Col>
                    <Col className="pl-0" xs="auto">
                        <Row>
                            {/* <Col className="pl-0" xs="auto">
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        if (checkboxAllCallback) checkboxAllCallback(filteredRows);
                                    }}
                                >
                                    <span className="icon material-icons-outlined">check_box</span>
                                </Button>
                            </Col> */}
                            <Col className="pl-0" xs="auto">
                                <Button
                                    color="danger"
                                    onClick={() => {
                                        setSelectedCheckboxes([]);
                                        if (checkboxClearCallback) {
                                            checkboxClearCallback([]);
                                        }
                                    }}
                                >
                                    <span className="material-icons-outlined">playlist_remove</span>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Fragment>
            );
        }
    }

    if (type === 'extraRow') {
        const ExtraRow = extraToolbarRow;
        return (
            <Fragment>
                <Col className="mr-auto col-auto">{exportCSVBtn}</Col>
                <Col className="ml-auto col-auto">{searchPanel}</Col>
                <ExtraRow />
            </Fragment>
        );
    }

    const listItems = [
        {
            label: 'Download',
            subItems: [
                {
                    label: 'CSV',
                    onClick: () => {
                        exportCSV();
                    }
                }
            ]
        }
    ];

    return (
        <Fragment>
            <Col className="search" xs="auto">
                <span className="search-icon material-icons-outlined">filter_list</span>
                {searchPanel}
            </Col>
            <Col className="more" xs="auto">
                <MenuTrigger
                    Trigger={
                        <ButtonRipple>
                            <span className="material-icons-outlined">more_vert</span>
                        </ButtonRipple>
                    }
                    menu={{ className: 'reverse', listItems }}
                />
            </Col>
        </Fragment>
    );
};

export default Toolbar;
