import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import SpinnerCard from 'components/Spinner/SpinnerCard';
import ReactTable from 'components/ReactTable/ReactTable';
import { generateColumns } from './helpers';
import { useDispatch, useSelector as uSel } from 'react-redux';
import { IAppStoreState as S } from 'redux/interfaces';
import CodesLink from 'components/CodesLink/CodesLink';
import { setCodesAction } from 'Dashboard/redux/dashboard.actions';

const OverviewTopTen = (): JSX.Element => {
  const dispatch = useDispatch();
  const [codesRedirect, setCodesRedirect] = useState({
    codeId: '',
    codeName: '',
    trigger: false
  });
  const overview = uSel((state: S) => state?.smartercodes?.overview);
  const isLoading = uSel((state: S) => state?.smartercodes?.api?.codeReview?.isLoading || false);
  
  return (
    <>
      <CodesLink
        codeId={codesRedirect.codeId}
        codeName={codesRedirect.codeName}
        trigger={codesRedirect.trigger}
      />
      <div className="table-wrapper">
        <Row>
          <Col sm="12" xl="6">
            {isLoading ? (
              <SpinnerCard />
            ) : (
              <Card className="card-accent-success card-table">
                <CardHeader>Most Valid Attempts</CardHeader>
                <CardBody>
                  <ReactTable
                    columns={generateColumns()}
                    data={overview.valid}
                    rowsMeta={{
                      onClick: (row) => {
                        dispatch(
                          setCodesAction([{
                            id: row.original.codeId,
                            name: row.original.code
                          }])
                        );

                        setCodesRedirect({
                          codeId: row.original.codeId,
                          codeName: row.original.code,
                          trigger: true
                        });
                      }
                    }}
                    sort={{ id: 'count', desc: true }}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
          <Col sm="12" xl="6" xs="12">
            {isLoading ? (
              <SpinnerCard />
            ) : (
              <Card className="card-accent-danger card-table">
                <CardHeader>Most Invalid Attempts</CardHeader>
                <CardBody>
                  <ReactTable
                    columns={generateColumns()}
                    data={overview.invalid}
                    rowsMeta={{
                      onClick: (row) => {
                        dispatch(
                          setCodesAction([{
                            id: row.original.codeId,
                            name: row.original.code
                          }])
                        );

                        setCodesRedirect({
                          codeId: row.original.codeId,
                          codeName: row.original.code,
                          trigger: true
                        });
                      }
                    }}
                    sort={{ id: 'count', desc: true }}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OverviewTopTen;
