import React, { Fragment } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Select from 'components/Select/Select';
import { ActiveChannel, ActivePublisher, ActiveSource, Channel } from '../../types';
import { UseState } from 'types';
import { getPublishers, getSources } from '../../helpers';
import { stringSort } from '../../../../../../../utils';

interface Props {
  allChannels: Channel[];
  activeChannels: UseState<ActiveChannel>;
  activeSources: UseState<ActiveSource>;
  activePublishers: UseState<ActivePublisher>;
  isTableLoading: boolean;
  onSubmit: () => void;
}

const Options = ({
  allChannels,
  activeChannels: [activeChannels, setActiveChannels],
  activeSources: [activeSources, setActiveSources],
  activePublishers: [activePublishers, setActivePublishers],
  isTableLoading,
  onSubmit
}: Props): JSX.Element => {
  // Create lists of all possible sources and channels,
  // based on the options the user has picked so far
  const sortedChannels = stringSort(allChannels, ({ name }) => name);
  const filteredSources = getSources(activeChannels?.value);
  const filteredPublishers = getPublishers(activeSources?.value ?? []);

  // Create select option data from the lists
  const channelOptions = [
    ...(sortedChannels.length ? [{ label: 'Any', value: sortedChannels }] : []),
    ...sortedChannels.map((channel) => ({ label: channel.name, value: [channel] }))
  ];
  const sourceOptions = [
    ...(filteredSources.length ? [{ label: 'Any', value: filteredSources }] : []),
    ...filteredSources.map((source) => ({ label: source.name, value: [source] }))
  ];
  const publisherOptions = [
    ...(filteredPublishers.length ? [{ label: 'Any', value: filteredPublishers }] : []),
    ...filteredPublishers.map((publisher) => ({ label: publisher.name, value: [publisher] }))
  ];

  // On select change, update value and clear child values
  const onChannelChange = ({ label, value }) => {
    if (label !== activeChannels?.label) {
      setActiveChannels({ label, value });
      setActiveSources(undefined);
      setActivePublishers(undefined);
    }
  };
  const onSourceChange = ({ label, value }) => {
    if (label !== activeSources?.label) {
      setActiveSources({ label, value });
      setActivePublishers(undefined);
    }
  };
  const onPublisherChange = ({ label, value }) => {
    if (label !== activePublishers?.label) setActivePublishers({ label, value });
  };

  const isDisableChannels = !Boolean(sortedChannels.length);
  const isDisableSources = !Boolean(filteredSources.length);
  const isDisablePublishers = !Boolean(filteredPublishers.length);

  return (
    <Row style={{ width: '100%', flex: '1 1 auto', padding: '0 10px' }}>
      <Col style={{ flex: '1 1 25%', padding: '0 10px' }}>
        <Select
          containerClassName="mb-0"
          disabled={isDisableChannels}
          label="Channels"
          onChange={onChannelChange}
          options={channelOptions}
          placeholder={isDisableChannels ? 'No Channels Found' : 'Select a Channel...'}
          value={activeChannels}
        />
      </Col>
      <Col style={{ flex: '1 1 25%', padding: '0 10px' }}>
        <Select
          containerClassName="mb-0"
          disabled={isDisableSources}
          key={`${JSON.stringify(filteredSources)}${JSON.stringify(activeSources)}`}
          label="Sources"
          onChange={onSourceChange}
          options={sourceOptions}
          placeholder={isDisableSources ? 'No Sources Found' : 'Select a Source...'}
          value={activeSources}
        />
      </Col>
      <Col style={{ flex: '1 1 25%', padding: '0 10px' }}>
        <Select
          containerClassName="mb-0"
          disabled={isDisablePublishers}
          key={`${JSON.stringify(filteredPublishers)}${JSON.stringify(activePublishers)}`}
          label="Publishers"
          onChange={onPublisherChange}
          options={publisherOptions}
          placeholder={isDisablePublishers ? 'No Publishers Found' : 'Select a Publisher...'}
          value={activePublishers}
        />
      </Col>
      <Col
        style={{
          display: 'flex',
          flex: '0 0 auto',
          padding: '0 10px',
          width: 'auto',
          alignItems: 'center'
        }}
      >
        <Button
          color="primary"
          disabled={
            (channelOptions.length && activeChannels) === undefined ||
            (sourceOptions.length && activeSources) === undefined ||
            (publisherOptions.length && activePublishers === undefined) ||
            isTableLoading
          }
          onClick={() => {
            onSubmit();
          }}
        >
          {isTableLoading ? (
            <i className="fa fa-spinner" />
          ) : (
            <Fragment>
              <span className=" icon material-icons-outlined">arrow_forward_ios</span>
            </Fragment>
          )}
        </Button>
      </Col>
    </Row>
  );
};

export default Options;
