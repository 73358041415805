export const helpEn = {
    home: {
        header: 'This is the Help page',
        body: 'See your help stuff here.',
        link: 'Open FAQ page'
    },
    faq: {
        header: 'This is the FAQ page',
        body: 'Code away here...'
    }
};

export const helpNl = {};
