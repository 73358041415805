import { UserActionTypes, AuthActionTypes } from '../actions';
import { IUserSettings } from 'services/api.service';
import { Publisher, Network, Tag } from 'index.types';
import { AccountActionTypes, UploadImage } from 'Dashboard/views/Account/redux/account.actions';
export enum USERTYPE {
    ADVERTISER = 'advertiser',
    PUBLISHER = 'publisher',
    NETWORK = 'network',
    AGENCY = 'agency',
    SMARTERCLICK = 'smarterclick'
}

export type User = {
    client_id: number;
    client_name: string;
    client_email: string;
    last_login: string;
    settings: IUserSettings;
    publishers: Publisher[];
    networks: Network[];
    smartercodes_tags: Tag[];
    user_type: USERTYPE;
    old_settings: any;
    loaded: boolean;
    mfaActive: number;
    uploadImage?: UploadImage;
};
export const defaultSettings: IUserSettings = {
    currency: 'GBP',
    currency_symbol: '£',
    date: 'DD/MM/YYYY',
    landing: '', // '/smartercodes/overview',
    profile_image: '',
    banner: '',
    logo: '',
    timezone: 'Europe/London',
    currency_rate: 1
};
// TODO ensure incoming settings have an allowa
export function user(state: Partial<User> = { loaded: false, mfaActive: 2 }, action) {
    switch (action.type) {
        case UserActionTypes.GET_DATA_SUCCESS:
            return {
                ...state,
                ...action.user,
                settings: {
                    ...defaultSettings,
                    ...(action.user ? action.user.settings : {})
                },
                loaded: true
            };
        case UserActionTypes.GET_MFA_ACTIVE_SUCCESS:
            return {
                ...state,
                ...action.user
            };

        // case AuthActionTypes.LOGIN_SUCCESS:
        //     return {
        //         ...state,
        //         ...action.user,

        //         settings: {
        //             ...defaultSettings,
        //             ...(action.user ? action.user.settings : {})
        //         },
        //         loaded: true
        //     };
        case UserActionTypes.SET_SETTINGS_REQUEST:
            return {
                ...state,

                settings: {
                    ...state.settings,
                    ...action.settings
                },
                old_settings: {
                    ...state.settings
                }
            };
        case UserActionTypes.SET_SETTINGS_SUCCESS:
            return {
                ...state,

                settings: {
                    ...state.settings,
                    ...action.settings
                },
                old_settings: undefined
            };
        case UserActionTypes.SET_SETTINGS_FAILURE:
            return {
                ...state,

                settings: {
                    ...state.old_settings
                }
            };
        case AuthActionTypes.LOGOUT_SUCCESS:
            return {};

        case UserActionTypes.GET_DATA_REQUEST:
            return {
                ...state,
                data: undefined
            };

        case AccountActionTypes.REMOVE_MFA_SUCCESS:
            return {
                ...state,
                mfaActive: 0
            };
        case AccountActionTypes.UPDATE_MFA_CONF_SUCCESS:
            return {
                ...state,
                mfaActive: 1
            };
        case AccountActionTypes.UPLOAD_IMAGE_REQUEST:
            return {
                ...state,
                uploadImage: {
                    isUploading: true,
                    ...action.payload
                }
            };
        case AccountActionTypes.UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                uploadImage: {
                    isUploading: false,
                    ...action.payload
                }
            };
        case AccountActionTypes.UPLOAD_IMAGE_FAILURE:
            return {
                ...state,
                uploadImage: {
                    isUploading: false,
                    ...action.payload
                }
            };
        default:
            return state;
    }
}
