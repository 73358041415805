import { ISmartercodesState, TTimelineLineChart } from '../smartercodes.reducers';
import { IContext } from 'Dashboard/redux/dashboard.reducer';
import moment from 'moment-timezone';
import get from 'lodash.get';
import { getLineChartMeta, fillEmptyTimelineChart, getDatesFromApiResult } from 'utils';
import findIndex from 'lodash.findindex';

/**
 * Increments the various lineChart fields based on which section the code's timestamp falls into
 */
export function incrementLineChart(
    rawItem: any,
    startDate: any,
    endDate: any,
    lineChart: any,
    settings: Object,
    timezone: string,
) {
    const lineChartMeta = getLineChartMeta(startDate, endDate);

    // dateTime returns in 1970 - Format dateTime to correct date then adjust to timezone
    const codeDate = moment.utc(rawItem.datetime * 1000).tz(timezone);

    // The diff method is bugged - it ignores timezones in objects - you have to create a
    // new standard moment object from the string of a timezone object
    const codeDiff = moment
        .utc(codeDate.format('YYYY-MM-DD HH:mm'))
        .diff(moment.utc(startDate.format('YYYY-MM-DD HH:mm')));

    const index = Math.floor((codeDiff / lineChartMeta.dateDiff) * lineChartMeta.maxLabels);
    if (index >= 0 && index < lineChartMeta.maxLabels) {
        const invalid = get(rawItem, 'invalid', 0);
        const valid = get(rawItem, 'valid', 0);
        const orders = get(rawItem, 'orders', 0);
        const revenue = get(rawItem, 'revenue', 0);
        const basketDiscount = get(rawItem, 'basket_discount', 0) / 100;
        const deliveryDiscount = get(rawItem, 'delivery_discount', 0) / 100;

        lineChart.validCodeAttempts[index].y += valid;
        lineChart.invalidCodeAttempts[index].y += invalid;
        lineChart.codeAttempts[index].y += valid + invalid;
        lineChart.revenue[index].y += revenue;
        lineChart.orders[index].y += orders;
        lineChart.basketDiscount[index].y += revenue > 0 && valid > 0 ? basketDiscount : 0;
        lineChart.deliveryDiscount[index].y += revenue > 0 && valid > 0 ? deliveryDiscount : 0;
        lineChart.totalDiscount[index].y +=
            revenue > 0 && valid > 0 ? basketDiscount + deliveryDiscount : 0;
    }
}

/**
 *
 */
function setTableData(rawItem, numericalData, numericalDataMap, key, timezone) {
    const newDate = moment(moment.utc(rawItem.datetime * 1000))
        .tz(timezone)
        .startOf('day')
        .valueOf();

    //const newDate = moment.utc(moment.unix(rawItem.datetime)).startOf('day').valueOf();

    const isEmpty = (val) => val === '-';

    if (numericalDataMap[newDate]) {
        const index = findIndex(numericalData, (item) => item.date === newDate);
        const oldData = numericalData[index];
        const discount =
            rawItem.valid > 0 && rawItem.revenue > 0
                ? (rawItem.basket_discount || 0) + (rawItem.delivery_discount || 0)
                : 0;
        const newData = {
            key,
            date: newDate || '-',
            attempts: isEmpty(oldData.attempts)
                ? rawItem.code_attempts || '-'
                : (rawItem.code_attempts || 0) + oldData.attempts,
            valid: isEmpty(oldData.valid)
                ? rawItem.valid || '-'
                : (rawItem.valid || 0) + oldData.valid,
            invalid: isEmpty(oldData.invalid)
                ? rawItem.invalid || '-'
                : (rawItem.invalid || 0) + oldData.invalid,

            orders: isEmpty(oldData.orders)
                ? rawItem.orders || '-'
                : (rawItem.orders || 0) + oldData.orders,
            revenue: isEmpty(oldData.revenue)
                ? rawItem.revenue || '-'
                : (rawItem.revenue || 0) + oldData.revenue,
            totalDiscount: isEmpty(oldData.totalDiscount)
                ? discount || '-'
                : discount + oldData.totalDiscount
        };
        numericalData[index] = newData;
    } else {
        const discount =
            rawItem.valid > 0 && rawItem.revenue > 0
                ? (rawItem.basket_discount || 0) + (rawItem.delivery_discount || 0)
                : 0;
        numericalData.push({
            key,
            date: newDate || '-',
            attempts: rawItem.code_attempts || '-',
            valid: rawItem.valid || '-',
            invalid: rawItem.invalid || '-',
            orders: rawItem.orders || '-',
            revenue: rawItem.revenue || '-',
            totalDiscount: discount || '-'
        });
        numericalDataMap[newDate] = true;
    }
}

export default function apiDataTimeline(
    state: ISmartercodesState,
    rawApiData: any,
    context: IContext,
    extraParams: any,
) {
    let newState = { ...state };
    const timelineOutput = get(rawApiData, 'output', []);
    const timezone = extraParams.timezone;
    const { startDate, endDate } = getDatesFromApiResult(rawApiData, timezone);

    const lineChartMeta = getLineChartMeta(startDate, endDate);
    const lineChartData: TTimelineLineChart = fillEmptyTimelineChart(startDate, endDate, {
        codeAttempts: [],
        validCodeAttempts: [],
        invalidCodeAttempts: [],
        basketDiscount: [],
        deliveryDiscount: [],
        totalDiscount: [],
        orders: [],
        revenue: []
    });

    const numericalData = [];
    const numericalDataMap = {};

    // we loop the data only once to improve performance
    timelineOutput.forEach((rawItem, i) => {
        incrementLineChart(rawItem, startDate, endDate, lineChartData, state, timezone); // not a pure function, will modify map

        setTableData(rawItem, numericalData, numericalDataMap, i, timezone); // not a pure function, will modify numericalData array

        // TODO: add additional methods here for calculating other info
    });

    newState.overview = {
        ...newState.overview,
        lineChartData,
        lineChartMeta
    };

    newState.timeline = {
        ...newState.timeline,
        lineChartData,
        lineChartMeta,
        numericalData
    };

    // TODO: add to any other page's store keys here!

    return newState;
}
