import { API, IUserAuthResetPassword, IUserAuthForgotPassword } from 'services/api.service';
import { IActionLoginArgs } from '../interfaces';
import { getUserDataAction } from './user.actions';
import { APIFailureAction } from './error.actions';
import ReactGA from 'react-ga';

export enum AuthActionTypes {
    LOGIN_REQUEST = 'LOGIN_REQUEST',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',

    LOGOUT_REQUEST = 'LOGOUT_REQUEST',
    LOGOUT_FAILURE = 'LOGOUT_FAILURE',
    LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',

    STATUS_REQUEST = 'STATUS_REQUEST',
    STATUS_YES = 'STATUS_YES',
    STATUS_NO = 'STATUS_NO',

    FORGOT_INIT_REQUEST = 'AUTH_FORGOT_INIT_REQUEST',
    FORGOT_INIT_SUCCESS = 'AUTH_FORGOT_INIT_SUCCESS',

    FORGOT_CONF_REQUEST = 'AUTH_FORGOT_CONF_REQUEST',
    FORGOT_CONF_SUCCESS = 'AUTH_FORGOT_CONF_SUCCESS',

    CLEAR_ERROR = 'AUTH_CLEAR_ERROR',
    NEED_MFA = 'AUTH_NEED_MFA'
}

export const forgotInitAction = (params: IUserAuthForgotPassword) => (dispatch) => {
    dispatch({
        type: AuthActionTypes.FORGOT_INIT_REQUEST
    });
    try {
        API.user().auth().forgotPassword(params);
        dispatch({ type: AuthActionTypes.FORGOT_INIT_SUCCESS });
    } catch (error) {
        // @ts-ignore
        dispatch(APIFailureAction(error.response, error.errorMessage + ' (forgotInitAction)'));
    }
};
export const forgotConfirmAction = (params: IUserAuthResetPassword) => (dispatch) => {
    dispatch({
        type: AuthActionTypes.FORGOT_CONF_REQUEST
    });
    try {
        API.user().auth().resetPassword(params);
    } catch (error) {
        // @ts-ignore
        dispatch(APIFailureAction(error.response, error.errorMessage + ' (forgotConfirmAction)'));
    }
};

export const loginAction =
    ({ email, password, mfa, remember }: IActionLoginArgs) =>
    async (dispatch) => {
        dispatch({
            type: AuthActionTypes.LOGIN_REQUEST
        });
        try {
            let userAuthVerdict = await API.user().auth().login({ email, password, mfa });
            if (!userAuthVerdict) {
                throw new Error('User Auth Response is empty or missing key / secret');
            }
            // if (userAuthVerdict.result !== 'success') {
            //     throw new Error(
            //         userAuthVerdict.errors && userAuthVerdict.errors.length
            //             ? userAuthVerdict.errors[0]
            //             : 'No error detail was given'
            //     );
            // }

            let authenticate = {
                key: userAuthVerdict.key,
                secret: userAuthVerdict.secret
            };
            if (remember) {
                localStorage.setItem('authenticate', JSON.stringify(authenticate));
            } else {
                sessionStorage.setItem('authenticate', JSON.stringify(authenticate));
            }

            // API.setAuthFailAction((c, e) => dispatch(APIAuthFailureAction(c, e)));
            // API.setFailAction((c, e) => dispatch(APIFailureAction(c, e)));
            API.setCredentials(userAuthVerdict.key, userAuthVerdict.secret);
            dispatch({
                type: AuthActionTypes.LOGIN_SUCCESS,
                client: {
                    client_email: userAuthVerdict.client_email,
                    client_id: userAuthVerdict.client_id,
                    client_name: userAuthVerdict.client_name
                }
            });
            ReactGA.event({
                category: 'Login',
                action: 'Successful Login Attempt'
            });
            dispatch(getUserDataAction());
        } catch (error: any) {
            // @ts-ignore
            dispatch({
                type: AuthActionTypes.LOGIN_FAILURE,
                error: error.errorMessage || error.message
            });
            setTimeout(() => {
                dispatch(clearErrorAction());
            }, 5000);
            ReactGA.event({
                category: 'Login',
                action: 'Failed Login Attempt'
            });
        }
    };

export const statusAction = () => async (dispatch) => {
    let authenticate =
        localStorage.getItem('authenticate') || sessionStorage.getItem('authenticate');

    if (authenticate) {
        dispatch({
            type: AuthActionTypes.STATUS_REQUEST
        });

        try {
            let creds = JSON.parse(authenticate);
            if (!(creds.key && creds.secret)) {
                throw new Error('Missing key or secret');
            }
            API.setCredentials(creds.key, creds.secret);

            let verdict = await API.user().data().get();

            if (!verdict) {
                throw new Error('Missing verdict');
            }
            if (verdict.result === 'error') {
            } else {
                // API.setAuthFailAction((c, e) => dispatch(APIAuthFailureAction(c, e)));
                // API.setFailAction((c, e) => dispatch(APIFailureAction(c, e)));
                dispatch({
                    type: AuthActionTypes.STATUS_YES,
                    client: {
                        client_email: verdict.client_email,
                        client_id: verdict.client_id,
                        client_name: verdict.client_name
                    }
                });
                dispatch(getUserDataAction());
            }
        } catch (e) {
            dispatch(statusNoAction(e));
        }
    } else {
        dispatch(statusNoAction('No credentials found'));
    }
};

const statusNoAction = (error) => (dispatch) => {
    API.clear();
    localStorage.clear();
    sessionStorage.clear();
    dispatch({
        type: AuthActionTypes.STATUS_NO,
        error
    });
};

export const logoutAction = () => {
    API.clear();
    localStorage.clear();
    sessionStorage.clear();

    return {
        type: AuthActionTypes.LOGOUT_SUCCESS
    };
};

export const clearErrorAction = () => ({
    type: AuthActionTypes.CLEAR_ERROR
});
