import { IRouteItem } from 'index.types';
import Account from './home/Account';
import Profile from './views/Terms/Terms';

//Smarter click Routes

export const routes: IRouteItem[] = [
    {
        path: '/account',
        i18nKey: 'nav.account.home',
        isExact: true,
        component: Account,
        key: 0
    },
    {
        path: '/account/terms',
        i18nKey: 'nav.account.home',
        isExact: true,
        component: Profile,
        key: 1
    }
];
