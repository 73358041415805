import { cellNumber, cellString } from 'components/ReactTable/utils';

export const generateColumns = () => {
  return [
    {
      id: 'voucherCode',
      accessorKey: 'voucherCode',
      header: 'Code',
      cell: cellString
    },
    {
      id: 'message',
      accessorKey: 'message',
      header: 'Displayed Message',
      cell: cellString
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: 'Discount Type',
      cell: cellString
    },
    {
      id: 'attempts',
      accessorKey: 'attempts',
      header: 'Number of Attempts',
      cell: cellNumber,
      meta: { align: 'right' }
    }
  ];
};
