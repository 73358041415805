import React, { Fragment, FunctionComponent, useState } from 'react';
import {
    Button,
    ButtonProps,
    Col,
    FormGroup,
    Input,
    InputProps,
    Label,
    Row,
    Alert
} from 'reactstrap';
import { isPasswordValid } from 'utils';
import Spinner from 'components/Spinner/Spinner';

type Props = {
    requestInProcess: any;
    success: any;
    deleteMFAAction: any;
    changeMFAResetErrorAction: any;
};
export const ModalMFADelete: FunctionComponent<Props> = (props: Props) => {
    const [passwordInputCurrentValue, setPasswordInputCurrentValue] = useState('');

    // Displayed if the user has a mfaActive = 1
    let input: { [a: string]: InputProps } = {
        password: {
            autoComplete: 'password',
            id: 'password',
            name: 'password',
            type: 'password',
            value: passwordInputCurrentValue,
            onChange: (e) => setPasswordInputCurrentValue(e.target.value),
            valid: isPasswordValid(passwordInputCurrentValue),
            disabled: props.requestInProcess
        }
    };
    let button: { [a: string]: ButtonProps } = {
        delete: {
            children: 'Remove MFA',
            onClick: () => {
                props.deleteMFAAction(passwordInputCurrentValue);
                props.changeMFAResetErrorAction();
            },
            color: 'danger'
        }
    };
    if (props.requestInProcess) {
        return (
            <Fragment>
                <Spinner large />
            </Fragment>
        );
    }
    if (!props.success) {
        return (
            <Fragment>
                <FormGroup row>
                    <Col md="4" style={{ display: 'flex', alignItems: 'center' }}>
                        <Label className="mb-0" htmlFor="password">
                            Current Password:
                        </Label>
                    </Col>
                    <Col md="8" xs="12">
                        <Input {...input.password} />
                    </Col>
                </FormGroup>

                <Row>
                    <Col xs="12">
                        <Button {...button.delete} />
                    </Col>
                </Row>
            </Fragment>
        );
    }

    return <Alert className="text-center">Removed</Alert>;
};
