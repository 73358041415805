import React from 'react';
import { useSelector as uSel } from 'react-redux';
import { IAppStoreState as S } from 'redux/interfaces';
import ReactTable from 'components/ReactTable/ReactTable';
import { generateColumns } from './helpers';
import SpinnerCard from 'components/Spinner/SpinnerCard';
import { Card, CardBody } from 'reactstrap';

const CodesMessages = (): JSX.Element => {
  const isLoading = uSel((state: S) => state?.smartercodes?.api?.codeDetails?.isLoading || false);
  const messages = uSel(
    (state: S) => state?.smartercodes?.codes?.currentCode?.messages || []
  );
  const tagName = uSel(
    (state: S) => state?.dashboard?.context?.tag?.tag_name
  );

  const downloadName = `Voucher messages${tagName ? ` - ${tagName}` : ''}`;

  if (isLoading) return <SpinnerCard />;

  return (
    <Card className="card-table">
      <CardBody>
        <ReactTable
          columns={generateColumns()}
          data={messages}
          download={downloadName}
          pagination
          search
          sort={{ id: 'attempts', desc: true }}
        />
      </CardBody>
    </Card>
  );
};

export default CodesMessages;
