// @ts-nocheck
import PercentageWidget from 'components/PercentageWidget/PercentageWidget';
import NumericalWidget from 'components/NumericalWidget/NumericalWidget';
import SpinnerCard from 'components/Spinner/SpinnerCard';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { IAppStoreState } from 'redux/interfaces';
import { calcPercentFromTotal, formatNumber } from 'utils';
import { FormatCurrencyWithStore } from 'components/Locales';
import './OverviewNumericalSummary.scss';

const mapStateToProps = (state: IAppStoreState) => ({
    overview: state.smartercodes.overview,
    isLoading: state.smartercodes.api.codeReview.isLoading,
    revenueLostIsLoading: state.smartercodes.api.revenueLost.isLoading,
    revenueLost: state.smartercodes.overview.revenueLost
});

const mapDispatchToProps = () => ({});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const OverviewNumericalSummary: FunctionComponent<Props> = ({
    overview,
    isLoading,
    revenueLostIsLoading,
    revenueLost
}: Props) => {
    const {
        codeRevenue,
        validCodesRevenue,
        invalidCodesRevenue,
        codeAttempts,
        validCodeAttempts,
        invalidCodeAttempts
    } = overview?.summary || {};

    return (
        <Row id="overview-numerical-summary">
            <Col lg="4" md="6" xs="12">
                {isLoading ? (
                    <SpinnerCard />
                ) : (
                    <PercentageWidget
                        invalid={[
                            formatNumber(invalidCodeAttempts, 'en-GB'),
                            calcPercentFromTotal(invalidCodeAttempts, codeAttempts)
                        ]}
                        title="Code Attempts"
                        tooltip="Total amount of times a customer entered a discount code in the voucher box at checkout"
                        total={formatNumber(codeAttempts, 'en-GB')}
                        valid={[
                            formatNumber(validCodeAttempts, 'en-GB'),
                            calcPercentFromTotal(validCodeAttempts, codeAttempts)
                        ]}
                    />
                )}
            </Col>
            <Col lg="4" md="6" xs="12">
                {isLoading ? (
                    <SpinnerCard />
                ) : (
                    <PercentageWidget
                        invalid={[
                          FormatCurrencyWithStore(invalidCodesRevenue),
                          calcPercentFromTotal(invalidCodesRevenue, codeRevenue)
                        ]}
                        title="Code Revenue"
                        tooltip="Tracks revenue made associated with each code entry, distinguishing between successful and unsuccessful attempts"
                        total={FormatCurrencyWithStore(codeRevenue)}
                        valid={[
                            FormatCurrencyWithStore(validCodesRevenue),
                            calcPercentFromTotal(validCodesRevenue, codeRevenue)
                        ]}
                    />
                )}
            </Col>
            <Col lg="4" md="6" xs="12">
                {revenueLostIsLoading ? (
                    <SpinnerCard />
                ) : (
                    <><NumericalWidget
                        title="Revenue Lost"
                        tooltip="The sum of all basket values where a code attempt was marked as invalid, and the transaction was not completed. This value is recorded based on the user session, capturing the basket value at the last code attempt."
                        total={FormatCurrencyWithStore(revenueLost)}
                    /></>
                )}
            </Col>
        </Row>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewNumericalSummary);
