import { store } from 'redux/store';
import { formatDateFromNumber, formatNumber, formatNumberAsCurrency } from 'utils';

export const FormatCurrencyWithStore = (
    value,
    rounding?,
    minimumFractionDigits?,
    maximumFractionDigits?,
    fallback?,
    allow0?
): string => {
    const state = store.getState();
    const currencyCode = state?.user?.settings?.currency || 'GBP';
    // @ts-ignore
    const locale = state?.i18n?.locale || 'en';

    return formatNumberAsCurrency(
        value,
        currencyCode,
        locale,
        rounding,
        minimumFractionDigits,
        maximumFractionDigits,
        fallback,
        allow0
    );
};

export const FormatNumberWithStore = (
    value,
    minimumFractionDigits?,
    maximumFractionDigits?,
    fallback?
) => {
    const state = store.getState();
    // @ts-ignore
    const locale = state?.i18n?.locale || 'en';

    return formatNumber(value, locale, minimumFractionDigits, maximumFractionDigits, fallback);
};

export const FormatPercentWithStore = (
    value = 0,
    minimumFractionDigits = 0,
    maximumFractionDigits = 0,
    fallback = '-'
): string => {
    const state = store.getState();
    // @ts-ignore
    const locale = state?.i18n?.locale || 'en';

    return `${formatNumber(
        value,
        locale,
        minimumFractionDigits,
        maximumFractionDigits,
        fallback
    )}%`;
};

export const FormatDateWithStore = (value) => {
    const state = store.getState();
    const dateFormat = state?.user?.settings?.date || 'DD/MM/YYYY';

    return formatDateFromNumber(dateFormat, value);
};
