export const accountEn = {
    home: {
        header: 'This is the Account page',
        body: 'See your account stuff here.',
        link: 'Open FAQ page'
    },
    profile: {
        header: 'This is the Profile page',
        body: 'Choose your language below'
    },
    langCodes: {
        en: 'English',
        nl: 'Dutch'
    }
};

export const accountNl = {};
