import React from 'react';
import { FilterFn, SortingFn, sortingFns } from '@tanstack/react-table';
import { RankingInfo, rankItem, compareItems } from '@tanstack/match-sorter-utils';

declare module '@tanstack/table-core' {
    interface FilterMeta {
        itemRank: RankingInfo;
    }
}
export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({ itemRank });

    // Return if the item should be filtered in/out
    return itemRank.passed;
};

export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
    let dir = 0;

    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
        dir = compareItems(
            rowA.columnFiltersMeta[columnId]?.itemRank!,
            rowB.columnFiltersMeta[columnId]?.itemRank!
        );
    }

    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};
export const currency = { GBP: '£', USD: '$', EUR: '€' };

export const pageButtons = (
    pageIndex: number,
    goToPage: (pageIndex: number) => void,
    maxPage: number,
    buttonLimit: number
): JSX.Element[] => {
    const buttons: any[] = [];
    let hasPrepend = false;
    let hasAppend = false;

    // Helper functions
    const addButton = (i, key, disabled) => {
        buttons.push(
            <div
                //active={pageIndex === i}
                color="light"
                //disabled={disabled}
                key={key}
                onClick={() => {
                    goToPage(i);
                }}
            >
                {i + 1}
            </div>
        );
    };

    const appendDots = (key) => {
        if (hasAppend === false) {
            buttons.push(
                <div className="page-ellipsis ellipsis-append" key={key}>
                    ...
                </div>
            );
            hasAppend = true;
        }
    };

    const prependDots = (key) => {
        if (hasPrepend === false) {
            buttons.push(
                <div className="page-ellipsis ellipsis-prepend" key={key}>
                    ...
                </div>
            );
            hasPrepend = true;
        }
    };

    // Make sure there's always a page 1
    if (!maxPage) addButton(0, `button-${0}}`, true);

    // Loop through each button and add to list
    for (let i = 0; i < maxPage; i += 1) {
        const key = `button-${i}}`;
        const isTooManyPages = maxPage > buttonLimit;

        // Handle normal buttons
        if (!isTooManyPages) {
            const isOnePage = maxPage === 1;
            addButton(i, key, isOnePage);
        }

        // Handle too many buttons
        if (isTooManyPages) {
            const isFirstPageToCenter = pageIndex <= Math.ceil(buttonLimit / 2) - 1;
            const isCenterToLastPage = pageIndex >= maxPage - Math.floor(buttonLimit / 2);
            const isMiddlePages = !isFirstPageToCenter && !isCenterToLastPage;

            // [][][][] ... >
            if (isFirstPageToCenter) {
                if (i <= buttonLimit - 1) addButton(i, key, false);
                else appendDots(key);
            }

            // < ... [][][][] ... >
            if (isMiddlePages) {
                const beforeButtons = i <= pageIndex - Math.ceil(buttonLimit / 2);
                const afterButtons = i >= pageIndex + Math.ceil(buttonLimit / 2);
                const selectedButtons = !beforeButtons && !afterButtons;

                if (beforeButtons) prependDots(key);
                if (afterButtons) appendDots(key);
                if (selectedButtons) addButton(i, key, false);
            }

            // < ... [][][][]
            if (isCenterToLastPage) {
                if (i >= maxPage - buttonLimit) addButton(i, key, false);
                if (i < maxPage - buttonLimit) prependDots(key);
            }
        }
    }

    return buttons;
};

export const paginationLabel = (size) => {
    const max = 100000000000000;
    return size < max ? size : 'All';
};
