import { FormatCurrencyWithStore, FormatNumberWithStore } from 'components/Locales';
import moment from 'moment-timezone';

const formatDate = (value: number, ticks: number[], timezone: string, dateFormat: string) => {
    const days = ticks.map((tick) => moment(tick).tz(timezone).format(dateFormat));
    const isUniqueDays = [...new Set(days)].length === days.length;
    const format = isUniqueDays ? dateFormat : 'HH:mm';

    return moment(value).tz(timezone).format(format);
};

export const getOptions = (timezone = 'UTC') => ({
    scales: {
        x: {
            ticks: {
                callback: function (val, _, allVals) {
                    // @ts-ignore - getLabelForValue exists in the lib but not the types
                    const label = (val) => this.getLabelForValue(val);
                    const tick = label(val);
                    const ticks = allVals.map(({ value }) => {
                        const tick = label(value);
                        return tick ? tick.valueOf() : 0;
                    });

                    return tick ? formatDate(tick, ticks, timezone, 'DD/MM/YYYY') : '';
                }
            }
        },
        y: {
            type: 'linear',
            position: 'left',
            beginAtZero: true,
            ticks: {},
            count: 7
        },
        y2: {
            type: 'linear',
            position: 'right',
            beginAtZero: true,
            grid: {
                drawOnChartArea: false
            },
            ticks: {
                count: (context) => context.chart.config.options.scales.y.count
            }
        }
    },
    maintainAspectRatio: false,
    responsive: true,
    elements: {
        point: {
            radius: 0,
            hitRadius: 1,
            hoverRadius: 2,
            hoverBorderWidth: 3
        },
        bar: {
            borderRadius: 4
        }
    },
    hover: {
        intersect: false
    },
    plugins: {
        tooltip: {
            enabled: true,
            intersect: false,
            mode: 'index',
            position: 'nearest',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            callbacks: {
                title: (scales) => {
                    const context = scales[0] || {};
                    const label = Number(scales?.[0]?.label);
                    const days = context.chart.config.data.datasets[0].data.map(({ x }) => x);

                    return label ? formatDate(label, days, timezone, 'DD/MM/YYYY') : '';
                },
                label: ({ dataset, raw }) => {
                    const label = dataset.label;
                    const value = raw.y;
                    const axis = dataset.yAxisID;
                    const formatted =
                        axis === 'y'
                            ? FormatNumberWithStore(value, 0, 2)
                            : FormatCurrencyWithStore(value);

                    return `${label}: ${formatted}`;
                }
            }
        },
        legend: {
            display: false
        },
        htmlLegend: {
            containerId: 'legend-container'
        }
    }
});

export const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, _, { containerId }) {
        const legendEl = document.getElementById(containerId) || null;

        if (!legendEl) return;

        const rawItems = chart.options.plugins.legend.labels.generateLabels(chart);

        legendEl.innerHTML = '';

        type Axes = {
            [key: string]: {
                name: string;
                data: {
                    onclick: () => void;
                    background: string;
                    borderColor: string;
                    borderWidth: string;
                    color: string;
                    textDecoration: string;
                    text: string;
                    icon: string;
                }[];
            };
        };

        // Compile all details
        const axes: Axes = rawItems.reduce((acc, item, i) => {
            const dataset = chart.data.datasets[i];
            const axisId = dataset.yAxisID;
            const axisName = chart.options.scales[axisId].title.text;

            return {
                ...acc,
                [axisId]: {
                    ...(acc[axisId] || []),
                    name: axisName,
                    data: [
                        ...(acc[axisId]?.data || []),
                        {
                            onclick: () => {
                                const { type } = chart.config;
                                if (type === 'pie' || type === 'doughnut') {
                                    // Pie and doughnut charts only have a single dataset and visibility is per item
                                    chart.toggleDataVisibility(item.index);
                                } else {
                                    chart.setDatasetVisibility(
                                        item.datasetIndex,
                                        !chart.isDatasetVisible(item.datasetIndex)
                                    );
                                }
                                chart.update();
                            },
                            background: item.fillStyle.trim(),
                            borderColor: item.strokeStyle.trim(),
                            borderWidth: item.lineWidth + 'px',
                            color: item.fontColor.trim(),
                            textDecoration: item.hidden ? 'line-through' : '',
                            text: item.text,
                            icon: dataset.type === 'line' ? 'show_chart' : 'bar_chart'
                        }
                    ]
                }
            };
        }, {});

        // Sort axes
        const axesSorted = Object.entries(axes)
            .sort(([a], [b]) => {
                const posA = chart.options.scales[a].position;
                const posB = chart.options.scales[b].position;

                if (posA === 'left' && posB === 'right') return -1;
                if (posA === 'right' && posB === 'left') return 1;
                return 0;
            })
            .map((x) => x[1]);

        // Append to DOM
        Object.values(axesSorted).forEach(({ name, data }) => {
            const axis = document.createElement('div');
            const ul = document.createElement('ul');
            const axisLabel = document.createElement('span');

            axis.classList.add('legend-axis');
            axisLabel.innerText = name;
            axisLabel.classList.add('axis-label');

            data.forEach((item) => {
                const li = document.createElement('li');
                const span = document.createElement('span');
                const p = document.createElement('p');
                const textNode = document.createTextNode(item.text);

                span.style.color = item.background;
                const iconText = document.createTextNode(item.icon);
                span.classList.add('material-icons-outlined');
                document.createTextNode(item.text);

                p.style.color = item.color;
                p.style.textDecoration = item.textDecoration;

                li.onclick = item.onclick;
                li.classList.add(`type-${item.icon}`);

                span.appendChild(iconText);
                p.appendChild(textNode);
                li.appendChild(span);
                li.appendChild(p);
                ul.appendChild(li);
            });

            axis.appendChild(axisLabel);
            axis.appendChild(ul);
            legendEl.appendChild(axis);
        });
    }
};
