export const hasUniqueValues = <ArrayContents>(array: ArrayContents[]): boolean =>
    new Set(array).size === array.length;

/**
 * Works exactly like Array.filter(), except returns both matching and
 * non-matching values in separate arrays
 * @example
 * const catDog = ['cat', 'dog', 'cat'];
 * const [cats, dogs] = filterBoth(catDog, (a) => a === 'cat');
 * console.log(cats); // ['cat', 'cat'];
 * console.log(dogs); // ['dog'];
 */
export const filterBoth = <T,>(
  array: T[],
  matchCallback: (value: T) => boolean
): [T[], T[]] => {
  return array.reduce<[T[], T[]]>(
    (acc, value) => {
      const isMatch = matchCallback(value);

      if (isMatch) return [[...acc[0], value], [...acc[1]]];
      return [[...acc[0]], [...acc[1], value]];
    },
    [[], []]
  );
};

/**
 * Sort strings alphabetically, callback exists to turn non-strings into strings
 * @example
 * const people = [ { name: 'Steve', age: 85 }, { name: 'Bob', age: 20 } ];
 * const [ youngPeople, oldPeople ] = (people, (person) => person.age > 70);
 * console.log(youngPeople); // [ { name: 'Bob', age: 20 } ];
 * console.log(oldPeople); // [ { name: 'Steve', age: 85 } ];
 */
export const stringSort = <T,>(
  array: T[],
  callback: (a: T) => string = ((a) => a as unknown as string)
) => array.sort((a: T, b: T) => {
  const aa = callback(a);
  const bb = callback(b);
  
  return (aa > bb) ? 1 : ((bb > aa) ? -1 : 0);
});
