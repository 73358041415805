import { API } from '../../services/api.service';
import { APIFailureAction } from './error.actions';

export enum CurrencyListActionTypes {
    GET_CURRENCY_LIST_REQUEST = 'GET_CURRENCY_LIST_REQUEST',
    GET_CURRENCY_LIST_SUCCESS = 'GET_CURRENCY_LIST_SUCCESS',
    GET_CURRENCY_LIST_FAILURE = 'GET_CURRENCY_LIST_FAILURE'
}

export const getCurrenciesListAction = () => async dispatch => {
    dispatch({
        type: CurrencyListActionTypes.GET_CURRENCY_LIST_REQUEST
    });
    try {
        let userData = await API.user().currencyList().get();

        dispatch({
            type: CurrencyListActionTypes.GET_CURRENCY_LIST_SUCCESS,
            payload: Object.values(userData?.data ?? {})
        });
    } catch (error) {
        // @ts-ignore
        dispatch(APIFailureAction(error.response, error.errorMessage + ' (getCurrenciesAction)'));
        dispatch({
            type: CurrencyListActionTypes.GET_CURRENCY_LIST_FAILURE
        });
    }
};
