import React, { useEffect, FC, ChangeEvent, useState } from 'react';
import './Checkbox.scss';

type Props = {
    checked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    rowIndex?: number;
    id?: string;
    onKeyDown?: (e) => void;
    // Allow custom parameters to be passed to input
    [x: string]: unknown;
};
const Checkbox: FC<Props> = ({
    checked,
    rowIndex,
    id,
    onChange,
    onKeyDown,
    ...parameters
}: Props) => {
    const [rippleStyles, setRippleStyles] = useState({});

    useEffect(() => {
        let isMounted = true;
        let timeout1, timeout2;

        if(isMounted){
            setRippleStyles({
                transition: 'opacity 0s, transform 0s',
                opacity: 0,
                transform: 'scale(1)'
            });
            setRippleStyles({
                transition: 'opacity 0.15s, transform 0.3s',
                opacity: 1,
                transform: 'scale(2)'
            });
            timeout1 = setTimeout(function () {
                setRippleStyles({
                    transition: 'opacity 0.15s, transform 0.3s',
                    opacity: 0,
                    transform: 'scale(2)'
                });
            }, 150);
    
            // Reset
            timeout2 = setTimeout(function () {
                setRippleStyles({});
            }, 300);
        }

        return () => {
            if(typeof timeout1 !== 'undefined'){
                clearTimeout(timeout1);
            }
            if(typeof timeout2 !== 'undefined'){
                clearTimeout(timeout2);
            }
        };
    }, [checked]);

    return (
        <div className="custom-checkbox-container">
            <input
                checked={checked}
                className="hidden-dom-checkbox"
                id={rowIndex ? 'checkbox' + rowIndex : id}
                name={rowIndex ? 'checkbox' + rowIndex : id}
                onChange={onChange}
                onKeyDown={onKeyDown}
                type="checkbox"
                {...parameters}
            />
            <div className="custom-checkbox-shade" style={rippleStyles} />
            <div className="custom-checkbox" />
        </div>
    );
};

export default Checkbox;
