import React, { useEffect } from 'react';
import { useDispatch, useSelector as uSel } from 'react-redux';
import { IAppStoreState as S } from 'redux/interfaces';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'reactstrap';
import { UpdateEndedAction } from 'Dashboard/redux/dashboard.actions';
import { GetReportRouteAction } from '../../redux/smartercodes.actions';
import { ReportsRoutes } from '../../redux/types';
import OverviewNumericalSummary from './components/OverviewNumericalSummary/OverviewNumericalSummary';
import OverviewGraphicalSummary from './components/OverviewGraphicalSummary/OverviewGraphicalSummary';
import OverviewTopTen from './components/OverviewTopTen/OverviewTopTen';
import useWake from '../../../../../utils/customHooks/useWake';
import moment from 'moment/moment';

const Overview = () => {
  const dispatch = useDispatch();
  const startDate = uSel((state: S) => state.dashboard?.context?.startDate);
  const endDate = uSel((state: S) => state.dashboard?.context?.endDate);
  const tag = uSel((state: S) => state.dashboard?.context?.tag);
  const isCodeReviewLoading = uSel((state: S) => state.smartercodes?.api?.codeReview?.isLoading);
  const isTimelineLoading = uSel((state: S) => state.smartercodes?.api?.timeline?.isLoading);
  const isRevenueLostLoading = uSel((state: S) => state.smartercodes?.api?.revenueLost?.isLoading);
  const timezone = uSel((state: S) => state.user?.settings?.timezone);
  const activeDKs = uSel((state: S) => state.dashboard?.context?.activeDKs);
  
  const requestReports = () => {
    dispatch(GetReportRouteAction({ route: ReportsRoutes.REVENUE_LOST }));
    dispatch(GetReportRouteAction({ route: ReportsRoutes.CODE_REVIEW }));
    dispatch(GetReportRouteAction({ route: ReportsRoutes.TIMELINE }));
  };

  // Reset scroll on page load
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);
  
  useWake(() => {
    const lastDay = moment(endDate, 'YYYY/MM/DD');
    const today = moment();
    const includesToday = lastDay.isSame(today, "day");
    if(includesToday) requestReports();
  }, 70);
  
  useEffect(() => {
    requestReports();
  }, [startDate, endDate, tag, timezone, activeDKs]);

  // Fades in the cards when loading finished
  useEffect(() => {
    if (!isCodeReviewLoading && !isTimelineLoading && !isRevenueLostLoading) {
      const timeoutId = setTimeout(() => dispatch(UpdateEndedAction()), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isCodeReviewLoading, isTimelineLoading, isRevenueLostLoading]);

  return (
    <React.Fragment>
      <Helmet>
        <title>intent.ly - Overview</title>
      </Helmet>
      <Row>
        <Col lg="12" xl="12" xs="12">
          <OverviewNumericalSummary />
        </Col>

        <Col lg="12" xl="12" xs="12">
          <OverviewGraphicalSummary />
        </Col>
      </Row>

      <OverviewTopTen />
    </React.Fragment>
  );
};

export default Overview;
