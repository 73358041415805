import React from 'react';
import { useSelector as uSel } from 'react-redux';
import { IAppStoreState as S } from 'redux/interfaces';
import ReactTable from 'components/ReactTable/ReactTable';
import { generateColumns } from './helpers';
import SpinnerCard from 'components/Spinner/SpinnerCard';

const CodesDailyBreakdown = (): JSX.Element => {
    const isLoading = uSel((state: S) => state?.smartercodes?.api?.codeDetails?.isLoading || false);
    const codeDetails = uSel(
        (state: S) => state?.smartercodes?.codes?.currentCode?.codeDetails || []
    );
    const tagName = uSel(
      (state: S) => state?.dashboard?.context?.tag?.tag_name
    );

    const downloadName = `Daily Breakdown${tagName ? ` - ${tagName}` : ''}`;

    if (isLoading) {
        return <SpinnerCard />;
    }
    return (
        <ReactTable
            columns={generateColumns()}
            data={codeDetails}
            download={downloadName}
            pagination
            search
            sort={{ id: 'datestamp', desc: false }}
        />
    );
};

export default CodesDailyBreakdown;
