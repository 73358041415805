import React, { FC, useCallback, useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { standardStyles, standardTheme } from '../../utils/reactSelectStyles';
import './LimitSelect.scss';

type Props = {
    defaultValue?: { value: string | number; label: string | number };
    menuIsOpen?: boolean;
    options: Array<{
        label: string | number | Object;
        value: string | number | Object;
        color?: string;
        isFixed?: boolean;
        isDisabled?: boolean;
    }>;
    onChange: Function;
    onInputChange: (inputValue:string)=>void;
    onMenuOpen?: Function;
    onMenuClose?: Function;
    onBlur: ()=>void;
    onFocus: ()=>void;
    inputValue: string;
    isFocused: boolean;
    isSearchable?: boolean;
    isClearable?: boolean;
    isLoading ?: boolean;
    isMulti?: boolean;
    label?: string;
    className?: string;
    classNamePrefix?: string;
    placeholder?: string;
    containerClassName?: string;
    value?: { value; label } | { value; label; }[];
    name?;
    styles?;
    disabled?: boolean;
    style?:Record<string, unknown>;
};

const Select: FC<Props> = (props: Props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const animatedComponents = useMemo(()=> props.isMulti ? makeAnimated() : undefined, [props.isMulti]);
    const label = props.label ? <span className="mr-4">{props.label}</span> : '';

    const handleOnChange = useCallback((input) => {
        props.onChange(input);
        if (!props.isMulti) {
            setDropdownOpen(!dropdownOpen);
        }
    },[]);

    const handleMenuClose = useCallback(() => {
        setDropdownOpen(false);
        props.onMenuClose && props.onMenuClose();
    },[setDropdownOpen, props.onMenuClose]);

    const handleMenuOpen = useCallback(() => {
        setDropdownOpen(true);
        props.onMenuOpen && props.onMenuOpen();
    },[setDropdownOpen, props.onMenuOpen]);

    return (
        <div
            className={`select-container ${
                typeof props.containerClassName === 'string' ? props.containerClassName : 'mb-3'
            }${
              props.disabled ? ' disabled' : ''
            }`}
        >
            {label}
            <ReactSelect
                autoFocus={props.isFocused}
                className={`select-input${props.className ? ` ${props.className}` : ''}`}
                classNamePrefix={props.classNamePrefix}
                closeMenuOnSelect={!props.isMulti}
                components={animatedComponents}
                defaultValue={props.defaultValue}
                inputValue={props.inputValue}
                isClearable={props.isClearable}
                isDisabled={props.disabled}
                isLoading={props.isLoading}
                isMulti={props.isMulti}
                isSearchable={props.isSearchable}
                menuIsOpen={props.menuIsOpen || dropdownOpen}
                name={props.name}
                onBlur={props.onBlur}
                onChange={handleOnChange}
                onFocus={props.onFocus}
                onInputChange={props.onInputChange}
                onMenuClose={handleMenuClose}
                onMenuOpen={handleMenuOpen}
                openMenuOnFocus={true}
                options={props.options}
                placeholder={props.placeholder}
                styles={{ ...standardStyles, ...props.style }}
                theme={standardTheme}
                value={props.value}
            />
        </div>
    );
};

export default Select;
