import React, { FunctionComponent } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

type Props = RouteComponentProps;

export const LoginAs: FunctionComponent<Props> = (props: Props) => {
    sessionStorage.clear();
    localStorage.clear();
    try {
        if (!props.location.hash.startsWith('#smc-data-')) {
            throw new Error('Missing encoded bits.');
        }
        let base64 = props.location.hash.split('-')[2];
        let decoded = JSON.parse(atob(base64));
        if (decoded.apiKey && decoded.apiSecret) {
            let authenticate = {
                key: decoded.apiKey,
                secret: decoded.apiSecret
            };
            localStorage.setItem('authenticate', JSON.stringify(authenticate));
        }
    } catch (e) {
        console.error(e);
    }
    return <Redirect to="/" />;
};
