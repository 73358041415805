import React from 'react';
import { cellBoolean, cellCurrency, cellString } from 'components/ReactTable/utils';
import { Cell } from '@tanstack/table-core';
import { history } from '../../../../../../../redux/store';

export const generateColumns = (showBasket) => [
  {
    id: 'voucherCode',
    accessorKey: 'voucherCode',
    header: 'Code',
    cell: cellString
  },
  {
    id: 'validity',
    accessorKey: 'validity',
    header: 'Valid',
    cell: cellBoolean,
    download: (cell) => {
      const validity = cell.getValue();
      return validity > 0?"Valid":"Invalid";
    }
  },
  {
    id: 'message',
    accessorKey: 'message',
    header: 'Displayed Message',
    cell: cellString,
  },
  {
    id: 'channel',
    accessorKey: 'channel',
    header: 'Channel',
    cell: cellString,
    meta: {
      onCellClick: (cell) => {
        const { channelId, channel } = cell.row.original;
        history.push({
            pathname: `/referrals`,
            search: `?type=channel&id=${channelId}&name=${channel}`
        });
      }
    }
  },
  {
    id: 'source',
    accessorKey: 'source',
    header: 'Source',
    cell: cellString,
    meta: {
      onCellClick: (cell) => {
        const { sourceId, source } = cell.row.original;
        history.push({
          pathname: `/referrals`,
          search: `?type=source&id=${sourceId}&name=${source}`
        });
      }
    }
  },
  {
    id: 'publisher',
    accessorKey: 'publisher',
    header: 'Publisher',
    cell: cellString,
    meta: {
      onCellClick: (cell) => {
        const { publisherId, publisher } = cell.row.original;
        history.push({
          pathname: `/referrals`,
          search: `?type=publisher&id=${publisherId}&name=${publisher}`
        });
      }
    }
  },
  {
    id: 'basketValue',
    accessorKey: 'basketValue',
    header: 'Basket Value',
    cell: cellCurrency,
    meta: { align: 'right' }
  },
  {
    id: 'basketView',
    accessorKey: 'basketView',
    header: 'Basket View',
    enableSorting: false,
    cell: (cell: Cell<Record<string, string | number>, unknown>): JSX.Element => {
      const text = cell?.getValue?.() as string;
      return (
        <span className="material-icons-outlined" style={{ fontSize: '1.2rem' }}>
          {text}
        </span>
      );
    },
    download: (cell) => {
      const row = cell.row.original;
      const basket = row.basket;
      // escape the double quote otherwise the CSV will be broken when the text contains double quote
      return basket?.map((item) => item.title.replace(/"/g, '""'))?.join('; ');
    },
    meta: {
      align: 'center',
      onCellClick: showBasket
    }
  }
];
