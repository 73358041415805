import { cellCurrency, cellNumber, cellPercent, cellString } from 'components/ReactTable/utils';
import { ColumnDef } from '@tanstack/table-core';
import { history } from '../../../../../../../redux/store';

type dataValue = Record<string, string | number>;
type GenerateColumns = () => (ColumnDef<dataValue, string> | ColumnDef<dataValue, number>)[];

export const generateColumns: GenerateColumns = () => [
  {
    id: 'channel',
    accessorKey: 'channel',
    header: 'Channel',
    cell: cellString,
    meta: {
      onCellClick: (cell) => {
        const { channelId, channel } = cell.row.original;
        history.push({
          pathname: `/referrals`,
          search: `?type=channel&id=${channelId}&name=${channel}`
        });
      }
    }
  },
  {
    id: 'source',
    accessorKey: 'source',
    header: 'Source',
    cell: cellString,
    meta: {
      onCellClick: (cell) => {
        const { sourceId, source } = cell.row.original;
        history.push({
          pathname: `/referrals`,
          search: `?type=source&id=${sourceId}&name=${source}`
        });
      }
    }
  },
  {
    id: 'publisher',
    accessorKey: 'publisher',
    header: 'Publisher',
    cell: cellString,
    meta: {
      onCellClick: (cell) => {
        const { publisherId, publisher } = cell.row.original;
        history.push({
          pathname: `/referrals`,
          search: `?type=publisher&id=${publisherId}&name=${publisher}`
        });
      }
    }
  },
  {
    id: 'uniqueUsers',
    accessorKey: 'uniqueUsers',
    header: 'Unique users',
    cell: cellNumber,
    meta: { align: 'right' }
  },
  {
    id: 'codeAttempts',
    accessorKey: 'codeAttempts',
    header: 'Code Attempts',
    cell: cellNumber,
    meta: { align: 'right' }
  },
  {
    id: 'valid',
    accessorKey: 'valid',
    header: 'Valid',
    cell: cellNumber,
    meta: { align: 'right' }
  },
  {
    id: 'invalid',
    accessorKey: 'invalid',
    header: 'Invalid',
    cell: cellNumber,
    meta: { align: 'right' }
  },
  {
    id: 'validPercent',
    accessorKey: 'validPercent',
    header: 'Valid Rate',
    cell: cellPercent,
    meta: {
      align: 'right',
      from: ['valid', 'codeAttempts']
    }
  },
  {
    id: 'totalDiscount',
    accessorKey: 'totalDiscount',
    header: 'Total Discount',
    cell: cellCurrency,
    meta: { align: 'right' }
  },
  {
    id: 'totalRevenue',
    accessorKey: 'totalRevenue',
    header: 'Revenue',
    cell: cellCurrency,
    meta: { align: 'right' }
  }
];
