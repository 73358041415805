export const smartercodesEn = {
    home: {
        name: 'Smarter Codes'
    },
    view: {
        highlevel: {
            name: 'Highlevel'
        },
        overview: {
            name: 'Overview'
        },
        validity: {
            name: 'Validity'
        },
        referrals: {
            name: 'Referrals'
        },
        codes: {
            name: 'Explore'
        },
        referralsLinks: {
            name: 'Referrals Links'
        }
    }
};

export const smartercodesNL = {};
