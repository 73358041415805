import React, { Fragment } from 'react';
import { Alert } from 'reactstrap';
import Spinner from 'components/Spinner/Spinner';
import ReactTable from 'components/ReactTable/ReactTable';
import { generateColumns } from '../../helpers';

interface Props {
  isTableLoading: boolean;
  error: string | null;
  tagName: string | undefined;
  tableData: any[];
}

const Table = ({ isTableLoading, error, tagName, tableData }: Props) => {
  const downloadName = `Referral Links${tagName ? ` - ${tagName}` : ''}`;

  return (
    <>
      {isTableLoading ? (
        <Spinner large />
      ) : (
        <Fragment>
          {error && (
            <Alert className="text-center" color="danger">
              {error}
            </Alert>
          )}
          {tableData ? (
            <ReactTable
              columns={generateColumns()}
              data={tableData}
              download={downloadName}
              pagination
              search
              sort={{ id: 'landings', desc: true }}
            />
          ) : null}
        </Fragment>
      )}
    </>
  );
};

export default Table;
