import { useEffect } from 'react';

/*
 * Works the same as window.setInterval, but as a React hook.
 * Will clean up after itself when the parent component is dismounted
 */
function useInterval(callback: () => void, milliseconds = 1000) {
  useEffect(() => {
    const interval = setInterval(callback, milliseconds);
    return () => {
      clearInterval(interval);
    };
  }, []);
}

export default useInterval;
