import { cellNumber, cellString } from 'components/ReactTable/utils';
import { Channel, Publisher, Source, TableRow } from './types';
import { API } from 'services/api.service';
import moment from 'moment';
import { filterBoth, stringSort } from 'utils';
import { ColumnDef } from '@tanstack/table-core';

type dataValue = Record<string, string | number>;
type GenerateColumns = () => (ColumnDef<dataValue, string> | ColumnDef<dataValue, number>)[];

export const generateColumns: GenerateColumns = () => [
  {
    id: 'channel',
    accessorKey: 'channel',
    header: 'Channel',
    cell: cellString
  },
  {
    id: 'source',
    accessorKey: 'source',
    header: 'Source',
    cell: cellString
  },
  {
    id: 'publisher',
    accessorKey: 'publisher',
    header: 'Publisher',
    cell: cellString
  },
  {
    id: 'landingLink',
    accessorKey: 'landingLink',
    header: 'Landing Link',
    cell: cellString,
    meta: { wrapText: 'wrap-text' }
  },
  {
    id: 'referralLink',
    accessorKey: 'referralLink',
    header: 'Referral Link',
    cell: cellString,
    meta: { wrapText: 'wrap-text' }
  },
  {
    id: 'landings',
    accessorKey: 'landings',
    header: 'Landings',
    cell: cellNumber,
    meta: { align: 'right' }
  }
];

interface RequestChannels {
  tagId: number;
  startDate: string;
  endDate: string;
}

// Get the list of all channels for the given tag and date
export const requestChannels = async ({
  tagId,
  startDate,
  endDate
}: RequestChannels): Promise<Channel[]> => {
  const response = await API.reports().smartercodes({
    path: '/list-channels',
    body: JSON.stringify({
      tag_index: tagId,
      start_date: startDate,
      end_date: endDate
    })
  });

  return response.data.output.channels;
};

// Get the report data by tag, date, channel, source and publisher
export async function requestReport({
  tagId,
  startDate,
  endDate,
  dynamicKeys,
  timezone,
  channels,
  sources,
  publishers
}): Promise<TableRow[]> {
  if (requestTooBig({ startDate, endDate, channels, sources, publishers })) {
    throw new Error('Request too big, reduce date range or use more specific options');
  }

  const response = await API.reports().smartercodes({
    path: '/referral-links',
    body: JSON.stringify({
      tag_index: [tagId],
      start_date: startDate,
      end_date: endDate,
      dynamic_key: JSON.stringify(dynamicKeys),
      timezone,
      channel_id: channels.map(({ id }) => id),
      source_id: sources.map(({ id }) => id),
      publisher_id: publishers.map(({ id }) => id)
    })
  });

  return response.data.output.map(
    ({ channel_id, source_id, publisher_id, landing_link, referral_link, count }) => ({
      channelId: response.data.map?.channels?.[channel_id] ?? channel_id,
      sourceId: response.data.map?.sources?.[source_id] ?? source_id,
      publisherId: response.data.map?.publisher?.[publisher_id] ?? publisher_id,
      landingLink: landing_link,
      referralLink: referral_link,
      landings: count
    })
  );
}

// Stop requests that are ludicrous
export function requestTooBig({ startDate, endDate, channels, sources, publishers }) {
  const invalidDate = moment(endDate, 'YYYY/MM/DD').diff(moment(startDate, 'YYYY/MM/DD'), 'months') > 11;
  const invalidParams = channels.length > 1 || sources.length > 1 || publishers.length > 1;

  return invalidDate && invalidParams;
}

// Extract sources from channels or publishers from sources, as one is nested inside the other
const getSubValue = (
  values: Array<Channel | Source> = [],
  key: 'sources' | 'publishers'
): Array<Source> | Publisher => {
  return values
    .filter((value) => value[key] !== undefined)
    .map((value) => value[key])
    .flat();
};

// Extract a list of sources from a list of channels
export const getSources = (values: Array<Channel> = []): Source[] => {
  const raw = getSubValue(values, 'sources') as Source[];
  const [ unknown, known ] = filterBoth(raw, (value) => value.name.toLowerCase() === 'unknown');
  const sorted = stringSort(known, ({ name }) => name);
  
  return unknown.length ? [unknown[0], ...sorted] : sorted;
};

// Extract a list of publishers from a list of sources
export const getPublishers = (values: Array<Source> = []): Publisher[] => {
  const raw = getSubValue(values, 'publishers') as Publisher[];
  const [ unknown, known ] = filterBoth(raw, (value) => value.name.toLowerCase() === 'unknown');
  const sorted = stringSort(known, ({ name }) => name);
  
  return unknown.length ? [unknown[0], ...sorted] : sorted;
};

// This is a temporary function, once the map key on /reports/smartercodes/referral-links
// endpoint gets filled, we can handle this in the requestReport function
export const fillNames = (values: TableRow[], channelsArray: Channel[]): TableRow[] => {
  const sourcesArray = getSources(channelsArray);
  const publishersArray = getPublishers(sourcesArray);

  const channelsObj = channelsArray.reduce(
    (acc, channel) => ({ ...acc, [channel.id]: channel }),
    {}
  );
  const sourcesObj = sourcesArray.reduce((acc, source) => ({ ...acc, [source.id]: source }), {});
  const publishersObj = publishersArray.reduce(
    (acc, channel) => ({ ...acc, [channel.id]: channel }),
    {}
  );

  return values.map((value) => {
    const channel = channelsObj[value.channelId]?.name ?? `id: ${value.channelId}`;
    const source = sourcesObj[value.sourceId]?.name ?? `id: ${value.sourceId}`;
    // ADA-1057: publisher is only for affiliate channel, display - instead of unknown for those cases
    const publisher = (channel === 'affiliate')? (publishersObj[value.publisherId]?.name ?? `id: ${value.publisherId}`):'-';

    return { ...value, channel, source, publisher };
  });
};
