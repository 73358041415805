import { FormatCurrencyWithStore, FormatNumberWithStore } from 'components/Locales';
import { compose, map } from 'ramda';
import { hasUniqueValues } from 'utils';
import { roundLargeNumber } from 'utils/numbers';
import { tickCount } from './constants';

export const formatTicksCurrency = (value: number, ticks: number[], tickCount: number) => {
    const hideDecimal = compose(
        hasUniqueValues,
        map((tick) => FormatCurrencyWithStore(tick, (value) => roundLargeNumber(value), 0, 0))
    )(ticks);

    if (hideDecimal)
        return FormatCurrencyWithStore(value, (value) => roundLargeNumber(value), 0, 0);
    return FormatCurrencyWithStore(value, (value) => roundLargeNumber(value));
};

export const formatChartTick = (value) => {
    const rounded = Math.round(value / 100) * 100;
    return FormatCurrencyWithStore(rounded);
};

export const defaultNumberScale = (title, is0) => ({
    title: {
        display: true,
        text: title
    },
    ticks: {
        callback: (tick: number) => FormatNumberWithStore(tick, 0, 2),
        count: tickCount
    },
    ...(is0 ? { max: 100 } : {})
});

export const defaultCurrencyScale = (is0) => ({
    title: {
        display: true,
        text: 'Currency'
    },
    ticks: {
        callback: (tick: number, _, ticks: number[]) =>
            formatTicksCurrency(
                tick,
                map(({ value }) => value, ticks),
                tickCount
            ),
        z: 100,
        count: tickCount
    },

    ...(is0 ? { max: 100 } : {})
});
