import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Spinner from './Spinner';

interface Props {
    height?: string | number;
    text?: string;
}

const SpinnerCard = ({ height, text }: Props) => {
    return (
        <Card className="spinner-card" data-testid="spinner-card" style={{ height }}>
            <CardBody>
                <Spinner large />
                {text && <span>{text}</span>}
            </CardBody>
        </Card>
    );
};

export default SpinnerCard;
