import { ISmartercodesState } from '../smartercodes.reducers';
import { IContext } from 'Dashboard/redux/dashboard.reducer';

export default function apiDataHighlevelGrouping(
    state: ISmartercodesState,
    rawApiData: any,
    context: IContext,
    extraParams: any
) {
    let newState = { ...state };
    // format `highlevel grouping` data however needed here!
    return newState;
}
