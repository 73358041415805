import { useEffect, useState } from 'react';

/**
 * Use a callback once when all the dependencies are truthy
 * @param {function} callback - The callback function
 * @param {unknown[]} dependencies - The hook dependencies, should be truthy to make callback run
 */
const useOnce = (callback: () => void, dependencies: unknown[]): void => {
  const [usedOnce, setUsedOnce] = useState(false);

  useEffect(() => {
    // If no dependency is falsy, run callback once
    if (!usedOnce && dependencies.every((dependency) => dependency)) {
      setUsedOnce(true);
      callback();
    }
  }, [...dependencies]);
};

export default useOnce;