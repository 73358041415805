import React, { FunctionComponent } from 'react';
import { Nav } from 'reactstrap';
import get from 'lodash.get';

import HeaderDropdownAccount from './HeaderDropdowns/Account';
import { connect } from 'react-redux';
import { IAppStoreState } from 'redux/interfaces';

import logo from '../../assets/img/brand/logo.svg';

const mapStateToProps = (state: IAppStoreState) => ({
    queryString: get(state, 'router.location.search', '')
});

const mapDispatchToProps = dispatch => ({
    // ...
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const Header: FunctionComponent<Props> = (props: Props) => {
    return (
        <div id="header">
            {/* <AppSidebarToggler className="d-lg-none" display="md" mobile /> */}

            <a className="navbar-brand" href={`/${props.queryString}`}>
                <img
                    alt="Smarter Click"
                    height={25}
                    src={logo}
                    width={130} 
                />
            </a>

            <Nav className="ml-auto" navbar>
                <HeaderDropdownAccount />
            </Nav>

        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
