import React, { FC, useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';

import Select from '../../../../components/Select/Select';
import './AdvancedOptions.scss';
import { addFabAction, removeFabAction } from 'redux/actions';
import { useWindowResizeEffect } from '../../../../utils/customHooks';
import { setAdvOptsAction } from '../../../redux/dashboard.actions';

// Redux
// =================================================================================================
const mapStateToProps = state => ({
    fabExists: !!state.fab.icon || !!state.fab.text,
    allDKs: state.dashboard.context.tag.dynamic_keys,
    activeDKs: state.dashboard.context.activeDKs
});

const mapDispatchToProps = dispatch => ({
    addFabAction: (icon, text, backgroundColor, onClick) =>
        dispatch(addFabAction(icon, text, backgroundColor, onClick)),
    removeFabAction: () => dispatch(removeFabAction()),
    setAdvOptsAction: activeDKs => dispatch(setAdvOptsAction(activeDKs))
});

// Helper Functions
// =================================================================================================
/*
 * Reads the height set by auto so we can animate to it later
 */
const readMaxHeight = (setAnimation, setHeight, setMaxHeight, DOMRef, setArrowRotation, props) => {
    // Grab auto height
    setAnimation({});
    setHeight('auto');
    if (Object.keys(props.allDKs).length) {
        setMaxHeight(DOMRef.current.getBoundingClientRect().height);
    }

    // Reset visuals closed
    setHeight(0);
    setArrowRotation({ transform: 'rotate(0deg)' });
    setAnimation({ transition: 'height 0.3s, opacity 0.3s' });
};

/*
 * Returns boolean whether the selects match the store
 */
const DKsMatchStore = (existingAdvOpts, newAdvOpts) => {
    const existingKeysArr = Object.keys(existingAdvOpts);
    const newKeysArr = Object.keys(newAdvOpts);

    // If the number of selected DKs !== previous search, we need to show submit button
    if (!(newKeysArr.length === existingKeysArr.length) && existingKeysArr.length > 0) {
        return false;
    }

    // Loop through and see if all the selects match the previous DKs
    else {
        let allDKsMatchFlag = true;

        for (const advOptionKey of newKeysArr) {
            const newSelect = newAdvOpts[advOptionKey];
            const newType = newSelect.type;
            const newOption = newSelect.value;

            if (newType === 'dynamicKey') {
                const prevOption = existingAdvOpts[advOptionKey];

                if (newOption !== prevOption) {
                    return false;
                }
            }
        }

        return allDKsMatchFlag;
    }
};

/*
 * Reset selects back to store values
 */
const resetSelects = (props, setSelectValues) => {
    const tempSelectValues = {};

    Object.keys(props.activeDKs).forEach(key => {
        const value = props.activeDKs[key];

        tempSelectValues[key] = { type: 'dynamicKey', label: value, value: value };
    });

    setSelectValues(tempSelectValues);
};

/*
 * Toggle Advanced Options Open or Closed
 */
const toggleOpen = (height, maxHeight, setHeight, setArrowRotation, props, setSelectValues) => {
    if (height === 0) {
        setHeight(maxHeight);
        setArrowRotation({ transform: 'rotate(180deg)' });
    } else {
        setHeight(0);
        setArrowRotation({ transform: 'rotate(0deg)' });
        props.removeFabAction();
    }
};

// Component
// =================================================================================================
type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & {};
const DynamicKeys: FC<Props> = (props: Props) => {
    const [height, setHeight]: any = React.useState('auto');
    const [maxHeight, setMaxHeight] = React.useState(0);
    const [animation, setAnimation]: any = React.useState({});
    const [arrowRotation, setArrowRotation] = React.useState({ transform: 'rotate(0deg)' });
    const DOMRef: any = React.useRef(null);
    const [sortedSelects, setSortedSelects]: [Array<any>, Function] = useState([]);
    const [selectValues, setSelectValues] = useState({});
    const [highlightExpand, setHighlightExpand] = useState('');

    /*
     * Update max height when number of selects change
     */
    useEffect(() => {
        sortedSelects.forEach(select => {
            if (props.activeDKs[select.name]) {
                let validOption = false;

                select.options.forEach(option => {
                    if (option === props.activeDKs[select.name]) {
                        validOption = true;
                    }
                });

                if (validOption) {
                    setSelectValues({
                        ...selectValues,
                        [select.name]: {
                            type: 'dynamicKey',
                            label: props.activeDKs[select.name],
                            value: props.activeDKs[select.name]
                        }
                    });
                } else {
                    props.setAdvOptsAction(selectValues);
                }
            }
        });

        setTimeout(function () {
            readMaxHeight(setAnimation, setHeight, setMaxHeight, DOMRef, setArrowRotation, props);
        }, 500);
    }, [sortedSelects]);

    /*
     * Concat all selects into a sorted array for display
     */
    useEffect(() => {
        const dynamicKeysArray = Object.keys(props.allDKs).map(dkName => {
            const options = props.allDKs[dkName];
            return { name: dkName, type: 'dynamicKey', options: [...options] };
        });
        const selectsArray = [...dynamicKeysArray /*, ...otherOptions */];

        selectsArray.sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            } else if (b.name > a.name) {
                return -1;
            }
            return 0;
        });

        setSortedSelects(selectsArray);
    }, [props.allDKs]);

    /*
     * Highlight expand button when we have active options
     */
    useEffect(() => {
        let anyActive = false;
        Object.keys(props.activeDKs).forEach(key => {
            if (props.activeDKs[key]) {
                anyActive = true;
                return;
            }
        });

        if (anyActive) {
            setHighlightExpand('active');
        } else {
            setHighlightExpand('');
        }
    }, [props.activeDKs]);

    /*
     * Add / Remove FAB for Submitting Options
     *
     * Loop through and compare each value in the store (that informs the current data on the page)
     * and see if our select value(s) match it.
     */
    useEffect(() => {
        if (DKsMatchStore(props.activeDKs, selectValues)) {
            props.removeFabAction();
        } else {
            props.addFabAction('chevron-right', 'Submit Advanced Options', '#4CAF50', () => {
                toggleOpen(height, maxHeight, setHeight, setArrowRotation, props, setSelectValues);
                props.setAdvOptsAction(selectValues);
                props.removeFabAction();
            });
        }
    }, [selectValues, props.activeDKs]);

    /*
     * Window resize custom hook
     */
    useWindowResizeEffect(() => {
        if (DOMRef.current) {
            readMaxHeight(setAnimation, setHeight, setMaxHeight, DOMRef, setArrowRotation, props);
        }
    });

    if (Object.keys(props.allDKs).length) {
        return (
            <div id="advanced-options">
                <div
                    className={`advanced-options-content ${height === 0 ? 'hide' : ''}`}
                    ref={DOMRef}
                    style={{ height: height, ...animation }}
                >
                    <div className="advanced-option-container">
                        <hr className="hr" />
                        <table style={{ width: '100%' }}>
                            <tbody>
                                {sortedSelects.map(dynamicKey => {
                                    return (
                                        <tr className="advanced-option" key={dynamicKey.name}>
                                            <td>{`${dynamicKey.name}:`}</td>
                                            <td style={{ width: '100%' }}>
                                                <Select
                                                    containerClassName=" " // remove default padding
                                                    isMulti={false}
                                                    onChange={function (option) {
                                                        setSelectValues({
                                                            ...selectValues,
                                                            [dynamicKey.name]: {
                                                                type: dynamicKey.type,
                                                                label: option.label,
                                                                value: option.value
                                                            }
                                                        });
                                                    }}
                                                    onMenuClose={() => { }}
                                                    onMenuOpen={() => { }}
                                                    options={(function () {
                                                        const emptyValue = {
                                                            value: undefined,
                                                            label: 'All',
                                                            color: '#20a8d8',
                                                            isFixed: true
                                                        };

                                                        const fullValues = dynamicKey.options.map(
                                                            option => {
                                                                return {
                                                                    value: option,
                                                                    label: option,
                                                                    color: '#20a8d8',
                                                                    isFixed: true
                                                                };
                                                            }
                                                        );

                                                        return [emptyValue, ...fullValues];
                                                    })()}
                                                    value={(function () {
                                                        const previousOrAll = selectValues[
                                                            dynamicKey.name
                                                        ] || {
                                                            value: '',
                                                            label: 'All'
                                                        };
                                                        return previousOrAll;
                                                    })()}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="advanced-options-expand">
                    <hr className="hr" />
                    <Button
                        className={`expand-button ${highlightExpand}`}
                        onClick={() => {
                            toggleOpen(
                                height,
                                maxHeight,
                                setHeight,
                                setArrowRotation,
                                props,
                                setSelectValues
                            );
                            if (height !== 0) {
                                setTimeout(function () {
                                    resetSelects(props, setSelectValues);
                                }, 500);
                            }
                        }}
                    >
                        Advanced Options
                        <i aria-hidden="true" className="fa fa-caret-down" style={arrowRotation} />
                    </Button>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicKeys);
