import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector as uSel } from 'react-redux';
import { Button, Card, CardBody, Row } from 'reactstrap';
import { IAppStoreState as S } from 'redux/interfaces';
import moment from 'moment-timezone';
import Col from 'components/CustomCols/CustomCols';
import FilterByDate from 'components/DatePicker/FilterByDate';
import Select from 'components/Select/Select';
import {
  GetReportRouteAction,
  setReferralsBreakdownAction
} from 'Dashboard/views/SmarterCodes/redux/smartercodes.actions';
import { ReportsRoutes } from 'Dashboard/views/SmarterCodes/redux/types';
import {
  focusOutOfContextAction,
  setDatesAction,
  setTagAction
} from '../../redux/dashboard.actions';
import './Context.scss';
import DynamicKeys from './DynamicKeys/AdvancedOptions';
import { isValidDate } from './helper';

const Context = (): JSX.Element => {
  const dispatch = useDispatch();
  const startDateStore = uSel((state: S) => state.dashboard.context.startDate);
  const endDateStore = uSel((state: S) => state.dashboard.context.endDate);
  const { tag_name, tag_index } = uSel((state: S) => state.dashboard.context.tag);
  const tagStore = { tag_name, tag_index };
  const tags = uSel((state: S) => state.user.smartercodes_tags);
  const showHighlevel = uSel((state: S) => state.dashboard.access.highlevel);
  const context = uSel((state: S) => state.dashboard.context);
  const dateFormat = uSel((state: any) => state?.user?.settings?.date || 'DD/MM/YYYY');
  const timezone = uSel((state: any) => state?.user?.settings?.timezone || 'UTC');
  const defaultDate = moment().tz(timezone).format('YYYY/MM/DD');

  const [startDateLocal, setStartDateLocal] = useState(startDateStore || defaultDate);
  const [endDateLocal, setEndDateLocal] = useState(endDateStore || defaultDate);
  const [tagLocal, setTagLocal] = useState({
    tag_name: tagStore.tag_name,
    tag_index: tagStore.tag_index
  });

  const readySubmit =
    startDateStore !== startDateLocal ||
    endDateStore !== endDateLocal ||
    JSON.stringify(tagStore) !== JSON.stringify(tagLocal);

  useEffect(() => {
    if (timezone && (!startDateStore || !endDateStore)) {
      dispatch(
        setDatesAction({
          startDate: startDateStore || defaultDate,
          endDate: endDateStore || defaultDate
        })
      );
    }
  }, [timezone]);

  //End For Submit button
  useEffect(() => {
    dispatch(GetReportRouteAction({ route: ReportsRoutes.CODE_REVIEW }));
  }, [context]);

  /**
   * @param tag_index index for the selected tag.
   * @description If the index is not found with the user then return a blank tag (should not happen). Sets the new name immediately locally then dispatches request for data. Incoming data will set the name again.
   */
  const onSetDropdownValueTag = (tag_index) => {
    tag_index = +tag_index; // for some reason we get a string, turn to an int
    let currentTag = tags.find((tag) => tag.tag_index === tag_index);
    if (currentTag) dispatch(setTagAction(currentTag));
    dispatch(setReferralsBreakdownAction(null));
  };

  const options = tags
    .filter(({ active }) => !!active)
    .map(({ tag_name, tag_index }) => ({ label: tag_name, value: tag_index }));

  const handlesubmit = () => {
    dispatch(setReferralsBreakdownAction(null));
    dispatch(focusOutOfContextAction());

    // Handle shorthand when date range picker is changed
    if (isValidDate(startDateLocal) && isValidDate(endDateLocal)) {
      dispatch(
        setDatesAction({
          startDate: startDateLocal,
          endDate: endDateLocal
        })
      );

      //Handle website/merchant change
      onSetDropdownValueTag(tagLocal.tag_index);
    }
  };

  return (
    <Card className="context mt-4">
      <CardBody>
        <Row
          id="context-main-body"
          style={{
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          {showHighlevel && (
            <Col
              className="tag-select-container"
              sm={{ size: 6, order: 1 }}
              style={{ flex: '2 2 auto' }}
              xl={{ size: 'auto', order: 2, offset: 0 }}
              xs="12"
            >
              <div className="tag-select-container">
                <Select
                  className="tag-select"
                  classNamePrefix="tag-select"
                  containerClassName="mb-4 mb-xl-0"
                  label="Website"
                  onChange={({ label, value }) => {
                    setTagLocal({
                      tag_name: label,
                      tag_index: value
                    });
                  }}
                  options={options}
                  placeholder="Select a Tag"
                  value={{
                    label: tagLocal.tag_name,
                    value: tagLocal.tag_index
                  }}
                />
              </div>
            </Col>
          )}

          <Col
            className="date-select-container align-items-center mb-sm-0"
            sm={{ size: 6, order: showHighlevel ? 3 : 1 }}
            style={{ flex: '1 1 auto' }}
            xl={{ size: showHighlevel ? 'auto' : 6, order: 1, offset: 0 }}
            xs="12"
          >
            <FilterByDate
              dateFormat={dateFormat}
              endDate={endDateLocal}
              pickerClassName="mb-2 mb-xl-0"
              selectClassName="mb-4 mb-sm-2 mb-xl-0"
              setEndDate={setEndDateLocal}
              setStartDate={setStartDateLocal}
              showHighLevel={showHighlevel}
              startDate={startDateLocal}
            />
          </Col>

          <Col
            className={classnames('callout-spacer', 'd-flex d-sm-flex d-xl-none')}
            sm={{ size: 6, order: 2 }}
            xs="0"
          />
          <Col
            className={classnames('justify-content-center callout-container', 'd-flex')}
            sm={{ size: 6, order: 2 }}
            xl={{ size: 'auto', order: 3 }}
            xs="12"
          >
            <Button color="primary" disabled={!readySubmit} onClick={handlesubmit}>
              Submit
            </Button>
          </Col>
        </Row>
        <DynamicKeys />
      </CardBody>
    </Card>
  );
};

export default Context;
