import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    LineController,
    BarController
} from 'chart.js';
import 'chartjs-plugin-annotation';
import merge from 'lodash.merge';
import React, { FC, useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { getStyle } from 'utils';
import { getOptions, htmlLegendPlugin } from './helpers';

// Init chart with tree shaking
ChartJS.register(
    LineController,
    BarController,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    // Plugins
    htmlLegendPlugin
);

type Props = {
    labels: Array<string>;
    datasets;
    options;
    timezone: string;
    setRef?: Function;
    height?: number;
};

const LineChart: FC<Props> = ({ labels, datasets, options, timezone, setRef, height }: Props) => {
    const lineChartData = {
        labels,
        datasets: datasets.map((item) => {
            return {
                ...item,
                backgroundColor: getStyle(item.backgroundColor),
                borderColor: getStyle(item.borderColor || item.backgroundColor),
                hoverBackgroundColor: getStyle(item.hoverBackgroundColor || item.backgroundColor),
                hoverBorderColor: getStyle(
                    item.hoverBorderColor || item.hoverBackgroundColor || item.backgroundColor
                ),
                borderWidth: item.borderWidth || 2,
                fill: item.fill || false,
                cubicInterpolationMode: 'monotone',
                tension: 0.3
            };
        })
    };

    const defaultOptions = getOptions(timezone);
    const lineChartOpts = merge(defaultOptions, options);

    const setReff = (ref) => {
        const chartRef = ref?.chartInstance;
        if (setRef && chartRef) setRef(chartRef);
    };

    // I am a horrible theme switching hack, please replace me
    // ================================================================
    const local = localStorage.getItem('theme') || 'light';
    const [theme, setTheme] = useState(local);
    const [liveTheme, setLiveTheme] = useState(local);

    useEffect(() => {
        const time = setInterval(() => {
            const local = localStorage.getItem('theme') || 'light';
            if (liveTheme !== local) setLiveTheme(local);
        }, 1000);

        return () => {
            clearInterval(time);
        };
    }, [liveTheme]);

    useEffect(() => {
        if (theme !== liveTheme) setTheme(liveTheme);
    }, [liveTheme]);

    // Chart lib doesn't clear its old tooltip on dismount
    if (theme !== liveTheme) {
        var tooltip = document.querySelector('.chart-wrapper div[class*="-tooltip"]');

        if (tooltip !== null) tooltip.parentNode!.removeChild(tooltip);
    }
    // ================================================================

    return (
        <div className="chart">
            <div className="legend-container" id="legend-container" />
            <div
                className="chart-wrapper"
                data-testid="chart-wrapper"
                style={{ height: height ? `${height}px` : '250px' }}
            >
                {theme === liveTheme && (
                    <Chart data={lineChartData} options={lineChartOpts} ref={setReff} type="line" />
                )}
            </div>
        </div>
    );
};

export default LineChart;
