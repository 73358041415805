import React from 'react';
import {
  FormatCurrencyWithStore,
  FormatDateWithStore,
  FormatNumberWithStore,
  FormatPercentWithStore
} from 'components/Locales';
import { apply, curry, map, pipe, reduce, sum } from 'ramda';
import { divide } from 'utils/numbers';
import { CellContext, HeaderContext, Row, RowData, Table } from '@tanstack/table-core';
import { calcPercentFromTotal } from '../../utils';
import { piper } from 'utils/ramda';

// Get raw value from table data
// ============================================================================
const getFilteredRows = <T,>(table: Table<T>) => table.getFilteredRowModel().rows;
export const rowValue = <T extends RowData>(key: string, row: Row<T>): number => row?.original?.[key] ?? 0;
export const cellValue = <T,>(cell: CellContext<T, number>): number => cell?.getValue?.() ?? 0;


export const cellString = (
  cell: CellContext<Record<string, string | number>, string>
): string => cell?.getValue?.() ?? '-';

// Turn raw values into presentable cell data
// ============================================================================
type CellDate = <T,>(cell: CellContext<T, number>) => string;
/**
 * A function for formatting human-readable dates from table cell data
 * @example
 * const columns = [{ header: 'Users', cell: cellDate }];
 */
export const cellDate: CellDate = pipe(cellValue, FormatDateWithStore);

type CellNumber = <T extends RowData>(cell: CellContext<T, number>) => string;
/**
 * A function for formatting strings from table cell data
 * @example
 * const columns = [{ header: 'Users', cell: cellNumber }];
 */
export const cellNumber: CellNumber = pipe(cellValue, FormatNumberWithStore);

type CellCurrency = <T extends RowData>(cell: CellContext<T, number>) => string;
/**
 * A function for formatting currency from table cell data
 * @example
 * const columns = [{ header: 'Revenue', cell: cellCurrency }];
 */
export const cellCurrency: CellCurrency = pipe(cellValue, FormatCurrencyWithStore);

type CellPercent = <T extends RowData>(cell: CellContext<T, number>) => string;
/**
 * A function for formatting percentages, from cell value
 * @example
 * const columns = [{
 *   header: 'Valid Rate',
 *   cell: cellPercent,
 *   meta: { from: ['totalAttempts', 'validAttempts'] }
 * }];
 */
export const cellPercent: CellPercent = (cell) => piper(
  cell.getValue(),
  FormatPercentWithStore,
);

type CellCurrencyAverage = <T extends RowData>(cell: CellContext<T, number>) => string;
/**
 * A function for formatting average currency, from sibling values in the same row
 * @example
 * const columns = [{
 *   header: 'Average Customer Spend',
 *   cell: cellCurrencyAverage,
 *   meta: { from: ['revenue', 'uniqueUsers'] }
 * }];
 */
export const cellCurrencyAverage: CellCurrencyAverage = (cell) => piper(
  cell.getValue(),
  FormatCurrencyWithStore
);


type CellBoolean = <T extends RowData>(cell: CellContext<T, number>) => JSX.Element;
/**
 * A function for formatting a visual tick or cross from table cell data
 * @example
 * const columns = [{ header: 'Users', cell: cellBoolean }];
 */
export const cellBoolean: CellBoolean = (cell) =>
  cell.getValue() > 0 ? (
    <span className="material-icons-outlined" style={{ color: '#4CAF50', lineHeight: 1.5 }}>
      check
    </span>
  ) : (
    <span className="material-icons-outlined" style={{ color: '#F44336', lineHeight: 1.5 }}>
      close
    </span>
  );

// Turn many cells into a single footer value
// ============================================================================
/**
 * A function for totalling number type cells into a footer total
 * @example
 * const columns = [{
 *   header: 'Users',
 *   cell: cellNumber,
 *   footer: footerNumber
 * }];
 */
export const footerNumber = <Row extends RowData>({
  table,
  column
}: HeaderContext<Row, number>): string => {
  const { id } = column;
  const rows = getFilteredRows(table);
  const getValue = curry(rowValue)(id);

  // @ts-ignore - Ramda isn't good at passing along types
  return piper(rows, map(getValue), sum, FormatNumberWithStore);
};

/**
 * A function for totalling currency type cells into a footer total
 * @example
 * const columns = [{
 *   header: 'Revenue',
 *   cell: cellCurrency,
 *   footer: footerCurrency
 * }];
 */
export const footerCurrency = <Row extends RowData>({
  table,
  column
}: HeaderContext<Row, number>): string => {
  const { id } = column;
  const rows = getFilteredRows(table);
  const getValue = curry(rowValue)(id) as (row: Row) => number;
  
  return piper(rows, map(getValue), sum, FormatCurrencyWithStore);
};

/**
 * A function for formatting percentages, from totals of other columns
 * @example
 * const columns = [{
 *   header: 'Engage Rate',
 *   cell: cellPercent,
 *   footer: footerPercent,
 *   meta: { from: ['validAttempts', 'totalAttempts'] }
 * }];
 */
export const footerPercent = <T extends RowData>({
  table,
  column
}: HeaderContext<T, number>): string => {
  const rows = getFilteredRows(table);
  const [partialKey, fullKey] = column.columnDef.meta?.from ?? [];

  return piper(
    rows,
    reduce(
      (acc, row: Row<T>) => [
        acc[0] + rowValue(partialKey, row),
        acc[1] + rowValue(fullKey, row)
      ],
      [0, 0]
    ),
    apply(calcPercentFromTotal),
    FormatPercentWithStore
  );
};

/**
 * A function for formatting currencies, from totals of other columns
 * @example
 * const columns = [{
 *   header: 'Engage Rate',
 *   cell: cellPercent,
 *   footer: footerPercent,
 *   meta: { from: ['validAttempts', 'totalAttempts'] }
 * }];
 */
export const footerCurrencyAverage = <T extends RowData>({
  table,
  column
}: HeaderContext<T, number>) => {
  const rows = getFilteredRows(table);
  const [coreKey, compareKey] = column.columnDef.meta?.from ?? [];

  return piper(
    rows,
    reduce(
      ([accCurrency, accNumber], row: Row<T>) => {
        const newCurrency = rowValue(coreKey, row);
        const newNumber = rowValue(compareKey, row);
        return [accCurrency + newCurrency, accNumber + newNumber];
      },
      [0, 0]
    ),
    ([valueCurrency, valueNumber]) => divide(valueCurrency, valueNumber),
    FormatCurrencyWithStore
  );
};

export const cellStringSliceFormatter = (props) => {
  switch (true) {
    case props.getValue().includes('invalid'):
      return 'Invalid';
    case props.getValue().includes('full'):
      return 'Full Discount';
    case props.getValue().includes('basket'):
      return 'Basket Disc.';
    case props.getValue().includes('deliver'):
      return 'Delivery Disc.';
    case props.getValue().includes('no_dis'):
      return 'No Discount';
    default:
      return '-';
  }
};
