import moment from 'moment-timezone';
import { IDashboardState } from 'Dashboard/redux/dashboard.reducer';
import { GeneralFailureAction, APIFailureAction } from 'redux/actions';
import { API } from 'services/api.service';

export enum OverlayReportsActionTypes {
    API_REQUEST = 'OVERLAYS_API_REQUEST',
    API_SUCCESS = 'OVERLAYS_API_SUCCESS',
    API_DATA = 'OVERLAYS_API_DATA',
    GROUP_DEVICE_DATA = 'GROUP_DEVICE_DATA',
    HIDE_PAUSED_CAMPAIGNS = 'HIDE_PAUSED_CAMPAIGNS',
    OPTIONAL_COLUMNS = 'OPTIONAL_COLUMNS'
}

export enum OverlayReportsRoutes {
    SINGLE_TAG = 'singleTag',
    DATA = 'overlayData',
    DAILY_DATA = 'dailyData',
    YEARLY_STATS = 'yearlyStats',
    REALTIME_DATA = 'realtimeData'
}

export interface IGetOverlayReportRouteAction {
    refresh?: boolean;
    route: OverlayReportsRoutes;
    extraParams?: {
        [key: string]: any;
    };
    readStore?: any;
}

const getPath = route => {
    switch (route) {
        case OverlayReportsRoutes.SINGLE_TAG:
            return '/last-drill-down';
        case OverlayReportsRoutes.DATA:
            return '/data';
        case OverlayReportsRoutes.DAILY_DATA:
        case OverlayReportsRoutes.YEARLY_STATS:
            return '/daily-data';
        case OverlayReportsRoutes.REALTIME_DATA:
            return '/user-funnel';
        default:
            return '';
    }
};

export const GroupDeviceDataAction = (groupDevices: boolean) => {
    return {
        type: OverlayReportsActionTypes.GROUP_DEVICE_DATA,
        value: groupDevices
    };
};

export const HidePausedCampaignsAction = (hidePaused: boolean) => {
    return {
        type: OverlayReportsActionTypes.HIDE_PAUSED_CAMPAIGNS,
        value: hidePaused
    };
};

export const CampaignPerformanceOptionalColumnsAction = (columns: string[]) => {
    return {
        type: OverlayReportsActionTypes.OPTIONAL_COLUMNS,
        value: columns
    };
};

export const GetOverlayReportRouteAction = ({
    refresh,
    route,
    extraParams = {},
    readStore
}: IGetOverlayReportRouteAction) => async (dispatch, getState) => {
    const state = getState();
    const { context }: IDashboardState = state.dashboard;
    const format = 'YYYY-MM-DD';

    if (!context.multiTags.length) {
        dispatch(
            GeneralFailureAction(
                { refresh, route, extraParams },
                'A tag should have been specified and it was not.'
            )
        );
        return;
    }

    const body = {
        ...extraParams,
        start_date: moment(context.startDate).format(format),
        end_date: moment(context.endDate).format(format),
        tag_index: context.multiTags.map(tag => tag.tag_index),
        timezone: state.user.settings.timezone
    };

    if (route === OverlayReportsRoutes.YEARLY_STATS) {
        body.start_date = moment()
            .subtract(365, 'days')
            .format(format);
        body.end_date = moment().format(format);
    }

    const newRequestBodyHash = JSON.stringify(body);

    const baseData = state.overlays.api[route];

    let isLoading;
    let data;
    let requestBodyHash;

    if (extraParams.graph_number) {
        const isLoadingObj = baseData.isLoading;
        const dataObj = baseData.data;
        const requestHashObj = baseData.requestBodyHash;

        if (isLoadingObj) {
            isLoading = isLoadingObj[extraParams.graph_number];
        }

        if (dataObj) {
            data = dataObj[extraParams.graph_number];
        }

        if (requestHashObj) {
            requestBodyHash = requestHashObj[extraParams.graph_number];
        }
    } else if (extraParams.pixel_table_number) {
        const isLoadingObj = baseData.isLoading;
        const dataObj = baseData.data;
        const requestHashObj = baseData.requestBodyHash;

        if (isLoadingObj) {
            isLoading = isLoadingObj[extraParams.pixel_table_number];
        }

        if (dataObj) {
            data = dataObj[extraParams.pixel_table_number];
        }

        if (requestHashObj) {
            requestBodyHash = requestHashObj[extraParams.pixel_table_number];
        }
    } else {
        isLoading = baseData.isLoading;
        data = baseData.data;
        requestBodyHash = baseData.requestBodyHash;
    }

    const isSame = requestBodyHash === newRequestBodyHash;

    const formData = new FormData();

    Object.keys(body).forEach(key => {
        formData.append(key, body[key]);
    });

    if ((!isSame || (isSame && refresh)) && isLoading !== true) {
        //If ask for refresh, then do it anyway.
        dispatch({
            type: OverlayReportsActionTypes.API_REQUEST,
            route,
            ...(extraParams.graph_number && { graphNumber: extraParams.graph_number }),
            ...(extraParams.pixel_table_number && { tableNumber: extraParams.pixel_table_number })
        });

        try {
            let { data } = await API.reports().overlays({
                path: getPath(route),
                body: formData
            });

            dispatch({
                type: OverlayReportsActionTypes.API_DATA,
                data,
                route,
                context,
                requestBodyHash: newRequestBodyHash,
                extraParams,
                readStore
            });
        } catch (error) {
            dispatch(
                // @ts-ignore
                APIFailureAction(error.response, error.errorMessage + ' (GetReportRouteAction)')
            );
        }
    } else if (isLoading) {
        return;
    } else {
        dispatch({
            type: OverlayReportsActionTypes.API_DATA,
            data,
            route,
            context,
            requestBodyHash: newRequestBodyHash,
            extraParams,
            readStore
        });
    }
};
