import React, { useEffect } from 'react';
import cx from 'classnames';
import kebabCase from 'lodash/kebabCase';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import { IAppStoreState as S } from 'redux/interfaces';
import { hideModalAction, showModalAction } from '../../redux/actions';
import CodeSelectContent from './content/CodeSelectModalContent';
import LoginContent from './content/LoginModal/LoginModal';
import MfaContent from './content/MfaModalContent';
import StandardContent from './content/StandardModalContent';
import UploadImageContent from './content/UploadImageContent';
import './Modal.scss';
import ReleaseNotesContent from './ReleaseNotesModalContent/ReleaseNotesModalContent';
import BasketModal from './content/BasketModal';
import usePersist from 'utils/customHooks/usePersist';

const ModalIndex = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state: S) => state.auth);
  const modal = useSelector((state: S) => state.modal);

  const { loggedIn, checkingStatus } = auth;
  const { currentModal, executionQueue, isShow } = modal;
  
  const shouldPersist = Object.keys(currentModal).length > 0;
  const persistModal = usePersist(shouldPersist ? currentModal : undefined ) ?? {};

  // @ts-ignore
  const { modalType, content } = persistModal;
  const { basket } = content ?? {};

  // If there is no other modal open and we have modal/s in the queue, show next one
  useEffect(() => {
    if (!isShow && executionQueue.length > 0) dispatch(showModalAction());
  }, [isShow, executionQueue]);

  // If we're logged in, try to auto sign in and hide the modal
  useEffect(() => {
    if (modalType === 'loginModal' && auth && !checkingStatus && loggedIn && isShow)
      dispatch(hideModalAction());
  }, [modalType, auth, checkingStatus, loggedIn, isShow]);

  return ReactDOM.createPortal(
    <Modal
      backdropClassName={kebabCase(modalType)}
      centered={checkingStatus}
      className={cx(kebabCase(modalType), modalType === 'releaseNotes' ? 'modal-lg' : '')}
      isOpen={isShow}
      style={{ marginBottom: checkingStatus ? '5vh' : '' }}
      toggle={() => {
        if (modalType !== 'loginModal') dispatch(hideModalAction());
      }}
    >
        {(() => {
          if (modalType === 'loginModal') return <LoginContent />;
          if (modalType === 'mfaModalAdd') return <MfaContent add={true} />;
          if (modalType === 'mfaModalDelete') return <MfaContent delete={true} />;
          if (modalType === 'codeSelectModal') return <CodeSelectContent />;
          if (modalType === 'uploadProfilePic')
            return <UploadImageContent imageType="profile_image" />;
          if (modalType === 'uploadProfileBanner') return <UploadImageContent imageType="banner" />;
          if (modalType === 'releaseNotes') return <ReleaseNotesContent />;
          if (modalType === 'standard') return <StandardContent />;
          if (modalType === 'basket') return <BasketModal basket={basket} />;
          return null;
        })()}
    </Modal>,
    document.getElementById('modal-root') as Element
  );
};

export default ModalIndex;
