import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from './Account.routes';

const Account = () => {
    return (
        <>
            {routes.map((route) => (
                <Route
                    component={route.component}
                    exact={route.isExact || false}
                    // i18nKey={route.i18nKey}
                    key={route.key}
                    path={route.path}
                />
            ))}
        </>
    );
};

export default Account;
