import React from 'react';
import { useSelector as uSel } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import ReactTable from 'components/ReactTable/ReactTable';
import Spinner from 'components/Spinner/Spinner';
import { IAppStoreState as S } from 'redux/interfaces';
import { generateColumns } from './helpers';
import { Cell } from '@tanstack/table-core';

type dataValue = Record<string, string | number>;
interface Props {
  onCellClick: (cell: Cell<dataValue,unknown>, index: number) => void,
}

const ReferralsDetailed = ({ onCellClick }: Props): JSX.Element => {
  const referrals = uSel((state: S) => state?.smartercodes?.referrals || {});
  const isLoading = uSel((state: S) => state?.smartercodes?.api?.advanceView?.isLoading || false);
  const tagName = uSel((state: S) => state?.dashboard?.context?.tag?.tag_name);

  const downloadName = `Referrals Summary${tagName ? ` - ${tagName}` : ''}`;
  const tableData  = referrals.referralsList ?? [];
  
  return (
    <Card className="card-table">
      <CardBody>
        {isLoading ? (
          <Spinner large />
        ) : (
          <ReactTable
            columns={generateColumns(onCellClick)}
            data={tableData}
            download={downloadName}
            footer
            pagination
            search
            sort={{ id: 'uniqueUsers', desc: true }}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default ReferralsDetailed;
