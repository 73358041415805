export const ripple = (e) => {
    const { target, clientY, clientX } = e;

    target.classList.remove('ink-ripple-play');
    const { top, left } = target.getBoundingClientRect();

    target.style.setProperty('--top', `${clientY - top}px`);
    target.style.setProperty('--left', `${clientX - left}px`);
    target.classList.add('ink-ripple-play');
};
