import { IMenuItem, IRouteItem } from 'index.types';
import Codes from './views/Codes/Codes';
import Overview from './views/Overview/Overview';
import Referrals from './views/Referrals/Referrals';
import Validity from './views/Validity/Validity';
import ReferralsLinks from './views/ReferralsLinks/ReferralsLinks';

//Smarter click Routes
// const Overview = React.lazy(() => import('./views/Overview/Overview'));
// const Validity = React.lazy(() => import('./views/Validity/Validity'));
// const Referrals = React.lazy(() => import('./views/Referrals/Referrals'));

const routes: IRouteItem[] = [
    {
        path: '/explore',
        i18nKey: 'smartercodes.view.codes',
        component: Codes,
        key: 20,
        requiresTag: true
    },
    {
        path: '/overview',
        i18nKey: 'smartercodes.view.overview',
        component: Overview,
        key: 25,
        requiresTag: true
    },
    {
        path: '/validity',
        i18nKey: 'smartercodes.view.validity',
        component: Validity,
        key: 40,
        requiresTag: true
    },
    {
        path: '/referrals',
        i18nKey: 'smartercodes.view.referrals',
        component: Referrals,
        key: 50,
        requiresTag: true
    },
    {
        path: '/referral-links',
        i18nKey: 'smartercodes.view.referralsLinks',
        component: ReferralsLinks,
        key: 60,
        requiresTag: true
    }
];
const SmarterCodesItems: IMenuItem[] = [
    {
        i18nKey: 'smartercodes.view.overview.name',
        url: '/overview',
        icon: 'space_dashboard',
        key: 10,
        requiresTag: true
    },
    {
        i18nKey: 'smartercodes.view.codes.name',
        url: '/explore',
        icon: 'style',
        key: 60,
        requiresTag: true
    },
    {
        i18nKey: 'smartercodes.view.referrals.name',
        url: '/referrals',
        icon: 'signpost',
        key: 40,
        requiresTag: true
    },
    {
        i18nKey: 'smartercodes.view.validity.name',
        url: '/validity',
        icon: 'thumbs_up_down',
        key: 30,
        requiresTag: true
    },
    {
        i18nKey: 'smartercodes.view.referralsLinks.name',
        url: '/referral-links',
        icon: 'link',
        key: 50,
        requiresTag: true
    }
];

export { SmarterCodesItems, routes };
