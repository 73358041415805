import React, { useEffect, CSSProperties } from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.scss';

type Props = {};

// TODO: Refactor this into a general purpose Toast component if needed. At the moment, it's only designed for error messages

const Toast: React.FC<Props> = ({ children }) => {
    useEffect(() => {
        displayError();
    }, []);

    const formattedErrorMessage = () => <b>{children}</b>;

    const displayError = () => {
        toast.error(formattedErrorMessage(), {
            position: toast.POSITION.BOTTOM_CENTER,
            className: 'toast-error',
            bodyClassName: 'toast-error-body'
        });
    };

    const containerStyle: CSSProperties = {
        zIndex: 10000,
        textAlign: 'center'
    };

    return (
        <div className="animated">
            <ToastContainer
                autoClose={6000}
                position="top-right"
                style={containerStyle}
                transition={Slide}
            />
        </div>
    );
};

export default Toast;
