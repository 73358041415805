import { IContext } from 'Dashboard/redux/dashboard.reducer';
import get from 'lodash.get';
import { ChartProps } from 'react-chartjs-2';
import { calcPercentFromTotal, isCodeStatusIdValid } from '../../../../../utils';
import {
    EValidityTypes,
    ISmartercodesState,
    TCodeReviewData,
    TCodeReviewDataOutput
} from '../smartercodes.reducers';

export enum validityTypes {
    valid = 'VALID',
    invalid = 'INVALID'
}

/**
 * combine duplicate code items by adding them to a keyed object
 * also sorts codes into two types: valid and invalid
 */
export function sortByValidity(rawItem: any, validityMap: any) {
    const codeKey = `${rawItem.name}`;
    const codeId = rawItem.code_id;

    if (isCodeStatusIdValid(rawItem.status_id)) {
        //TODO: double check the values generated by this block, they don't match the v1 client dashboard!!!
        const nextCount = get(rawItem, 'code_attempts') || 0;
        const nextRevenue = get(rawItem, 'revenue') || 0;
        const nextMaxDiscount = get(rawItem, 'max_discount');
        const nextMinDiscount = get(rawItem, 'min_discount');

        const prevCount = get(validityMap[validityTypes.valid][codeKey], `count`) || 0;
        const prevRevenue = get(validityMap[validityTypes.valid][codeKey], `revenue`) || 0;
        const prevMaxDiscount = get(
            validityMap[validityTypes.valid][codeKey],
            `maxDiscount`,
            nextMaxDiscount
        );
        const prevMinDiscount = get(
            validityMap[validityTypes.valid][codeKey],
            'minDiscount',
            nextMinDiscount
        );

        validityMap[validityTypes.valid][codeKey] = {
            codeId,
            count: prevCount + nextCount,
            revenue: prevRevenue + nextRevenue,
            maxDiscount: nextMaxDiscount > prevMaxDiscount ? nextMaxDiscount : prevMaxDiscount,
            minDiscount: nextMinDiscount < prevMinDiscount ? nextMinDiscount : prevMinDiscount
        };
    } else {
        const prevCount = get(validityMap[validityTypes.invalid][codeKey], `count`) || 0;
        const prevRevenue = get(validityMap[validityTypes.invalid][codeKey], `revenue`) || 0;

        const nextCount = get(rawItem, 'code_attempts') || 0;
        const nextRevenue = get(rawItem, 'revenue') || 0;

        validityMap[validityTypes.invalid][codeKey] = {
            codeId,
            count: prevCount + nextCount,
            revenue: prevRevenue + nextRevenue
        };
    }
}

/**
 * generates a summary from all items returned by codeReview api
 */
export function incrementSummary(rawItem: any, accumulator: any) {
    const isValid = isCodeStatusIdValid(rawItem.status_id);

    accumulator.codeAttempts += get(rawItem, 'code_attempts') || 0;
    accumulator.codeRevenue += get(rawItem, 'revenue') || 0;

    if (isValid) {
        accumulator.validCodeAttempts += get(rawItem, 'code_attempts') || 0;
        accumulator.validCodesRevenue += get(rawItem, 'revenue') || 0;
    } else {
        accumulator.invalidCodeAttempts += get(rawItem, 'code_attempts') || 0;
        accumulator.invalidCodesRevenue += get(rawItem, 'revenue') || 0;
    }
}

const validity = (data: TCodeReviewDataOutput[], rawApiData: TCodeReviewData) => {
    let defaults = rawApiData.map.stats;

    let arr = data.reduce(
        (acc, datum, i) => {
            acc[datum.status_id].push({
                ...defaults,
                ...datum
            });
            return acc;
        },
        {
            0: [],
            1: [],
            2: [],
            3: [],
            4: []
        }
    );

    let overall: any = {},
        detailed: any = {};

    Object.keys(EValidityTypes).forEach((key, i) => {
        overall[key] = {
            key,
            attempts: 0,
            orders: 0,
            conversion_rate: 0,
            revenue: 0,
            average_order_value: 0,
            total_discount_value: 0,
            average_discount_value: 0,
            min_discount: 100,
            average_discount: 0,
            max_discount: 0,
            hasUpdated: false
        };
        detailed[key] = arr[i].map((datum: TCodeReviewDataOutput, i) => {
            overall[key].hasUpdated = true;
            overall[key].attempts += datum.code_attempts || 0;
            overall[key].orders += datum.orders || 0;
            overall[key].revenue += datum.revenue || 0;
            overall[key].total_discount_value +=
                datum.revenue > 0 && datum.status_id > 0
                    ? datum.delivery_discount + datum.basket_discount
                    : 0;

            overall[key].max_discount =
                Math.max(overall[key].max_discount, datum.max_discount) || 0;
            overall[key].min_discount =
                Math.min(overall[key].min_discount, datum.min_discount) || 0;

            return {
                ...datum,
                name: `${datum.name}`,
                key: i,
                conversion_rate: (datum.orders / datum.code_attempts) * 100 || 0,
                average_order_value: datum.revenue / datum.orders || 0,
                total_discount_value:
                    datum.revenue > 0 && datum.status_id
                        ? datum.delivery_discount + datum.basket_discount
                        : 0,
                average_discount_value:
                    (datum.delivery_discount + datum.basket_discount) / datum.code_attempts || 0
            };
        });

        overall[key].conversion_rate = calcPercentFromTotal(
            overall[key].orders,
            overall[key].attempts
        );

        overall[key].min_discount = overall[key].hasUpdated ? overall[key].min_discount : '-';
        overall[key].max_discount = overall[key].hasUpdated ? overall[key].max_discount : '-';

        // console.log('key', key, overall[key]);
        overall[key].conversion_rate = (overall[key].orders / overall[key].attempts) * 100 || 0;
        overall[key].average_discount_value =
            overall[key].total_discount_value / overall[key].attempts || 0;

        overall[key].average_order_value = overall[key].revenue / overall[key].orders || 0;
    });
    // console.log('overall', overall);
    let pieDatasets: ChartProps<'pie', (number | null)[], any>['data']['datasets'] = [
        {
            data: [
                overall[EValidityTypes.valid_codes_full_discount].attempts,
                overall[EValidityTypes.valid_codes_basket_discount].attempts,
                overall[EValidityTypes.valid_codes_delivery_discount].attempts,
                overall[EValidityTypes.valid_codes_no_discount].attempts,
                overall[EValidityTypes.invalid_codes].attempts
            ],
            backgroundColor: ['--success', '--primary', '--info', '--warning', '--danger'],
            hoverBackgroundColor: [
                '--success-hover',
                '--primary-hover',
                '--info-hover',
                '--warning-hover',
                '--danger-hover'
            ]
        }
    ];

    let ret: any = {
        // ...newState.validity,
        pieDatasets,
        overall,
        detailed
    };
    return ret;
};

/**
 * Helper functions for createAllEntries() and createCombinedEntries()
 */
const calcAverageDiscount = (deliveryDiscount = 0, basketDiscount = 0, orders = 0) => {
    if ((deliveryDiscount || basketDiscount) && orders) {
        return (deliveryDiscount + basketDiscount) / orders;
    } else {
        return 0;
    }
};

const calcAverageDiscountPercent = (revenue = 0, basketDiscount = 0, deliveryDiscount = 0) => {
    if ((basketDiscount || deliveryDiscount) && revenue) {
        return (
            ((basketDiscount + deliveryDiscount) / (revenue + basketDiscount + deliveryDiscount)) *
            100
        );
    } else {
        return 0;
    }

    // if (deliveryValue && revenue && basketDiscount && deliveryDiscount) {
    //     return (
    //         ((deliveryValue + basketDiscount) / (revenue + basketDiscount + deliveryDiscount)) * 100
    //     );
    // } else {
    //     return 0;
    // }
};

const calcTotalDiscount = (deliveryDiscount = 0, basketDiscount = 0, revenue, status) => {
    return revenue > 0 && status > 0 ? deliveryDiscount + basketDiscount : 0;
};

/**
 * Create a new entry from api data
 */
const createAllEntries = (rawItem, allEntries) => {
    const data = {
        ...rawItem, // TODO: don't deconstruct rawItem because it's camel case raw data, instead pick only keys you want, as done below
        name: `${rawItem.name}`,
        codeId: rawItem.code_id,
        totalRevenue: rawItem.revenue || 0,
        totalAttempts: rawItem.code_attempts || 0,
        totalOrders: rawItem.orders || 0,
        averageDiscount: calcAverageDiscount(
            rawItem.delivery_discount,
            rawItem.basket_discount,
            rawItem.orders
        ),
        totalDiscount: calcTotalDiscount(
            rawItem.delivery_discount,
            rawItem.basket_discount,
            rawItem.revenue,
            rawItem.status_id
        ),
        averageDiscountPercent: calcAverageDiscountPercent(
            rawItem.revenue,
            rawItem.basket_discount,
            rawItem.delivery_discount
        ),
        minDiscount: rawItem.min_discount,
        maxDiscount: rawItem.max_discount
    };

    allEntries.push(data);
};

/**
 * Combine entries with matching code ID
 */
const createCombinedEntries = (rawItem, combinedEntries) => {
    const id = rawItem.code_id;
    if (!id) return;

    const codeAttempts = rawItem.code_attempts || 0;
    let combined = combinedEntries[id];
    const deliveryDiscount = get(rawItem, 'delivery_discount', 0);
    const basketDiscount = get(rawItem, 'basket_discount', 0);
    const status = get(rawItem, 'status_id', 0);

    // create new code, empty if doesn't exist
    if (!combined) {
        combined = {
            name: `${rawItem.name}` || '',
            codeId: id,
            totalRevenue: 0,
            totalAttempts: 0,
            totalOrders: 0,
            totalDiscount: 0,
            validAttempts: 0,
            invalidAttempts: 0,
            minDiscount: Infinity,
            maxDiscount: -Infinity,
            totalRevenueWithDiscounts: 0,
            totalOrdersWithDiscounts: 0,
            totalDiscountPercentage: 0
        };
    }

    // increment totals
    combined.totalRevenue += rawItem.revenue || 0;
    combined.totalAttempts += codeAttempts;
    combined.totalOrders += rawItem.orders || 0;
    combined.totalDiscount += deliveryDiscount + basketDiscount;
    combined.totalRevenueWithDiscounts +=
        deliveryDiscount === 0 && basketDiscount === 0 ? 0 : rawItem.revenue || 0;

    if (isCodeStatusIdValid(status)) {
        combined.validAttempts += codeAttempts;
    } else {
        combined.invalidAttempts += codeAttempts;
    }

    // compare max/min values
    combined.minDiscount = Math.min(combined.minDiscount, get(rawItem, 'min_discount', Infinity));
    combined.maxDiscount = Math.max(combined.maxDiscount, get(rawItem, 'max_discount', -Infinity));

    // finally overwrite previous data
    combinedEntries[id] = combined;
};

export default function apiDataCodeReview(
    state: ISmartercodesState,
    rawApiData: TCodeReviewData,
    context: IContext,
    extraParams: any
) {
    let newState = { ...state };

    const codeReviewOutput = get(rawApiData, 'output', []);

    const validityMap = {
        [validityTypes.valid]: {},
        [validityTypes.invalid]: {}
    };
    //...Object.keys(validityTypes).map(type => validityTypes[type])

    const summary = {
        codeAttempts: 0,
        codeRevenue: 0,
        validCodeAttempts: 0,
        validCodesRevenue: 0,
        invalidCodeAttempts: 0,
        invalidCodesRevenue: 0
    };

    const allEntries = [];
    const combinedEntries = {};

    codeReviewOutput.forEach((rawItem) => {
        sortByValidity(rawItem, validityMap); // not a pure function, will modify map

        incrementSummary(rawItem, summary); // not a pure function, will modify summary

        createAllEntries(rawItem, allEntries); // not a pure function, will modify allEntries

        createCombinedEntries(rawItem, combinedEntries); // not a pure function, will modify combinedEntries
        // TODO: add additional methods here for calculating other info
    });

    const validCount = Object.keys(validityMap[validityTypes.valid]).reduce((total, key) => {
        const item = validityMap[validityTypes.valid][key];
        return total + (item.count || 0);
    }, 0);
    const invalidCount = Object.keys(validityMap[validityTypes.invalid]).reduce((total, key) => {
        const item = validityMap[validityTypes.invalid][key];
        return total + (item.count || 0);
    }, 0);

    newState.validity = {
        ...newState.validity,
        ...validity(codeReviewOutput, rawApiData)
    };

    newState.overview = {
        ...newState.overview,
        validCount,
        invalidCount,
        valid: Object.keys(validityMap[validityTypes.valid])
            .map((key) => ({ ...validityMap[validityTypes.valid][key], code: key, key }))
            .sort((a: any, b: any) => b.count - a.count)
            .slice(0, 10),
        invalid: Object.keys(validityMap[validityTypes.invalid])
            .map((key) => ({ ...validityMap[validityTypes.invalid][key], code: key, key }))
            .sort((a: any, b: any) => b.count - a.count)
            .slice(0, 10),
        summary
    };

    // Update the current code if we have picked one
    newState.codes.currentCode = {
        ...newState.codes.currentCode,
        combinedCurrentEntry: {
            ...get(newState.codes.currentCode, 'combinedCurrentEntry', undefined),
            identity: get(state.codes.currentCode, 'combinedCurrentEntry.identity', [])
        }
    };

    newState.codes = {
        ...newState.codes,
        allEntries,
        combinedEntries
    };

    // TODO: add to any other page's store keys here!

    return newState;
}
