import React, { FC, ReactChild, useState, useEffect } from 'react';
import { Tooltip as ReactStrapTooltip } from 'reactstrap';

import './Tooltip.scss';

/**
 * generates a random unique ID
 */
function generateRandomId() {
    let random = '';
    for (let i = 0; i < 4; i++) {
        random += Math.random()
            .toString(36)
            .substring(2, 15);
    }
    return `tooltip_${random}`;
}

type Props = {
    // required
    children: ReactChild | string;
    // optional
    id?: string;
    placement?: 'right' | 'left';
    className?: string;
    style?: object;
    testId?: string;
    icon?: JSX.Element;
};

const Tooltip: FC<Props> = (props: Props) => {
    const [isTooltipOpen, toggleTooltip] = useState(false);

    // if no id explicitly defined gen a random one
    const [id, setId] = useState(props.id || generateRandomId());

    // if an id is set later, component should update internal id!
    useEffect(() => {
        if (props.id && id !== props.id) setId(props.id);
    }, [props.id, id]);

    return (
        <>
            {props.icon ? (
                props.icon
            ) : (
            <span
                className={`material-icons-outlined pl-1${props.className ? ' ' + props.className : ''}`}
                data-testid={props.testId || `tooltip-${props.id ?? id}`}
                id={props.id ?? id}
                style={{ cursor: 'pointer', fontSize:20, ...props.style }}>
                info
            </span>
            )}
            
            <ReactStrapTooltip
                className="custom-tooltip"
                delay={{ show: 0, hide: 250 }}
                isOpen={isTooltipOpen}
                placement={props.placement || 'right'}
                target={props.id ?? id}
                toggle={() => toggleTooltip(!isTooltipOpen)}
            >
                {props.children}
            </ReactStrapTooltip>
        </>
    );
};

export default Tooltip;
