import { ISmartercodesState } from '../smartercodes.reducers';
import { AdvanceViewExtra } from './types';

export default function apiDataAdvanceViewExtra(
  state: ISmartercodesState,
  rawApiData: AdvanceViewExtra
) {
  let newState = { ...state };
  const output = rawApiData?.output ?? [];
  const map = rawApiData?.map ?? {};

  const publisherBreakdownList = output.map((rawItem) => ({
    codeId: rawItem.code_id,
    channel: map.channels[rawItem.channel_id],
    source: map.sources[rawItem.source_id],
    // ADA-1057: publisher is only for affiliate channel, display - instead of unknown for those cases
    publisher: map.channels[rawItem.channel_id] === 'affiliate' ? map.publishers[rawItem.publisher_id] : '-',
    code: `${rawItem.code_name ?? ''}`,
    codeAttempts: rawItem.code_attempts ?? 0,
    validAttempts: rawItem.valid ?? 0,
    invalidAttempts: (rawItem.code_attempts ?? 0) - (rawItem.valid ?? 0),
    uniqueUsers: rawItem.unique_users ?? 0,
    orders: rawItem.orders ?? 0,
    revenue: rawItem.revenue ?? 0,
    discountValue: (rawItem.discount_basket ?? 0) + (rawItem.discount_delivery ?? 0),
    averageOrderValue: (rawItem.revenue ?? 0) / (rawItem.orders ?? 0) || 0
  }));

  newState.referrals = {
    ...newState.referrals,
    publisherBreakdownList
  };

  return newState;
}
