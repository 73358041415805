import { ISmartercodesState } from '../smartercodes.reducers';
import { AdvanceView } from './types';
import { calcPercentFromTotal } from '../../../../../utils';

export default function apiDataAdvanceView(state: ISmartercodesState, rawApiData: AdvanceView) {
  let newState = { ...state };
  const output = rawApiData?.output ?? [];
  const map = rawApiData?.map ?? {};

  const referralsList = output.map((rawItem) => ({
    channelId: rawItem.channel_id ?? 0,
    channel: map.channels[rawItem.channel_id] ?? '',
    sourceId: rawItem.source_id ?? 0,
    source: map.sources[rawItem.source_id] ?? '',
    publisherId: rawItem.publisher_id ?? 0,
    // ADA-1057: publisher is only for affiliate channel, display - instead of unknown for those cases
    publisher: map.channels[rawItem.channel_id] === 'affiliate' ? map.publishers[rawItem.publisher_id] : '-' ?? '',
    uniqueUsers: rawItem.unique_users ?? 0,
    codeAttempts: rawItem.code_attempts ?? 0,
    validAttempts: rawItem.valid ?? 0,
    validRate: calcPercentFromTotal(
      rawItem.valid ?? 0,
      rawItem.code_attempts ?? 0,
    ),
    orders: rawItem.orders ?? 0,
    conversionRate: calcPercentFromTotal(
      rawItem.orders ?? 0,
      rawItem.unique_users ?? 0,
    ),
    revenue: rawItem.revenue ?? 0,
    discountValue: (rawItem.discount_basket ?? 0) + (rawItem.discount_delivery ?? 0),
    averageOrderValue: (rawItem.revenue ?? 0) / (rawItem.orders ?? 0) || 0
  }));

  newState.referrals = {
    ...newState.referrals,
    referralsList
  };

  return newState;
}
