export const navEn = {
    main: {
        home: 'Home',
        dashboard: 'Dashboard',
        smartercodes: 'Smarter Codes',
        account: 'Account',
        help: 'Help'
    },
    help: {
        home: 'Help',
        faq: 'Faq'
    },
    account: {
        home: 'Account',
        profile: 'Profile'
    },
    smartercodes: {
        home: 'SmarterCodes Home',
        timeline: 'Timeline',
        overview: 'Overview'
    }
};

export const navNl = {};
