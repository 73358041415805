import { ErrorActionType } from '../actions/error.actions';

export interface IErrorState {
    data: any;
    fatal: boolean;
    is: boolean;
    message: string;
}

const initialState: IErrorState = {
    data: {},
    fatal: false,
    message: '',
    is: false
};

export function error(state: IErrorState = initialState, action): IErrorState {
    switch (action.type) {
        // case ErrorActionType.AUTH_FAILURE:
        case ErrorActionType.API_FAILURE:
        case ErrorActionType.GENERAL_FAILURE:
            return {
                data: action.data,
                message: action.message,
                is: true,
                fatal: action.fatal
            };

        default:
            return state;
    }
}
