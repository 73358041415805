import { readQueryString } from 'utils';
import { ReportsRoutes } from './types';

export const getInitialStringValue = (queryStringId, emptyValue) => {
    let value = emptyValue;
    const qsCodes = readQueryString(queryStringId);

    if (qsCodes) {
        value = JSON.parse(qsCodes);
    }

    return value;
};

export const getInitialArrayValue = (queryStringId, emptyValue) => {
    let value: Array<{ codeId: number }> = emptyValue;
    const qsCodes = readQueryString(queryStringId);

    if (qsCodes) {
        value = qsCodes.split(',').map(id => ({ codeId: +id }));
    }

    return value;
};

export const getPath = (route: ReportsRoutes) => {
    switch (route) {
        case ReportsRoutes.OVERVIEW:
            return '/overview';
        case ReportsRoutes.ADVANCE_VIEW:
            return '/advance-view';
        case ReportsRoutes.FILTER_CODES:
            return '/filter-codes';
        case ReportsRoutes.ADVANCE_VIEW_EXTRA:
            return '/advance-view';
        case ReportsRoutes.CODE_DETAILS:
            return '/code-details';
        case ReportsRoutes.CODE_REVIEW:
            return '/code-review';
        case ReportsRoutes.HIGHLEVEL_GROUPING:
            return '/high-level-grouping';
        case ReportsRoutes.REVENUE_LOST:
            return '/revenue-lost';
        case ReportsRoutes.TIMELINE:
            return '/timeline';
        case ReportsRoutes.TOP_PUBLISHERS:
            return '/top-publishers';
        case ReportsRoutes.UNIQUE_USERS:
            return '/unique-users';
        default:
            return '';
    }
};
