import React, { Fragment, FunctionComponent, useState } from 'react';
import {
    Button,
    ButtonProps,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    InputProps,
    Label,
    Row,
    Alert
} from 'reactstrap';
import { isPasswordValid } from '../../../../utils';
import phoneImg from '../../../../assets/img/mfa/qr-scan-animation.gif';
import Spinner from 'components/Spinner/Spinner';

type Props = {
    requestInProcess: any;
    updateMFAConfirmAction: any;
    updateMFAInitiateAction: any;
    haveQRCode: any;
    QRCode: any;
    success: any;
    changeMFAResetErrorAction: any;
};
export const ModalMFASetup: FunctionComponent<Props> = (props: Props) => {
    const [codeInputCurrentValue, setCodeInputCurrentValue] = useState('');
    const [passwordInputCurrentValue, setPasswordInputCurrentValue] = useState('');
    // Experimenting putting the relevant controlled variable inputs at the section.
    let input: { [a: string]: InputProps } = {
        code: {
            id: 'appendedPrependedInput',
            type: 'text',
            value: codeInputCurrentValue,
            'data-testid': 'mfa-code-input',
            valid: isPasswordValid(codeInputCurrentValue),
            autoComplete: 'off',
            name: 'mfa-code',
            onChange: (e) => {
                setCodeInputCurrentValue(e.currentTarget.value);
                //props.changeMFAResetErrorAction();
            }
            // onKeyPress: e => props.changeMFAResetErrorAction()
        },
        password: {
            autoComplete: 'password',
            id: 'password',
            name: 'password',
            type: 'password',
            value: passwordInputCurrentValue,
            onChange: (e) => {
                setPasswordInputCurrentValue(e.target.value);
                // props.changeMFAResetErrorAction();
            },
            valid: isPasswordValid(passwordInputCurrentValue),
            disabled: props.requestInProcess
        }
    };
    const button: { [a: string]: ButtonProps } = {
        code: {
            type: 'button',
            color: 'primary',
            disabled: !input.code.valid,
            onClick: () => {
                props.updateMFAConfirmAction(codeInputCurrentValue);
                props.changeMFAResetErrorAction();
            }
        },
        password: {
            type: 'button',
            color: 'success',
            children: 'Submit',
            disabled: !input.password.valid || props.requestInProcess,
            onClick: () => {
                props.updateMFAInitiateAction(passwordInputCurrentValue);
                props.changeMFAResetErrorAction();
            }
        }
    };
    //A password is required to retrrieve the QR code, so this must be entered if no QR code.
    // Else Shown when we don't have the QR code.
    // Submitting password triggers MFA route action
    // Account reducer populates the QR code and flows through to change the section
    if (props.requestInProcess) {
        return (
            <Fragment>
                <Spinner large />
            </Fragment>
        );
    }
    if (!props.haveQRCode) {
        return (
            <Fragment>
                <FormGroup row>
                    <Col md="4" style={{ display: 'flex', alignItems: 'center' }}>
                        <Label className="mb-0" htmlFor="password">
                            Current Password:
                        </Label>
                    </Col>
                    <Col md="8" xs="12">
                        <Input {...input.password} />
                    </Col>
                </FormGroup>

                <Row>
                    <Col xs="12">
                        <Button {...button.password} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
    if (props.success) {
        return <Alert className="text-center">Success</Alert>;
    }

    return (
        <Fragment>
            <Row>
                <Col className="text-center" xs="12">
                    <p>
                        <b>Step 1. Please use your phone to scan the QR code:</b>
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className="text-center align-self-center" md={{ size: '5', offset: 1 }}>
                    <img alt="q r code " src={props.QRCode} style={{ width: '100%' }} />
                    {/* note that the image source is the base 64 img string returned from server. 
                            Can't show this until the src is actually populated or it'll be a broken image link */}
                </Col>
                <Col className="text-center align-self-center mt-3 mt-md-0" md={{ size: '5' }}>
                    <img alt="phone" src={phoneImg} style={{ width: '100%' }} />
                </Col>
            </Row>

            <Row>
                <Col className="text-center mt-5" xs="12">
                    <p>
                        <b>Step 2. Enter the code generated by your device:</b>
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className="text-center" md={{ size: 12 }} xs="12">
                    <FormGroup>
                        <InputGroup className="input-prepend">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-key" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input {...input.code} />
                            <InputGroupAddon addonType="append">
                                <Button {...button.code}>
                                    <i className="fa fa-shield mr-1" />
                                    <span>Verify</span>
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
        </Fragment>
    );
};
