import { defaultCurrencyScale, defaultNumberScale } from 'components/Charts/LineChart/utils';
import { ChartProps } from 'react-chartjs-2';

export const getDatasets = (
    lineChartData
): ChartProps<'bar' | 'line', (number | null)[], any>['data']['datasets'] => [
    {
        type: 'bar',
        label: 'Valid',
        backgroundColor: '--success',
        borderColor: '--success',
        hoverBackgroundColor: '--success-hover',
        hoverBorderColor: '--success-hover',
        yAxisID: 'y',
        data: lineChartData.validCodeAttempts || []
    },
    {
        type: 'bar',
        label: 'Invalid',
        backgroundColor: '--danger',
        borderColor: '--danger',
        hoverBackgroundColor: '--danger-hover',
        hoverBorderColor: '--danger-hover',
        yAxisID: 'y',
        data: lineChartData.invalidCodeAttempts || []
    },
    {
        label: 'Revenue',
        type: 'line',
        backgroundColor: '--purple',
        borderColor: '--purple',
        hoverBackgroundColor: '--purple-hover',
        hoverBorderColor: '--purple-hover',
        yAxisID: 'y2',
        data: lineChartData.revenue || []
    },
    {
        label: 'Total Discount',
        type: 'line',
        backgroundColor: '--amber',
        borderColor: '--amber',
        hoverBackgroundColor: '--amber-hover',
        hoverBorderColor: '--amber-hover',
        yAxisID: 'y2',
        data: lineChartData.totalDiscount || []
    }
];

export const getOptions = (
    { revenue, validCodeAttempts, invalidCodeAttempts, totalDiscount }
) => {
    const numberIs0 =
        (invalidCodeAttempts || []).reduce((total, curr) => total + curr.y, 0) === 0 &&
        (validCodeAttempts || []).reduce((total, curr) => total + curr.y, 0) === 0;
    const currencyIs0 =
        (revenue || []).reduce((total, curr) => total + curr.y, 0) === 0 &&
        (totalDiscount || []).reduce((total, curr) => total + curr.y, 0) === 0;

    return {
        scales: {
            y: defaultNumberScale('Code Attempts', numberIs0),
            y2: defaultCurrencyScale(currencyIs0)
        }
    };
};
