import moment, { Moment } from 'moment';
import { Channel, Publisher, Source } from '../../views/SmarterCodes/views/ReferralsLinks/types';
import { API } from '../../../services/api.service';
import { filterBoth, stringSort } from 'utils';
const dateOptions: string[] = [
    'Today',
    'Yesterday',
    'This_Week',
    'Last_Week',
    'Last_3_Days',
    'Last_7_Days',
    'This_Month',
    'Last_Month',
    'Quarter_To_Date',
    'Last_Quarter',
    'Custom',
    'Last_30_Days',
    'Last_60_Days',
    'Last_90_Days',
    'Last_365_Days',
    'Year_To_Date'
];

export const calcShorthandFromDates = (now: Moment, startDate: Moment, endDate: Moment): string => {
    const today = now.clone().set({ hour: 12, minute: 0, second: 0 });
    const thisMonth = Number(today.format('M'));
    const thisYear = Number(today.format('Y'));

    if (startDate && endDate) {
        // Basic integers
        const startEndDiff = endDate.diff(startDate, 'days', true);
        const todayStartDiff = today.diff(startDate, 'days', true);
        const todayEndDiff = today.diff(endDate, 'days', true);
        const startWeekday = startDate.day();
        const endWeekday = endDate.day();
        const startDay = Number(startDate.format('D'));
        const startMonth = Number(startDate.format('M'));
        const startYear = Number(startDate.format('Y'));
        const endDay = Number(endDate.format('D'));
        const endMonth = Number(endDate.format('M'));
        const endYear = Number(endDate.format('Y'));
        const startDaysInMonth = startDate.daysInMonth();
        const endDaysInMonth = endDate.daysInMonth();
        const firstDayOfYear = moment().startOf('year');

        // Helper bools
        const endsToday = todayEndDiff < 1;
        const endsYesterday = todayEndDiff < 2 && todayEndDiff >= 1;
        const isOneDay = startEndDiff === 0;
        const startsThisMonday = startWeekday === 1 && startEndDiff <= 7;
        const startsLastMonday = startWeekday === 1 && todayStartDiff > 7 && todayStartDiff < 15;
        const endsLastSunday = endWeekday === 0 && todayEndDiff < 8;
        const lasts3Days = startEndDiff === 3;
        const lasts7Days = startEndDiff === 7;
        const startsThis1st = startDay === 1 && startMonth === thisMonth && startYear === thisYear;
        const startsLastMonth =
            (startMonth === thisMonth - 1 && startYear === thisYear) ||
            (startMonth === 12 && thisMonth === 1 && startYear === thisYear - 1);
        const startsLast1st = startDay === 1 && startsLastMonth;
        const endsLastMonthEnd = startsLastMonth && endDay === startDaysInMonth;
        const lasts30Days = startEndDiff === 30;
        const lasts60Days = startEndDiff === 60;
        const lasts90Days = startEndDiff === 90;
        const lasts365Days = startEndDiff === 365;
        const yearToEnd = startDate.format('YYYY/MM/DD') === firstDayOfYear.format('YYYY/MM/DD');

        // Basic Quarters
        const thisQuarter = (() => {
            if (thisMonth <= 3) return 1;
            if (thisMonth <= 6) return 2;
            if (thisMonth <= 9) return 3;
            return 4;
        })();
        const lastQuarter = (() => {
            if (thisMonth <= 3) return 4;
            if (thisMonth <= 6) return 1;
            if (thisMonth <= 9) return 2;
            return 3;
        })();
        const startQuarter = (() => {
            if (startMonth <= 3) return 1;
            if (startMonth <= 6) return 2;
            if (startMonth <= 9) return 3;
            return 4;
        })();
        const endQuarter = (() => {
            if (endMonth <= 3) return 1;
            if (startMonth <= 6) return 2;
            if (startMonth <= 9) return 3;
            return 4;
        })();

        // Advanced Quarters
        const startsThisQuarter = startQuarter === thisQuarter && startYear === thisYear;
        const startsLastQuarter =
            (startQuarter === lastQuarter && startYear === thisYear) ||
            (startQuarter === 4 && lastQuarter === 4 && startYear === thisYear - 1);
        const starts1stAnyQuarter =
            (startMonth === 1 || startMonth === 4 || startMonth === 7 || startMonth === 10) &&
            startDay === 1;
        const endsLastQuarter =
            (thisQuarter === endQuarter + 1 && endYear === thisYear) ||
            (endQuarter === 4 && lastQuarter === 4 && endYear === thisYear - 1);
        const endsAnyQuarterEnd =
            (endMonth === 3 || endMonth === 6 || endMonth === 9 || endMonth === 12) &&
            endDay === endDaysInMonth;

        // Main Logic
        if (endsToday && isOneDay) return dateOptions[0]; // Today
        if (endsYesterday && isOneDay) return dateOptions[1]; //Yesterday
        if (endsToday && startsThisMonday) return dateOptions[2]; //This Week
        if (endsLastSunday && startsLastMonday) return dateOptions[3]; //LAST Week
        if (endsToday && lasts3Days) return dateOptions[4]; // Last 3 Days
        if (endsToday && lasts7Days) return dateOptions[5]; // Last 7 Days
        if (endsToday && startsThis1st) return dateOptions[6]; // This Month
        if (endsLastMonthEnd && startsLast1st) return dateOptions[7]; // Last Month
        if (endsToday && startsThisQuarter && starts1stAnyQuarter) return dateOptions[8]; // Quarter To Date
        if (endsLastQuarter && startsLastQuarter && starts1stAnyQuarter && endsAnyQuarterEnd)
            return dateOptions[9]; // Last Quarter
        if (endsToday && lasts30Days) return dateOptions[11]; // Last 30 Days
        if (endsToday && lasts60Days) return dateOptions[12]; // Last 60 Days
        if (endsToday && lasts90Days) return dateOptions[13]; // Last 90 Days
        if (endsToday && lasts365Days) return dateOptions[14]; // Last 365 Days
        if (endsToday && yearToEnd) return dateOptions[15]; // Year to date
        return dateOptions[10]; // CUSTOM
    }
    // Default to custom
    return dateOptions[10];
};

export const isValidDate = (dateToCheck) => {
    if (typeof dateToCheck !== 'string') {
        return false;
    }
    const regex = /^\d{4}\/\d{2}\/\d{2}$/;
    if (dateToCheck.match(regex) === null) {
        return false;
    }
    const [year, month, day] = dateToCheck.split('/');
    const isoFormattedStr = `${year}-${month}-${day}`;
    const date = new Date(isoFormattedStr);
    const timestamp = date.getTime();
    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
        return false;
    }
    return date.toISOString().startsWith(isoFormattedStr);
};

interface RequestCodes {
    tagId: number;
    startDate: string;
    endDate: string;
    channels: {}[],
    sources: {}[],
    publishers: {}[],
    search: string;
    limit: number,
    offset: number
}

// Get the list of all channels for the given tag and date
export const requestCodes = async ({
    tagId,
    startDate,
    endDate,
    channels,
    sources,
    publishers,
    search,
    limit,
    offset
}: RequestCodes): Promise<any> => {
    const response = await API.reports().smartercodes({
        path: '/filter-codes',
        body: JSON.stringify({
            tag_index: [tagId],
            start_date: startDate,
            end_date: endDate,
            channel_id: channels,
            source_id: sources,
            publisher_id: publishers,
            search,
            limit,
            offset
        })
    });

    return response.data.output.map(({ code, code_id, tag_index }) => ({
        name: code, id: code_id, tagId: tag_index
    })) ?? [];
};

interface RequestChannels {
    tagId: number;
    startDate: string;
    endDate: string;
}

// Get the list of all channels for the given tag and date
export const requestChannels = async ({
    tagId,
    startDate,
    endDate
}: RequestChannels): Promise<Channel[]> => {
    const response = await API.reports().smartercodes({
        path: '/list-channels',
        body: JSON.stringify({
            tag_index: tagId,
            start_date: startDate,
            end_date: endDate
        })
    });

    return response.data.output.channels;
};


export const createOptions = (array) => [
    { label: 'All', value: [] },
    ...array.map((item) => ({ label: item.name, value: item }))
];

// Extract sources from channels or publishers from sources, as one is nested inside the other
const getSubValue = (
    values: Array<Channel | Source> = [],
    key: 'sources' | 'publishers'
): Array<Source> | Publisher => {
    return values
        .filter((value) => value[key] !== undefined)
        .map((value) => value[key])
        .flat();
};

// Extract a list of sources from a list of channels
export const getSources = (values: Array<Channel> = []): Source[] => {
    const rawSources = getSubValue(values, 'sources') as Source[];
    const [unknown, known] = filterBoth(rawSources, (value) => value.name.toLowerCase() === 'unknown');
    const sorted = stringSort(known, ({ name }) => name);

    return unknown.length ? [...unknown, ...sorted] : sorted;
};

// Extract a list of publishers from a list of sources
export const getPublishers = (values: Array<Source> = []): Publisher[] => {
    const raw = getSubValue(values, 'publishers') as Publisher[];
    const [unknown, known] = filterBoth(raw, (value) => value.name.toLowerCase() === 'unknown');
    const sorted = stringSort(known, ({ name }) => name);

    return unknown.length ? [...unknown, ...sorted] : sorted;
};

export const compareIds = (array1: { id: number }[], array2: { id: number }[]) => {
    const ids1 = array1.map(({ id }) => id);
    const ids2 = array2.map(({ id }) => id);

    return JSON.stringify(ids1) === JSON.stringify(ids2);
};
