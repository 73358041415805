import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import CodesGeographicalBreakdown from '../../components/CodesGeographicalBreakdown/CodesGeographicalBreakdown';
import CodesMap from '../../components/CodesMap/CodesMap';
import { Country, RawWorldData } from '../WorldMap/types';

const GlobalAttempts = (): JSX.Element => {
    const [countryNames, setCountryNames] = useState<Country>({});
    const [rawWorldData, setRawWorldData] = useState<RawWorldData>();
    useEffect(() => {
        let isMounted = true;
        (async () => {
            const result: Country = await fetch('/world-110m-country-names.json').then(x=>x.json());
            const worldMapRawResult: RawWorldData = await fetch('/world-110m.json').then(x=>x.json());
            if(isMounted){
                setCountryNames(result);
                setRawWorldData(worldMapRawResult);
            }
        })();

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <Card className="card-table">
            <div className="card-media">
                <CodesMap countryNames={countryNames} rawWorldData={rawWorldData}/>
            </div>

            <CardBody>
                <CodesGeographicalBreakdown countryNames={countryNames}/>
            </CardBody>
        </Card>
    );
};

export default GlobalAttempts;
