import { defaultCurrencyScale, defaultNumberScale } from 'components/Charts/LineChart/utils';

export const getOptions = (linechartData) => {
    const { revenue, invalidCodeAttempts, validCodeAttempts } = linechartData;
    const numberIs0 =
        (invalidCodeAttempts || []).reduce((total, curr) => total + curr.y, 0) === 0 &&
        (validCodeAttempts || []).reduce((total, curr) => total + curr.y, 0) === 0;
    const currencyIs0 = (revenue || []).reduce((total, curr) => total + curr.y, 0) === 0;

    return {
        scales: {
            y: defaultNumberScale('Code Attempts', numberIs0),
            y2: defaultCurrencyScale(currencyIs0)
        }
    };
};
