import React, { useCallback, useState } from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
import { Button, Col, Row } from 'reactstrap';

import CustomInput from 'components/Form/Input/Input';

type Props = {
    setEmail: any;
    email: string;
    setPassword: any;
    password: string;
    setForgot: any;
    mfaNeeded: boolean;
    setMFANeeded: any;
    loginCallback: any;
    disableSubmitButton?: boolean;
};

const loginEnterKey = (e, loginAction, email, password, mfa, remember) => {
    var key = e.key || e.keyCode;

    if (key === 'Enter' || key === 13) {
        loginAction({
            email: email,
            password: password,
            mfa,
            remember
        });
    }
};

const Credentials = ({
    mfaNeeded,
    email,
    setEmail,
    loginCallback,
    password,
    setPassword,
    setForgot,
    setMFANeeded: setMfaNeeded,
    disableSubmitButton
}: Props): JSX.Element => {
    const [mfa, setMfa]: [string, Function] = useState('');
    const [remember, setRemember] = useState(true);
    const emailOnChange = useCallback((e) => setEmail(e.target.value),[]);
    return (
        <form>
            <CustomInput
                autoComplete="current-email"
                id="login-email"
                label="Email"
                onChange={emailOnChange}
                onKeyDown={(e) => {
                    loginEnterKey(e, loginCallback, email, password, mfa, remember);
                }}
                type="text"
                value={email}
            />

            <CustomInput
                autoComplete="current-password"
                className=""
                id="login-password"
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                    loginEnterKey(e, loginCallback, email, password, mfa, remember);
                }}
                type="password"
                value={password}
            />

            <div className="forgot-password-button">
                <Button color="link" onClick={() => setForgot(true)}>
                    Forgot password?
                </Button>
            </div>

            <div className="checkbox-personalised">
                <Checkbox
                    checked={mfaNeeded}
                    data-lpignore="true"
                    id="mfa"
                    onChange={() => {
                        setMfaNeeded((prev) => !prev);
                    }}
                />
                <label htmlFor="mfa">MFA</label>
            </div>

            {mfaNeeded && (
                <CustomInput
                    autoComplete="multi-factor-authentication"
                    autoFocus
                    className="mt-3"
                    data-lpignore="true"
                    id="login-mfa"
                    label="MFA"
                    onChange={(e) => setMfa(e.target.value)}
                    onKeyDown={(e) => {
                        loginEnterKey(e, loginCallback, email, password, mfa, remember);
                    }}
                    type="text"
                    value={mfa}
                />
            )}

            <div className="checkbox-personalised">
                <Checkbox
                    checked={remember}
                    data-lpignore="true"
                    id="remember"
                    onChange={() => setRemember((prev) => !prev)}
                    onKeyDown={(e) => {
                        loginEnterKey(e, loginCallback, email, password, mfa, remember);
                    }}
                />
                <label htmlFor="remember">Remember me</label>
            </div>

            <Row className="mt-4">
                <Col>
                    <Button
                        className="btn-block"
                        color="primary"
                        disabled={!email.length || !password.length || disableSubmitButton}
                        onClick={() => {
                            loginCallback({
                                email: email,
                                password: password,
                                mfa,
                                remember
                            });
                        }}
                        type="button"
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
        </form>
    );
};

export default Credentials;
