import React, { FunctionComponent } from 'react';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

type Props = {
    addModalAction: Function;
};
const AccountAbout: FunctionComponent<Props> = (props: Props) => {
    return (
        <Card>
            <CardHeader>
                <i className="fa fa-question-circle" />
                <span>About</span>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col className="">
                        <p><Link to="/account/terms">Terms and Conditions</Link></p>
                        <p><Link target="_blank" to={{ pathname: "https://intent.ly/privacy-policy/" }}>Privacy Policy</Link></p>
                        <p><Link target="_blank" to={{ pathname: "https://intent.ly/data-retention-policy/" }}>Data Retention Policy</Link></p>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default AccountAbout;
