import { CurrencyListActionTypes } from '../actions';

type Currency = {
    id: number;
    code: string;
    region: string;
    symbol: string;
    rate: number;
}
export type ICurrencyListState = Currency[];

const initial: ICurrencyListState = [];

export function currencyList(state: ICurrencyListState = initial, action): ICurrencyListState {
    switch (action.type) {
        case CurrencyListActionTypes.GET_CURRENCY_LIST_REQUEST:
            return state;

        case CurrencyListActionTypes.GET_CURRENCY_LIST_SUCCESS:
            return action.payload;

        case CurrencyListActionTypes.GET_CURRENCY_LIST_FAILURE:
            return state;

        default:
            return state;
    }
}
