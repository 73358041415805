import React, { FunctionComponent, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  GetReportRouteAction,
  setCurrentCodeAction
} from 'Dashboard/views/SmarterCodes/redux/smartercodes.actions';
import { IGetReportRouteAction, ReportsRoutes } from 'Dashboard/views/SmarterCodes/redux/types';
import { IAppStoreState } from 'redux/interfaces';

const mapStateToProps = (state: IAppStoreState) => ({
  // ...
});

const mapDispatchToProps = dispatch => ({
    GetReportRouteAction: (params: IGetReportRouteAction) => dispatch(GetReportRouteAction(params)),
    setCurrentCodeAction: a => dispatch(setCurrentCodeAction(a))
});

type Props = ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> & {
        // required
        codeId: number | string;
        codeName: string;
        // optional
        trigger?: boolean;
    };

const CodesLink: FunctionComponent<Props> = (props: Props) => {
    useEffect(() => {
        props.GetReportRouteAction({ route: ReportsRoutes.CODE_REVIEW });
        if (props.trigger) {
            props.setCurrentCodeAction([{ name: props.codeName, codeId: props.codeId }]);
        }
    }, []);

    if (props.trigger) {
        return <Redirect push to="/explore" />;
    } else {
        return null;
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CodesLink);
