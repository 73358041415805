import moment from 'moment';
import { CurrencyCodes } from 'types';

/**
 * Accepts a number to be converted to a monetary unit
 * @param pennies GBP pennies
 * @param currencyCode Code of currency you are convertin to
 * @param languageCode Language of user
 * @param fallback Fallback value for unknown or empty values
 * @param minimumFractionDigits Max number of decimal places
 * @param maximumFractionDigits Min number of decimal places
 * @param allow0 Allow a value of 0 or use default value
 * @return Formatted currency or fallback
 */
export const formatNumberAsCurrency = function (
    pennies: number,
    currencyCode: keyof typeof CurrencyCodes = 'GBP',
    locale: string = 'en',
    rounding: (value: number) => number = (value) => value,
    minimumFractionDigits: number = 2,
    maximumFractionDigits: number = 2,
    fallback: string = '-',
    allow0 = true
): string {
    const whole = pennies / 100 || 0;
    const rounded = rounding(whole);

    if (isNaN(rounded) || !isFinite(rounded)) return fallback;
    if (!allow0 && rounded === 0) return fallback;
    return new Intl.NumberFormat(`${locale}-u-nu-latn`, {
        style: 'currency',
        currency: currencyCode,
        maximumFractionDigits: maximumFractionDigits,
        minimumFractionDigits: minimumFractionDigits
    }).format(rounded);
};

/**
 * returns only the currency code
 */
export const getCurrencySymbol = (
    languageCode: string, // (optional) e.g. en-GB, en-US
    currencyCode: string // (optional) e.g. GBP, USD
) =>
    (0)
        .toLocaleString(languageCode, {
            currency: currencyCode,
            style: 'currency',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        })
        .replace(/\d/g, '')
        .trim();

/**
 * Returns a formatted String from a Number. (inserts commas, decimal points etc)
 * if number is invalid type the fallback is returned
 */
export function formatNumber(
    number: number | string,
    locale: string = 'en-GB',
    minimumFractionDigits: number = 0,
    maximumFractionDigits: number = 0,
    fallback = '-'
): string {
    const parsedValue = Number(number);

    if (isNaN(parsedValue) || !isFinite(parsedValue)) return fallback;
    return parsedValue.toLocaleString(locale, {
        style: 'decimal',
        minimumFractionDigits,
        maximumFractionDigits
    });
}

/**
 * Returns a `formatted` percentage from a part and a total.
 * if either part or total are invalid numbers the fallback is returned
 */
export function formatPercentFromTotal(
    part: number,
    total: number = 1,
    fallback?: any,
    fractionDigits: number = 0
) {
    const percent = calcPercentFromTotal(part, total, fallback, fractionDigits);

    if (typeof percent !== 'undefined' && percent !== fallback) {
        return `${percent.toFixed(fractionDigits)}%`;
    }
    return fallback;
}

export function formatPercent(
    value: number,
    fractionDigits: number = 0
): string {
    return `${value.toFixed(fractionDigits)}%`;
}

/**
 * Returns a `formatted` percentage from two parts.
 * The two parts are added together to make the total.
 * if either part or total are invalid numbers the fallback is returned
 */
export function formatPercentFromParts(
    part1: number,
    part2: number,
    fallback?: any,
    fractionDigits: number = 0
) {
    return formatPercentFromTotal(part1, part1 + part2, fallback, fractionDigits);
}

/**
 * Returns a `formatted` date from a date number
 */
export function formatDateFromNumber(dateFormat: string, value?: number) {
    return moment(value).format(dateFormat);
}

/**
 * Returns a `numeric` percentage from a part and a total.
 */
const powMap: Record<number, number> = {};
export function calcPercentFromTotal(
    part: number,
    total = 1,
    fallback = 0,
    decimalAccuracy = 4,
): number {
    if (typeof powMap[decimalAccuracy] === 'undefined') {
        powMap[decimalAccuracy] = Math.pow(10, decimalAccuracy);
    }
    const pow = powMap[decimalAccuracy];
    const val = (part * pow / total * pow) * 100;
    const percent = (val / pow) / pow;

    if (!isNaN(percent) && isFinite(percent)) return percent;
    return fallback;
}

/**
 * Checks if a code id is valid or not
 */
export function isCodeStatusIdValid(id: number) {
    if (id === 0) {
        return false;
    } else {
        return true;
    }
}

/**
 *
 * @param pw password string to check
 * @description Establish if password is rudimentally 'correct'
 */
export const isPasswordValid = (pw: string) => typeof pw === 'string' && pw.length > 1;

/**
 * get various metadata related to timeline chart
 * including how many labels to show, label type, dateDiff between labels etc
 */
export function getLineChartMeta(startDate, endDate) {
    const dateDiff = endDate.diff(startDate);
    const dateHours = Math.round(moment.duration(dateDiff, 'milliseconds').asHours());
    const dateDays = Math.round(moment.duration(dateDiff, 'milliseconds').asDays());
    const dateWeeks = Math.round(moment.duration(dateDiff, 'milliseconds').asWeeks());
    const dateMonths = Math.round(moment.duration(dateDiff, 'milliseconds').asMonths());
    const dateYears = Math.round(moment.duration(dateDiff, 'milliseconds').asYears());

    let maxAdjustment = 1;
    let dateAdjustment = 0;
    let maxLabels;
    let type;

    if (dateHours <= 72) {
        maxLabels = dateHours;
        maxAdjustment = 0;
        dateAdjustment = 60 * 1000;
        type = 'hour';
    } else if (dateDays <= 90) {
        maxLabels = dateDays;
        type = 'day';
    } else if (dateWeeks <= 52) {
        maxLabels = dateWeeks;
        type = 'week';
    } else if (dateMonths <= 48) {
        maxLabels = dateMonths;
        type = 'month';
    } else {
        maxLabels = dateYears;
        type = 'year';
    }

    let labels: Array<any> = [];
    for (let i = 0; i < maxLabels; i++) {
        const add = ((dateDiff + dateAdjustment) / (maxLabels - maxAdjustment)) * i;
        const dateAtI = moment(startDate).add(add, 'milliseconds');
        labels.push(dateAtI);
    }

    return {
        dateDiff,
        maxLabels,
        labels,
        type
    };
}

/**
 * accepts an empty map of arrays and generates empty timeline values bucketed into time chunks
 */
export function fillEmptyTimelineChart(startDate, endDate, map) {
    const lineChartMeta = getLineChartMeta(startDate, endDate);

    for (let i = 0; i < lineChartMeta.maxLabels; i++) {
        const add = (lineChartMeta.dateDiff / (lineChartMeta.maxLabels - 1)) * i;
        // const dateAtI = moment(startDate);
        const dateAtI = moment(startDate).add(add, 'milliseconds').valueOf();
        Object.keys(map).forEach((key) => {
            const emptyPoint = {
                x: dateAtI,
                y: 0
            };
            map[key].push(emptyPoint);
        });
    }

    return map;
}

export function getDatesFromApiResult(rawApiData, timezone) {
    const timeRange = rawApiData?.timestamp || { startDate: undefined, endDate: undefined };
    const startDate = moment(timeRange.min * 1000).tz(timezone);
    const endDate = moment(timeRange.max * 1000).tz(timezone);
    return { startDate, endDate };
}

/**
 * Accepts a moment date and returns a standardised string
 */
export function formatStringFromMoment(date, timeType) {
    if (!timeType) {
        return date;
    }
    let format;
    if (timeType === 'hour') {
        format = 'HH:mm';
    } else if (timeType === 'day') {
        format = 'DD/MM';
    } else if (timeType === 'week') {
        format = 'DD/MM';
    } else if (timeType === 'month') {
        format = 'MM/YYYY';
    } else if (timeType === 'year') {
        format = 'YYYY';
    }

    return moment.utc(moment(date)).format(format).toString();
}

export function readQueryString(key) {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get(key);
}

export * from './styles';
export * from './arrayManipulation';
