import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector as uSel } from 'react-redux';
import { Button, Card, CardBody } from 'reactstrap';
import { IAppStoreState as S } from 'redux/interfaces';
import moment from 'moment-timezone';
import FilterByDate from 'components/DatePicker/FilterByDate';
import Select from 'components/Select/Select';
import LimitSelect from 'components/LimitSelect/LimitSelect';
import {
  setReferralsBreakdownAction
} from 'Dashboard/views/SmarterCodes/redux/smartercodes.actions';
import {
  focusOutOfContextAction,
  setCodesAction,
  setDatesAction,
  setReferralAction,
  setTagAction,
  UpdateEndedAction
} from '../../redux/dashboard.actions';
import './CodesContext.scss';
import DynamicKeys from './DynamicKeys/AdvancedOptions';
import { createOptions, isValidDate, requestCodes } from './helper';
import {
  getPublishers,
  getSources,
  requestChannels
} from './helper';
import {
  ActiveChannel,
  ActivePublisher,
  ActiveSource,
  Channel
} from './types';
import { stringSort } from '../../../utils';
import Tooltip from 'components/Tooltip/Tooltip';

const EMPTY_ARRAY = [];
const all  = { label: 'All', value: EMPTY_ARRAY };

const CodesContext = (): JSX.Element => {
  const dispatch = useDispatch();

  // Store values
  // =========================
  const channelsStore = uSel((state: S) => state.dashboard.context.channels);
  const sourcesStore = uSel((state: S) => state.dashboard.context.sources);
  const publishersStore = uSel((state: S) => state.dashboard.context.publishers);
  const codesStore = uSel((state: S) => state.dashboard.context.codes);
  const startDateStore = uSel((state: S) => state.dashboard.context.startDate);
  const endDateStore = uSel((state: S) => state.dashboard.context.endDate);
  const { tag_name, tag_index } = uSel((state: S) => state.dashboard.context.tag);
  const tagStore = useMemo(()=>({ tag_name, tag_index }),[tag_name, tag_index]);
  const tags = uSel((state: S) => state.user.smartercodes_tags);
  const showHighlevel = uSel((state: S) => state.dashboard.access.highlevel);
  const dateFormat = uSel((state: S) => state?.user?.settings?.date || 'DD/MM/YYYY');
  const timezone = uSel((state: S) => state?.user?.settings?.timezone || 'UTC');

  // Local values
  // =========================
  // Top line
  const defaultDate = moment().tz(timezone).format('YYYY/MM/DD');
  const [startDateLocal, setStartDateLocal] = useState(startDateStore || defaultDate);
  const [endDateLocal, setEndDateLocal] = useState(endDateStore || defaultDate);
  const [userInput, setUserInput] = useState("");
  const [userInputForRequest, setUserInputForRequest] = useState("");
  const [isUserInputFocus, setIsUserInputFocus] = useState(false);
  const [tagLocal, setTagLocal] = useState(tagStore);
  // Channels
  const [allChannels, setAllChannels] = useState<Channel[]>(EMPTY_ARRAY);
  const [isChannelsLoading, setIsChannelsLoading] = useState(false);
  const sortedChannels = useMemo(() => stringSort(allChannels, ({ name }) => name), [JSON.stringify(allChannels)]);
  const [activeChannels, setActiveChannels] = useState<ActiveChannel>(all);
  // Sources
  const filteredSources = getSources(
    activeChannels?.label === 'All' ? allChannels : activeChannels?.value
  );
  const [activeSources, setActiveSources] = useState<ActiveSource>(all);
  // Publishers
  const filteredPublishers = getPublishers(
    activeSources?.label === 'All' ? filteredSources : activeSources?.value
  );
  const [activePublishers, setActivePublishers] = useState<ActivePublisher>(all);
  // Codes
  const [allCodes, setAllCodes] = useState<Array<{name:string;tagId:string;id:string;}>>(EMPTY_ARRAY);
  const [isCodesLoading, setIsCodesLoading] = useState(false);
  const [activeCodes, setActiveCodes] = useState(codesStore?.length>0 ? codesStore : EMPTY_ARRAY);

  const startDateChanged = startDateLocal !== startDateStore;
  const tagChanged = tagLocal !== tagStore;
  const endDateChanged = endDateLocal !== endDateStore;
  const shouldRequestChannels = startDateChanged || endDateChanged || tagChanged;

  // Create select option data from the lists
  const channelOptions = createOptions(sortedChannels);
  const sourceOptions = createOptions(filteredSources);
  const publisherOptions = createOptions(filteredPublishers);
  const activeCodesSelected = activeCodes.map(item=>Number(item.id));
  const codesOptions = createOptions(allCodes.filter((item)=>activeCodesSelected.indexOf(Number(item.id))===-1));
  const isAllCodes = !activeCodes.length;

  // On select change, update value and clear child values
  const onChannelChange = ({ label, value }) => {
    if (label !== activeChannels?.label) {
      setActiveChannels({ label, value: [value] });
      setActiveSources(all);
      setActivePublishers(all);
      setActiveCodes(EMPTY_ARRAY);
    }
  };
  const onSourceChange = ({ label, value }) => {
    if (label !== activeSources?.label) {
      setActiveSources({ label, value: [value] });
      setActivePublishers(all);
      setActiveCodes(EMPTY_ARRAY);
    }
  };
  const onPublisherChange = ({ label, value }) => {
    if (label !== activePublishers?.label) setActivePublishers({ label, value: [value] });
    setActiveCodes(EMPTY_ARRAY);
  };

  // Request codes
  // ==========================
  useEffect(() => {
    setIsCodesLoading(true);
    requestCodes({
      tagId: tagLocal.tag_index,
      startDate: startDateLocal,
      endDate: endDateLocal,
      channels: (activeChannels?.value ?? []).map(({ id }) => id),
      sources: (activeSources?.value ?? []).map(({ id }) => id),
      publishers: (activePublishers?.value ?? []).map(({ id }) => id),
      search: userInputForRequest,
      limit: 100,
      offset: 0
    }).then((response) => {
      setIsCodesLoading(false);
      setAllCodes(response.length === 0 ? EMPTY_ARRAY : response);
      dispatch(UpdateEndedAction());
    });
  }, [
    startDateLocal,
    endDateLocal,
    tagLocal.tag_index,
    activeChannels?.label,
    activeSources?.label,
    activePublishers?.label,
    userInputForRequest,
  ]);

  // Request channels
  // ==========================
  useEffect(() => {
    setIsChannelsLoading(true);
    requestChannels({
      tagId: tagLocal.tag_index,
      startDate: startDateLocal,
      endDate: endDateLocal
    }).then((response) => {
      setIsChannelsLoading(false);

      setAllChannels(response.length === 0 ? EMPTY_ARRAY : response);

      const channel = response?.filter(({ name = '' }) => 
        name === channelsStore?.[0]?.name)?.map(
          (value) => ({ label: value?.name, value: [value] }))?.[0];
      const source = getSources(response).filter(
        ({ id }) => id === sourcesStore?.[0]?.id
      )?.map(
        (value) => ({ label: value?.name, value: [value] })
      )?.[0];
      const publisher = getPublishers(getSources(response)).filter(
        ({ id }) => id === publishersStore?.[0]?.id
      )?.map(
        (value) => ({ label: value?.name, value: [value] })
      )?.[0];

      if(channel) setActiveChannels(channel);
      if(source) setActiveSources(source);
      if(publisher) setActivePublishers(publisher);

      dispatch(UpdateEndedAction());
    });
  }, []);

  useEffect(() => {
    if(shouldRequestChannels) {
      setIsChannelsLoading(true);
      requestChannels({
        tagId: tagLocal.tag_index,
        startDate: startDateLocal,
        endDate: endDateLocal
      }).then((response) => {
        setIsChannelsLoading(false);
        setAllChannels(response.length === 0 ? EMPTY_ARRAY : response);
        dispatch(UpdateEndedAction());
      });
    }
  }, [startDateLocal, endDateLocal, tagLocal.tag_index]);

  useEffect(() => {
    if (timezone && (!startDateStore || !endDateStore)) {
      dispatch(
        setDatesAction({
          startDate: startDateStore || defaultDate,
          endDate: endDateStore || defaultDate
        })
      );
    }
  }, [timezone]);

  const options = tags
    .filter(({ active }) => !!active)
    .map(({ tag_name, tag_index }) => ({ label: tag_name, value: tag_index }));

  /**
   * @param tag_index index for the selected tag.
   * @description If the index is not found with the user then return a blank tag (should not happen). Sets the new name immediately locally then dispatches request for data. Incoming data will set the name again.
   */
  const onSetDropdownValueTag = (tag_index) => {
    tag_index = +tag_index; // for some reason we get a string, turn to an int
    let currentTag = tags.find((tag) => tag.tag_index === tag_index);
    if (currentTag) dispatch(setTagAction(currentTag));
    dispatch(setReferralsBreakdownAction(null));
  };

  const handleSubmit = useCallback(() => {
    dispatch(setReferralsBreakdownAction(null));
    dispatch(focusOutOfContextAction());

    // Handle shorthand when date range picker is changed
    if (isValidDate(startDateLocal) && isValidDate(endDateLocal)) {
      dispatch(
        setDatesAction({
          startDate: startDateLocal,
          endDate: endDateLocal
        })
      );

      //Handle website/merchant change
      onSetDropdownValueTag(tagLocal.tag_index);

      dispatch(
        setReferralAction({
          channels: activeChannels?.value?.[0]?.id
            ? [
                {
                  id: activeChannels.value[0].id,
                  name: activeChannels.value[0].name
                }
              ]
            : [],
          sources: activeSources?.value?.[0]?.id
            ? [
                {
                  id: activeSources.value[0].id,
                  name: activeSources.value[0].name
                }
              ]
            : [],
          publishers: activePublishers?.value?.[0]?.id
            ? [
                {
                  id: activePublishers.value[0].id,
                  name: activePublishers.value[0].name
                }
              ]
            : []
        })
      );
    
      dispatch(
        setCodesAction(activeCodes.map((option) => {
          return {
            id: Number(option.id),
            name: option.name,
          };
        }))
      );
    }
  },[startDateLocal, endDateLocal, tagLocal.tag_index, activeChannels, activeSources, activePublishers, activeCodes]);

  const readySubmit =
    startDateStore !== startDateLocal ||
    endDateStore !== endDateLocal ||
    JSON.stringify(tagStore) !== JSON.stringify(tagLocal) ||
    channelsStore?.[0]?.id !== activeChannels?.value?.[0]?.id ||
    sourcesStore?.[0]?.id !== activeSources?.value?.[0]?.id ||
    publishersStore?.[0]?.id !== activePublishers?.value?.[0]?.id ||
    JSON.stringify(activeCodes.map((code) => code?.id)) !== JSON.stringify(codesStore.map((code) => code?.id));

  const handleBlur = useCallback(()=>{
    setIsUserInputFocus(false);
  },[]);

  const handleFocus = useCallback(()=>{
    setIsUserInputFocus(true);
  },[]);

  // when user is typing, this event will be triggered
  const handleInputChange = useCallback((inputValue: string) => {
    //get the character entered by user here in inputValue
    setUserInput(inputValue);
    setTimeout(()=>setUserInputForRequest(inputValue),500);

  },[]);

  // when user select any options in the list, this will be triggered
  const handleChange = useCallback((options) => {
    const isArray = Array.isArray(options);

    if(isArray) {
      const hasAll = Boolean(options.find((option) => option.label === 'All'));
      if(hasAll) setActiveCodes([]);
      if(!hasAll) setActiveCodes(options.map((option) => option.value));
    }

    if(!isArray) {
      const isAll = options?.label === 'All';
      if(isAll) {
        setActiveCodes(EMPTY_ARRAY);
      }else {
        if(options?.value)
          setActiveCodes([options?.value]);
      }
    }
  },[]);

  const handleDateRangeChange = useCallback(() => {
    setActiveChannels(all);
    setActiveSources(all);
    setActivePublishers(all);
    setActiveCodes(EMPTY_ARRAY);
  },[]);

  return (
    <Card className="codes-context mt-4">
      <CardBody>
        <div className="title" id="date-title">
          <p>Select date range</p>
        </div>

        {showHighlevel && (
          <div className="tag-container">
            <Select
              className="tag-select"
              classNamePrefix="tag-select"
              containerClassName=""
              label="Website"
              onChange={({ label, value }) => {
                setActiveChannels(all);
                setActiveSources(all);
                setActivePublishers(all);
                setActiveCodes(EMPTY_ARRAY);
                setTagLocal({
                  tag_name: label,
                  tag_index: value
                });
              }}
              options={options}
              placeholder="Select a Tag"
              value={[
                {
                  label: tagLocal.tag_name,
                  value: tagLocal.tag_index
                }
              ]}
            />
          </div>
        )}

        <div className="date-container">
          <FilterByDate
            dateFormat={dateFormat}
            endDate={endDateLocal}
            onChange={handleDateRangeChange}
            pickerClassName="mb-2 mb-xl-0"
            selectClassName="mb-4 mb-sm-2 mb-xl-0"
            setEndDate={setEndDateLocal}
            setStartDate={setStartDateLocal}
            showHighLevel={showHighlevel}
            startDate={startDateLocal}
          />
        </div>

        <div className="title" id="filter-title">
          <p>Apply filters</p>
        
          <Tooltip id="info">
            Filters will only show possible options. If there are no options, change a previous filter or the date range.
          </Tooltip>
        </div>

        <div className="channels-container">
          <Select
            className="channels-select"
            classNamePrefix="channels-select"
            containerClassName=""
            disabled={isChannelsLoading}
            label="Channels"
            onChange={onChannelChange}
            options={channelOptions}
            placeholder="Select a Channel"
            value={activeChannels || [{ label: 'All', value: sortedChannels }]}
          />
        </div>

        <div className="sources-container">
          <Select
            className="sources-select"
            classNamePrefix="sources-select"
            containerClassName=""
            disabled={isChannelsLoading}
            label="Sources"
            onChange={onSourceChange}
            options={sourceOptions}
            placeholder="Select a Source"
            value={activeSources || [{ label: 'All', value: filteredSources }]}
          />
        </div>

        <div className="publisher-container">
          <Select
            className="publisher-select"
            classNamePrefix="publisher-select"
            containerClassName=""
            disabled={isChannelsLoading}
            label="Publisher"
            onChange={onPublisherChange}
            options={publisherOptions}
            placeholder="Select a Publisher"
            value={activePublishers || [{ label: 'All', value: filteredPublishers }]}
          />
        </div>

        <div className="codes-container">
          <LimitSelect
            className="codes-select"
            classNamePrefix="codes-select"
            containerClassName=""
            inputValue={userInput}
            isClearable={true}
            isFocused={isUserInputFocus}
            isLoading={isCodesLoading}
            isMulti={!isAllCodes}
            label="Codes"
            menuIsOpen={userInputForRequest.length>0}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onInputChange={handleInputChange}
            options={codesOptions}
            placeholder="Select a Code"
            value={activeCodes.length ?
              activeCodes.map((code) => ({ label: code.name, value: code })) :
              [all]
            }
          />
        </div>

        <div className="submit-container">
          <Button color="primary" disabled={!readySubmit} onClick={handleSubmit}>
            Submit
          </Button>
        </div>

        <DynamicKeys />
      </CardBody>
    </Card>
  );
};

export default CodesContext;
