import { navEn, navNl } from './nav';
import { accountEn, accountNl } from './account';
import { helpEn, helpNl } from './help';
import { dashboardEn, dashboardNl } from './dashboard';
import { smartercodesEn, smartercodesNL } from './smartercodes';
import { layoutEn, layoutNl } from './layout';
import { reportsEn } from './reports';

// TODO: add support for the following langs

// French (Nathan)
// Spanish (Carlos, Borja)
// Italian (Francesco and Andy)
// Russian (Andy)
// Greek (Constantia)
// German (Giedre)
// Chinese (Charles)
// Polish (Kamil)
// Bulgarian (Monika)

export const i18nTranslations = {
    en: {
        nav: navEn,
        account: accountEn,
        help: helpEn,
        dashboard: dashboardEn,
        smartercodes: smartercodesEn,
        layout: layoutEn,
        reports: reportsEn
    },
    nl: {
        nav: navNl,
        account: accountNl,
        help: helpNl,
        dashboard: dashboardNl,
        smartercodes: smartercodesNL,
        layout: layoutNl
    }
};
