import { pipe } from 'ramda';

/**
 * A copy of Ramda's pipe function that takes a data
 * first approach, similar to Remeda or ts_belt.
 * 
 * Creates a more readable API for programmers
 * used to the imperative coding style.
 * @example
 * const team = [
 *   { name: 'gary', points: 2 },
 *   { name: 'steve', points: 5 }
 * ]
 * 
 * const value = piper(
 *   team,
 *   map(({ points }) => points),
 *   sum
 * );
 * console.log(value); // 7
 */
export const piper = <T extends unknown, S extends Function[], U>(...args: [T, ...S]): U => {
  const [data, ...functions] = args;
  
  // @ts-ignore - Can't edit ramda to anticipate a spread
  // no choice but to ignore the warning
  return pipe((a: T) => a, ...functions)(data);
};
