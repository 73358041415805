 import React, { FC, useState, useRef, useEffect } from 'react';
import {
    Button,
    ButtonDropdown,
    ButtonGroup,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';
import cx from 'classnames';
import ReactGA from 'react-ga';

type Props = {
    // required
    dropdown: Array<{
        onClick: Function;
        text: string;
        key: string | number;
    }>;
    // optional
    style?: Object;
    className?: string;
    onClickPrint?: React.MouseEventHandler<any>;
};

const DownloadDropdown: FC<Props> = (props: Props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const dlElement = useRef(null);

    function runGa(ref){
        ref.current.addEventListener('click', function(e){
            let clickedElValue = e.target.innerText + ' Downloaded';
            ReactGA.event({
                category: 'Download File',
                action: clickedElValue,
            });
        });
    }

    function DetectDatePickerClick() {
        useEffect(() => {
            if (dlElement){
                runGa(dlElement);
            }
        });
    }

    DetectDatePickerClick();

    return (
        <ButtonGroup aria-label="Second group" className={cx(props.className)} style={props.style}>
            {props.onClickPrint && (
                <Button color="outline-info" onClick={props.onClickPrint}>
                    <i className="fa fa-print" />
                </Button>
            )}
            <ButtonDropdown
                className="mr-1"
                isOpen={dropdownOpen}
                toggle={() => setDropdownOpen(!dropdownOpen)}
            >
                <DropdownToggle caret color="primary">
                    <i className="fa fa-download mr-1" />
                </DropdownToggle>
                <DropdownMenu>
                    <div ref={dlElement}>
                        <DropdownItem header>Download</DropdownItem>
                        {props.dropdown.map(item => (
                            <DropdownItem key={item.key} onClick={() => item.onClick()}>
                                {item.text}
                            </DropdownItem>
                        ))}
                    </div>
                </DropdownMenu>
            </ButtonDropdown>
        </ButtonGroup>
    );
};

export default DownloadDropdown;
