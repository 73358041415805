import React, { FunctionComponent, useEffect, useState } from 'react';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import './Account.scss';

type Props = {
    user: any;
    addModalAction: any;
};

const AccountMFA: FunctionComponent<Props> = (props: Props) => {
    const [mfaStatus, setMfaStatus] = useState(props.user.mfaActive); // 0 = off, 1 = active, 2 = unset

    // let userSettings: IUserSettings = get(props.user, 'settings', {});
    useEffect(() => {
        // userSettings = get(props.user, 'settings', {});
        setMfaStatus(props.user.mfaActive);
    }, [props.user]);

    const mfaButtonColour = () => {
        switch (mfaStatus) {
            case 0:
                return 'danger';
            case 1:
                return 'success';

            default:
                return 'light';
        }
    };

    const MFAText = () => {
        switch (mfaStatus) {
            case 0:
                return (
                    <p>
                        <b>Multi-factor Authentication is OFF</b>
                        <br />
                        <b>Click the lock button to begin setup</b>
                    </p>
                );
            case 1:
                return (
                    <strong>
                        <p>Multi-factor Authentication is ON</p>
                        <p>Click the lock button to make changes</p>
                    </strong>
                );
            case 2:
                return (
                    <strong>
                        <p>Multi-factor Authentication</p>
                        <p>Status is unknown</p>
                    </strong>
                );
            default:
                return '';
        }
    };
    const onMfaSetup = () => {
        props.addModalAction(props.user.mfaActive ? 'mfaModalDelete' : 'mfaModalAdd', '', null);
    };

    return (
        <Card>
            <CardHeader>
                <i className="fa fa-lock" />
                <span>MFA</span>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col className="text-center align-self-center mt-3 mt-xl-0" xl="8">
                        {MFAText()}
                    </Col>
                    <Col className="text-center" xl="4">
                        <Button
                            className="btn-pill mt-5 mb-5"
                            color={mfaButtonColour()}
                            onClick={() => onMfaSetup()}
                            size="lg"
                            style={{
                                width: '120px',
                                height: '120px',
                                color: '#fff'
                            }}
                        >
                            <i className="fa fa-lock" style={{ fontSize: '60px' }} />
                        </Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default AccountMFA;
