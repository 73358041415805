import moment from 'moment';
import ReactGA from 'react-ga';
import { isInclusivelyBeforeDay } from 'react-dates';

const changeStartDate = (setIsCustom, startDateNew, setStartDateHasChanged, setStartDateLocal) => {
    setStartDateHasChanged(true);
    setStartDateLocal(startDateNew);
    //setIsCustom();
};

const changeEndDate = (endDateNew, setIsCustom, setStartDateHasChanged, setEndDateLocal) => {
    setStartDateHasChanged(false);
    setEndDateLocal(endDateNew);
    //setIsCustom();
};

const setIsCustom = (setDateShorthand) => {
    setDateShorthand({ value: 'CUSTOM', label: 'Custom' });
};

export const onDatesChange = (
    setFocusedInput,
    focusedInput,
    newDates,
    setDateShorthand,
    setStartDateHasChanged,
    setStartDateLocal,
    setEndDateLocal
) => {
    const startDateNew = newDates.startDate?.format('YYYY/MM/DD') || null;
    const endDateNew = newDates.endDate?.format('YYYY/MM/DD') || null;

    if (focusedInput === 'endDate') {
        // If endDate is before startDate, change startDate
        if (!endDateNew || moment(endDateNew).isBefore(moment(startDateNew))) {
            changeStartDate(setIsCustom, startDateNew, setStartDateHasChanged, setStartDateLocal);
        } else if (endDateNew) {
            changeEndDate(endDateNew, setIsCustom, setStartDateHasChanged, setEndDateLocal);
            setFocusedInput(null);
        }
    } else if (focusedInput === 'startDate') {
        changeStartDate(setIsCustom, startDateNew, setStartDateHasChanged, setStartDateLocal);
    }
};

export const onFocusChange = (newFocusedInput, startDateHasChanged, setFocusedInput) => {
    if (newFocusedInput === 'endDate' && !startDateHasChanged) setFocusedInput('startDate');
    else if (newFocusedInput === 'startDate') setFocusedInput('startDate');
    else if (newFocusedInput === 'endDate' && startDateHasChanged) setFocusedInput('endDate');
    else if (newFocusedInput === null) setFocusedInput(newFocusedInput);
};

export const isOutsideRange = (allowAnyDate, date, usePastDate, disallowTodaysDate) => {
    const today = moment();
    let bool;
    if (allowAnyDate) bool = false;
    else {
        bool = isInclusivelyBeforeDay(date, today);
        if (usePastDate === true) bool = !bool;
    }
    if (date.format('YY-MM-DD') === today.format('YY-MM-DD')) bool = disallowTodaysDate;
    return bool;
};

export const initialVisibleMonth = (usePastDate, numberOfMonths) => {
    const today = moment();
    if (usePastDate && numberOfMonths > 0) return today.add(1 - numberOfMonths, 'M');
    else return today;
};

export const runGa = (ref) => {
    ref.current.addEventListener('click', function (e) {
        let clickedElValue = e.target.getAttribute('aria-label');
        if (clickedElValue !== null) {
            if (clickedElValue.indexOf(' 2021 as your check-in date. It’s available.') > -1) {
                clickedElValue =
                    'Chose start date: ' +
                    clickedElValue
                        .split('Choose ')[1]
                        .replace(' as your check-in date. It’s available.', '');
            } else if (
                clickedElValue.indexOf(' 2021 as your check-out date. It’s available.') > -1
            ) {
                clickedElValue =
                    'Chose end date: ' +
                    clickedElValue
                        .split('Choose ')[1]
                        .replace(' as your check-out date. It’s available.', '');
            }
            ReactGA.event({
                category: 'Select Dates',
                action: clickedElValue
            });
        }
    });
};

export const isValidDate = (dateToCheck) => {
    if (typeof dateToCheck !== 'string') {
        return false;
    }
    const regex = /^\d{4}\/\d{2}\/\d{2}$/;
    if (dateToCheck.match(regex) === null) {
        return false;
    }
    const [year, month, day] = dateToCheck.split('/');

    const isoFormattedStr = `${year}-${month}-${day}`;

    const date = new Date(isoFormattedStr);

    const timestamp = date.getTime();

    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
        return false;
    }
    return date.toISOString().startsWith(isoFormattedStr);
};
