import React, { FC, useState, useEffect, useRef } from 'react';
import { Button } from 'reactstrap';

import './FAB.scss';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    backgroundColor: state.fab.backgroundColor,
    icon: state.fab.icon,
    text: state.fab.text,
    onClick: state.fab.onClick
});

const mapDispatchToProps = dispatch => ({});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & {};

const FAB: FC<Props> = (props: Props) => {
    const [persistIcon, setPersistIcon] = useState('');
    const [persistText, setPersistText] = useState('');
    const [persistBackgroundColor, setPersistBackgroundColor] = useState('');
    const fadeOutTimer: any = useRef(undefined);

    /*
     * If fading to nothing, persist FAB visuals.
     * Otherwise immediately switch
     */
    useEffect(() => {
        if (!props.text && !props.icon) {
            fadeOutTimer.current = setTimeout(function() {
                setPersistIcon(props.icon);
                setPersistText(props.text);
                setPersistBackgroundColor(props.backgroundColor);
            }, 1000);
        } else {
            clearTimeout(fadeOutTimer.current);
            setPersistIcon(props.icon);
            setPersistText(props.text);
            setPersistBackgroundColor(props.backgroundColor);
        }
    }, [props.text, props.icon, props.backgroundColor]);

    return (
        <Button
            className={`${persistText ? 'fab-text' : ''} 
                ${props.icon || props.text ? 'show' : ''}
            `}
            id="fab"
            onClick={props.onClick}
        >
            <div className="fab-shadow" />
            <div
                className="fab-background"
                style={{ backgroundColor: persistBackgroundColor || '' }}
            >
                <span className="fab-icon">
                    <i aria-hidden="true" className={`fa fa-${persistIcon}`} />
                </span>
                <span className="fab-text">{persistText || ''}</span>
            </div>
        </Button>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FAB);
