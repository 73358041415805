import { cellCurrency, cellDate, cellNumber, cellPercent } from 'components/ReactTable/utils';

export const generateColumns = () => [
  {
    id: 'datestamp',
    accessorKey: 'datestamp',
    header: 'Date',
    cell: cellDate
  },
  {
    id: 'uniqueUsers',
    accessorKey: 'uniqueUsers',
    header: 'Users',
    cell: cellNumber,
    meta: { align: 'right' }
  },
  {
    id: 'codeAttempts',
    accessorKey: 'codeAttempts',
    header: 'Code Attempts',
    cell: cellNumber,
    meta: { align: 'right' }
  },
  {
    id: 'valid',
    accessorKey: 'valid',
    header: 'Valid',
    cell: cellNumber,
    meta: { align: 'right' }
  },
  {
    id: 'invalid',
    accessorKey: 'invalid',
    header: 'Invalid',
    cell: cellNumber,
    meta: { align: 'right' }
  },
  {
    id: 'validRate',
    accessorKey: 'validRate',
    header: 'Valid Rate',
    cell: cellPercent,
    meta: {
      align: 'right',
      from: ['valid', 'codeAttempts']
    }
  },
  {
    id: 'totalDiscount',
    accessorKey: 'totalDiscount',
    header: 'Total Discount',
    cell: cellCurrency,
    meta: { align: 'right' }
  },
  {
    id: 'revenue',
    accessorKey: 'revenue',
    header: 'Revenue',
    cell: cellCurrency,
    meta: { align: 'right' }
  }
];
