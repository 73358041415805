import React, { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector as uSel } from 'react-redux';
import { Helmet } from 'react-helmet';
import { IAppStoreState as S } from 'redux/interfaces';
import {
  GetReportRouteAction,
  setReferralsBreakdownAction
} from '../../redux/smartercodes.actions';
import { ReportsRoutes } from '../../redux/types';
import ReferralsDetailed from './components/ReferralsDetailed/ReferralsDetailed';
import ReferralsBreakdown from './components/ReferralsBreakdown/ReferralsBreakdown';
import { UpdateEndedAction } from 'Dashboard/redux/dashboard.actions';
import { smoothScroll } from './helpers';
import useWake from '../../../../../utils/customHooks/useWake';
import moment from 'moment/moment';
import { history } from '../../../../../redux/store';

const Referrals = (): JSX.Element => {
  const dispatch = useDispatch();
  const startDate = uSel((state: S) => state.dashboard?.context.startDate);
  const endDate = uSel((state: S) => state.dashboard?.context.endDate);
  const tag = uSel((state: S) => state.dashboard?.context.tag);
  const activeDKs = uSel((state: S) => state.dashboard?.context.activeDKs);
  const isLoading = uSel((state: S) => state.smartercodes?.api?.advanceView?.isLoading);
  const timezone = uSel((state: S) => state.user?.settings?.timezone);
  const breakdown = uSel((state: S) => state.smartercodes.referrals.breakdown);
  const breakdownData = uSel(
    (state: S) => state?.smartercodes?.referrals?.publisherBreakdownList || []
  );

  const scrollRef = useRef(null);

  // Reset scroll on page load
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const requestReports = () => {
    dispatch(GetReportRouteAction({ route: ReportsRoutes.ADVANCE_VIEW }));
  };

  const requestBreakdownReports = (details) => {
    if (!details) return;
    const { type, id } = details;

    dispatch(
      GetReportRouteAction({
        route: ReportsRoutes.ADVANCE_VIEW_EXTRA,
        extraParams: {
          [`${type}_id`]: [id],
          table_number: 1
        }
      })
    );
  };

  useEffect(() => {
    const breakdownUrl = Object.fromEntries(new URLSearchParams(window.location.search));
    const breakdownUrlValid = breakdownUrl.type && breakdownUrl.id && breakdownUrl.name;
    if(breakdownUrlValid) {
      const { type, name, id } = breakdownUrl;
      dispatch(setReferralsBreakdownAction({ type, name, id: Number(id) }));
      history.replace({ search: `` });
    }
  }, []);

  useEffect(() => {
    if(breakdownData.length) setTimeout(() => {
      smoothScroll(scrollRef);
    }, 300);
  }, [JSON.stringify(breakdownData)]);
  
  useWake(() => {
    const lastDay = moment(endDate, 'YYYY/MM/DD');
    const today = moment();
    const includesToday = lastDay.isSame(today, "day");
    if(includesToday) {
      requestReports();
      requestBreakdownReports(breakdown);
    }
  }, 70);

  // Grab data for top Referrals table
  useEffect(() => {
    requestReports();
  }, [startDate, endDate, tag, activeDKs, timezone]);

  // Grab data for bottom drilldown table
  useEffect(() => {
    requestBreakdownReports(breakdown);
  }, [JSON.stringify(breakdown)]);

  //  Fades in the cards when loading finished
  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => dispatch(UpdateEndedAction()), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isLoading]);

  // Add a tag to target when smooth scrolling, give it an offset
  // so the header doesn't get in the way
  const scrollTarget = (
    <span ref={scrollRef} style={{ display: 'block', position: 'relative', top: '-100px' }} />
  );

  return (
    <Fragment>
      <Helmet>
        <title>intent.ly - Referrals</title>
      </Helmet>
      <ReferralsDetailed
        onCellClick={(cell, i: number) => {
          const { channel, channelId, sourceId, source, publisherId, publisher } =
            cell.row.original as {
              channel: string;
              channelId: number;
              sourceId: number;
              source: string;
              publisherId: number;
              publisher: string;
            };

          smoothScroll(scrollRef);

          if (i === 0) {
            dispatch(
              setReferralsBreakdownAction({ type: 'channel', name: channel, id: channelId })
            );
          }

          if (i === 1) {
            dispatch(setReferralsBreakdownAction({ type: 'source', name: source, id: sourceId }));
          }

          if (i === 2)
            dispatch(
              setReferralsBreakdownAction({ type: 'publisher', name: publisher, id: publisherId })
            );
        }}
      />
      {scrollTarget}
      <ReferralsBreakdown filter={breakdown} />
    </Fragment>
  );
};

export default Referrals;
