import React from 'react';

interface Props {
    label: string | number;
    onClick: Function;
}

const ButtonRipple = ({ label, onClick }: Props): JSX.Element => {
    return (
        <li
            className="option"
            key={label}
            onClick={() => {
                onClick();
            }}
        >
            <span className="option-text">{label}</span>
        </li>
    );
};

export default ButtonRipple;
