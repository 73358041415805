import React, { useEffect } from 'react';
import { useDispatch, useSelector as uSel } from 'react-redux';
import { Helmet } from 'react-helmet';
import { IAppStoreState as S } from 'redux/interfaces';
import { EValidityTypes } from '../../redux/smartercodes.reducers';
import { UpdateEndedAction } from 'Dashboard/redux/dashboard.actions';
import { GetReportRouteAction } from '../../redux/smartercodes.actions';
import { ReportsRoutes } from '../../redux/types';
import Totals from './components/Totals/Totals';
import ValidityCategoryDetailed from './components/ValidityCategoryDetailed/ValidityCategoryDetailed';
import useWake from '../../../../../utils/customHooks/useWake';
import moment from 'moment/moment';

const types = {
  [EValidityTypes.valid_codes_full_discount]: {
    colour: 'success',
    text: 'Valid Codes (Full Discount)',
    textColour: '#fff'
  },
  [EValidityTypes.valid_codes_basket_discount]: {
    colour: 'primary',
    text: 'Valid Basket Discount',
    textColour: '#fff'
  },
  [EValidityTypes.valid_codes_delivery_discount]: {
    colour: 'info',
    text: 'Valid Delivery Discount',
    textColour: '#000'
  },
  [EValidityTypes.valid_codes_no_discount]: {
    colour: 'warning',
    text: 'Valid Codes (No Discount)',
    textColour: '#000'
  },
  [EValidityTypes.invalid_codes]: {
    colour: 'danger',
    text: 'Invalid Codes',
    textColour: '#fff'
  }
};

const Validity = (): JSX.Element => {
    const dispatch = useDispatch();
    const context = uSel((state: S) => state.dashboard?.context);
    const validity = uSel((state: S) => state.smartercodes?.validity);
    const isLoading = uSel((state: S) => state.smartercodes?.api?.codeReview?.isLoading);
    const timezone = uSel((state: S) => state.user?.settings?.timezone);
    const tagName = uSel(
      (state: S) => state?.dashboard?.context?.tag?.tag_name
    );

    const downloadNameTotals = `Validity Totals${tagName ? ` - ${tagName}` : ''}`;
    const downloadNameDetails = `Validity Details${tagName ? ` - ${tagName}` : ''}`;

  const requestReports = () => {
    dispatch(GetReportRouteAction({ route: ReportsRoutes.CODE_REVIEW }));
  };

  // Reset scroll on page load
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  useWake(() => {
    const lastDay = moment(context.endDate, 'YYYY/MM/DD');
    const today = moment();
    const includesToday = lastDay.isSame(today, "day");
    if(includesToday) requestReports();
  }, 70);
  
  useEffect(() => {
    requestReports();
  }, [context, timezone]);

    useEffect(() => {
        if (!isLoading) dispatch(UpdateEndedAction());
    }, [isLoading]);

    return (
        <div id="validity">
            <Helmet>
                <title>intent.ly - Validity</title>
            </Helmet>

            <Totals
                breakdownAnchor="breakdown"
                downloadName={downloadNameTotals}
                isLoading={isLoading}
                overall={validity.overall}
                pieDatasets={validity.pieDatasets}
                types={types}
            />

            <ValidityCategoryDetailed
                breakdownAnchor="breakdown"
                data={validity.detailed || {}}
                downloadName={downloadNameDetails}
                isLoading={isLoading}
                types={types}
            />
        </div>
    );
};

export default Validity;
