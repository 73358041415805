import { IContext } from 'Dashboard/redux/dashboard.reducer';
import { ChartProps } from 'react-chartjs-2';
import { OverlaysState, OverlaySingleTagData } from '../overlays.reducers';

const baseColors = ['#20a8d8', '#4dbd74', '#f86c6b', '#ffc107', '#20c997', '#29363d'];

const pieColors = [...baseColors, ...baseColors];

export function apiOverlayDataSingleTag(
    state: OverlaysState,
    rawApiData: OverlaySingleTagData,
    context: IContext,
    extraParams: { graph_number: number }
) {
    const { output } = rawApiData;

    const chartData: ChartProps<'pie', (number | null)[], any>['data'] = {
        datasets: [],
        labels: []
    };

    const chartLabels: string[] = [];
    const chartDatatasets: ChartProps<'pie', (number | null)[], any>['data']['datasets'] = [];

    const countArr: number[] = [];

    const tableRows: { name: string; count: number; key: number }[] = [];
    let totalCount = 0;

    output
        .sort((a, b) => b.count - a.count)
        .forEach((item) => {
            const { name, count, overlay_id } = item;
            chartLabels.push(name);
            countArr.push(count);
            totalCount += count;
            tableRows.push({ name, count, key: overlay_id });
        });

    chartDatatasets.push({
        data: countArr,
        backgroundColor: pieColors,
        hoverBackgroundColor: pieColors
    });

    chartData.datasets = chartDatatasets;
    chartData.labels = chartLabels;

    const newState: OverlaysState = {
        ...state,
        singleTag: {
            ...state.singleTag,
            [extraParams.graph_number]: { chartData, tableData: { totalCount, tableRows } }
        }
    };
    return newState;
}
