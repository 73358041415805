export const reportsEn = {
    home: {
        name: 'Reports'
    },
    view: {
        overlays: {
            name: 'Overlays'
        }
    }
};
