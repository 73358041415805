import React, { FunctionComponent } from 'react';
import { Alert } from 'reactstrap';

type Props = {};

const Unauthorised: FunctionComponent<Props> = (props: Props) => {
    return <Alert color="danger">You must be logged in to view this page.</Alert>;
};

export default Unauthorised;
