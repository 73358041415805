import React, { Fragment } from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';
import { hideModalAction } from '../../../redux/actions';

interface Props {
  basket: ({ title: string, image: string } & Record<string, string> )[];
}

const BasketModal = ({ basket }: Props) => {
  if(typeof basket ===  'undefined' || !basket){
    basket = [];
  }
  const useImages = basket.find((product) => product.image);

  return (
    <Fragment>
      <ModalHeader toggle={() => hideModalAction()}>
        <div className="title">Basket Items View</div>
        <div className="description">A list with all the items a customer had in the basket when they used a code</div>
      </ModalHeader>
      <ModalBody>
        <div className="products">
          {basket.length>0?basket.map((product) => {
            const { title, image, ...other } = product;
            const src = image?.length ? image : 'https://cdn.smct.io/94685-373.png';
            return (
              <div className="product" key={product.title}>
                {useImages && (
                  <div className="image">
                    <img alt="Product Thumbnail" src={src} />
                  </div>
                )}
                <div className="description">
                  <div className="title">{title}</div>
                  <div className="tags">
                    {Object.entries(other).map(([key, value]) => {
                      return (
                        <div key={title + key}>
                          <span className="tag-title">{key.slice(0, 1).toUpperCase()}{key.slice(1)}:</span>
                          <span>{String(value).slice(0, 1).toUpperCase()}{String(value).slice(1)}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          }): (<>No data is found.</>)}
        </div>
      </ModalBody>
    </Fragment>
  );
};

export default BasketModal;
