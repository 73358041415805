import { length, compose, toString } from 'ramda';

export const roundLargeNumber = (value) => {
    const tenToPower = (numLength) => Math.pow(10, Math.floor(numLength / 2));
    const adjust = compose(tenToPower, length, toString, Math.round)(value);

    return adjust > 10 ? Math.round(value / adjust) * adjust : value;
};

export const divide = (a = 0, b = 0) => {
    const value = a / b;
    return Number.isFinite(value) ? value : 0;
};
