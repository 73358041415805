import React from 'react';
import { Card, CardBody } from 'reactstrap';
import './NoPermissions.scss';

const Unauthorised = () => {
    return (
        <div id="no-permissions">
            <Card>
                <CardBody>
                    <span>Looks like you're not signed up to the Codes platform.</span>
                    <span>Please contact our customer service team.</span>
                    <a className="btn btn-primary" href="mailto:support@intent.ly">
                        Send email
                    </a>
                </CardBody>
            </Card>
        </div>
    );
};

export default Unauthorised;
