import React, { FC } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import './PercentageWidget.scss';
import Tooltip from 'components/Tooltip/Tooltip';
import { formatPercent } from '../../utils';

type Props = {
    title: string;
    total: number | string;
    valid: [number, number];
    invalid: [number, number];
    tooltip: string;
};

const PercentageWidget: FC<Props> = ({
    title,
    total,
    valid: [validValue = 0, validPercantage = 0],
    invalid: [invalidValue = 0, invalidPercantage = 0],
    tooltip
}: Props) => {
    return (
        <Card className="percentage-widget">
            <CardHeader className="no-divider">
                <span>{title}</span>
                {tooltip ? <Tooltip id={`tooltip-${title.replace(' ', '-')}`}>{tooltip}</Tooltip> : ''}
            </CardHeader>
            <CardBody>
                <div className="total">{total}</div>
                <div className="footer">
                    <div className="line-titles">
                        <span>Valid Codes</span>
                        <span>Invalid Codes</span>
                    </div>

                    <div className="line">
                        <div className="line-left" style={{ width: `${validPercantage}%` }} />
                        <div className="line-right" style={{ width: `${invalidPercantage}%` }} />
                    </div>

                    <div className="line-text">
                        <div>
                            <span className="value">{validValue}</span>
                            <span className="percentage">{formatPercent(validPercantage)}</span>
                        </div>

                        <div>
                            <span className="value">{invalidValue}</span>
                            <span className="percentage">{formatPercent(invalidPercantage)}</span>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default PercentageWidget;
