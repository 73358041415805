import React, { useEffect } from 'react';
import './Search.scss';
const Search = ({
    value: initialValue,
    onChange,
    debounce = 1000,
    ...props
}: {
    value: string | number;
    onChange: (value: string | number) => void;
    debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
    const [value, setValue] = React.useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <>
            <span className="search-icon material-icons-outlined">filter_list</span>
            <input {...props} onChange={(e) => setValue(e.target.value)} value={value} />
        </>
    );
};
export default Search;
