import React from 'react';
import { useSelector as uSel } from 'react-redux';
import { IAppStoreState as S } from 'redux/interfaces';

import SpinnerCard from 'components/Spinner/SpinnerCard';
import ReactTable from 'components/ReactTable/ReactTable';
import { generateColumns } from './helpers';
import { Country } from '../WorldMap/types';

const CodesGeographicalBreakdown = ({ countryNames }:{countryNames: Country}): JSX.Element => {
    const countryNameMap = {};
    for(let key in countryNames) {
        if(countryNames.hasOwnProperty(key)){
            const { code, name } = countryNames[key];
            countryNameMap[code] = name;
        }
    }

    const isLoading = uSel((state: S) => state?.smartercodes?.api?.codeDetails?.isLoading || false);
    const countryData = uSel(
        (state: S) => state?.smartercodes?.codes?.currentCode?.countries || []
    );
    const tagName = uSel(
      (state: S) => state?.dashboard?.context?.tag?.tag_name
    );
    
    const downloadName = `Geolocation${tagName ? ` - ${tagName}` : ''}`;
    const countryDataFinal = countryData.map((item: { countryCode: string }) => {
        const name = countryNameMap[item.countryCode] ?? 'Unknown' ;
        return { ...item, countryName: name };
    });

    if (isLoading) {
        return <SpinnerCard />;
    }
    return (
        <ReactTable
            columns={generateColumns()}
            data={countryDataFinal}
            download={downloadName}
            pagination
            search
            sort={{ id: 'attempts', desc: true }}
        />
    );
};

export default CodesGeographicalBreakdown;
