import { cellNumber, cellValue } from 'components/ReactTable/utils';

export const generateColumns = () => {
    return [
        {
            id: 'countryName',
            accessorKey: 'countryName',
            header: 'Country',
            cell: cellValue
        },
        {
            id: 'attempts',
            accessorKey: 'attempts',
            header: 'Code Attempts',
            cell: cellNumber
        }
    ];
};
