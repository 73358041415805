import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker as ReactDatesRange } from 'react-dates';
import { ANCHOR_LEFT } from 'react-dates/constants';
import 'react-dates/initialize';

import './DateRangePicker.scss';
import {
    initialVisibleMonth,
    isOutsideRange,
    isValidDate,
    onDatesChange,
    onFocusChange,
    runGa
} from './helper';
import { Input } from './types';

type Props = {
    startDate: string | null;
    endDate: string | null;
    setStartDate: Function;
    setEndDate: Function;
    endDateId: string;
    startDateId: string;
    numberOfMonths?: number | any;
    firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
    usePastDate?: boolean;
    allowAnyDate?: boolean;
    disallowTodaysDate?: boolean;
    dateFormat: string;
    setShorthand: Function;
    onChange?: () => void;
};

const DateRangePicker = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setShorthand,
    startDateId,
    allowAnyDate,
    disallowTodaysDate,
    usePastDate,
    numberOfMonths,
    firstDayOfWeek,
    endDateId,
    dateFormat,
    onChange
}: Props): JSX.Element => {
    const [focusedInput, setFocusedInput] = useState<Input>(null);
    const [startDateHasChanged, setStartDateHasChanged] = useState(false);

    const dpElement = useRef(null);

    if (dpElement.current) runGa(dpElement);

    useEffect(() => {
        if (startDateHasChanged && isValidDate(startDate)) setFocusedInput('endDate');
    }, [startDateHasChanged]);

    return (
        <div ref={dpElement} style={{ width: '100%' }}>
            <ReactDatesRange
                anchorDirection={ANCHOR_LEFT}
                displayFormat={dateFormat}
                endDate={endDate ? moment(endDate) : null}
                endDateId={endDateId}
                firstDayOfWeek={firstDayOfWeek}
                focusedInput={focusedInput}
                initialVisibleMonth={() => initialVisibleMonth(usePastDate, numberOfMonths)}
                isOutsideRange={(date) =>
                    isOutsideRange(allowAnyDate, date, usePastDate, disallowTodaysDate)
                }
                keepOpenOnDateSelect={false}
                minimumNights={0}
                numberOfMonths={numberOfMonths}
                onClose={({ startDate, endDate }) => {
                    if (isValidDate(startDate) || isValidDate(endDate)) {
                        setStartDateHasChanged(false);
                    }
                    
                    // Stop a partially changed range from breaking the code
                    if (startDate?.isAfter(endDate)) {
                        setStartDate(endDate);
                    }
                    if(typeof onChange === 'function'){
                    onChange();
                    }
                }}
                onDatesChange={(newDates) =>
                    onDatesChange(
                        setFocusedInput,
                        focusedInput,
                        newDates,
                        setShorthand,
                        setStartDateHasChanged,
                        setStartDate,
                        setEndDate
                    )
                }
                onFocusChange={(newFocusedInput) =>
                    onFocusChange(newFocusedInput, startDateHasChanged, setFocusedInput)
                }
                small={true}
                startDate={startDate ? moment(startDate) : null}
                startDateId={startDateId}
            />
        </div>
    );
};

export default DateRangePicker;
