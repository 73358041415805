import moment from 'moment';
import set from 'lodash.set';
import { getInitialArrayValue, getInitialStringValue } from './utils';
import { DashboardActionTypes } from '../../../redux/dashboard.actions';
import {
    CodesViewActionTypes,
    ReferralsViewActionTypes,
    ReportsRoutes,
    SmarterCodesActionTypes,
    ValidityViewActionTypes
} from './types';
import {
    apiDataAdvanceView,
    apiDataAdvanceViewExtra,
    apiDataCodeDetails,
    apiDataCodeReview,
    apiDataHighlevelGrouping,
    apiDataOverview,
    apiDataTimeline,
    apiDataTopPublishers,
    apiDataUniqueUsers
} from './api';
import { AuthActionTypes, ErrorActionType } from 'redux/actions';
import { getCombinedCurrentEntry } from './api/codeDetails.reducers';
import { ChartProps } from 'react-chartjs-2';
import { PublisherBreakdownList, ReferralsList } from './types';
import apiDataRevenueLost from './api/revenueLost.reducers';
import apiDataFilterCodes from './api/filterCodes.reducers';

export enum EValidityTypes {
    invalid_codes = 'invalid_codes',
    valid_codes_full_discount = 'valid_codes_full_discount',
    valid_codes_basket_discount = 'valid_codes_basket_discount',
    valid_codes_delivery_discount = 'valid_codes_delivery_discount',
    valid_codes_no_discount = 'valid_codes_no_discount'
}

type TDataTimestamp = {
    min: number;
    max: number;
};
export type TCodeReviewDataOutput = {
    name: string;
    code_attempts: number;
    revenue: number;
    orders: number;
    basket_value: number;
    delivery_value: number;
    code_id: number;
    status_id: number;
    tag_index: number;
    average_order_value: number;
    conversion_rate: number;
    total_discount: number;
    basket_discount: number;
    delivery_discount: number;
    max_discount: number;
    min_discount: number;
    average_discount_value: number;
};

export type TFilterCodesDataOutput = {
    code: string;
    code_id: string;
    tag_index: string;
};

// enum TDataStatus {
//     'invalid_codes',
//     'valid_codes_full_discount',
//     'valid_codes_basket_discount',
//     'valid_codes_delivery_discount',
//     'valid_codes_no_discount'
// }

export type TTimelineLineChart = {
    codeAttempts: any[];
    validCodeAttempts: any[];
    invalidCodeAttempts: any[];
    basketDiscount: any[];
    deliveryDiscount: any[];
    totalDiscount: any[];
    orders: any[];
    revenue: any[];
};

export type TCodeDetailsLineChart = {
    validCodeAttempts: any[];
    invalidCodeAttempts: any[];
    // validFullDiscount: number[];
    // validBasketDiscount: number[];
    // validDeliveryDiscount: number[];
    revenue: any[];
    totalDiscount: any[];
};

export type TCodeReviewData = {
    timestamp: TDataTimestamp;
    total: any[];
    output: TCodeReviewDataOutput[];
    map: {
        stats: {
            name: string;
            code_attempts: number;
            revenue: number;
            basket_value: number;
            basket_discount: number;
            delivery_value: number;
            delivery_discount: number;
            code_id: number;
            orders: number;
            status_id: number;
            min_discount: number;
            max_discount: number;
        };
        status: EValidityTypes;
    };
};

export type TRevenueLostData = {
    timestamp: TDataTimestamp;
    total: [];
    output: Array<{ revenue_lost: number }>;
    map: {};
};

export type TFilterCodesData = {
    output: TFilterCodesDataOutput[];
    total: never[];
    map: never[];
    timestamp: TDataTimestamp;
};

export interface IAPIRoute<T> {
    data: T;
    lastUpdated: string;
    requestBodyHash: string;
    isLoading: boolean;
}

export type TSmartercodesOverview = {
    lineChartMeta: any;
    lineChartData: TTimelineLineChart;
    validCount: number;
    invalidCount: number;
    valid: any[];
    invalid: any[];
    summary: Object;
    revenueLost: number;
};

export type TSmartercodesTimeline = {
    lineChartMeta: any;
    lineChartData: TTimelineLineChart;
    numericalData: Array<any>;
};

export interface ISmartercodesState {
    overview: TSmartercodesOverview;
    timeline: TSmartercodesTimeline;
    codes: {
        allEntries: Array<Object>;
        combinedEntries: Object;
        currentCode: any;
        // currentCode: {
        // combinedCurrentEntry: {
        //     identity: Array<Object>;
        // };
        // codeDetails: Array<any>;
        // lineChartData: TCodeDetailsLineChart;
        // sources: any[];
        // };
        lineChartMeta: any;
        codesList: Record<string, any>[];
    };
    validity: {
        pieDatasets: ChartProps<'pie', (number | null)[], any>['data']['datasets'];
        overall: {
            [validity in EValidityTypes]?: any[];
        };
        detailed: {
            [validity in EValidityTypes]?: any[];
        };
        activeType: EValidityTypes | string;
    };
    referrals: {
        referralsList: ReferralsList
        publisherBreakdownList: PublisherBreakdownList,
        breakdown: { type: 'channel' | 'source' | 'publisher'; name: string; id: number; } | null;
    };
    api: {
        overview: IAPIRoute<Object>;
        codeReview: IAPIRoute<TCodeReviewData>;
        timeline: IAPIRoute<Object>;
        filterCodes: IAPIRoute<Object>;
        advanceView: IAPIRoute<Object>;
        advanceViewExtra: IAPIRoute<Object>;
        uniqueUsers: IAPIRoute<Object>;
        topPublishers: IAPIRoute<Object>;
        codeDetails: IAPIRoute<Object>;
        highlevelGrouping: IAPIRoute<Object>;
        revenueLost: IAPIRoute<Object>;
    };
}

const initialState: ISmartercodesState = {
    overview: {
        lineChartMeta: {},
        lineChartData: {} as TTimelineLineChart,
        validCount: 0,
        invalidCount: 0,
        valid: [],
        invalid: [],
        summary: {},
        revenueLost: 0,
    },
    timeline: {
        lineChartMeta: {},
        lineChartData: {} as TTimelineLineChart,
        numericalData: []
    },
    codes: {
        allEntries: [],
        combinedEntries: {} as IAPIRoute<TCodeReviewData>,
        currentCode: {
            combinedCurrentEntry: {
                identity: getInitialArrayValue('codes', [])
            }
            // codeDetails: [],
            // lineChartData: {} as TCodeDetailsLineChart,
            // sources: []
        },
        lineChartMeta: {},
        codesList: []
    },
    validity: {
        pieDatasets: [],
        overall: {},
        detailed: {},
        activeType:
            EValidityTypes[getInitialStringValue('activeValidity', 'valid_codes_full_discount')]
    },
    referrals: {
        referralsList: [],
        publisherBreakdownList: [],
        breakdown: null
    },
    api: {
        overview: { isLoading: false } as IAPIRoute<TCodeReviewData>,
        codeReview: { isLoading: false } as IAPIRoute<TCodeReviewData>,
        filterCodes: { isLoading: false } as IAPIRoute<Object>,
        timeline: { isLoading: false } as IAPIRoute<Object>,
        advanceView: { isLoading: false } as IAPIRoute<Object>,
        advanceViewExtra: { isLoading: false } as IAPIRoute<Object>,
        uniqueUsers: { isLoading: false } as IAPIRoute<Object>,
        topPublishers: { isLoading: false } as IAPIRoute<Object>,
        codeDetails: { isLoading: false } as IAPIRoute<Object>,
        highlevelGrouping: { isLoading: false } as IAPIRoute<Object>,
        revenueLost: { isLoading: false } as IAPIRoute<Object>,
    }
};

export function apiRequest(state, action) {
    let newState = { ...state };
    set(newState, ['api', action.route, 'isLoading'], true);
    return newState;
}

export function apiSuccess(state, action) {
    let newState = { ...state };
    set(newState, ['api', action.route, 'isLoading'], false);
    return newState;
}

export function apiFailure(state, action) {
    let newState = { ...state };
    set(newState, ['api', action.route, 'isLoading'], false);
    return newState;
}

export function dataReferrals(state, action) {
    return {
        ...state,
        referrals: {
            data: action.data
        }
    };
}

/**
 * main api reducer which triggers additional reducers
 */
export function apiData(state: ISmartercodesState, action) {
    let newState;

    const { data, context, extraParams } = action;

    // pass the raw api data into specific reducers for each endpoint
    switch (action.route) {
        case ReportsRoutes.OVERVIEW:
            newState = apiDataOverview(state, data, context, extraParams);
            break;
        case ReportsRoutes.ADVANCE_VIEW:
            newState = apiDataAdvanceView(state, data);
            break;
        case ReportsRoutes.ADVANCE_VIEW_EXTRA:
            newState = apiDataAdvanceViewExtra(state, data);
            break;
        case ReportsRoutes.CODE_DETAILS:
            newState = apiDataCodeDetails(state, data, context, extraParams);
            break;
        case ReportsRoutes.CODE_REVIEW:
            newState = apiDataCodeReview(state, data, context, extraParams);
            break;
        case ReportsRoutes.REVENUE_LOST:
            newState = apiDataRevenueLost(state, data);
            break;
        case ReportsRoutes.FILTER_CODES:
            newState = apiDataFilterCodes(state, data);
            break;
        case ReportsRoutes.HIGHLEVEL_GROUPING:
            newState = apiDataHighlevelGrouping(state, data, context, extraParams);
            break;
        case ReportsRoutes.TIMELINE:
            newState = apiDataTimeline(state, data, context, extraParams);
            break;
        case ReportsRoutes.TOP_PUBLISHERS:
            newState = apiDataTopPublishers(state, data, context, extraParams);
            break;
        case ReportsRoutes.UNIQUE_USERS:
            newState = apiDataUniqueUsers(state, data, context, extraParams);
            break;
        default:
            newState = { ...state };
            break;
    }

    // save the raw api data and the hash
    newState.api[action.route] = {
        data: action.data,
        lastUpdated: moment().toISOString(),
        requestBodyHash: action.requestBodyHash,
        isLoading: false
    };

    return newState;
}

function setCode(state: ISmartercodesState, action) {
    let newState = { ...state };
    const identity = action.payload.identity;
    const selectedCodes = identity.map((codeEntry) => {
        return state.codes.combinedEntries[codeEntry.codeId];
    });

    const combinedCurrentEntry = getCombinedCurrentEntry(selectedCodes);

    newState.codes.currentCode = {
        ...newState.codes.currentCode,
        combinedCurrentEntry: {
            identity: action.payload.identity,
            ...combinedCurrentEntry
        }
    };

    return newState;
}

function setReferralsBreakdown(
    state,
    action: {
        type: string;
        payload: { type: string; name: string; id: number; }
    }
) {
    return {
        ...state,
        referrals: {
            ...state.referrals,
            breakdown: action.payload
        }
    };
}

function setValidityBreakdown(state, action) {
    const { activeType } = action.payload;

    const newState = {
        ...state,
        validity: {
            ...state.validity,
            activeType
        }
    };

    return newState;
}

/**
 * the main smartercodes reducer
 */
export function smartercodes(state = initialState, action) {
    switch (action.type) {
        case DashboardActionTypes.SET_CURRENT_TAG:
            return {
                ...state,
                codes: {
                    ...state.codes,
                    currentCode: {}
                }
            };
        case SmarterCodesActionTypes.API_REQUEST:
            return apiRequest(state, action);
        case SmarterCodesActionTypes.API_SUCCESS:
            return apiSuccess(state, action);
        case ErrorActionType.API_FAILURE:
            return apiFailure(state, action);
        case SmarterCodesActionTypes.DATA_REFERRALS:
            return dataReferrals(state, action);
        case SmarterCodesActionTypes.API_DATA:
            return apiData(state, action);
        case CodesViewActionTypes.SET:
            return setCode(state, action);
        case AuthActionTypes.LOGOUT_SUCCESS:
            return { ...initialState };
        case ReferralsViewActionTypes.SET_BREAKDOWN:
            return setReferralsBreakdown(state, action);
        case ValidityViewActionTypes.SET_BREAKDOWN:
            return setValidityBreakdown(state, action);
        default:
            return { ...state };
    }
}
