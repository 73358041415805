import { OverlayDailyData, OverlaysState, DailyImpressions, DailyData } from '../overlays.reducers';

export type DailyBreakdownTableRow = {
    date: string;
    impressions: number;
    overlaysLoaded: number;
    overlaysShown: number;
    shownPercent: number;
    engagement: number;
    engagePercent: number;
    sales: number;
    revenue: number;
    conversionPercent: number;
    commission?: number;
    commissionPercent?: number;
    epc?: number;
    key: string;
};

export type DailyBreakdownFooterRow = {
    impressions: number;
    overlaysLoaded: number;
    overlaysShown: number;
    engagement: number;
    sales: number;
    revenue: number;
    commission: number;
    epc: number;
};

export type DailyBreakdownChartData = {
    dates: string[];
    revenue: number[];
    shown: number[];
    engagement: number[];
};

export type BaseAddableKeys =
    | 'overlay_loaded'
    | 'overlay_shown'
    | 'engagement'
    | 'revenue_approved'
    | 'revenue_pending'
    | 'revenue_declined'
    | 'sales'
    | 'commission_approved'
    | 'commission_pending'
    | 'commission_declined';

type AddableKeys = BaseAddableKeys | 'script_access';

export function apiOverlayDailyData(state: OverlaysState, rawApiData: OverlayDailyData) {
    const { tableRows, chartData } = processData(rawApiData);

    const footerRow = dailyBreakdownFooterData(tableRows);

    const newState: OverlaysState = {
        ...state,
        dailyData: {
            ...state.dailyData,
            tableRows,
            footerRow,
            chartData
        }
    };

    return newState;
}

export function dailyBreakdownFooterData(tableRows: DailyBreakdownTableRow[]) {
    let impressions = 0;
    let overlaysLoaded = 0;
    let overlaysShown = 0;
    let engagement = 0;
    let sales = 0;
    let revenue = 0;
    let commission = 0;

    tableRows.forEach(row => {
        impressions += row.impressions;
        overlaysLoaded += row.overlaysLoaded;
        overlaysShown += row.overlaysShown;
        engagement += row.engagement;
        sales += row.sales;
        revenue += row.revenue;
        commission += row.commission || 0;
    });

    const epc = commission / engagement;

    return {
        impressions,
        overlaysLoaded,
        overlaysShown,
        engagement,
        sales,
        revenue,
        commission,
        epc
    };
}

export function isFullData(data: DailyData | DailyImpressions): data is DailyData {
    return Object.keys(data).length !== 1;
}

function processData(data: OverlayDailyData) {
    const { output } = data;
    const dates = Object.keys(output).sort((a, b) => Date.parse(a) - Date.parse(b));

    const tableRows: DailyBreakdownTableRow[] = [];

    const chartData: DailyBreakdownChartData = {
        dates: [],
        revenue: [],
        shown: [],
        engagement: []
    };

    const addableKeys = new Set([
        'overlay_loaded',
        'overlay_shown',
        'engagement',
        'revenue_approved',
        'revenue_pending',
        'revenue_declined',
        'sales',
        'commission_approved',
        'commission_pending',
        'commission_declined',
        'script_access'
    ]);

    let canViewCommission = false;

    for (const date of dates) {
        const campaigns = Object.values(output[date]);

        const rowData = {} as Pick<DailyData, AddableKeys>;

        for (const campaign of campaigns) {
            if (isFullData(campaign) && campaign.commission_approved !== undefined) {
                canViewCommission = true;
            }

            for (const key in campaign) {
                if (addableKeys.has(key)) {
                    if (!rowData[key]) {
                        rowData[key] = 0;
                    }

                    rowData[key] += campaign[key] || 0;
                }
            }
        }

        const row = { date, key: date } as DailyBreakdownTableRow;

        const {
            engagement,
            overlay_loaded,
            overlay_shown,
            revenue_approved,
            revenue_pending,
            sales,
            commission_approved,
            commission_pending,
            script_access
        } = rowData;

        const revenue = revenue_approved + revenue_pending;

        row.engagement = engagement;
        row.overlaysLoaded = overlay_loaded;
        row.overlaysShown = overlay_shown;
        row.revenue = revenue;
        row.sales = sales;
        row.impressions = script_access;

        row.shownPercent = (row.overlaysShown / row.overlaysLoaded) * 100;
        row.engagePercent = (row.engagement / row.overlaysShown) * 100;
        row.conversionPercent = (row.sales / row.engagement) * 100;

        if (canViewCommission) {
            const commission = commission_approved! + commission_pending!;
            row.commission = commission;
            row.commissionPercent = (row.commission / row.revenue) * 100;
            row.epc = row.commission / row.engagement;
        }

        tableRows.push(row);

        chartData.dates.push(date);
        chartData.revenue.push(row.revenue);
        chartData.shown.push(row.overlaysShown);
        chartData.engagement.push(row.engagement);
    }

    return { tableRows, chartData };
}
