import React, { FunctionComponent, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Button } from 'reactstrap';
import { logoutAction, addModalAction } from 'redux/actions';
import { IAppStoreState } from 'redux/interfaces';
import get from 'lodash.get';
import { push } from 'connected-react-router';
import './Account.scss';
import defaultUser from '../../../assets/img/accountPhotos/defaultUser.jpg';

const mapStateToProps = (state: IAppStoreState) => ({
    loggedIn: state.auth.loggedIn,
    user: state.user
});
const mapDispatchToProps = (dispatch) => ({
    handleRouterPush: (path) => dispatch(push(path)),
    logoutAction: () => dispatch(logoutAction()),
    addModalAction: (a, b, c) => dispatch(addModalAction(a, b, c))
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const HeaderDropdownAccount: FunctionComponent<Props> = (props: Props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    let userImage: string;
    if (
        props.user &&
        props.user.uploadImage &&
        props.user.uploadImage.imageLink &&
        props.user.uploadImage.imageType === 'profile_image'
    ) {
        userImage = props.user.uploadImage.imageLink;
    } else {
        userImage = get(props, 'user.settings.profile_image') || defaultUser;
    }
    const clientName = get(props, 'user.client_name');
    if (get(props, 'user.loaded', false)) {
        return (
            <Dropdown isOpen={dropdownOpen} nav toggle={() => setDropdownOpen(!dropdownOpen)}>
                <DropdownToggle nav>
                    <span className="user-name">{clientName}</span>
                    {/* <Badge children="1" color="warning" /> */}
                    {userImage && (
                        <img
                            alt="selfie"
                            className="img-avatar"
                            data-testid="profile-image"
                            src={userImage}
                            style={{ boxShadow: 'px 2px 2px 0px rgba(0,0,0,0.25)' }}
                        />
                    )}
                </DropdownToggle>
                <DropdownMenu right>
                    {localStorage.getItem('theme') === 'dark' ? (
                        <DropdownItem
                            onClick={() => {
                                localStorage.setItem('theme', 'light');
                                document.documentElement.setAttribute('data-theme', 'light');
                            }}
                        >
                            <span className="icon material-icons-outlined">light_mode</span>
                            <span>Light mode</span>
                        </DropdownItem>
                    ) : (
                        <DropdownItem
                            onClick={() => {
                                localStorage.setItem('theme', 'dark');
                                document.documentElement.setAttribute('data-theme', 'dark');
                            }}
                        >
                            <span className="icon material-icons-outlined">dark_mode</span>
                            <span>Dark mode</span>
                        </DropdownItem>
                    )}

                    <DropdownItem onClick={() => props.handleRouterPush('/account')}>
                        <span className="icon material-icons-outlined">person</span>
                        <span>Account</span>
                    </DropdownItem>
                    <DropdownItem
                        onClick={() => {
                            props.logoutAction();
                            props.addModalAction('loginModal', '', null);
                        }}
                    >
                        <span className="icon material-icons-outlined">logout</span>
                        <span>Log out</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    } else {
        return (
            <Fragment>
                {props.loggedIn ? (
                    <></>
                ) : (
                    <Button
                        className="mr-2"
                        color="primary"
                        onClick={() =>
                            props.addModalAction('loginModal', 'this is my content', null)
                        }
                    >
                        <i className="icon-login" /> Log in
                    </Button>
                )}
            </Fragment>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDropdownAccount);
