import { combineReducers } from 'redux';
import { i18nReducer as i18n } from 'react-redux-i18n';
import { connectRouter } from 'connected-react-router';

import { user } from './user.reducer';
import { error } from './error.reducers';
import { auth } from './auth.reducer';
import { modal } from './modal.reducer';
import { currencyList } from './currencyList.reducer';
import { fab } from './fab.reducer';

import { dashboard } from '../../Dashboard/redux/dashboard.reducer';
import { smartercodes } from '../../Dashboard/views/SmarterCodes/redux/smartercodes.reducers';
import { account } from '../../Dashboard/views/Account/redux/account.reducers';
import { overlays } from '../../Dashboard/views/Reports/redux/overlays.reducers';

const createRootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        auth,
        user,
        error,
        i18n,
        modal,
        dashboard,
        smartercodes,
        account,
        currencyList,
        fab,
        overlays
    });

export default createRootReducer;
