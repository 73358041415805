import React from 'react';
import { Button } from 'reactstrap';

// Helpers =========================================================================================
const pageButtons = (
    pageIndex: number,
    goToPage: (pageIndex: number) => void,
    maxPage: number,
    buttonLimit: number
): JSX.Element[] => {
    const buttons: any[] = [];

    // Helper functions
    const addButton = (i, key, disabled) => {
        buttons.push(
            <Button
                active={pageIndex === i}
                color="secondary"
                key={key}
                onClick={() => {
                    goToPage(i);
                }}
                outline
                size="sm"
            >
                {i + 1}
            </Button>
        );
    };

    // Make sure there's always a page 1
    if (!maxPage) addButton(0, `button-${0}}`, true);

    // Loop through each button and add to list
    for (let i = 0; i < maxPage; i += 1) {
        const key = `button-${i}}`;
        const isTooManyPages = maxPage > buttonLimit;

        // Handle normal buttons
        if (!isTooManyPages) {
            const isOnePage = maxPage === 1;
            addButton(i, key, isOnePage);
        }

        // Handle too many buttons
        if (isTooManyPages) {
            const isFirstPageToCenter = pageIndex <= Math.ceil(buttonLimit / 2) - 1;
            const isCenterToLastPage = pageIndex >= maxPage - Math.floor(buttonLimit / 2);
            const isMiddlePages = !isFirstPageToCenter && !isCenterToLastPage;

            // [][][][] ... >
            if (isFirstPageToCenter) {
                if (i <= buttonLimit - 1) addButton(i, key, false);
                //else appendDots(key);
            }

            // < ... [][][][] ... >
            if (isMiddlePages) {
                const beforeButtons = i <= pageIndex - Math.ceil(buttonLimit / 2);
                const afterButtons = i >= pageIndex + Math.ceil(buttonLimit / 2);
                const selectedButtons = !beforeButtons && !afterButtons;

                //if (beforeButtons) prependDots(key);
                //if (afterButtons) appendDots(key);
                if (selectedButtons) addButton(i, key, false);
            }

            // < ... [][][][]
            if (isCenterToLastPage) {
                if (i >= maxPage - buttonLimit) addButton(i, key, false);
            }
        }
    }

    return buttons;
};

export default pageButtons;
