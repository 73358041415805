import React, { useRef, useEffect, useState, FunctionComponent } from 'react';
import ReactGA from 'react-ga';

type Props = {};

function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
        if (entry.isIntersecting === true && window.innerHeight !== document.body.scrollHeight) {
            ReactGA.event({
                category: 'Scroll',
                action: 'Scrolled to Footer',
                value: entry.intersectionRatio
            });
        }
    });

    useEffect(() => {
        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, []);

    return isIntersecting;
}

const Footer: FunctionComponent<Props> = () => {
    const ref = useRef(null);
    useOnScreen(ref);

    return (
        <footer>
            <span
                className="ml-auto"
                ref={ref}
                style={{
                    color: 'grey'
                }}
            >
                Copyright &copy; {new Date().getFullYear()} intent.ly Global Ltd
            </span>
        </footer>
    );
};

export default Footer;
