import React, { useCallback, useState } from 'react';
import './Download.scss';
import { CSVLink } from 'react-csv';
import { processDataToDownload } from './helper';

type Props = {
    fileName;
    table;
    footerEnabled;
};
const Download = ({ fileName, table, footerEnabled }: Props): JSX.Element => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<{ csvHeaders?: unknown; csvData?: unknown; }>({});

    const getData = useCallback(async () => {
        if(!loading){
            setLoading(true);
            const tableHeaders = table.getHeaderGroups();
            const tableRawData = table.getSortedRowModel().rows;
            const tableFooters = table.getFooterGroups();
            const dataToDownload = processDataToDownload(tableHeaders, tableRawData, footerEnabled ? tableFooters : [], table);
            setData(dataToDownload);
            setLoading(false);
        }
    },[table, setLoading, setData]);

    return (
        <div className="download-navigation">
            <CSVLink
                asyncOnClick={true}
                data={data.csvData ?? []}
                filename={fileName}
                headers={data.csvHeaders ?? []}
                onClick={getData}
                target="_blank"
            >
                <span className="material-icons-outlined">download</span>
            </CSVLink>
        </div>
    );
};
export default Download;
