import React, { createRef, useState } from 'react';
import { ChartProps } from 'react-chartjs-2';
import { useSelector as uSel } from 'react-redux';
import { Card, CardBody } from 'reactstrap';

import DownloadWrapper from 'components/Charts/DownloadWrapper';
import LineChart from 'components/Charts/LineChart/LineChart';
import SpinnerCard from 'components/Spinner/SpinnerCard';
import { IAppStoreState as S } from 'redux/interfaces';
import { getOptions } from './helpers';
import './OverviewGraphicalSummary.scss';

const OverviewGraphicalSummary = (): JSX.Element => {
    const overview = uSel((state: S) => state.smartercodes.overview);
    const isCodeReviewLoading = uSel((state: S) => state.smartercodes.api.codeReview.isLoading);
    const isTimelineLoading = uSel((state: S) => state.smartercodes.api.codeReview.isLoading);
    const timezone = uSel((state: S) => state.user.settings.timezone);

    const [lineRef, setLineRef] = useState(createRef());
    const lineChartData = overview?.lineChartData || {};
    const lineChartMeta = overview?.lineChartMeta || {};
    const isLoading = isCodeReviewLoading || isTimelineLoading;
    const lineHeader = 'Voucher Code Revenue';
    const datasets: ChartProps<'bar' | 'line', (number | null)[], any>['data']['datasets'] = [
        {
            fill: false,
            type: 'line',
            label: 'Revenue',
            backgroundColor: '--purple',
            borderColor: '--purple',
            hoverBackgroundColor: '--purple-hover',
            hoverBorderColor: '--purple-hover',
            yAxisID: 'y2',
            data: lineChartData.revenue || []
        },
        {
            type: 'bar',
            label: 'Valid',
            backgroundColor: '--success',
            borderColor: '--success',
            hoverBackgroundColor: '--success-hover',
            hoverBorderColor: '--success-hover',
            yAxisID: 'y',
            data: lineChartData.validCodeAttempts || []
        },
        {
            type: 'bar',
            label: 'Invalid',
            backgroundColor: '--danger',
            borderColor: '--danger',
            hoverBackgroundColor: '--danger-hover',
            hoverBorderColor: '--danger-hover',
            yAxisID: 'y',
            data: lineChartData.invalidCodeAttempts || []
        }
    ];

    const options = getOptions(lineChartData);

    return (
        <>
            {isLoading ? (
                <SpinnerCard height="calc(100% - 1.5rem)" />
            ) : (
                <Card style={{ height: 'calc(100% - 1.5rem)' }}>
                    <CardBody style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: '100%' }}>
                            <DownloadWrapper
                                canvasRef={lineRef}
                                downloadName={lineHeader}
                                header={lineHeader}
                            >
                                <LineChart
                                    datasets={datasets}
                                    labels={lineChartMeta.labels || []}
                                    options={options}
                                    setRef={setLineRef}
                                    timezone={timezone}
                                />
                            </DownloadWrapper>
                        </div>
                    </CardBody>
                </Card>
            )}
        </>
    );
};

export default OverviewGraphicalSummary;
