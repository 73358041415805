import { useRef } from 'react';
import useInterval from './useTimeout';

/*
 * Will run a callback if the user has woken a sleeping computer
 * 
 * [Warning] Will sometimes false fire due to browser optimisations pausing
 * javascript. For example when the user leaves the tab for a long time.
 * Only use where this is acceptable.
 */
const useWake = (callback: () => void, minutes = 1) => {
  const prev = useRef((new Date()).getTime());
  const milliseconds = minutes * 10000;

  useInterval(function() {
    const then = prev.current;
    const now = (new Date()).getTime();
    const rerenderBuffer = 5000;
    const limit = then + milliseconds + rerenderBuffer;

    if (now > limit) callback();
    prev.current = now;
  }, milliseconds);
};

export default useWake;
