import { useEffect, useRef } from 'react';

const usePersist = <T extends unknown> (data: T): T | null => {
  const persist = useRef(data);
  const persistData = persist.current;

  useEffect(() => {
    if (data !== undefined) persist.current = data;
  }, [data]);

  return (() => {
    if(data !== undefined) return data;
    if(persistData !== undefined) return persistData;
    return null;
  })();
};

export default usePersist;
