import React from 'react';
import WorldMap from '../WorldMap/WorldMap';
import Legend from '../WorldMap/Legend';
import SpinnerCard from 'components/Spinner/SpinnerCard';
import { connect } from 'react-redux';
import { IAppStoreState } from 'redux/interfaces';
import get from 'lodash.get';
import { Country, RawWorldData } from '../WorldMap/types';

export type ApiCountry = {
    countryCode: string;
    attempts: number;
    key: number;
};

const mapStateToProps = (state: IAppStoreState) => ({
    countriesData: get(state.smartercodes.codes.currentCode, 'countries'),
    totalAttempts: get(state.smartercodes.codes.currentCode, 'combinedCurrentEntry.totalAttempts'),
    isLoading: state.smartercodes.api.codeDetails.isLoading
});

const mapDispatchToProps = dispatch => ({});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> 
    & {countryNames: Country} 
    & {rawWorldData: RawWorldData | undefined};

const CodesMap: React.FC<Props> = ({ countriesData, totalAttempts, isLoading, countryNames, rawWorldData }) => {
    if (isLoading) {
        return <SpinnerCard />;
    }

    if (!countriesData) return null;

    return (
        <div className="world-map">
            <Legend />
            <WorldMap apiData={countriesData} countryNames={countryNames} rawWorldData={rawWorldData} totalAttempts={totalAttempts} />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CodesMap);
