import React, { LegacyRef } from 'react';
import classNames from 'classnames';
import { ripple } from './helpers';
import './ButtonRipple.scss';

interface Props {
    children;
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
}

const ButtonRipple = React.forwardRef(
    (
        { children, disabled = false, className = '', onClick = () => {}, type = 'button' }: Props,
        ref?: LegacyRef<HTMLButtonElement>
    ): JSX.Element => {
        return (
            <button
                className={classNames('button-ripple', className)}
                disabled={disabled}
                onClick={onClick}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') ripple(e);
                    onClick();
                }}
                onMouseDown={(e) => {
                    ripple(e);
                    onClick();
                }}
                ref={ref}
                type={type}
            >
                {children}
            </button>
        );
    }
);

export default ButtonRipple;
