import moment, { Moment } from 'moment';

import { SelectOption } from './types';

// Change date range picker when short hand is changed
export const changeShorthandToPicker = (
    option: SelectOption,
    customRange?: { startDate: Moment; endDate: Moment }
): { start: Moment; end: Moment } => {
    switch (option.value) {
        case 'TODAY':
            return { start: moment(), end: moment() };
        case 'YESTERDAY':
            return { start: moment().subtract(1, 'day'), end: moment().subtract(1, 'day') };
        case 'LAST_3_DAYS':
            return { start: moment().subtract(3, 'days'), end: moment() };
        case 'LAST_7_DAYS':
            return { start: moment().subtract(7, 'days'), end: moment() };
        case 'LAST_30_DAYS':
            return { start: moment().subtract(30, 'days'), end: moment() };
        case 'LAST_60_DAYS':
            return { start: moment().subtract(60, 'days'), end: moment() };
        case 'LAST_90_DAYS':
            return { start: moment().subtract(90, 'days'), end: moment() };
        case 'LAST_365_DAYS':
            return { start: moment().subtract(365, 'days'), end: moment() };
        case 'THIS_WEEK':
            return { start: moment().startOf('isoWeek'), end: moment() };
        case 'LAST_WEEK':
            return {
                start: moment().subtract(1, 'week').startOf('isoWeek'),
                end: moment().subtract(1, 'week').endOf('isoWeek')
            };
        case 'THIS_MONTH':
            return { start: moment().startOf('month'), end: moment() };
        case 'LAST_MONTH':
            return {
                start: moment().subtract(1, 'month').startOf('month'),
                end: moment().subtract(1, 'month').endOf('month')
            };
        case 'THIS_YEAR':
            return { start: moment().startOf('year'), end: moment() };
        case 'CUSTOM':
            const startDate = customRange?.startDate || moment();
            const endDate = customRange?.endDate || moment();
            return { start: startDate, end: endDate };
    }

    return { start: moment(), end: moment() };
};
