import React, { Fragment } from 'react';

// Demo Images/Videos
//=================================================================================================
import multicodeVideo from '../../../assets/releaseNotes/video/multicodes.mp4';
import weekendHighlightImage from '../../../assets/releaseNotes/img/weekendGraph.png';

const DemoContent = props => {
    return (
        <Fragment>
            <div className="version-entry">
                <h3 className="h3">Release 1.0.18{/* versionNumber */}</h3>

                <div className="release-entry">
                    <h4 className="h4">Multicodes</h4>
                    <div className="media-container">
                        <div className="media-occluder">
                            <video autoPlay={true} loop={true}>
                                <source src={multicodeVideo} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <p>On the codes page, you can now select data for multiple codes at once!</p>
                </div>

                <div className="release-entry">
                    <h4 className="h4">Weekends highlighted on graphs</h4>
                    <div className="media-container">
                        <div className="media-occluder">
                            <img alt="weekend highlighting graph" src={weekendHighlightImage} />
                        </div>
                    </div>
                    <p>
                        Weekends on graphs are now highlighted in blue for greater ease of insight.
                    </p>
                </div>

                <div className="release-entry">
                    <h4 className="h4">Bugfix: Timezones</h4>
                    <p>Fixed a bug where data would sometimes be off by an hour</p>
                </div>

                <div className="release-entry">
                    <h4 className="h4">A few minor tweaks</h4>
                    <p>
                        Things have been shuffled around just a little to make a bit more visual
                        sense for your ease of use.
                    </p>
                </div>
            </div>

            <div className="version-entry">
                <h3 className="h3">Release 1.0.17{/* versionNumber */}</h3>

                <div className="release-entry">
                    <h4 className="h4">Multicodes</h4>
                    <div className="media-container">
                        <div className="media-occluder">
                            <video autoPlay={true} loop={true}>
                                <source src={multicodeVideo} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <p>On the codes page, you can now select data for multiple codes at once!</p>
                </div>

                <div className="release-entry">
                    <h4 className="h4">Weekends highlighted on graphs</h4>
                    <div className="media-container">
                        <div className="media-occluder">
                            <img alt="weekend highlighting graph" src={weekendHighlightImage} />
                        </div>
                    </div>
                    <p>
                        Weekends on graphs are now highlighted in blue for greater ease of insight.
                    </p>
                </div>

                <div className="release-entry">
                    <h4 className="h4">Bugfix: Timezones</h4>
                    <p>Fixed a bug where data would sometimes be off by an hour</p>
                </div>

                <div className="release-entry">
                    <h4 className="h4">A few minor tweaks</h4>
                    <p>
                        Things have been shuffled around just a little to make a bit more visual
                        sense for your ease of use.
                    </p>
                </div>
            </div>
        </Fragment>
    );
};

export default DemoContent;
