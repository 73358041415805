import { ChartProps } from 'react-chartjs-2';

export const getDatasets = (
    data
): ChartProps<'pie', (number | null)[], any>['data']['datasets'] => [
    {
        data: [
            data?.[0]?.data[0] || 0,
            data?.[0]?.data[1] || 0,
            data?.[0]?.data[2] || 0,
            data?.[0]?.data[3] || 0,
            data?.[0]?.data[4] || 0
        ],
        backgroundColor: ['--success', '--primary', '--info', '--warning', '--danger'],
        hoverBackgroundColor: [
            '--success-hover',
            '--primary-hover',
            '--info-hover',
            '--warning-hover',
            '--danger-hover'
        ],
        borderColor: '#F3F3F3'
    }
];
