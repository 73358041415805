import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ModalHeader, ModalBody, Input, Label, Form, FormGroup, Button } from 'reactstrap';
import { hideModalAction } from '../../../redux/actions';
import { uploadImageAction } from 'Dashboard/views/Account/redux/account.actions';

const mapStateToProps = state => ({
    modal: state.modal
});

const mapDispatchToProps = dispatch => ({
    hideModalAction: () => dispatch(hideModalAction()),
    uploadImageAction: (image, imageType) => dispatch(uploadImageAction(image, imageType))
});

export type UploadImageType = 'profile_image' | 'banner';

type Props = ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> & {
        // required
        imageType: UploadImageType;
        // optional
    };

const isSupported = (file: string) => {
    const regex = /\.(gif|jpe?g|png)/i;
    return regex.test(file);
};

const getTitle = (type: string) => {
    switch (type) {
        case 'profile_image':
            return 'Update Profile Picture';
        case 'banner':
            return 'Update Profile Banner';
        default:
            return 'Upload Image';
    }
};

const UploadImageContent: React.FC<Props> = (props: Props) => {
    const [file, setFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        props.uploadImageAction(file, props.imageType);
        props.hideModalAction();
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isSupported(e.target.value) && e.target.files) {
            setFile(e.target.files[0]);
            setPreviewUrl(URL.createObjectURL(e.target.files[0]));
        }
    };

    return (
        <>
            <ModalHeader toggle={() => props.hideModalAction()}>
                <i className="fa fa-image mr-2"> </i>
                <span>{getTitle(props.imageType)}</span>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4">
                        <Label htmlFor={props.imageType}>
                            <i className="fa fa-upload mr-2" />
                            <span>Upload picture (JPG, JPEG, PNG, GIF only)</span>
                        </Label>
                        <Input
                            id={props.imageType}
                            name={props.imageType}
                            onChange={handleChange}
                            type="file"
                        />
                    </FormGroup>
                    {previewUrl && (
                        <FormGroup>
                            <h6>Preview</h6>
                            <img alt="Preview" src={previewUrl} style={{ maxWidth: '100%' }} />
                        </FormGroup>
                    )}
                    <FormGroup>
                        <Button color="primary" type="submit">
                            Submit
                        </Button>
                    </FormGroup>
                </Form>
            </ModalBody>
        </>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadImageContent);
