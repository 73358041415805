import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';

import CustomInput from '../../../../../Form/Input/Input';

const UpdatePassword = ({ onChange, onSubmit }): JSX.Element => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const params = new URL(`${document.location}`).searchParams;
    const resetCode = params.get('token') || '';

    return (
        <form>
            <CustomInput
                autoComplete="off"
                id="new-password"
                label="New Password"
                onChange={(e) => {
                    onChange();
                    setNewPassword(e.target.value);
                }}
                type="password"
                value={newPassword}
            />

            <CustomInput
                autoComplete="off"
                id="confirm-password"
                label="Confirm password"
                onChange={(e) => {
                    onChange();
                    setConfirmPassword(e.target.value);
                }}
                type="password"
                value={confirmPassword}
            />

            <Row className="mt-4">
                <Col className="d-flex justify-content-center">
                    <Button
                        className="btn-block"
                        color="primary"
                        onClick={() => {
                            onSubmit(resetCode, newPassword, confirmPassword);
                        }}
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
        </form>
    );
};

export default UpdatePassword;
