import cx from 'classnames';
import { SmarterCodesItems } from 'Dashboard/views/SmarterCodes/SmarterCodes.routes';
import { IMenuItem } from 'index.types';
import React from 'react';
import { useSelector as uSel } from 'react-redux';
import { IAppStoreState as S } from 'redux/interfaces';
import Footer from './Footer';
import Header from './Header';
import SideMenu from './SideMenu/SideMenu';

interface Props {
    className: string;
    children: JSX.Element;
}

const Layout = ({ className, children }: Props): JSX.Element => {
    const search = uSel((state: any) => state?.router?.location?.search);
    const smartercodes = uSel((state: S) => state.dashboard?.access?.smartercodes);

    const menuItems: IMenuItem[] = [];

    if (smartercodes) {
        menuItems.push({
            i18nKey: 'smartercodes.home.name',
            url: '',
            icon: '',
            key: 20,
            children: SmarterCodesItems,
            requiresTag: true
        });
    }

    return (
        <div className={cx('app', className)}>
            <Header />

            <div className="app-body">
                <SideMenu menu={menuItems} queryString={search} />

                <main className="main">{children}</main>
            </div>

            <Footer />
        </div>
    );
};

export default Layout;
