import React from 'react';
import { Card, CardBody } from 'reactstrap';

import CodesDailyBreakdown from '../../components/CodesDailyBreakdown/CodesDailyBreakdown';
import CodesPerformanceTimeline from '../../components/CodesPerformanceTimeline/CodesPerformanceTimeline';

interface Props {}

const Daily = (props: Props): JSX.Element => {
    return (
        <Card className="card-table has-media">
            <div className="card-media">
                <CodesPerformanceTimeline />
            </div>

            <CardBody>
                <CodesDailyBreakdown />
            </CardBody>
        </Card>
    );
};

export default Daily;
