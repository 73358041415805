export const dashboardEn = {
    name: 'Dashboard',
    loading: 'Loading...',
    video: {
        test: {
            test: {
                world: 'hello'
            }
        },
        title: 'Intent.ly Explained'
    },
    new: {
        title: "What's New?",
        body1:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Crasdig nissim purus sed elit malesuada, quis consectetur ex posuere. Quisque eget diam viverra augue aliquet imperdiet quis sit amet lectus. Sed eget lacinia mi. Vestibulum viverra eu neque sed vehicula. Mauris porta massa nibh, quis bibendum diam eleifend nec. Donec vitae tortor ipsum. Vivamus sit amet auctor mi.',
        body2:
            'Morbi eu sodales massa. Phasellus ut fermentum lectus, et semper augue. Donec arcu neque, porttitor sit amet viverra vel, pulvinar non justo. Duis gravida pretium nisl, sed ultricies lorem lobortis a. Nullam malesuada lorem quis justo tincidunt, sit amet rutrum est varius. Quisque venenatis lectus eget odio lacinia, eget dictum lacus faucibus. Suspendisse lacus risus, posuere ac sagittis eget, iaculis a diam. Cras sit amet dui vel mauris commodo venenatis.',
        body3:
            'Curabitur interdum nisl quis justo elementum fermentum. Aenean eget tincidunt eros. Nam iaculis, libero eu scelerisque ullamcorper, nisi tellus egestas purus, vel varius lectus augue a tellus. Quisque pellentesque metus a lacinia bibendum. Suspendisse a accumsan magna. Maecenas efficitur lobortis dapibus. Integer sit amet enim sit amet erat interdum fermentum a sit amet odio. Ut purus nibh, egestas vitae porttitor at, mattis non turpis.',
        body4:
            'Proin pharetra nulla ut metus pretium, at mollis nisl congue. Aenean mi leo, pulvinar eu volutpat vehicula, auctor id felis. Aliquam interdum sed est condimentum sodales. Vivamus euismod ultrices molestie. Nunc ullamcorper urna eget lectus varius ullamcorper. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla, leo id sagittis ultricies, magna risus pharetra arcu, finibus molestie neque ex eget elit.'
    },
    soon: {
        title: 'Coming Soon',
        body1:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dignissim purus sed elit malesuada, quis consectetur ex posuere. Quisque eget diam viverra augue aliquet imperdiet quis sit amet lectus. Sed eget lacinia mi. Vestibulum viverra eu neque sed vehicula. Mauris porta massa nibh, quis bibendum diam eleifend nec. Donec vitae tortor ipsum. Vivamus sit amet auctor mi.',
        body2:
            'Morbi eu sodales massa. Phasellus ut fermentum lectus, et semper augue. Donec arcu neque, porttitor sit amet viverra vel, pulvinar non justo. Duis gravida pretium nisl, sed ultricies lorem lobortis a. Nullam malesuada lorem quis justo tincidunt, sit amet rutrum est varius. Quisque venenatis lectus eget odio lacinia, eget dictum lacus faucibus. Suspendisse lacus risus, posuere ac sagittis eget, iaculis a diam. Cras sit amet dui vel mauris commodo venenatis.',
        body3:
            'Curabitur interdum nisl quis justo elementum fermentum. Aenean eget tincidunt eros. Nam iaculis, libero eu scelerisque ullamcorper, nisi tellus egestas purus, vel varius lectus augue a tellus. Quisque pellentesque metus a lacinia bibendum. Suspendisse a accumsan magna. Maecenas efficitur lobortis dapibus. Integer sit amet enim sit amet erat interdum fermentum a sit amet odio. Ut purus nibh, egestas vitae porttitor at, mattis non turpis.',
        body4:
            'Proin pharetra nulla ut metus pretium, at mollis nisl congue. Aenean mi leo, pulvinar eu volutpat vehicula, auctor id felis. Aliquam interdum sed est condimentum sodales. Vivamus euismod ultrices molestie. Nunc ullamcorper urna eget lectus varius ullamcorper. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla, leo id sagittis ultricies, magna risus pharetra arcu, finibus molestie neque ex eget elit.'
    }
};

export const dashboardNl = {};
