import React from 'react';
import { Col, Container, Row } from 'reactstrap';

type Props = { children: any };
type State = { error: any };

export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error });
    }

    render() {
        if (this.state.error) {
            return (
                <div className="app flex-row align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="6">
                                <div className="clearfix">
                                    <h1 className="float-left display-3 mr-4">500</h1>
                                    <h4 className="pt-3">Oops! There was a terminal error.</h4>
                                    <p className="text-muted float-left">
                                        The page you are trying to load had an internal script
                                        error, please contact us so we can investigate the issue.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }

        // when there's not an error, return the children unchanged
        return this.props.children;
    }
}
