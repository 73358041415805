import React from 'react';
import { useDispatch } from 'react-redux';
import { addModalAction } from 'redux/actions';
import Modal from '../components/Modal/Modal';

const ResetPassword = (): JSX.Element => {
    const dispatch = useDispatch();

    dispatch(addModalAction('loginModal', '', []));

    return <Modal />;
};

export default ResetPassword;
