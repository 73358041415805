import { API, IUserSettings } from 'services/api.service';
import { APIFailureAction } from './error.actions';

export enum UserActionTypes {
    GET_DATA_REQUEST = 'USER_GET_DATA_REQUEST',
    GET_DATA_SUCCESS = 'USER_GET_DATA_SUCCESS',
    // GET_DATA_FAILURE = 'USER_GET_DATA_FAILURE',

    GET_MFA_ACTIVE_REQUEST = 'USER_GET_MFA_ACTIVE_REQUEST',
    GET_MFA_ACTIVE_SUCCESS = 'USER_GET_MFA_ACTIVE_SUCCESS',
    // GET_MFA_ACTIVE_FAILURE = 'USER_GET_MFA_ACTIVE_FAILURE',

    //   SET_DATA_REQUEST = 'USER_SET_DATA_REQUEST',
    //   SET_DATA_SUCCESS = 'USER_SET_DATA_SUCCESS',
    //   SET_DATA_FAILURE = 'USER_SET_DATA_FAILURE',

    SET_SETTINGS_REQUEST = 'USER_SET_SETTINGS_REQUEST',
    SET_SETTINGS_SUCCESS = 'USER_SET_SETTINGS_SUCCESS',
    SET_SETTINGS_FAILURE = 'USER_SET_SETTINGS_FAILURE',

    CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'
}

export const getUserDataAction = () => async dispatch => {
    dispatch({
        type: UserActionTypes.GET_DATA_REQUEST
    });

    try {
        let userData = API.user()
            .data()
            .get();
        // let MFAStatus = API.user()
        //     .auth()
        //     .mfa()
        //     .get();
        let results = await Promise.all([userData]);

        // TODO: for development only
        // results[0].tags = results[0].tags.filter(tag => tag.tag_name.match(/smartercodes/));

        let user = {
            ...results[0]
            // mfaActive: results[1].active
        };
        dispatch({
            type: UserActionTypes.GET_DATA_SUCCESS,
            user
        });
    } catch (error) {
        // @ts-ignore
        dispatch(APIFailureAction(error.response, error.errorMessage + ' (getUserDataAction)', true));
    }
};
export const getUserMFAActiveAction = () => async dispatch => {
    dispatch({
        type: UserActionTypes.GET_MFA_ACTIVE_REQUEST
    });

    try {
        // let userData = API.user()
        //     .data()
        //     .get();
        let MFAStatus = API.user()
            .auth()
            .mfa()
            .get();
        let results = await Promise.all([MFAStatus]);

        // TODO: for development only
        // results[0].tags = results[0].tags.filter(tag => tag.tag_name.match(/smartercodes/));

        let user = {
            // ...results[0],
            mfaActive: results[0].active
        };
        dispatch({
            type: UserActionTypes.GET_MFA_ACTIVE_SUCCESS,
            user
        });
    } catch (error) {
        // @ts-ignore
        dispatch(APIFailureAction(error.response, error.errorMessage + ' (getUserMFAActiveAction)', true));
    }
};

export const setUserSettingsAction = (settings: Partial<IUserSettings>) => async dispatch => {
    dispatch({
        type: UserActionTypes.SET_SETTINGS_REQUEST,
        settings
    });
    try {
        let settingsResponse = await API.user()
            .settings()
            .post({ ...settings });
        dispatch({
            type: UserActionTypes.SET_SETTINGS_SUCCESS,
            settings: settingsResponse.settings
        });
        if (settings.currency) {
            dispatch({
                type: UserActionTypes.GET_DATA_REQUEST
            });
            try {
                let userData = API.user()
                    .data()
                    .get();
                let results = await Promise.all([userData]);
                let user = {
                    ...results[0]
                };

                dispatch({
                    type: UserActionTypes.GET_DATA_SUCCESS,
                    user
                });
            } catch (error) {
                dispatch(
                    // @ts-ignore
                    APIFailureAction(error.response,error.errorMessage + ' (getUserDataAction)',true)
                );
            }
        }
    } catch (error) {
        dispatch({
            type: UserActionTypes.SET_SETTINGS_FAILURE
        });
        // @ts-ignore
        dispatch(APIFailureAction(error.response, error.errorMessage + ' (setUserSettingsAction)'));
    }
};
