import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    PieController,
    Title,
    Tooltip
} from 'chart.js';
import 'chartjs-plugin-annotation';
import cx from 'classnames';
import merge from 'lodash.merge';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { Alert } from 'reactstrap';
import { getStyle } from 'utils';
import { getOptions } from './helpers';

// Init chart with tree shaking
ChartJS.register(PieController, ArcElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

type Props = {
    labels: string[];
    datasets;
    options: any;
    setRef?: Function;
    height?: number;
    error?: string;
};

const PieChart = ({ labels, datasets, options, height = 100, setRef, error }: Props) => {
    const defaultOptions = getOptions();
    const totalOptions = merge(defaultOptions, options);

    const setRefFunc = (ref) => {
        const chartRef = ref?.chartInstance;
        if (setRef && chartRef) setRef(chartRef);
    };

    const data = {
        labels,
        datasets: datasets.map((dataset) => ({
            borderColor: '#ffffff',
            ...dataset,
            backgroundColor: dataset.backgroundColor.map((color) => getStyle(color)),

            borderWidth: 1,
            hoverBackgroundColor: (
                dataset.hoverBackgroundColor ||
                dataset.backgroundColor ||
                []
            ).map((color) => getStyle(color)),
            hoverBorderColor: 'rgba(255,255,255,1)'
        }))
    };

    // I am a horrible theme switching hack, please replace me
    // ================================================================
    const local = localStorage.getItem('theme') || 'light';
    const [theme, setTheme] = useState(local);
    const [liveTheme, setLiveTheme] = useState(local);

    useEffect(() => {
        const time = setInterval(() => {
            const local = localStorage.getItem('theme') || 'light';
            if (liveTheme !== local) setLiveTheme(local);
        }, 1000);

        return () => {
            clearInterval(time);
        };
    }, [liveTheme]);

    useEffect(() => {
        if (theme !== liveTheme) setTheme(liveTheme);
    }, [liveTheme]);
    // ================================================================

    return (
        <div
            className={cx('pie-chart-wrapper chart-wrapper', 'mt-2', {
                'u-flex-center u-flex-justify-center': error
            })}
            data-testid="pie-chart-wrapper"
        >
            {theme === liveTheme &&
                (error ? (
                    <Alert className="text-center mt-4" color="info">
                        {error}
                    </Alert>
                ) : (
                    <Chart
                        data={data || []}
                        height={height}
                        options={totalOptions}
                        ref={setRefFunc}
                        type="pie"
                    />
                ))}
        </div>
    );
};

export default PieChart;
