import { AuthActionTypes } from '../actions/auth.actions';

export interface IAuthState {
    loggedIn: boolean | null;
    loggingIn: boolean;
    checkingStatus: boolean;
    hasChecked: boolean;
    hasLoggedOut: boolean;
    isError: boolean;
    errorMessage: string;
    // user: User | undefined;
    // credentials: {
    //     key: string | undefined;
    //     secret: string | undefined;
    // };
}
const initialState: IAuthState = {
    loggedIn: null,
    loggingIn: false,
    checkingStatus: false,
    hasChecked: false,
    hasLoggedOut: false,
    isError: false,
    errorMessage: ''
    // credentials: {
    //     key: undefined,
    //     secret: undefined
    // },
    // user: undefined
};

export function auth(state: IAuthState = initialState, action): IAuthState {
    switch (action.type) {
        case AuthActionTypes.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
                loggedIn: false,
                isError: false,
                errorMessage: ''
            };
        case AuthActionTypes.STATUS_REQUEST:
            return {
                ...state,
                loggedIn: state.loggedIn || false,
                checkingStatus: true,
                // hasChecked: true,
                isError: false,
                errorMessage: ''
            };
        case AuthActionTypes.LOGIN_SUCCESS:
        case AuthActionTypes.STATUS_YES:
            return {
                ...state,
                loggedIn: true,
                loggingIn: false,
                checkingStatus: false,
                hasChecked: true,
                ...action.client
            };
        case AuthActionTypes.LOGIN_FAILURE:
            return {
                ...initialState,
                loggedIn: false,
                loggingIn: false,
                checkingStatus: false,
                errorMessage: action.error,
                isError: true
            };
        case AuthActionTypes.STATUS_NO:
            return {
                ...initialState,
                loggedIn: false,
                loggingIn: false,
                checkingStatus: false,
                hasChecked: true
            };
        case AuthActionTypes.LOGOUT_SUCCESS:
            return {
                ...initialState,
                loggedIn: false,
                hasLoggedOut: true
            };
        case AuthActionTypes.CLEAR_ERROR:
            return {
                ...state,
                isError: false,
                errorMessage: ''
            };
        default:
            return state;
    }
}
