import { formatNumber, formatNumberAsCurrency, formatPercentFromTotal } from 'utils';
import { OverlayData, OverlaysState } from '../overlays.reducers';

export type CampaignPerformanceTableRow = {
    tag?: string;
    overlay: string;
    id: string;
    overlayType: string;
    overlayLoaded: number;
    overlayShown: number;
    shownPercent: number;
    engagement: number;
    engagePercent: number;
    sales: number;
    revenue: number;
    aov: number;
    conversionPercent: number;
    commission?: number;
    commissionPercent?: number;
    epc?: number;
    rpo?: number;
    declinedRevenue?: number;
    declinedCommission?: number;
    approvalRatePercent?: number;
    on: number;
    key: number;
};

const overlayTypes = {
    emailToReveal: 'Email to Reveal',
    email: 'Email',
    dynamicForm: 'Dynamic Form',
    clickToReveal: 'Click to Reveal',
    clickRedirect: 'Click to Redirect',
    requestNotifications: 'Request Notifications',
    dataConsent: 'Data Consent',
    emailToGiftCloud: 'Email to GiftCloud'
};

export function apiOverlayData(state: OverlaysState, rawApiData: OverlayData) {
    const { output } = rawApiData;
    const {
        overlayData: { groupDeviceData, hidePaused }
    } = state;

    const tags = Object.values(output);

    const canViewCommission = tags.length
        ? typeof Object.values(tags[0])[0].commission_approved !== 'undefined'
        : false;

    const finalData = {} as { [key: string]: CampaignPerformanceTableRow };

    tags.forEach((tag) => {
        const overlays = Object.values(tag);
        const overlayNames: string[] = [];

        overlays.forEach((overlay) => {
            const {
                overlay_loaded: loaded,
                overlay_shown: shown,
                engagement,
                sales,
                revenue_approved: approvedRevenue,
                revenue_pending: pendingRevenue,
                revenue_declined: declinedRevenue,
                commission_approved: approvedCommission,
                commission_pending: pendingCommission,
                commission_declined: declinedCommission,
                tag_name: tagName,
                overlay_name: overlayName,
                overlay_id: overlayId,
                overlay_type: overlayType
            } = overlay;

            const revenue = approvedRevenue + pendingRevenue;
            const commission = (approvedCommission || 0) + (pendingCommission || 0);

            const regex = /\s*\((mobile|tablet|desktop)\)/gi;
            const formattedName = overlayName.replace(regex, '');
            const nameIndex = overlayNames.indexOf(formattedName);
            const formattedType = overlayTypes[overlayType] || overlayType;

            if (groupDeviceData && nameIndex > -1) {
                finalData[formattedName].overlay = formattedName;
                finalData[formattedName].overlayType = formattedType;
                finalData[formattedName].overlayLoaded += loaded;
                finalData[formattedName].overlayShown += shown;
                finalData[formattedName].shownPercent =
                    (finalData[formattedName].overlayShown /
                        finalData[formattedName].overlayLoaded) *
                    100;
                finalData[formattedName].engagement += engagement;
                finalData[formattedName].engagePercent =
                    (finalData[formattedName].engagement / finalData[formattedName].overlayShown) *
                    100;
                finalData[formattedName].sales += sales;
                finalData[formattedName].revenue += revenue;
                finalData[formattedName].aov =
                    finalData[formattedName].revenue / finalData[formattedName].sales;
                finalData[formattedName].conversionPercent =
                    (finalData[formattedName].sales / finalData[formattedName].engagement) * 100;

                if (canViewCommission) {
                    (finalData[formattedName] as any).commission += commission || 0;
                    finalData[formattedName].commissionPercent =
                        ((finalData[formattedName].commission || 0) /
                            finalData[formattedName].revenue) *
                        100;
                    finalData[formattedName].epc =
                        (finalData[formattedName].commission || 0) /
                        finalData[formattedName].engagement;
                    finalData[formattedName].rpo =
                        finalData[formattedName].revenue / finalData[formattedName].overlayShown;
                    (finalData[formattedName] as any).declinedRevenue += declinedRevenue || 0;
                    (finalData[formattedName] as any).declinedCommission += declinedCommission || 0;
                    finalData[formattedName].approvalRatePercent =
                        (finalData[formattedName].revenue /
                            (finalData[formattedName].revenue +
                                (finalData[formattedName].declinedRevenue || 0))) *
                        100;
                }
            } else {
                const rowItem: CampaignPerformanceTableRow = {
                    tag: tagName,
                    overlay: overlayName,
                    id: overlayId.toString(),
                    overlayType: formattedType,
                    overlayLoaded: loaded,
                    overlayShown: shown,
                    shownPercent: (shown / loaded) * 100,
                    engagement,
                    engagePercent: (engagement / shown) * 100,
                    sales,
                    revenue,
                    aov: revenue / sales,
                    conversionPercent: (sales / engagement) * 100,
                    on: overlay.overlay_on || 0,
                    key: overlayId
                };

                if (canViewCommission) {
                    rowItem.commission = commission || 0;
                    rowItem.commissionPercent = ((commission || 0) / revenue) * 100;
                    rowItem.epc = (commission || 0) / engagement;
                    rowItem.rpo = revenue / shown;
                    rowItem.declinedRevenue = declinedRevenue || 0;
                    rowItem.declinedCommission = declinedCommission || 0;
                    rowItem.approvalRatePercent =
                        (revenue / (revenue + (declinedRevenue || 0))) * 100;
                }

                const key = groupDeviceData ? formattedName : overlay.overlay_name;
                finalData[key] = rowItem;
            }

            overlayNames.push(formattedName);
        });
    });

    let tableRows: CampaignPerformanceTableRow[] = Object.values(finalData);

    if (hidePaused) {
        tableRows = tableRows.filter((row) => row.on === 1);
    }

    const newState = {
        ...state,
        overlayData: {
            ...state.overlayData,
            tableRows
        }
    };

    return newState;
}

const percentagePartsMap = {
    shownPercent: ['overlayShown', 'overlayLoaded'],
    engagePercent: ['engagement', 'overlayShown'],
    conversionPercent: ['sales', 'engagement'],
    commissionPercent: ['commission', 'revenue'],
    approvalRatePercent: ['revenue', 'revenue', 'declinedRevenue']
};

const currencyPartsMap = {
    epc: ['commission', 'engagement'],
    rpo: ['revenue', 'overlayShown'],
    aov: ['revenue', 'sales']
};

const declinedPartsMap = {
    declinedRevenue: ['declinedRevenue', 'revenue'],
    declinedCommission: ['declinedCommisson', 'commission']
};

export function campaignPerformanceFooterData(
    tableRows: CampaignPerformanceTableRow[],
    key: keyof CampaignPerformanceTableRow,
    locale: string,
    currencyCode?: string
) {
    switch (key) {
        case 'overlayLoaded':
        case 'overlayShown':
        case 'engagement':
        case 'sales':
            let numberTotal = 0;
            tableRows.forEach((row) => {
                numberTotal += row[key];
            });
            return formatNumber(numberTotal, locale);

        case 'shownPercent':
        case 'engagePercent':
        case 'conversionPercent':
        case 'commissionPercent':
        case 'approvalRatePercent':
            let total1 = 0;
            let total2 = 0;
            tableRows.forEach((row) => {
                total1 += row[percentagePartsMap[key][0]];
                total2 += row[percentagePartsMap[key][1]];

                if (row[percentagePartsMap[key][2]]) {
                    total2 += row[percentagePartsMap[key][2]];
                }
            });

            return formatPercentFromTotal(total1, total2, '-', 2);

        case 'revenue':
        case 'commission':
            let currencyTotal = 0;
            tableRows.forEach((row) => {
                currencyTotal += row[key] || 0;
            });

            if (typeof currencyCode !== 'undefined') {
                return formatNumberAsCurrency(
                    currencyTotal,
                    currencyCode as 'GBP',
                    locale
                );
            } else {
                return '';
            }
        case 'epc':
        case 'rpo':
        case 'aov':
            let currencyTotal1 = 0;
            let currencyTotal2 = 0;

            tableRows.forEach((row) => {
                currencyTotal1 += row[currencyPartsMap[key][0]] || 0;
                currencyTotal2 += row[currencyPartsMap[key][1]] || 0;
            });

            if (typeof currencyCode !== 'undefined') {
                return formatNumberAsCurrency(
                    currencyTotal1 / currencyTotal2,
                    currencyCode as 'GBP',
                    locale
                );
            } else {
                return '';
            }
        case 'declinedCommission':
        case 'declinedRevenue':
            let declinedTotal1 = 0;
            let declinedTotal2 = 0;

            tableRows.forEach((row) => {
                declinedTotal1 += row[declinedPartsMap[key][0]] || 0;
                declinedTotal2 += row[declinedPartsMap[key][1]] || 0;
            });

            if (typeof currencyCode !== 'undefined') {
                return formatNumberAsCurrency(
                    declinedTotal1 / declinedTotal1 + declinedTotal2,
                    currencyCode as 'GBP',
                    locale
                );
            } else {
                return '';
            }
        default:
            return '';
    }
}
