import moment from 'moment';
import { readQueryString } from 'utils';

export const getInitialStartDate = () => {
    const qsValue = readQueryString('dateStart');

    return qsValue ? moment(qsValue).format('YYYY/MM/DD') : null;
};

export const getInitialEndDate = () => {
    const qsValue = readQueryString('dateEnd');

    return qsValue ? moment(qsValue).format('YYYY/MM/DD') : null;
};

export const getInitialActiveDKs = () => {
    const urlParams: any = new URLSearchParams(window.location.search);
    const activeDKs: any = {};

    for (let [key, value] of urlParams) {
        key = decodeURI(key);
        value = decodeURI(value);

        if (key.indexOf('DK_') > -1) {
            activeDKs[key.substring(key.indexOf('_') + 1)] = value;
        }
    }

    return activeDKs;
};
