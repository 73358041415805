import get from 'lodash.get';
import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import { Card, CardBody } from 'reactstrap';
import { IUserSettings } from '../../../../services/api.service';
import accountBackgroundPhoto from '../../../../assets/img/accountPhotos/stars.jpg';
import './Account.scss';
import Spinner from 'components/Spinner/Spinner';
import { IAppStoreState } from 'redux/interfaces';
import { useDispatch, connect } from 'react-redux';
import defaultUser from '../../../../assets/img/accountPhotos/defaultUser.jpg';
import {
    addModalAction,
} from 'redux/actions';

const mapStateToProps = (state: IAppStoreState) => ({
    user: state.user,
    loggedIn: state.auth.loggedIn,
});

type Props = ReturnType<typeof mapStateToProps>

const AccountProfileHeader: FunctionComponent<Props> = ({ loggedIn, user }) => {
    const dispatch = useDispatch();
    const [userImageVisibility, setUserImageVisibility] = useState('');
    const [userImage, setUserImage] = useState<string>('');
    const [bannerImage, setBannerImage] = useState<string>('');
    const currentBannerImage = useRef('');
    const currentUserImage = useRef('');

    const userSettings: IUserSettings = get(user, 'settings', {});

    // remove flickering back to the default background when getting the new image
    useEffect(() => {
        if (user.uploadImage && user.uploadImage.imageLink && !user.uploadImage.isUploading) {
            if (user.uploadImage.imageType === 'profile_image') {
                currentUserImage.current = user.uploadImage.imageLink;
                setUserImage(user.uploadImage.imageLink);
            }

            if (user.uploadImage.imageType === 'banner') {
                currentBannerImage.current = user.uploadImage.imageLink;
                setBannerImage(user.uploadImage.imageLink);
            }
        } else {
            setUserImage(userSettings.profile_image || defaultUser);
            setBannerImage(userSettings.banner);
        }
    }, [user]);

    useEffect(() => {
        if (
            user &&
            user.uploadImage &&
            user.uploadImage.isUploading &&
            user.uploadImage.imageType === 'banner'
        ) {
            setUserImageVisibility('');
        } else if (loggedIn && user) {
            setTimeout(function () {
                setUserImageVisibility('show');
            }, 100);
        }
    }, [user.uploadImage]);

    return (
        <Card className="profile-header">
            <CardBody>
                <div
                    className="background-photo"
                    style={{
                        backgroundImage: `url(${
                            currentBannerImage.current || bannerImage || accountBackgroundPhoto
                        })`
                    }}
                />
                {user.uploadImage &&
                user.uploadImage.imageType === 'banner' &&
                user.uploadImage.isUploading ? (
                    <div className="background-photo-spinner">
                        <Spinner large />
                    </div>
                ) : (
                    <div className="background-photo-hover">
                        <div
                            className="background-photo-hover-content"
                            onClick={() => {
                                dispatch(addModalAction('uploadProfileBanner', '', ''));
                            }}
                        >
                            <i className="fa fa-image" />
                            <span>Change Banner</span>
                        </div>
                    </div>
                )}

                <div className="radial-gradient" />
                <div className="profile-photo-window">
                    <div className={`profile-photo-wrapper ${userImageVisibility}`}>
                        <div
                            className="profile-photo"
                            style={{
                                backgroundImage: `url(${currentUserImage.current || userImage})`,
                                backgroundColor: 'white',
                                cursor: 'pointer'
                            }}
                        />
                        {user.uploadImage &&
                        user.uploadImage.imageType === 'profile_image' &&
                        user.uploadImage.isUploading ? (
                            <div className="profile-photo-spinner">
                                <Spinner large />
                            </div>
                        ) : (
                            <div
                                className="profile-photo-hover"
                                onClick={() => dispatch(addModalAction('uploadProfilePic', '', ''))}
                            >
                                <i aria-hidden="true" className="fa fa-camera" />
                                <span className="change-image-text">Change Picture</span>
                            </div>
                        )}
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default connect(mapStateToProps)(AccountProfileHeader);
