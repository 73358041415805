import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import './NumericalWidget.scss';
import Tooltip from 'components/Tooltip/Tooltip';

const NumericalWidget = ({ title, total, tooltip }) => {
    return (
        <Card className="percentage-widget revenue-lost">
            <CardHeader>{title}{tooltip?<>&nbsp;<Tooltip>{tooltip}</Tooltip></>:''}</CardHeader>
            <CardBody>{total}</CardBody>
        </Card>
    );
};

export default NumericalWidget;
