import { FabActionTypes } from '../actions';

const initialState = {
    icon: '',
    text: '',
    backgroundColor: '',
    onClick: () => {}
};

export function fab(state: any = initialState, action) {
    switch (action.type) {
        case FabActionTypes.ADD:
            const newFab = {
                icon: action.payload.icon,
                text: action.payload.text,
                backgroundColor: action.payload.backgroundColor,
                onClick: action.payload.onClick
            };

            return {
                ...newFab
            };

        case FabActionTypes.REMOVE:
            const blankFab = { icon: '', text: '', onClick: () => {} };

            return {
                ...blankFab
            };

        default:
            return state;
    }
}
