import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector as uSel } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import ReactTable from 'components/ReactTable/ReactTable';
import SpinnerCard from 'components/Spinner/SpinnerCard';
import { IAppStoreState as S } from 'redux/interfaces';
import CodesLink from '../../../../../../../components/CodesLink/CodesLink';
import { generateColumns } from './helpers';
import './ReferralsBreakdown.scss';
import { startCase } from 'lodash';
import { setCodesAction, setReferralAction } from 'Dashboard/redux/dashboard.actions';

interface Props {
  filter: { type: string; name: string; id: number } | null;
}

const ReferralsBreakdown = ({ filter }: Props) => {
  const dispatch = useDispatch();
  const advanceLoading = uSel((state: S) => state?.smartercodes?.api?.advanceView?.isLoading);
  const extraLoading = uSel((state: S) => state?.smartercodes?.api?.advanceViewExtra?.isLoading);
  const isLoading = advanceLoading || extraLoading;
  const publisherBreakdownList = uSel(
    (state: S) => state?.smartercodes?.referrals?.publisherBreakdownList || []
  );
  const tagName = uSel((state: S) => state?.dashboard?.context?.tag?.tag_name);

  const [codesRedirect, setCodesRedirect] = useState({
    codeId: '',
    codeName: '',
    trigger: false
  });

  if (isLoading) return <SpinnerCard />;

  if (!filter)
    return (
      <Card id="no-network-publisher">
        <CardBody>
          <span className="icon material-icons-outlined">arrow_upward</span>
          <span>Pick a Channel, Source or Publisher from the table above</span>
        </CardBody>
      </Card>
    );

  const download = `Referrals Breakdown-${tagName}-${filter.name}`;

  const onClick = (cell) => {
    const { row } = cell;
    const { original } = row;
    const { code, codeId } = original;

    const { type, name, id } = filter;

    if(type === 'source') dispatch(
      setReferralAction({
        channels: [],
        sources: [{ id, name }],
        publishers: []
      })
    );
    if(type === 'publisher') dispatch(
      setReferralAction({
        channels: [],
        sources: [],
        publishers: [{ id, name }]
      })
    );
    if(type === 'channel') dispatch(
      setReferralAction({
        channels: [{ id, name }],
        sources: [],
        publishers: []
      })
    );
 
    dispatch(
      setCodesAction([{ id: codeId,name: code }])
    );

    setCodesRedirect({
      codeId: cell.row.original.codeId,
      codeName: cell.row.original.code,
      trigger: true
    });
  };

  return (
    <Fragment>
      <CodesLink
        codeId={codesRedirect.codeId}
        codeName={codesRedirect.codeName}
        trigger={codesRedirect.trigger}
      />
      <Card className="card-table">
        <CardHeader id="referral-title">
          <span>
            {startCase(filter.type)}: <strong>{filter.name}</strong>
          </span>
        </CardHeader>
        <CardBody>
          <ReactTable
            columns={generateColumns(filter.type, onClick)}
            data={publisherBreakdownList}
            download={download}
            footer
            pagination
            search
            sort={{ id: 'uniqueUsers', desc: true }}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ReferralsBreakdown;
