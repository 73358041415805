import { AccountActionTypes } from './account.actions';
import { AuthActionTypes } from 'redux/actions';

let initial: IAccountState = {
    changePassword: {
        requestInProcess: false,
        updated: false,
        isError: false,
        currentError: ''
    },
    changeMfa: {
        requestInProcess: false,
        isError: false,
        currentError: '',
        QRCode: '',
        haveQRCode: false,
        success: false
    }
};

export interface IAccountState {
    changePassword: {
        requestInProcess: boolean;
        isError: boolean;
        currentError: string;
        updated: boolean;
    };
    changeMfa: {
        requestInProcess: boolean;
        isError: boolean;
        currentError: string;
        haveQRCode: boolean;
        QRCode: string;
        success: boolean;
    };
}
export function account(state: IAccountState = initial, action): IAccountState {
    switch (action.type) {
        case AccountActionTypes.CHANGE_PASSWORD_RESET:
            return {
                ...state,
                changePassword: {
                    ...initial.changePassword
                }
            };
        case AccountActionTypes.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    requestInProcess: true
                }
            };
        case AccountActionTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    // requestInProcess: false,
                    updated: true
                }
            };
        case AccountActionTypes.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    requestInProcess: false,
                    isError: true,
                    currentError: action.error
                }
            };
        case AccountActionTypes.UPDATE_MFA_INIT_REQUEST:
            return {
                ...state,
                changeMfa: {
                    ...state.changeMfa,
                    requestInProcess: true
                }
            };
        case AccountActionTypes.UPDATE_MFA_INIT_SUCCESS:
            return {
                ...state,
                changeMfa: {
                    ...state.changeMfa,
                    requestInProcess: false,
                    QRCode: action.qr_code,
                    haveQRCode: true
                }
            };
        case AccountActionTypes.UPDATE_MFA_CONF_REQUEST:
            return {
                ...state,
                changeMfa: {
                    ...state.changeMfa,
                    requestInProcess: true
                }
            };
        case AccountActionTypes.UPDATE_MFA_CONF_SUCCESS:
            return {
                ...state,
                changeMfa: {
                    ...state.changeMfa,
                    isError: false,
                    currentError: '',
                    requestInProcess: false,
                    success: true
                }
            };
        case AccountActionTypes.UPDATE_MFA_CONF_FAILURE:
        case AccountActionTypes.UPDATE_MFA_INIT_FAILURE:
            return {
                ...state,
                changeMfa: {
                    ...state.changeMfa,
                    requestInProcess: false,
                    isError: true,
                    currentError: action.error
                }
            };
        case AccountActionTypes.REMOVE_MFA_REQUEST:
            return {
                ...state,
                changeMfa: {
                    ...state.changeMfa,
                    requestInProcess: true
                }
            };
        case AccountActionTypes.REMOVE_MFA_SUCCESS:
            return {
                ...state,
                changeMfa: {
                    ...state.changeMfa,
                    requestInProcess: false,
                    success: true
                }
            };
        case AccountActionTypes.REMOVE_MFA_FAILURE:
            return {
                ...state,
                changeMfa: {
                    ...state.changeMfa,
                    requestInProcess: false,
                    isError: true,
                    currentError: action.error
                }
            };
        case AccountActionTypes.CHANGE_MFA_ERROR_RESET:
            return {
                ...state,
                changeMfa: {
                    ...state.changeMfa,
                    isError: false,
                    currentError: ''
                }
            };
        case AccountActionTypes.CHANGE_MFA_RESET:
            return {
                ...state,
                changeMfa: {
                    ...initial.changeMfa
                }
            };
        case AuthActionTypes.LOGOUT_SUCCESS:
            return {
                ...initial
            };
        default:
            return { ...state };
    }
}
