import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Spinner from 'components/Spinner/Spinner';
import { fillNames, requestChannels, requestReport } from './helpers';
import { useDispatch, useSelector as uSel } from 'react-redux';
import { IAppStoreState as S } from 'redux/interfaces';
import './ReferralsLinks.scss';
import { ActiveChannel, ActivePublisher, ActiveSource, Channel } from './types';
import Options from './components/Options/Options';
import Table from './components/Table/Table';
import { UpdateEndedAction } from '../../../../redux/dashboard.actions';
import useTruthy from 'utils/customHooks/useOnTrue';
import useWake from '../../../../../utils/customHooks/useWake';

const ReferralsLinks = () => {
  const dispatchRedux = useDispatch();
  const startDate = uSel((state: S) =>
    moment(state.dashboard.context.startDate).format('YYYY-MM-DD')
  );
  const endDate = uSel((state: S) => moment(state.dashboard.context.endDate).format('YYYY-MM-DD'));
  const tagId = uSel((state: S) => state.dashboard.context.tag.tag_index);
  const timezone = uSel((state: S) => state.user.settings.timezone);
  const dynamicKeys = uSel((state: S) => state.dashboard.context.activeDKs);
  const tagName = uSel((state: S) => state?.dashboard?.context?.tag?.tag_name);

  // Channel data
  const [allChannels, setAllChannels] = useState<Channel[]>([]);
  const [isChannelsLoading, setIsChannelsLoading] = useState<any>();
  const [activeChannels, setActiveChannels] = useState<ActiveChannel>();
  const [activeSources, setActiveSources] = useState<ActiveSource>();
  const [activePublishers, setActivePublishers] = useState<ActivePublisher>();

  // Report data
  const [tableData, setTableData] = useState<any>(null);
  const [isTableLoading, setIsTableLoading] = useState<any>(false);
  const [error, setError] = useState<any>(null);

  const resetPage = () => {
    setActiveChannels(undefined);
    setActiveSources(undefined);
    setActivePublishers(undefined);
    setTableData(null);
  };

  const getChannels = () => {
    setIsChannelsLoading(true);
    requestChannels({
      tagId,
      startDate,
      endDate
    }).then((response) => {
      setIsChannelsLoading(false);
      setAllChannels(response);
      dispatchRedux(UpdateEndedAction());
    });
  };

  const getReferralLinks = () => {
    setError(null);
    setIsTableLoading(true);
    requestReport({
      tagId,
      startDate,
      endDate,
      dynamicKeys,
      timezone,
      channels: activeChannels?.value ?? [],
      sources: activeSources?.value ?? [],
      publishers: activePublishers?.value ?? []
    })
      .then((response) => {
        const responseWithNames = fillNames(response, allChannels);

        setTableData(responseWithNames);
        setIsTableLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setIsTableLoading(false);
      });
  };

  // Reset scroll on page load
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  // Refresh data on machine wake
  useWake(() => {
    const lastDay = moment(endDate, 'YYYY/MM/DD');
    const today = moment();
    const includesToday = lastDay.isSame(today, 'day');

    if (includesToday) getChannels();
    if (includesToday && tableData !== null) getReferralLinks();
  }, 70);

  // Request channels when context changes
  useTruthy(
    () => {
      resetPage();
      getChannels();
    },
    startDate !== 'Invalid date' && endDate !== 'Invalid date',
    [startDate, endDate, tagId, dynamicKeys]
  );

  return (
    <Card className="card-table">
      {isChannelsLoading ? (
        <CardBody>
          <Row style={{ justifyContent: 'center' }}>
            <Col xs="auto">
              <Spinner large />
            </Col>
          </Row>
        </CardBody>
      ) : (
        <>
          <CardHeader>
            <Options
              activeChannels={[activeChannels, setActiveChannels]}
              activePublishers={[activePublishers, setActivePublishers]}
              activeSources={[activeSources, setActiveSources]}
              allChannels={allChannels}
              isTableLoading={isTableLoading}
              onSubmit={() => {
                getReferralLinks();
              }}
            />
          </CardHeader>
          <CardBody>
            <Table
              error={error}
              isTableLoading={isTableLoading}
              tableData={tableData}
              tagName={tagName}
            />
          </CardBody>
        </>
      )}
    </Card>
  );
};

export default ReferralsLinks;
