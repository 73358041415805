import {
  cellCurrency,
  cellCurrencyAverage,
  cellNumber,
  cellString,
  footerCurrency,
  footerCurrencyAverage,
  footerNumber
} from 'components/ReactTable/utils';
import { ColumnDef } from '@tanstack/table-core';

type dataValue = Record<string, string | number>;
type GenerateColumns = (
  type: string,
  setCodesRedirect
) => (ColumnDef<dataValue, string> | ColumnDef<dataValue, number>)[];

export const generateColumns: GenerateColumns = (type, onClick) => [
  {
    id: 'code',
    accessorKey: 'code',
    header: 'Code',
    footer: () => 'Total',
    meta: {
      download: cellString,
      onCellClick: onClick
    }
  },
  ...(type !== 'channel' && type !== 'source'
    ? [
        {
          id: 'channel',
          accessorKey: 'channel',
          header: 'Channel',
          cell: cellString,
          meta: { download: cellString }
        }
      ]
    : []),
  ...(type !== 'source'
    ? [
        {
          id: 'source',
          accessorKey: 'source',
          header: 'Source',
          cell: cellString,
          meta: { download: cellString }
        }
      ]
    : []),
  ...(type !== 'publisher'
    ? [
        {
          id: 'publisher',
          accessorKey: 'publisher',
          header: 'Publisher',
          cell: cellString,
          meta: { download: cellString }
        }
      ]
    : []),
  {
    id: 'uniqueUsers',
    accessorKey: 'uniqueUsers',
    header: 'Users',
    cell: cellNumber,
    footer: footerNumber,
    meta: { align: 'right' }
  },
  {
    id: 'codeAttempts',
    accessorKey: 'codeAttempts',
    header: 'Code Attempts',
    cell: cellNumber,
    footer: footerNumber,
    meta: { align: 'right' }
  },
  {
    id: 'validAttempts',
    accessorKey: 'validAttempts',
    header: 'Valid Attempts',
    cell: cellNumber,
    footer: footerNumber,
    meta: { align: 'right' }
  },
  {
    id: 'invalidAttempts',
    accessorKey: 'invalidAttempts',
    header: 'Invalid Attempts',
    cell: cellNumber,
    footer: footerNumber,
    meta: { align: 'right' }
  },
  {
    id: 'orders',
    accessorKey: 'orders',
    header: 'Orders',
    cell: cellNumber,
    footer: footerNumber,
    meta: { align: 'right' }
  },
  {
    id: 'Revenue',
    header: 'Revenue',
    accessorKey: 'revenue',
    cell: cellCurrency,
    footer: footerCurrency,
    meta: { align: 'right' }
  },
  {
    id: 'discountValue',
    accessorKey: 'discountValue',
    header: 'Discount Value',
    cell: cellCurrency,
    footer: footerCurrency,
    meta: { align: 'right' }
  },
  {
    header: 'Avg. Order Value',
    accessorKey: 'averageOrderValue',
    align: 'right',
    cell: cellCurrencyAverage,
    footer: footerCurrencyAverage,
    meta: {
      align: 'right',
      from: ['revenue', 'orders']
    }
  }
];
