import React, { FunctionComponent } from 'react';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import { Button, Card, CardBody } from 'reactstrap';
import './Account.scss';

type Props = {};

const buttons = [
    {
        icon: { type: 'fa', value: 'twitter' },
        url: 'https://twitter.com/intent_ly',
        className: 'btn-twitter'
    },
    {
        icon: { type: 'fa', value: 'linkedin' },
        url: 'https://www.linkedin.com/company/intent-ly/mycompany/?viewAsMember=true',
        className: 'btn-linkedin'
    },
    {
        icon: { type: 'material', value: 'email' },
        url: 'mailto:team@intent.ly',
        className: 'btn-email'
    }
];

const AccountContact: FunctionComponent<Props> = (props: Props) => {
    return (
        <Card className="contact-card">
            <div className="card-media p-0" style={{ overflow: 'hidden' }}>
                <iframe
                    allowFullScreen
                    frameBorder="0"
                    height="350"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.521535522712!2d-0.017108283983090174!3d51.50364717963452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876034f589e04c3%3A0x37ae51f66f047f97!2sintent.ly%20Global%20ltd!5e0!3m2!1sen!2suk!4v1628693548069!5m2!1sen!2suk"
                    style={{ border: 0, width: '100%' }}
                    title="our location map"
                    width="100%"
                />
            </div>
            <CardBody>
                <h4>Contact Us</h4>
                <div className="d-flex align-items-center">
                    {buttons.map(({ icon: { type, value }, url, className }) => (
                        <a
                            className="mr-2"
                            href={url}
                            key={value}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <Button className={className} size="lg">
                                {type === 'fa' ? (
                                    <i className={`fa fa-${value}`} />
                                ) : (
                                    <span
                                        className="icon material-icons-outlined"
                                        style={{ color: 'var(--text-inverse)' }}
                                    >
                                        {value}
                                    </span>
                                )}
                            </Button>
                        </a>
                    ))}
                </div>
            </CardBody>
        </Card>
    );
};

export default AccountContact;
