export enum ModalActionTypes {
    ADD = 'ADD_MODAL',
    SHOW = 'SHOW_MODAL',
    HIDE = 'HIDE_MODAL'
}

export const addModalAction = (modalType, content, modalButtons) => ({
    type: ModalActionTypes.ADD,
    payload: {
        modalType,
        content,
        modalButtons
    }
});

export const showModalAction = () => ({
    type: ModalActionTypes.SHOW
});

export const hideModalAction = () => ({
    type: ModalActionTypes.HIDE
});
