import React from 'react';
import { useSelector as uSel } from 'react-redux';
import { IAppStoreState as S } from 'redux/interfaces';

import ReactTable from 'components/ReactTable/ReactTable';
import { generateColumns } from './helpers';
import SpinnerCard from 'components/Spinner/SpinnerCard';
import { Card, CardBody } from 'reactstrap';

const CodesDomainReferrals = (): JSX.Element => {
    const isLoading = uSel((state: S) => state?.smartercodes?.api?.codeDetails?.isLoading || false);
    const domainReferralsData = uSel(
        (state: S) => state?.smartercodes?.codes?.currentCode?.domainReferrers || []
    );
    const tagName = uSel(
      (state: S) => state?.dashboard?.context?.tag?.tag_name
    );
    
    const downloadName = `Domain Referrers${tagName ? ` - ${tagName}` : ''}`;

    if (isLoading) return <SpinnerCard />;

    return (
        <Card className="card-table">
            <CardBody>
                <ReactTable
                    columns={generateColumns()}
                    data={domainReferralsData}
                    download={downloadName}
                    pagination
                    search
                    sort={{ id: 'uniqueUsers', desc: true }}
                />
            </CardBody>
        </Card>
    );
};

export default CodesDomainReferrals;
