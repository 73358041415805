export enum FabActionTypes {
    ADD = 'ADD_FAB',
    REMOVE = 'REMOVE_FAB'
}

export const addFabAction = (icon, text, backgroundColor, onClick) => {
    return {
        type: FabActionTypes.ADD,
        payload: {
            icon,
            text,
            backgroundColor,
            onClick
        }
    };
};

export const removeFabAction = () => ({
    type: FabActionTypes.REMOVE
});
