import {
  cellCurrency,
  cellNumber,
  cellPercent,
  cellStringSliceFormatter
} from 'components/ReactTable/utils';
import { ColumnDef } from '@tanstack/table-core';

type dataValue = Record<string, string | number>;
type GenerateColumns = () => (ColumnDef<dataValue, string> | ColumnDef<dataValue, number>)[];

export const generateColumns: GenerateColumns = () => [
  {
    id: 'key',
    accessorKey: 'key',
    header: 'Code Types',
    cell: cellStringSliceFormatter
  },
  {
    id: 'attempts',
    accessorKey: 'attempts',
    header: 'Code Attempts',
    cell: cellNumber,
    meta: { align: 'right' }
  },
  {
    id: 'orders',
    accessorKey: 'orders',
    header: 'Orders',
    cell: cellNumber,
    meta: { align: 'right' }
  },
  {
    id: 'conversion_rate',
    accessorKey: 'conversion_rate',
    header: 'Conversion Rate',
    cell: cellPercent,
    meta: {
      align: 'right',
      from: ['orders', 'uniqueUsers']
    }
  },
  {
    id: 'revenue',
    accessorKey: 'revenue',
    header: 'Revenue',
    cell: cellCurrency,
    meta: { align: 'right' }
  },
  {
    id: 'average_order_value',
    accessorKey: 'average_order_value',
    header: 'Avg. Order Value',
    cell: cellCurrency,
    meta: { align: 'right' }
  },
  {
    id: 'total_discount_value',
    accessorKey: 'total_discount_value',
    header: 'Total Discount Value',
    cell: cellCurrency,
    meta: { align: 'right' }
  }
];
