import { logoutAction } from './auth.actions';
import { addModalAction, hideModalAction } from './modal.actions';

export enum ErrorActionType {
    API_FAILURE = 'API_FAILURE',
    // AUTH_FAILURE = 'AUTH_FAILURE',
    GENERAL_FAILURE = 'GENERAL_FAILURE'
}

// export const APIAuthFailureAction = (context, error) => dispatch => {
//     let body = error.message;;
//     let title = 'There was an authentication error :(';
//     dispatch(
//         addModalAction('', { title, body }, [
//             {
//                 func: () => dispatch(logoutAction()),
//                 text: 'Logout'
//             }
//         ])
//     );
// };

export const APIFailureAction = (response, errorMessage, fatal = false) => dispatch => {
    // dispatch({
    //     type: ErrorActionType.API_FAILURE,
    //     error,
    //     response,
    //     fatal
    // });

    let body;

    if (errorMessage.indexOf('Request not authenticated') > -1) {
        dispatch(logoutAction());
        return;
    }

    if (errorMessage.body) {
        body = errorMessage.tech
            ? [
                errorMessage.body,
                'Please contact us if this keeps happening and show this to our tech team:',
                errorMessage.tech
            ]
            : [errorMessage.body];
    } else {
        body = [errorMessage, 'Please contact us if this keeps happening.'];
    }

    // if (response && response.status === 401) {
    //     let title = 'There was an authentication error :(';
    //     dispatch(
    //         addModalAction('', { title, body }, [
    //             {
    //                 func: () => dispatch(logoutAction()),
    //                 text: 'Logout'
    //             }
    //         ])
    //     );
    // } else {
    let title = 'There was an error :(';
    dispatch(
        addModalAction('', { title, body }, [
            {
                func: () => dispatch(hideModalAction()),
                text: 'Close'
            }
        ])
    );
    // }
};

export const GeneralFailureAction = (context, error, fatal = false) => {
    return {
        type: ErrorActionType.GENERAL_FAILURE,
        error,
        context,
        fatal
    };
};
