import {
  cellCurrency,
  cellCurrencyAverage,
  cellNumber,
  cellPercent,
  cellString,
  footerCurrency,
  footerCurrencyAverage,
  footerNumber,
  footerPercent
} from 'components/ReactTable/utils';
import { Cell, ColumnDef } from '@tanstack/table-core';

type dataValue = Record<string, string | number>;
type GenerateColumns = (
  onCellClick: (cell: Cell<dataValue,unknown>, index: number) => void,
) => (ColumnDef<dataValue, string> | ColumnDef<dataValue, number>)[];

export const generateColumns: GenerateColumns = (onCellClick) => [
  {
    id: 'channel',
    accessorKey: 'channel',
    header: 'Channel',
    cell: cellString,
    footer: () => 'Total',
    meta: { onCellClick: onCellClick }
  },
  {
    id: 'source',
    accessorKey: 'source',
    header: 'Source',
    cell: cellString,
    meta: { onCellClick: onCellClick }
  },
  {
    id: 'publisher',
    header: 'Publisher',
    accessorKey: 'publisher',
    cell: cellString,
    meta: { onCellClick: onCellClick }
  },
  {
    id: 'uniqueUsers',
    accessorKey: 'uniqueUsers',
    header: 'Users',
    cell: cellNumber,
    footer: footerNumber,
    meta: { align: 'right' }
  },
  {
    id: 'codeAttempts',
    accessorKey: 'codeAttempts',
    header: 'Code Attempts',
    cell: cellNumber,
    footer: footerNumber,
    meta: { align: 'right' }
  },
  {
    id: 'validRate',
    accessorKey: 'validRate',
    header: 'Valid Rate',
    cell: cellPercent,
    footer: footerPercent,
    meta: {
      align: 'right',
      from: ['validAttempts', 'codeAttempts']
    }
  },
  {
    id: 'orders',
    accessorKey: 'orders',
    header: 'Orders',
    cell: cellNumber,
    footer: footerNumber,
    meta: { align: 'right' }
  },
  {
    id: 'conversionRate',
    accessorKey: 'conversionRate',
    header: 'Conversion Rate',
    cell: cellPercent,
    footer: footerPercent,
    meta: {
      align: 'right',
      from: ['orders', 'uniqueUsers']
    }
  },
  {
    id: 'revenue',
    accessorKey: 'revenue',
    header: 'Revenue',
    cell: cellCurrency,
    footer: footerCurrency,
    meta: { align: 'right' }
  },
  {
    id: 'discountValue',
    accessorKey: 'discountValue',
    header: 'Discount Value',
    cell: cellCurrency,
    footer: footerCurrency,
    meta: { align: 'right' }
  },
  {
    id: 'averageOrderValue',
    accessorKey: 'averageOrderValue',
    header: 'Avg. Order Value',
    cell: cellCurrencyAverage,
    footer: footerCurrencyAverage,
    meta: {
      align: 'right',
      from: ['revenue', 'orders']
    }
  }
];
