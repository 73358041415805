export const standardStyles = {
    container: (base, state) => {
        //console.log('control', state);
        return {
            ...base,
            flex: 1
        };
    },
    control: (base, state) => ({
        ...base
    }),
    valueContainer: (base, state) => ({
        ...base
    }),
    multiValue: (base, state) => ({
        ...base,
        cursor: 'pointer',
        borderRadius: '9999px'
    }),
    multiValueLabel: (base, state) => ({
        ...base,
        color: state.data.color,
        paddingLeft: '8px',
        backgroundColor: '#EEEEEE',
        borderRadius: '9999px 0 0 9999px'
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        borderRadius: '0 9999px 9999px 0',
        backgroundColor: '#EEEEEE',
        transition: 'color 0.3s, background-color 0.3s',
        ':hover': {
            backgroundColor: data.color,
            color: 'white'
        }
    }),
    menu: (base, state) => ({
        ...base
    })
};

export const standardTheme = {
    borderRadius: 4,
    colors: {
        danger: '#f86c6b',
        dangerLight: '#f86c6b',
        neutral0: 'hsl(0, 0%, 100%)',
        neutral5: 'hsl(0, 0%, 95%)',
        neutral10: 'hsl(0, 0%, 90%)',
        neutral20: 'hsl(0, 0%, 80%)',
        neutral30: 'hsl(0, 0%, 70%)',
        neutral40: 'hsl(0, 0%, 60%)',
        neutral50: 'hsl(0, 0%, 50%)',
        neutral60: 'hsl(0, 0%, 40%)',
        neutral70: 'hsl(0, 0%, 30%)',
        neutral80: 'hsl(0, 0%, 20%)',
        neutral90: 'hsl(0, 0%, 10%)',
        primary: '#63c2de',
        primary25: 'rgba(99, 194, 222, 0.25)',
        primary50: 'rgba(99, 194, 222, 0.5)',
        primary75: 'rgba(99, 194, 222, 0.75)'
    },
    spacing: {
        baseUnit: 4,
        controlHeight: 38,
        menuGutter: 0
    }
};

const combined: any = { standardStyles, standardTheme };

export default combined;
