import { geoMercator, geoPath } from 'd3-geo';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import React, { useEffect, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { feature } from 'topojson-client';
import { ApiCountry } from '../CodesMap/CodesMap';
import './WorldMap.scss';
import { Country, RawWorldData } from './types';

type Props = {
    apiData: Array<ApiCountry>;
    totalAttempts: number;
    countryNames: Country;
    rawWorldData: RawWorldData | undefined;
};

export const legendColors = {
    low: ['var(--colour-map--1)', 'var(--colour-map--2)'],
    medium: ['var(--colour-map--3)', 'var(--colour-map--4)'],
    high: ['var(--colour-map--5)', 'var(--colour-map--6)'],
    default: 'var(--colour-map--default)'
};

const getFillColor = (attempts = 0, totalAttempts = 0) => {
    const percentage = (attempts / totalAttempts) * 100;
    switch (true) {
        case percentage > 0 && percentage < 10:
            return legendColors.low[0];
        case percentage >= 10 && percentage < 20:
            return legendColors.low[1];
        case percentage >= 20 && percentage < 30:
            return legendColors.medium[0];
        case percentage >= 30 && percentage < 40:
            return legendColors.medium[1];
        case percentage >= 40 && percentage < 50:
            return legendColors.high[0];
        case percentage >= 50:
            return legendColors.high[1];
        default:
            return legendColors.default;
    }
};

const geoProjection = geoMercator()
    .scale(100)
    .translate([800 / 2, 450 / 2]);

const WorldMap: React.FC<Props> = ({ apiData, totalAttempts, countryNames, rawWorldData }) => {
    const featureData = useMemo(()=>{
        const features = (rawWorldData ?
            (feature(
              rawWorldData,
              rawWorldData.objects.countries
            ) as FeatureCollection<Geometry, GeoJsonProperties>).features : []);
        return features.filter(({ id }) => id !== '-99').map(
                ({ id, type, properties, geometry })=> {
                    const d = geoPath().projection(geoProjection)({ id, type, properties, geometry }) ??
                    undefined;
                    return { id, type , properties, geometry, d };
                });
    }, [rawWorldData]);
    
    // loop through the attempts data only once
    const attemptsMap = {};
    for(let key in apiData) {
        if(apiData[key]){
            const { attempts, countryCode } = apiData[key];
            attemptsMap[countryCode] = attempts;
        }
    }

    // Keep tooltip updated with the correct values
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [apiData, countryNames]);

    return (
        <div className="map-container">
            <div className="map-scale">
                <svg data-testid="world-map" viewBox="80 -70 635 415" width="100%">
                    <g className="countries">
                        {featureData.map(
                            ({ id, d })=> {
                            const { code, name } = countryNames && id && countryNames[id] ?
                            countryNames[id] : { code: "UC", name: "Unknown" };
                            const attempts = attemptsMap[code] ?? 0;
                            const tooltipMessage = `Code Attempts <br /> ${name ? `${name} <br />` : ''}  ${attempts}`;
                            const colour = getFillColor(attempts ?? 0, totalAttempts);
                            return (
                                <path
                                    className="country"
                                    d={d}
                                    data-for={code}
                                    data-testid={code}
                                    data-tip={tooltipMessage}
                                    fill={colour}
                                    id={`${id}`}
                                    key={`path-${id}`}
                                    stroke={colour}
                                    strokeWidth={1}
                                />
                            );
                        })}
                    </g>
                </svg>

                {apiData.map(({ countryCode }) => {
                    return (
                        <ReactTooltip
                            id={countryCode}
                            key={`tooltip-${countryCode}`}
                            multiline
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default WorldMap;
