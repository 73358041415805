import { useEffect } from 'react';

/**
 * Works the same as useEffect, only difference is it will wait for isTrue to be true
 * before running the callback.
 * @param {Function} callback Callback function to run on true
 * @param {Boolean} isTrue If dependencies have updated, callback runs when isTrue === true
 * @param {Array} dependencies Dependencies to run callback
 */
const useOnTrue = (callback: () => void, isTrue: boolean, dependencies: unknown[]): void => {
  useEffect(() => {
    if (isTrue) callback();
  }, [...dependencies]);
};

export default useOnTrue;
