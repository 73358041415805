export type ReferralsList = Array<{
  channelId: number;
  channel: string;
  sourceId: number;
  source: string;
  publisherId: number;
  publisher: string;
  uniqueUsers: number;
  codeAttempts: number;
  validAttempts: number;
  conversionRate: number;
  orders: number;
  revenue: number;
  discountValue: number;
  averageOrderValue: number;
}>;

export type PublisherBreakdownList = Array<{
  codeId: number;
  channel: string;
  source: string;
  publisher: string;
  code: string;
  codeAttempts: number;
  validAttempts: number;
  invalidAttempts: number;
  uniqueUsers: number;
  orders: number;
  revenue: number;
  discountValue: number;
  averageOrderValue: number;
}>;

export enum SmarterCodesActionTypes {
  API_REQUEST = 'CODES_API_REQUEST',
  API_SUCCESS = 'CODES_API_SUCCESS',
  // API_FAILURE = 'CODES_API_FAILURE',
  API_DATA = 'CODES_API_DATA',
  DATA_TIMELINE = 'CODES_DATA_TIMELINE',
  DATA_REFERRALS = 'CODES_DATA_REFERRALS',
  SET_CODE = 'CODES_SET_CODE',
  CLEAR_CODE = 'CODES_CLEAR_CODE'
}

export enum CodesViewActionTypes {
  SET = 'SET_CODE'
}

export enum ReportsRoutes {
  OVERVIEW = 'overview',
  CODE_REVIEW = 'codeReview',
  REVENUE_LOST = 'revenueLost',
  FILTER_CODES = 'filterCodes',
  TIMELINE = 'timeline',
  ADVANCE_VIEW = 'advanceView',
  ADVANCE_VIEW_EXTRA = 'advanceViewExtra',
  UNIQUE_USERS = 'uniqueUsers',
  TOP_PUBLISHERS = 'topPublishers',
  CODE_DETAILS = 'codeDetails',
  HIGHLEVEL_GROUPING = 'highlevelGrouping'
}

export enum ReferralsViewActionTypes {
  SET_BREAKDOWN = 'SET_REFERRALS_BREAKDOWN'
}

export enum ValidityViewActionTypes {
  SET_BREAKDOWN = 'SET_VALIDITY_BREAKDOWN'
}

export interface IGetReportRouteAction {
  refresh?: boolean;
  route: ReportsRoutes;
  extraParams?: {
    [key: string]: any;
  };
  readStore?: any;
  cacheMinutes?: number;
}