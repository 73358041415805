import momentTz from 'moment-timezone';
import { compose, map, filter } from 'ramda';
import React, { useState } from 'react';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import { Card, CardBody } from 'reactstrap';
import Select from '../../../../components/Select/Select';
import { standardStyles } from '../../../../utils/reactSelectStyles';
import './Account.scss';
import useOnce from '../../../../utils/customHooks/useOnce';

const formatOption = (value: string, label: string): { value: string; label: string } => ({
  value,
  label
});
const findOption = (list = [] as Option[], value = '') => {
  const option = list.find((item) => item.value === value) || { value: '', label: '' };
  return formatOption(option.value, option.label);
};

const dateOptions = [
  { value: 'DD/MM/YYYY', label: 'DD / MM / YYYY' },
  { value: 'MM/DD/YYYY', label: 'MM / DD / YYYY' },
  { value: 'YYYY/MM/DD', label: 'YYYY / MM / DD' },
  { value: 'DD/MM/YY', label: 'DD / MM / YY' },
  { value: 'MM/DD/YY', label: 'MM / DD / YY' },
  { value: 'YY/MM/DD', label: 'YY / MM / DD' }
];

const landingOptions = [
  { value: 'overview', label: 'Overview' },
  { value: 'explore', label: 'Explore' },
  { value: 'validity', label: 'Validity' },
  { value: 'referrals', label: 'Referrals' }
];

const timezoneOptions = compose(
  map((name) => ({ value: name, label: name })),
  filter((name) => {
    const noEtc = name.match(/^((?!Etc).)*$/g);
    const requireSlash = noEtc ? noEtc[0].match(/([/]+)/g) : null;
    return requireSlash ? { value: requireSlash[0], label: requireSlash[0] } : null;
  })
)(momentTz.tz.names());

type Option = { value: string; label: string };
type Props = {
  userSettings: { currency: string; timezone: string; date: string; landing: string };
  saveUserSettings: (settings: Record<string, any>) => void;
  currenciesApi: Option[];
};

const AccountSettings = ({
  userSettings: {
    currency: currencyApi = 'GBP',
    timezone: timezoneApi = 'Europe/London',
    date: dateApi,
    landing: landingApi = 'overview'
  },
  saveUserSettings,
  currenciesApi
}: Props) => {
  const safeLandingApi = landingApi.replace('smartercodes/', '');
  const [currencyOptions, setCurrencyOptions]: [Option[] | [], Function] = useState([]);
  const [currencyActive, setCurrencyActive] = useState<{ value: string; label: string; } | undefined>();
  const [timezoneActive, setTimezoneActive] = useState(findOption(timezoneOptions, timezoneApi));
  const [dateActive, setDateActive] = useState(
    dateApi ? findOption(dateOptions, dateApi) : findOption(dateOptions, 'DD/MM/YYYY')
  );
  const [landingActive, setLandingActive] = useState(
    findOption(landingOptions, safeLandingApi) || {
      value: 'overview',
      label: 'Overview'
    }
  );

  useOnce(() => {
    setCurrencyOptions(currenciesApi);
    setCurrencyActive(findOption(currenciesApi, currencyApi));
  }, [currenciesApi.length]);

  return (
    <Card>
      <CardBody>
        <h4 style={{ display: 'flex', alignItems: 'center' }}>
          <span>Settings</span>
        </h4>

        <Select
          label="Date format"
          name="date-format"
          onChange={(target) => {
            setDateActive(target);
            saveUserSettings({ date: target.value });
          }}
          options={dateOptions}
          styles={standardStyles}
          value={dateActive}
        />

        <Select
          label="Landing page"
          name="landing-page"
          onChange={(target) => {
            setLandingActive(target);
            saveUserSettings({ landing: target.value });
          }}
          options={landingOptions}
          styles={standardStyles}
          value={landingActive}
        />

        <Select
          label="Currency"
          name="currency"
          onChange={(option) => {
            const { value, symbol } = option;

            setCurrencyActive(option);
            saveUserSettings({ currency: value, currency_symbol: symbol });
          }}
          options={currencyOptions}
          styles={standardStyles}
          value={currencyActive}
        />

        <Select
          containerClassName=""
          label="Timezone"
          name="timezone"
          onChange={(target) => {
            setTimezoneActive(target);
            saveUserSettings({ timezone: target.value });
          }}
          options={timezoneOptions}
          styles={standardStyles}
          value={timezoneActive}
        />
      </CardBody>
    </Card>
  );
};

export default AccountSettings;
