import React from 'react';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';

const Terms = () => {
    return (
        <Row>
            <Helmet>
                <title>intent.ly - Terms &amp; Conditions</title>
            </Helmet>
            <Col xs="12">
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <h1>Software as a Service Subscription - Terms &amp; Conditions</h1>
                                <h4>
                                    <span>
                                        <strong>
                                            Software as a Service - Terms &amp; Conditions
                                        </strong>
                                    </span>
                                </h4>
                                <p>
                                    <strong>Terms of use (“Terms”)</strong>
                                </p>
                                <p>
                                    These Terms (together with the documents referred to herein)
                                    outline the terms on which you may make use of our SaaS product
                                    - Couponlytics.
                                </p>
                                <p>
                                    Please read these Terms carefully before you start to use our
                                    product, as these will apply to your use of our product. By
                                    using our product, you confirm that you accept these Terms and
                                    that you agree to comply with them.
                                </p>
                                <p>
                                    <strong>Information about us</strong>
                                </p>
                                <p>
                                    Couponlytics is a SaaS product operated by intent.ly Global
                                    Ltd. (“we”). We are registered in England and Wales under
                                    company number 06910868 and have our registered office at 30 Churchill Place, 7th Floor, Office 7-102, London, United Kingdom, E14 5RE.&nbsp;
                                </p>
                                <p>
                                    We have developed a range of software applications and platforms
                                    providing enhanced solutions to online businesses who wish to
                                    engage more customers on site, convert more transactions and
                                    understand the user journey of customers on site.
                                </p>
                                <p>
                                    These software applications are made available to subscribers
                                    via the internet on a monthly licence fee basis.&nbsp;
                                </p>
                                <h5>
                                    <span>
                                        <strong>Agreed terms</strong>
                                    </span>
                                </h5>
                                <p>
                                    <strong>1. Interpretation</strong>
                                </p>
                                <p>
                                    1.1 The definitions and rules of interpretation in this clause
                                    apply in this agreement.
                                </p>
                                <ul>
                                    <li style={{ listStyleType: 'none' }}>
                                        <ul>
                                            <li style={{ listStyleType: 'none' }}>
                                                <ul>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <ul>
                                                            <li>
                                                                <strong>Authorised Users</strong>:
                                                                those employees, agents and
                                                                independent contractors of the
                                                                Customer who are authorised by the
                                                                Customer to use the Services and the
                                                                Data, as further described in clause
                                                                2.2(d).
                                                            </li>
                                                            <li>
                                                                <strong>Business Day</strong>: a day
                                                                other than a Saturday, Sunday or
                                                                public holiday in England when banks
                                                                in London are open for business.
                                                            </li>
                                                            <li>
                                                                <strong>Case Studies</strong>: the
                                                                Case Studies of the Supplier,
                                                                provided to the Customer as part of
                                                                the Service.
                                                            </li>
                                                            <li>
                                                                <strong>
                                                                    Confidential Information
                                                                </strong>
                                                                : information that is proprietary or
                                                                confidential and is either clearly
                                                                labelled as such or identified as
                                                                Confidential Information in clause
                                                                10.6 or clause 10.7.
                                                            </li>
                                                            <li>
                                                                <strong>
                                                                    Controller, processor, data
                                                                    subject, personal data, personal
                                                                    data breach, processing and
                                                                    appropriate technical and
                                                                    organisational measures
                                                                </strong>
                                                                : as defined in the Data Protection
                                                                Legislation.
                                                            </li>
                                                            <li>
                                                                <strong>Customer Data</strong>: the
                                                                data inputted by the Customer,
                                                                Authorised Users, or the Supplier on
                                                                the Customer's behalf for the
                                                                purpose of using the Services or
                                                                facilitating the Customer's use of
                                                                the Services.
                                                            </li>
                                                            <li>
                                                                <strong>Data</strong>: the documents
                                                                made available to the Customer by
                                                                the Supplier online via
                                                                couponlytics.com or such other web
                                                                address notified by the Supplier to
                                                                the Customer from time to time which
                                                                sets out a description of the
                                                                Services, the user instructions for
                                                                the Services and the results of the
                                                                Service.
                                                            </li>
                                                            <li>
                                                                <strong>
                                                                    Data Protection Legislation
                                                                </strong>
                                                                : the UK Data Protection Legislation
                                                                and any other European Union
                                                                legislation relating to personal
                                                                data and all other legislation and
                                                                regulatory requirements in force
                                                                from time to time which apply to a
                                                                party relating to the use of
                                                                personal data (including, without
                                                                limitation, the privacy of
                                                                electronic communications);
                                                            </li>
                                                            <li>
                                                                <strong>Effective Date:</strong> the
                                                                date of this agreement.
                                                            </li>
                                                            <li>
                                                                <strong>
                                                                    Initial Subscription Term
                                                                </strong>
                                                                : the initial term of this agreement
                                                                as set out in Schedule 2.
                                                            </li>
                                                            <li>
                                                                <strong>
                                                                    Normal Business Hours
                                                                </strong>
                                                                : 8.00 am to 6.00 pm local UK time,
                                                                each Business Day.
                                                            </li>
                                                            <li>
                                                                <strong>Renewal Period</strong>: the
                                                                period described in clause 13.1.
                                                            </li>
                                                            <li>
                                                                <strong>Services</strong>: the
                                                                subscription services provided by
                                                                the Supplier to the Customer under
                                                                this agreement via couponlytics.com
                                                                or any other website notified to the
                                                                Customer by the Supplier from time
                                                                to time.
                                                            </li>
                                                            <li>
                                                                <strong>Software</strong>: the
                                                                online software applications
                                                                provided by the Supplier as part of
                                                                the Services.
                                                            </li>
                                                            <li>
                                                                <strong>Subscription Fees:</strong>{' '}
                                                                the subscription fees payable by the
                                                                Customer to the Supplier for the
                                                                User Subscriptions, as set out in
                                                                paragraph 1 of Schedule 1.
                                                            </li>
                                                            <li>
                                                                <strong>Subscription Term</strong>:
                                                                has the meaning given in clause 13.1
                                                                (being the Initial Subscription Term
                                                                together with any subsequent Renewal
                                                                Periods).
                                                            </li>
                                                            <li>
                                                                <strong>
                                                                    UK Data Protection Legislation
                                                                </strong>
                                                                : all applicable data protection and
                                                                privacy legislation in force from
                                                                time to time in the UK including the
                                                                General Data Protection Regulation
                                                                ((EU) 2016/679); the Data Protection
                                                                Act 2018; the Privacy and Electronic
                                                                Communications Directive 2002/58/EC
                                                                (as updated by Directive
                                                                2009/136/EC) and the Privacy and
                                                                Electronic Communications
                                                                Regulations 2003 (SI 2003/2426) as
                                                                amended.
                                                            </li>
                                                            <li>
                                                                <strong>User Subscriptions</strong>:
                                                                the user subscriptions purchased by
                                                                the Customer pursuant to clause 8.1
                                                                which entitle Authorised Users to
                                                                access and use the Services and the
                                                                Data in accordance with this
                                                                agreement.
                                                            </li>
                                                            <li>
                                                                <strong>Virus</strong>: any thing or
                                                                device (including any software,
                                                                code, file or programme) which may:
                                                                prevent, impair or otherwise
                                                                adversely affect the operation of
                                                                any computer software, hardware or
                                                                network, any telecommunications
                                                                service, equipment or network or any
                                                                other service or device; prevent,
                                                                impair or otherwise adversely affect
                                                                access to or the operation of any
                                                                programme or data, including the
                                                                reliability of any programme or
                                                                data(whether by re-arranging,
                                                                altering or erasing the programme or
                                                                data in whole or part or otherwise);
                                                                or adversely affect the user
                                                                experience, including worms, trojan
                                                                horses, viruses and other similar
                                                                things or devices.
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>
                                    1.2 &nbsp;Clause, schedule and paragraph headings shall not
                                    affect the interpretation of this agreement.
                                </p>
                                <p>
                                    1.3 &nbsp;A person includes an individual, corporate or
                                    unincorporated body (whether or not having separate legal
                                    personality) [and that person's legal and personal
                                    representatives, successors or permitted assigns].
                                </p>
                                <p>
                                    1.4 &nbsp;A reference to a company shall include any company,
                                    corporation or other body corporate, wherever and however
                                    incorporated or established.
                                </p>
                                <p>
                                    1.5 &nbsp;Unless the context otherwise requires, words in the
                                    singular shall include the plural and in the plural shall
                                    include the singular.
                                </p>
                                <p>
                                    1.6 &nbsp;Unless the context otherwise requires, a reference to
                                    one gender shall include a reference to the other genders.
                                </p>
                                <p>
                                    1.7 &nbsp;A reference to a statute or statutory provision is a
                                    reference to it as it is in force as at the date of this
                                    agreement.
                                </p>
                                <p>
                                    1.8 &nbsp;A reference to a statute or statutory provision shall
                                    include all subordinate legislation made as at the date of this
                                    agreement under that statute or statutory provision.
                                </p>
                                <p>
                                    1.9 &nbsp;A reference to writing or written includes faxes but
                                    not e-mail.
                                </p>
                                <p>
                                    1.10 &nbsp;References too clauses and schedules are to the
                                    clauses and schedules of this agreement; references to
                                    paragraphs are to paragraphs of the relevant schedule to this
                                    agreement.
                                </p>
                                <p>
                                    <strong>2. User subscriptions</strong>
                                </p>
                                <p>
                                    2.1 &nbsp;Subject to the Customer purchasing the User
                                    Subscriptions in accordance with clause 3 and clause 8.1, the
                                    restrictions set out in this clause 2 and the other terms and
                                    conditions of this agreement, the Supplier hereby grants to the
                                    Customer a non- exclusive, non-transferable right, without the
                                    right to grant sub-licences, to permit the Authorised Users to
                                    use the Services and the Data during the Subscription Term
                                    solely for the Customer's business operations.
                                </p>
                                <p>
                                    2.2 &nbsp;In relation to the Authorised Users, the Customer
                                    undertakes that:
                                </p>
                                <p>
                                    (a) &nbsp;the maximum number of Authorised Users that it
                                    authorises to access and use the Services and the Data shall not
                                    exceed the number of User Subscriptions it has purchased from
                                    time to time;
                                </p>
                                <p>
                                    (b) &nbsp;it will not allow or suffer any User Subscription to
                                    be used by more than one individual Authorised User unless it
                                    has been reassigned in its entirety to another individual
                                    Authorised User, in which case the prior Authorised User shall
                                    no longer have any right to access or use the Services and/or
                                    Data;
                                </p>
                                <p>
                                    (c) &nbsp;each Authorised User shall keep a secure password for
                                    his use of the Services and Data, that such password shall be
                                    changed no less frequently than monthly and that each Authorised
                                    User shall keep his password confidential;
                                </p>
                                <p>
                                    (d) &nbsp;it shall maintain a written, up to date list of
                                    current Authorised Users and provide such list to the Supplier
                                    within 5 Business Days of the Supplier's written request at any
                                    time or times;
                                </p>
                                <p>
                                    (e) &nbsp;it shall permit the Supplier or the Supplier's
                                    designated auditor to audit the Services in order to establish
                                    the name and password of each Authorised User and the Supplier's
                                    data processing facilities to audit compliance with this
                                    agreement. Each such audit may be conducted no more than once
                                    per quarter, at the Supplier's expense, and this right shall be
                                    exercised with reasonable prior notice, in such a manner as not
                                    to substantially interfere with the Customer's normal conduct of
                                    business;
                                </p>
                                <p>
                                    (f) &nbsp;if any of the audits referred to in clause 2.2(e)
                                    reveal that any password has been provided to any individual who
                                    is not an Authorised User, then without prejudice to the
                                    Supplier's other rights, the Customer shall promptly disable
                                    such passwords and the Supplier shall not issue any new
                                    passwords to any such individual; and
                                </p>
                                <p>
                                    (g) &nbsp;if any of the audits referred to in clause 2.2(e)
                                    reveal that the Customer has underpaid Subscription Fees to the
                                    Supplier, then without prejudice to the Supplier's other rights,
                                    the Customer shall pay to the Supplier an amount equal to such
                                    underpayment as calculated in accordance with the prices set out
                                    in paragraph 1 of Schedule 1 within 10 Business Days of the date
                                    of the relevant audit.
                                </p>
                                <p>
                                    2.3 The Customer shall not access, store, distribute or transmit
                                    any Viruses, or any material during the course of its use of the
                                    Services that:
                                </p>
                                <p>
                                    (a) &nbsp;is unlawful, harmful, threatening, defamatory,
                                    obscene, infringing, harassing or racially or ethnically
                                    offensive
                                </p>
                                <p>(b) &nbsp;facilitates illegal activity;</p>
                                <p>(c) &nbsp;depicts sexually explicit images;</p>
                                <p>(d) &nbsp;promotes unlawful violence;</p>
                                <p>
                                    (e) is discriminatory based on race, gender, colour, religious
                                    belief, sexual orientation, disability; or
                                </p>
                                <p>
                                    (f) is otherwise illegal or causes damage or injury to any
                                    person or property;
                                </p>
                                <p>
                                    and the Supplier reserves the right, without liability or
                                    prejudice to its other rights to the Customer, to disable the
                                    Customer's access to any material that breaches the provisions
                                    of this clause.
                                </p>
                                <p>2.4 &nbsp;The Customer shall not:</p>
                                <p>
                                    (a) &nbsp;except as may be allowed by any applicable law which
                                    is incapable of exclusion by agreement between the parties and
                                    except to the extent expressly permitted under this agreement:
                                </p>
                                <p>
                                    (i) &nbsp;attempt to copy, modify, duplicate, create derivative
                                    works from, frame, mirror, republish, download, display,
                                    transmit, or distribute all or any portion of the Software
                                    and/or Data (as applicable) in any form or media or by any
                                    means; or
                                </p>
                                <p>
                                    (ii) &nbsp;attempt to de-compile, reverse compile, disassemble,
                                    reverse engineer or otherwise reduce to human-perceivable form
                                    all or any part of the Software; or
                                </p>
                                <p>
                                    (b) &nbsp;access all or any part of the Services and Data in
                                    order to build a product or service which competes with the
                                    Services and/or the Data; or
                                </p>
                                <p>
                                    (c) &nbsp;use the Services and/or Data to provide services to
                                    third parties; or
                                </p>
                                <p>
                                    (d) &nbsp;subject to clause 22.1, license, sell, rent, lease,
                                    transfer, assign, distribute, display, disclose, or otherwise
                                    commercially exploit, or otherwise make the Services and/or Data
                                    available to any third party except the Authorised Users, or
                                </p>
                                <p>
                                    (e) &nbsp;attempt to obtain, or assist third parties in
                                    obtaining, access to the Services and/or Data, other than as
                                    provided under this clause 2; and
                                </p>
                                <p>
                                    2.5 &nbsp;The Customer shall use best endeavours to prevent any
                                    unauthorised access to, or use of, the Services and/or the Data
                                    and, in the event of any such unauthorised access or use,
                                    promptly notify the Supplier.
                                </p>
                                <p>
                                    2.6 &nbsp;The rights provided under this clause 2 are granted to
                                    the Customer only, and shall not be considered granted to any
                                    subsidiary or holding company of the Customer.
                                </p>
                                <p>
                                    <strong>3. Additional user subscriptions</strong>
                                </p>
                                <p>
                                    3.1 Subject to clause 3.2 and clause 3.3, the Customer may, from
                                    time to time during any Subscription Term, purchase additional
                                    User Subscriptions in excess of the number set out in paragraph
                                    1 of Schedule 1 and the Supplier shall grant access to the
                                    Services and the Data to such additional Authorised Users in
                                    accordance with the provisions of this agreement.
                                </p>
                                <p>
                                    3.2 &nbsp;If the Customer wishes to purchase additional User
                                    Subscriptions, the Customer shall notify the Supplier in
                                    writing. The Supplier shall evaluate such request for additional
                                    User Subscriptions and respond to the Customer with approval or
                                    rejection of the request [(such approval not to be unreasonably
                                    withheld)]. Where the Supplier approves the request, the
                                    Supplier shall activate the additional User Subscriptions within
                                    [NUMBER] days of its approval of the Customer's request.
                                </p>
                                <p>
                                    3.3 &nbsp;If the Supplier approves the Customer's request to
                                    purchase additional User Subscriptions, the Customer shall,
                                    within 30 days of the date of the Supplier's invoice, pay to the
                                    Supplier the relevant fees for such additional User
                                    Subscriptions as set out in paragraph 2 of Schedule 1 and, if
                                    such additional User Subscriptions are purchased by the Customer
                                    part way through the Initial Subscription Term or any Renewal
                                    Period (as applicable), such fees shall be pro-rated from the
                                    date of activation by the Supplier for the remainder of the
                                    Initial Subscription Term or then current Renewal Period (as
                                    applicable).
                                </p>
                                <p>
                                    <strong>4. Services</strong>
                                </p>
                                <p>
                                    4.1 &nbsp;The Supplier shall, during the Subscription Term,
                                    provide the Services and make available the Data to the Customer
                                    on and subject to the terms of this agreement.
                                </p>
                                <p>
                                    4.2 &nbsp;The Supplier shall use commercially reasonable
                                    endeavours to make the Services available 24 hours a day, seven
                                    days a week, except for:
                                </p>
                                <p>
                                    (a) &nbsp;planned maintenance carried out during the maintenance
                                    window of 10.00 pm to 2.00 am UK time; and
                                </p>
                                <p>
                                    (b) &nbsp;unscheduled maintenance performed outside Normal
                                    Business Hours, provided that the Supplier has used reasonable
                                    endeavours to give the Customer at least 6 Normal Business
                                    Hours' notice in advance.
                                </p>
                                <p>
                                    {' '}
                                    <strong>5. Customer data</strong>
                                </p>
                                <p>
                                    5.1 &nbsp;Both parties will comply with all applicable
                                    requirements of the Data Protection Legislation. This clause 5
                                    is in addition to, and does not relieve, remove or replace, a
                                    party's obligations or rights under the Data Protection
                                    Legislation.
                                </p>
                                <p>
                                    5.2 &nbsp;The parties acknowledge that the Supplier in
                                    performing the services does not process any personal data. if
                                    the Supplier processes any personal data on the Customer's
                                    behalf when performing its obligations under this agreement, the
                                    Customer is the controller and the Supplier is the processor for
                                    the purposes of the Data Protection Legislation.
                                </p>
                                <p>
                                    <strong>6. Supplier's obligations</strong>
                                </p>
                                <p>
                                    6.1 &nbsp;The Supplier undertakes that the Services will be
                                    performed with reasonable skill and care.
                                </p>
                                <p>
                                    6.2 &nbsp;The undertaking at clause 6.1 shall not apply to the
                                    extent of any non-conformance which is caused by use of the
                                    Services contrary to the Supplier's instructions, or
                                    modification or alteration of the Services by any party other
                                    than the Supplier or the Supplier's duly authorised contractors
                                    or agents. If the Services do not conform with the foregoing
                                    undertaking, Supplier will, at its expense, use all reasonable
                                    commercial endeavours to correct any such non-conformance
                                    promptly, or provide the Customer with an alternative means of
                                    accomplishing the desired performance. Such correction or
                                    substitution constitutes the Customer's sole and exclusive
                                    remedy for any breach of the undertaking set out in clause 6.1.
                                    Notwithstanding the foregoing, the Supplier:
                                </p>
                                <p>
                                    (a) &nbsp;does not warrant that the Customer's use of the
                                    Services will be uninterrupted or error-free; or that the
                                    Services, Data and/or the information obtained by the Customer
                                    through the Services will meet the Customer's requirements; and
                                </p>
                                <p>
                                    (b) &nbsp;is not responsible for any delays, delivery failures,
                                    or any other loss or damage resulting from the transfer of data
                                    over communications networks and facilities, including the
                                    internet, and the Customer acknowledges that the Services and
                                    Data may be subject to limitations, delays and other problems
                                    inherent in the use of such communications facilities.
                                </p>
                                <p>
                                    6.3 &nbsp;This agreement shall not prevent the Supplier from
                                    entering into similar agreements with third parties, and from
                                    licensing the Software and providing the Services to third
                                    parties nor from independently developing, using, selling or
                                    licensing Data, products and/or services which are similar to
                                    those provided under this agreement.
                                </p>
                                <p>
                                    6.4 &nbsp;The Supplier warrants that it has and will maintain
                                    all necessary licences, consents, and permissions necessary for
                                    the performance of its obligations under this agreement.
                                </p>
                                <p>
                                    <strong>7. Customer's obligations</strong>
                                </p>
                                <p>7.1&nbsp; &nbsp;The Customer shall:</p>
                                <p>(a) provide the Supplier with:</p>
                                <p>
                                    (i) all necessary co-operation in relation to this agreement;
                                    and
                                </p>
                                <p>
                                    (ii) all necessary access to such information as may be required
                                    by the Supplier; in order to provide the Services, including but
                                    not limited to Customer Data, security access information and
                                    configuration services;
                                </p>
                                <p>
                                    (b) &nbsp;without affecting its other obligations under this
                                    agreement, comply with all applicable laws and regulations with
                                    respect to its activities under this agreement;
                                </p>
                                <p>
                                    (c) &nbsp;carry out all other Customer responsibilities set out
                                    in this agreement in a timely and efficient manner. In the event
                                    of any delays in the Customer's provision of such assistance as
                                    agreed by the parties, the Supplier may adjust any agreed
                                    timetable or delivery schedule as reasonably necessary;
                                </p>
                                <p>
                                    (d) &nbsp;ensure that the Authorised Users use the Services and
                                    the Data in accordance with the terms and conditions of this
                                    agreement and shall be responsible for any Authorised User's
                                    breach of this agreement;
                                </p>
                                <p>
                                    (e) &nbsp;obtain and shall maintain all necessary licences,
                                    consents, and permissions necessary for the Supplier, its
                                    contractors and agents to perform their obligations under this
                                    agreement, including without limitation the Services;
                                </p>
                                <p>
                                    (f) &nbsp;ensure that its network and systems comply with the
                                    relevant specifications provided by the Supplier from time to
                                    time; and
                                </p>
                                <p>
                                    (g) &nbsp;be, to the extent permitted by law and except as
                                    otherwise expressly provided in this agreement, solely
                                    responsible for procuring, maintaining and securing its network
                                    connections and telecommunications links from its systems to the
                                    Supplier's data centres, and all problems, conditions, delays,
                                    delivery failures and all other loss or damage arising from or
                                    relating to the Customer's network connections or
                                    telecommunications links or caused by the internet.
                                </p>
                                <p>
                                    <strong>8. Charges and payment</strong>
                                </p>
                                <p>
                                    8.1 &nbsp;The Customer shall pay the Subscription Fees to the
                                    Supplier for the User Subscriptions in accordance with this
                                    clause 8 and Schedule 1
                                </p>
                                <p>
                                    8.2 &nbsp;The Customer shall on the Effective Date provide to
                                    the Supplier valid, up-to-date and complete credit card details
                                    or approved purchase order information acceptable to the
                                    Supplier and any other relevant valid, up-to-date and complete
                                    contact and billing details and, if the Customer provides:
                                </p>
                                <p>
                                    (a) its credit card details to the Supplier, the Customer hereby
                                    authorises the Supplier to bill such credit card:
                                </p>
                                <p>
                                    (i) on the Effective Date for the Subscription Fees payable in
                                    respect of the Initial Subscription Term; and
                                </p>
                                <p>
                                    (ii) subject to clause 13.1, on each anniversary of the
                                    Effective Date for the Subscription Fees payable in respect of
                                    the next Renewal Period;
                                </p>
                                <p>
                                    (b) its approved purchase order information to the Supplier, the
                                    Supplier shall invoice the Customer:
                                </p>
                                <p>
                                    (i) &nbsp;on the Effective Date for the Subscription Fees
                                    payable in respect of the Initial Subscription Term; and
                                </p>
                                <p>
                                    (ii) &nbsp;subject to clause 13.1, at least 30 days prior to
                                    each anniversary of the Effective Date for the Subscription Fees
                                    payable in respect of the next Renewal Period,
                                </p>
                                <p>
                                    and the Customer shall pay each invoice within 30 days after the
                                    date of such invoice.
                                </p>
                                <p>
                                    8.3 &nbsp;If the Supplier has not received payment within 30
                                    days after the due date, and without prejudice to any other
                                    rights and remedies of the Supplier:
                                </p>
                                <p>
                                    (a) &nbsp;the Supplier may, without liability to the Customer,
                                    disable the Customer's password, account and access to all or
                                    part of the Services and the Supplier shall be under no
                                    obligation to provide any or all of the Services while the
                                    invoice(s) concerned remain unpaid; and
                                </p>
                                <p>
                                    (b) &nbsp;interest shall accrue on a daily basis on such due
                                    amounts at an annual rate equal to 3% over the then current base
                                    lending rate of [the Supplier's bankers in the UK] from time to
                                    time, commencing on the due date and continuing until fully
                                    paid, whether before or after judgment.
                                </p>
                                <p>
                                    8.4 &nbsp;All amounts and fees stated or referred to in this
                                    agreement:
                                </p>
                                <p>(a) &nbsp;shall be payable in pounds sterling;</p>
                                <p>(b) &nbsp;are, non-cancellable and non-refundable;</p>
                                <p>
                                    (c) &nbsp;are exclusive of value added tax, which shall be added
                                    to the Supplier's invoice(s) at the appropriate rate.
                                </p>

                                <p>
                                    8.5 &nbsp;The Supplier shall be entitled to increase the
                                    Subscription Fees, the fees payable in respect of the additional
                                    User Subscriptions purchased pursuant to clause , at the start
                                    of each Renewal Period upon 90 days' prior notice to the
                                    Customer and shall be deemed to have been amended accordingly.
                                </p>
                                <p>
                                    <strong>9. Proprietary rights</strong>
                                </p>
                                <p>
                                    9.1 The Customer acknowledges and agrees that the Supplier owns
                                    all intellectual property rights in the Services and the Data.
                                    Except as expressly stated herein, this agreement does not grant
                                    the Customer any rights to, under or in, any patents, copyright,
                                    database right, trade secrets, trade names, trade marks (whether
                                    registered or unregistered), or any other rights or licences in
                                    respect of the Services or the Data.
                                </p>
                                <p>
                                    9.2 The Supplier confirms that it has all the rights in relation
                                    to the Services and the Data that are necessary to grant all the
                                    rights it purports to grant under, and in accordance with, the
                                    terms of this agreement.
                                </p>
                                <p>
                                    <strong>10. Confidentiality</strong>
                                </p>
                                <p>
                                    10.1 &nbsp;Each party may be given access to Confidential
                                    Information from the other party in order to perform its
                                    obligations under this agreement. A party's Confidential
                                    Information shall not be deemed to include information that:
                                </p>
                                <p>
                                    (a) &nbsp;is or becomes publicly known other than through any
                                    act or omission of the receiving party;
                                </p>
                                <p>
                                    (b) &nbsp;was in the other party's lawful possession before the
                                    disclosure;
                                </p>
                                <p>
                                    (c) &nbsp;is lawfully disclosed to the receiving party by a
                                    third party without restriction on disclosure; or
                                </p>
                                <p>
                                    (d) &nbsp;is independently developed by the receiving party,
                                    which independent development can be shown by written evidence.
                                </p>
                                <p>
                                    10.2 &nbsp;Subject to clause 10.4, each party shall hold the
                                    other's Confidential Information in confidence and not make the
                                    other's Confidential Information available to any third party,
                                    or use the other's Confidential Information for any purpose
                                    other than the implementation of this agreement.
                                </p>
                                <p>
                                    10.3 &nbsp;Each party shall take all reasonable steps to ensure
                                    that the other's Confidential Information to which it has access
                                    is not disclosed or distributed by its employees or agents in
                                    violation of the terms of this agreement.
                                </p>
                                <p>
                                    10.4 &nbsp;A party may disclose Confidential Information to the
                                    extent such Confidential Information is required to be disclosed
                                    by law, by any governmental or other regulatory authority or by
                                    a court or other authority of competent jurisdiction, provided
                                    that, to the extent it is legally permitted to do so, it gives
                                    the other party as much notice of such disclosure as possible
                                    and, where notice of disclosure is not prohibited and is given
                                    in accordance with this clause 10.4, it takes into account the
                                    reasonable requests of the other party in relation to the
                                    content of such disclosure.
                                </p>
                                <p>
                                    10.5 &nbsp;Neither party shall be responsible for any loss,
                                    destruction, alteration or disclosure of Confidential
                                    Information caused by any third party
                                </p>
                                <p>
                                    10.6 &nbsp;The Customer acknowledges that details of the
                                    Services, and the results of any performance tests of the
                                    Services, and (without limitation) Case Studies constitute the
                                    Supplier's Confidential Information and Intellectual Property.
                                </p>
                                <p>
                                    10.7 &nbsp;The Supplier acknowledges that the Customer Data is
                                    the Confidential Information of the Customer.
                                </p>
                                <p>
                                    10.8 &nbsp;No party shall make, or permit any person to make,
                                    any public announcement concerning this agreement without the
                                    prior written consent of the other parties (such consent not to
                                    be unreasonably withheld or delayed), except as required by law,
                                    any governmental or regulatory authority (including, without
                                    limitation, any relevant securities exchange), any court or
                                    other authority of competent jurisdiction.
                                </p>
                                <p>
                                    10.9 &nbsp;The above provisions of this clause 10 shall survive
                                    termination of this agreement, however arising.
                                </p>
                                <p>
                                    {' '}
                                    <strong>11. Indemnity</strong>
                                </p>
                                <p>
                                    11.1 The Customer shall defend, indemnify and hold harmless the
                                    Supplier against claims, actions, proceedings, losses, damages,
                                    expenses and costs (including without limitation court costs and
                                    reasonable legal fees) arising out of or in connection with the
                                    Customer's use of the Services and/or Data, provided that:
                                </p>
                                <p>
                                    (a) &nbsp;the Customer is given prompt notice of any such claim;
                                </p>
                                <p>
                                    (b) &nbsp;the Supplier provides reasonable co-operation to the
                                    Customer in the defence and settlement of such claim, at the
                                    Customer's expense; and
                                </p>
                                <p>
                                    (c) &nbsp;the Customer is given sole authority to defend or
                                    settle the claim.
                                </p>
                                <p>
                                    <strong>12. Limitation of liability</strong>
                                </p>
                                <p>
                                    12.1 Except as expressly and specifically provided in this
                                    agreement:
                                </p>
                                <p>
                                    (a) &nbsp;the Customer assumes sole responsibility for results
                                    obtained from the use of the Services and the Data by the
                                    Customer, and for conclusions drawn from such use. The Supplier
                                    shall have no liability for any damage caused by errors or
                                    omissions in any information, instructions or scripts provided
                                    to the Supplier by the Customer in connection with the Services,
                                    or any actions taken by the Supplier at the Customer's
                                    direction;
                                </p>
                                <p>
                                    (b) &nbsp;all warranties, representations, conditions and all
                                    other terms of any kind whatsoever implied by statute or common
                                    law are, to the fullest extent permitted by applicable law,
                                    excluded from this agreement; and
                                </p>
                                <p>
                                    (c) &nbsp;the Services and the Data are provided to the Customer
                                    on an "as is" basis. 12
                                </p>
                                <p>
                                    12.2 &nbsp;Nothing in this agreement excludes the liability of
                                    the Supplier:
                                </p>
                                <p>
                                    (a) &nbsp;for death or personal injury caused by the Supplier's
                                    negligence; or
                                </p>
                                <p>(b) &nbsp;for fraud or fraudulent misrepresentation.</p>
                                <p>12.3 &nbsp;Subject to clause 12.1 and clause 12.2:</p>
                                <p>
                                    (a) &nbsp;the Supplier shall not be liable whether in tort
                                    (including for negligence or breach of statutory duty),
                                    contract, misrepresentation, restitution or otherwise for any
                                    loss of profits, loss of business, depletion of goodwill and/or
                                    similar losses or loss or corruption of data or information, or
                                    pure economic loss, or for any special, indirect or
                                    consequential loss, costs, damages, charges or expenses however
                                    arising under this agreement; and
                                </p>
                                <p>
                                    (b) &nbsp;the Supplier's total aggregate liability in contract,
                                    tort (including negligence or breach of statutory duty),
                                    misrepresentation, restitution or otherwise, arising in
                                    connection with the performance or contemplated performance of
                                    this agreement shall be limited to the total Subscription Fees
                                    paid for the User Subscriptions during the 12 months immediately
                                    preceding the date on which the claim arose.
                                </p>
                                <p>
                                    <strong>13. Term and termination</strong>
                                </p>
                                <p>
                                    13.1 &nbsp;This agreement shall, unless otherwise terminated as
                                    provided in this clause 13, commence on the Effective Date and
                                    shall continue for the Initial Subscription Term and,
                                    thereafter, this agreement shall be automatically renewed for
                                    successive periods of 12 months (each a Renewal Period), unless:
                                </p>
                                <p>
                                    (a) &nbsp;either party notifies the other party of termination,
                                    in writing, at least 60 days before the end of the Initial
                                    Subscription Term or any Renewal Period, in which case this
                                    agreement shall terminate upon the expiry of the applicable
                                    Initial Subscription Term or Renewal Period; or
                                </p>
                                <p>
                                    (b) &nbsp;otherwise terminated in accordance with the provisions
                                    of this agreement;
                                </p>
                                <p>
                                    and the Initial Subscription Term together with any subsequent
                                    Renewal Periods shall constitute the Subscription Term.
                                </p>
                                <p>
                                    13.2 &nbsp;Without affecting any other right or remedy available
                                    to it, either party may terminate this agreement with immediate
                                    effect by giving written notice to the other party if:
                                </p>
                                <p>
                                    (a) the other party fails to pay any amount due under this
                                    agreement on the due date for payment and remains in default not
                                    less than 31 days after being notified in writing to make such
                                    payment;
                                </p>
                                <p>
                                    (b) &nbsp;the other party commits a material breach of any other
                                    term of this agreement which breach is irremediable or (if such
                                    breach is remediable) fails to remedy that breach within a
                                    period of 31 days after being notified in writing to do so;
                                </p>
                                <p>
                                    (c) &nbsp;the other party suspends, or threatens to suspend,
                                    payment of its debts or is unable to pay its debts as they fall
                                    due or admits inability to pay its debts or is deemed unable to
                                    pay its debts within the meaning of section 123 of the
                                    Insolvency Act 1986, as if the words "it is proved to the
                                    satisfaction of the court" did not appear in sections 123(1)(e)
                                    or 123(2) of the Insolvency Act 1986;
                                </p>
                                <p>
                                    (d) &nbsp;the other party commences negotiations with all or any
                                    class of its creditors with a view to rescheduling any of its
                                    debts, or makes a proposal for or enters into any compromise or
                                    arrangement with its creditors other than for the sole purpose
                                    of a scheme for a solvent amalgamation of that other party with
                                    one or more other companies or the solvent reconstruction of
                                    that other party;
                                </p>
                                <p>
                                    (e) &nbsp;a petition is filed, a notice is given, a resolution
                                    is passed, or an order is made, for or in connection with the
                                    winding up of that other party other than for the sole purpose
                                    of a scheme for a solvent amalgamation of that other party with
                                    one or more other companies or the solvent reconstruction of
                                    that other party;
                                </p>
                                <p>
                                    (f) &nbsp;an application is made to court, or an order is made,
                                    for the appointment of an administrator, or if a notice of
                                    intention to appoint an administrator is given or if an
                                    administrator is appointed, over the other party;
                                </p>
                                <p>
                                    (g) &nbsp;the holder of a qualifying floating charge over the
                                    assets of that other party has become entitled to appoint or has
                                    appointed an administrative receiver;
                                </p>
                                <p>
                                    (h) &nbsp;a person becomes entitled to appoint a receiver over
                                    the assets of the other party or a receiver is appointed over
                                    the assets of the other party;
                                </p>
                                <p>
                                    (i) &nbsp;a creditor or encumbrancer of the other party attaches
                                    or takes possession of, or a distress, execution, sequestration
                                    or other such process is levied or enforced on or sued against,
                                    the whole or any part of the other party's assets and such
                                    attachment or process is not discharged within 14 days;
                                </p>
                                <p>
                                    (j) &nbsp;any event occurs, or proceeding is taken, with respect
                                    to the other party in any jurisdiction to which it is subject
                                    that has an effect equivalent or similar to any of the events
                                    mentioned in clause 13.2(c) to clause 13.2(i) (inclusive);
                                </p>
                                <p>
                                    (k) &nbsp;the other party suspends or ceases, or threatens to
                                    suspend or cease, carrying on all or a substantial part of its
                                    business.
                                </p>
                                <p>13.3 On termination of this agreement for any reason:</p>
                                <p>
                                    (a) all licences granted under this agreement shall immediately
                                    terminate and the Customer shall immediately cease all use of
                                    the Services and/or the Data;
                                </p>
                                <p>
                                    (b) &nbsp;each party shall return and make no further use of any
                                    equipment, property, Data and other items (and all copies of
                                    them) belonging to the other party;
                                </p>
                                <p>
                                    (c) &nbsp;the Supplier may destroy or otherwise dispose of any
                                    of the Customer Data in its possession unless the Supplier
                                    receives, no later than ten days after the effective date of the
                                    termination of this agreement, a written request for the
                                    delivery to the Customer of the then most recent back-up of the
                                    Customer Data. The Supplier shall use reasonable commercial
                                    endeavours to deliver the back- up to the Customer within 30
                                    days of its receipt of such a written request, provided that the
                                    Customer has, at that time, paid all fees and charges
                                    outstanding at and resulting from termination (whether or not
                                    due at the date of termination). The Customer shall pay all
                                    reasonable expenses incurred by the Supplier in returning or
                                    disposing of Customer Data; and
                                </p>
                                <p>
                                    (d) &nbsp;any rights, remedies, obligations or liabilities of
                                    the parties that have accrued up to the date of termination,
                                    including the right to claim damages in respect of any breach of
                                    the agreement which existed at or before the date of termination
                                    shall not be affected or prejudiced.
                                </p>
                                <p>
                                    <strong>14. Liquidated damages</strong>
                                </p>
                                <p>
                                    14.1 &nbsp;Save as provided in 14.2 (below) if the Customer
                                    seeks to terminate this Agreement for any reason before the end
                                    of the Initial Subscription Term (except as provided for in
                                    clause 13.2) or is otherwise in breach of this any of the terms
                                    of this Agreement, the Customer shall pay the Supplier, as
                                    liquidated damages, the outstanding Subscription Fee for the
                                    whole of the Subscription Term.
                                </p>
                                <p>
                                    14.2 &nbsp;If the Customer is in breach of Clause 4.2 the
                                    Customer shall pay to the Supplier, as Liquidated Damages a sum
                                    equal to 5 years Subscription Fee.
                                </p>
                                <p>
                                    14.3 The parties confirm that these liquidated damages are
                                    reasonable and proportionate to protect the Supplier’s
                                    legitimate interest.
                                </p>
                                <p>
                                    <strong>15. Force majeure</strong>
                                </p>
                                <p>
                                    The Supplier shall have no liability to the Customer under this
                                    agreement if it is prevented from or delayed in performing its
                                    obligations under this agreement, or from carrying on its
                                    business, by acts, events, omissions or accidents beyond its
                                    reasonable control, including, without limitation, strikes,
                                    lock-outs or other industrial disputes (whether involving the
                                    workforce of the Supplier or any other party), failure of a
                                    utility service or transport or telecommunications network, act
                                    of God, war, riot, civil commotion, malicious damage, compliance
                                    with any law or governmental order, rule, regulation or
                                    direction, accident, breakdown of plant or machinery, fire,
                                    flood, storm or default of suppliers or sub-contractors,
                                    provided that the Customer is notified of such an event and its
                                    expected duration.
                                </p>
                                <p>
                                    <strong>16.&nbsp; Conflict</strong>
                                </p>
                                <p>
                                    If there is an inconsistency between any of the provisions in
                                    the main body of this agreement and the Schedules, the
                                    provisions in the main body of this agreement shall prevail.
                                </p>
                                <p>
                                    <strong>17.&nbsp; Variation</strong>
                                </p>
                                <p>
                                    No variation of this agreement shall be effective unless it is
                                    in writing and signed by the parties (or their authorised
                                    representatives).
                                </p>
                                <p>
                                    <strong>18.&nbsp; Waiver</strong>
                                </p>
                                <p>
                                    No failure or delay by a party to exercise any right or remedy
                                    provided under this agreement or by law shall constitute a
                                    waiver of that or any other right or remedy, nor shall it
                                    prevent or restrict the further exercise of that or any other
                                    right or remedy. No single or partial exercise of such right or
                                    remedy shall prevent or restrict the further exercise of that or
                                    any other right or remedy.
                                </p>
                                <p>
                                    <strong>19.&nbsp; Rights and remedies</strong>
                                </p>
                                <p>
                                    Except as expressly provided in this agreement, the rights and
                                    remedies provided under this agreement are in addition to, and
                                    not exclusive of, any rights or remedies provided by law.
                                </p>
                                <p>
                                    <strong>20.&nbsp; Severance</strong>
                                </p>
                                <p>
                                    20.1 &nbsp;If any provision or part-provision of this agreement
                                    is or becomes invalid, illegal or unenforceable, it shall be
                                    deemed deleted, but that shall not affect the validity and
                                    enforceability of the rest of this agreement.
                                </p>
                                <p>
                                    20.2 &nbsp;If any provision or part-provision of this agreement
                                    is deemed deleted under clause 20.1 the parties shall negotiate
                                    in good faith to agree a replacement provision that, to the
                                    greatest extent possible, achieves the intended commercial
                                    result of the original provision.
                                </p>
                                <p>
                                    <strong>21. Entire agreement</strong>
                                </p>
                                <p>
                                    21.1 This agreement constitutes the entire agreement between the
                                    parties and supersedes and extinguishes all previous agreements,
                                    promises, assurances, warranties, representations and
                                    understandings between them, whether written or oral, relating
                                    to its subject matter.
                                </p>
                                <p>
                                    21.2 &nbsp;Each party acknowledges that in entering into this
                                    agreement it does not rely on, and shall have no remedies in
                                    respect of, any statement, representation, assurance or warranty
                                    (whether made innocently or negligently) that is not set out in
                                    this agreement.
                                </p>
                                <p>
                                    21.3 &nbsp;Each party agrees that it shall have no claim for
                                    innocent or negligent misrepresentation or negligent
                                    misstatement based on any statement in this agreement.
                                </p>
                                <p>
                                    21.4 &nbsp;Nothing in this clause shall limit or exclude any
                                    liability for fraud.
                                </p>
                                <p>
                                    <strong>22. Assignment</strong>
                                </p>
                                <p>
                                    22.1 &nbsp;The Customer shall not, without the prior written
                                    consent of the Supplier, assign, transfer, charge, sub-contract
                                    or deal in any other manner with all or any of its rights or
                                    obligations under this agreement.
                                </p>
                                <p>
                                    22.2 &nbsp;The Supplier may at any time assign, transfer,
                                    charge, sub-contract or deal in any other manner with all or any
                                    of its rights or obligations under this agreement.
                                </p>
                                <p>
                                    <strong>23.&nbsp; No partnership or agency</strong>
                                </p>
                                <p>
                                    Nothing in this agreement is intended to or shall operate to
                                    create a partnership between the parties, or authorise either
                                    party to act as agent for the other, and neither party shall
                                    have the authority to act in the name or on behalf of or
                                    otherwise to bind the other in any way (including, but not
                                    limited to, the making of any representation or warranty, the
                                    assumption of any obligation or liability and the exercise of
                                    any right or power).
                                </p>
                                <p>
                                    <strong>24.&nbsp; Third party rights</strong>
                                </p>
                                <p>
                                    This agreement does not confer any rights on any person or party
                                    (other than the parties to this agreement and, where applicable,
                                    their successors and permitted assigns) pursuant to the
                                    Contracts (Rights of Third Parties) Act 1999.
                                </p>
                                <p>
                                    <strong>25.&nbsp; Notices</strong>
                                </p>
                                <p>
                                    25.1 &nbsp;Any notice required to be given under this agreement
                                    shall be in writing and shall be delivered by hand or sent by
                                    pre-paid first-class post or recorded delivery post to the other
                                    party at its address set out in this agreement, or such other
                                    address as may have been notified by that party for such
                                    purposes.
                                </p>
                                <p>
                                    25.2 &nbsp;A notice delivered by hand shall be deemed to have
                                    been received when delivered (or if delivery is not in business
                                    hours, at 9 am on the first business day following delivery). A
                                    correctly addressed notice sent by pre-paid first-class post or
                                    recorded delivery post shall be deemed to have been received at
                                    the time at which it would have been delivered in the normal
                                    course of post.
                                </p>
                                <p>
                                    <strong>26.&nbsp; Governing law</strong>
                                </p>
                                <p>
                                    This agreement and any dispute or claim arising out of or in
                                    connection with it or its subject matter or formation (including
                                    non-contractual disputes or claims) shall be governed by and
                                    construed in accordance with the law of England and Wales.
                                </p>
                                <p />
                                <strong>26. Jurisdiction</strong>

                                <p>
                                    Each party irrevocably agrees that the courts of England and
                                    Wales shall have exclusive jurisdiction to settle any dispute or
                                    claim arising out of or in connection with this agreement or its
                                    subject matter or formation (including non-contractual disputes
                                    or claims).
                                </p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default Terms;
