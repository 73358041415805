import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools, EnhancerOptions } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { i18nTranslations } from '../i18n';
import createRootReducer from './reducers';

export const history = createBrowserHistory();
export const rootReducer = createRootReducer(history);

const preloadedState = {};

const middlewares = [thunkMiddleware, routerMiddleware(history)];
const middlewareEnhancer = applyMiddleware(...middlewares);

const options: EnhancerOptions = { trace: true };
const composedEnhancers = composeWithDevTools(options);

const store = createStore(rootReducer, preloadedState, composedEnhancers(middlewareEnhancer));

// i18n translations
syncTranslationWithStore(store);
store.dispatch(loadTranslations(i18nTranslations));

// TODO: move into settings page / trigger on user-data api response
store.dispatch(setLocale('en')); // default locale

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../redux/reducers', () => store.replaceReducer(rootReducer));
}
export { store };
