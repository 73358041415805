import React, { Fragment } from 'react';
import { useSelector as uSel } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { IMenuItem } from '../../../index.types';
// import VersionNumber from './VersionNumber/VersionNumber';
import './SideMenu.scss';
import { IAppStoreState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

type Props = {
  menu: IMenuItem[];
  queryString?: string;
};

const SideMenu = ({ menu }: Props): JSX.Element => {
  const userType = useSelector((state: IAppStoreState) => state.user?.user_type);
  const isSmarterClickUser = userType === 'smarterclick';
  const pathname = uSel((state: any) => state?.router?.location?.pathname);
  const search = uSel((state: any) => state?.router?.location?.search);

  const navItem = (item: IMenuItem, url: string, level: number) => {
    const badge = item.badge ? (
      <Badge color={item.badge.variant} pill>
        {item.badge.text}
      </Badge>
    ) : null;

    return (
      <Fragment key={JSON.stringify(item)}>
        <li
          className={`nav-item ${item.children ? 'nav-dropdown open' : ''}`}
          style={{ background: 'initial', marginLeft: `${level}rem` }}
        >
          <Link
            className={classNames('nav-link', pathname.startsWith(url + item.url) ? 'active' : '')}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            to={{ pathname: url + item.url, search: search }}
          >
            <span
              data-testid="nav-link"
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <span className="icon material-icons-outlined">{item.icon}</span>
              <span>{I18n.t(item.i18nKey)}</span>
            </span>

            {badge}
          </Link>
          {item.children && (
            <ul className="nav-dropdown-items">
              {item.children.map((childItem) => {
                if (childItem.smcUsersOnly) {
                  if (isSmarterClickUser) {
                    return navItem(childItem, item.url, level + 1);
                  } else {
                    return null;
                  }
                } else {
                  return navItem(childItem, item.url, level + 1);
                }
              })}
            </ul>
          )}
        </li>
      </Fragment>
    );
  };

  return (
    <div className="sidebar">
      <div className="nav">
        <ul>{menu.map((item) => navItem(item, '', 0))}</ul>
        {/*<VersionNumber />*/}
      </div>
    </div>
  );
};
export default SideMenu;
